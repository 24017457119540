import _ from 'lodash';

import { Account } from '@/@types/account';
import {
  PaginatedAccounts,
  PaginatedAccountsFilters,
} from '@/@types/account/paginatedAccount';
import { accountService } from '@/services';
import { AccountService } from '@/services/@types/account';
import { mapGetAccountsFiltersToApi } from '@/services/useCases/account/adapters/api';
import {
  mapAccountToDomain,
  mapPaginatedAccountsToDomain,
} from '@/services/useCases/account/adapters/domain';

export type GetAccount = {
  input: { id: string };
  output: Account;
};

export async function getAccount(
  input: GetAccount['input'],
): Promise<GetAccount['output']> {
  const { data } = await accountService.get<AccountService.Account>(
    `/accounts/${input.id}`,
  );

  return mapAccountToDomain(data);
}

export type GetAccountByIds = {
  input: { ids: string[] };
  output: Account[];
};

export async function getAccountByIds(
  input: GetAccountByIds['input'],
): Promise<GetAccountByIds['output']> {
  const ids = _.uniq(input.ids)
    ?.filter((id) => id !== null)
    .join(',');

  const { data } = await accountService.get<AccountService.Account[]>(
    '/accounts',
    {
      params: new URLSearchParams({
        // Remove comma from the beginning or end of the string, if it exists
        ids: ids?.replace(/^,|,$/g, ''),
      }),
    },
  );

  return data.map(mapAccountToDomain);
}

type GetPaginatedAccountsInput = {
  pageSize: number;
  page: number;
  filters?: Partial<PaginatedAccountsFilters>;
};

export type GetPaginatedAccounts = {
  input: GetPaginatedAccountsInput;
  output: PaginatedAccounts;
};

export const getPaginatedAccounts = async (
  input: GetPaginatedAccounts['input'],
): Promise<GetPaginatedAccounts['output']> => {
  const { data: accounts } = await accountService.get<AccountService.Account[]>(
    '/accounts',
    {
      params: {
        ...mapGetAccountsFiltersToApi(input.filters),
        page: input.page.toString(),
        pageSize: input.pageSize.toString(),
      },
      version: 'v1',
    },
  );

  const paginatedAccounts = mapPaginatedAccountsToDomain({
    items: accounts,
  });

  return {
    items: [...paginatedAccounts.items],
    totalPages: paginatedAccounts.totalPages,
    totalRecords: paginatedAccounts.totalRecords,
  };
};
