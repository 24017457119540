import * as ParrotUi from '@kea-inc/parrot-ui';
import * as LucideIcons from 'lucide-react';
import styled, { css } from 'styled-components';

export const ParticipantConnection = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
`;

export const OngoingCallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FromToContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const Timer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Card = styled(ParrotUi.Card)<{ $isAgent?: boolean }>`
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-grow: 1;
  flex-direction: ${(props) => (props.$isAgent ? 'row-reverse' : undefined)};
`;

export const NameContainer = styled.div<{ $isAgent?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.$isAgent
      ? css`
          align-items: flex-end;
          width: 100%;
        `
      : undefined};
`;

export const NameTypography = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const MdWarning = styled(LucideIcons.AlertTriangle)`
  width: 1rem;
  height: 1rem;
`;

export const AlertCloseButton = styled(ParrotUi.AlertCloseButton)`
  width: 1rem;
  height: 1rem;
`;

export const TimeTypography = styled(ParrotUi.Typography)`
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: bold;
`;

export const PhoneIconButton = styled(ParrotUi.Button)`
  border-radius: 9999px;
`;
