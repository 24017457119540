import { Platform } from '@/@types/platform';
import { dataService } from '@/services';
import { PlatformService } from '@/services/@types/platform';

export type UpdatePlatform = {
  input: Platform;
  output: Platform;
};

export const updatePlatform = async (
  input: UpdatePlatform['input'],
): Promise<UpdatePlatform['output']> => {
  const { id, name } = input;
  const { data } = await dataService.patch<PlatformService.Platform>(
    `/platforms/${id}`,
    { name },
  );

  return data;
};
