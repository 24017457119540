import { CompressedMenuParser, KeaItem } from '@kea-inc/menu';

import {
  OrderMenuParsedItem,
  OrderItem,
  OrderMenuItem,
  OrderMenuItemModifier,
  OrderMenuItemOption,
} from '@/@types/order/item';

const isKeaItem = (
  item: OrderMenuParsedItem | null,
): item is OrderMenuParsedItem => !!item;

const calculateItemCost = (
  menuParser: CompressedMenuParser,
  item: OrderItem,
  parsedItem: KeaItem,
) =>
  item.options?.reduce((totalCost, option) => {
    const parsedOption = menuParser.getOptionById(option.id);
    if (parsedOption) {
      return totalCost + parsedOption.cost * option.quantity;
    }
    return totalCost;
  }, parsedItem.cost) ?? 0;

export const buildParsedModifiers = (
  menuParser: CompressedMenuParser,
  modifierIds: string[],
) => {
  return modifierIds.reduce(
    (modifiers, id) => {
      const parsedModifier = menuParser.getModifierById(id);
      if (parsedModifier) {
        const newModifier: OrderMenuItemModifier = {
          id,
          options: parsedModifier.optionIds.reduce(
            (parsedOptions, optionId) => {
              const parsedOption = buildParsedOption(menuParser, optionId);
              if (parsedOption) {
                parsedOptions[optionId] = parsedOption;
              }
              return parsedOptions;
            },
            {} as Record<string, OrderMenuItemOption>,
          ),
          spokenName:
            parsedModifier.spokenName ??
            parsedModifier.name ??
            parsedModifier.id,
        };
        modifiers[id] = newModifier;
      }
      return modifiers;
    },
    {} as Record<string, OrderMenuItemModifier>,
  );
};

export const buildParsedOption = (
  menuParser: CompressedMenuParser,
  optionId: string,
  quantity = 0,
) => {
  const parsedOption = menuParser.getOptionById(optionId);
  if (parsedOption) {
    const newOption: OrderMenuItemOption = {
      id: optionId,
      spokenName:
        parsedOption.spokenName ?? parsedOption.name ?? parsedOption.id,
      quantity,
      cost: parsedOption.cost,
      modifiers: buildParsedModifiers(
        menuParser,
        parsedOption.modifierIds,
      ) as Record<string, OrderMenuItemModifier>,
    };
    return newOption;
  }
};
export const buildOptionModifiers = (
  menuParser: CompressedMenuParser,
  option: { id: string; quantity: number },
  ancestorIds: string[],
) => {
  const ancestorId = ancestorIds.shift();

  if (!ancestorId) {
    return null;
  }
  const parsedOption = menuParser.getOptionById(ancestorId);
  const parsedModifier = menuParser.getModifierById(ancestorId);

  if (parsedOption) {
    const newOption: OrderMenuItemOption = {
      id: ancestorId,
      spokenName:
        parsedOption.spokenName ?? parsedOption.name ?? parsedOption.id,
      quantity: option.quantity,
      cost: parsedOption.cost,
      modifiers: buildOptionModifiers(
        menuParser,
        option,
        ancestorIds,
      ) as Record<string, OrderMenuItemModifier>,
    };
    return { [ancestorId]: newOption };
  }

  if (parsedModifier) {
    const newModifier: OrderMenuItemModifier = {
      id: ancestorId,
      options: buildOptionModifiers(menuParser, option, ancestorIds) as Record<
        string,
        OrderMenuItemOption
      >,
      spokenName:
        parsedModifier.spokenName ?? parsedModifier.name ?? parsedModifier.id,
    };
    return { [ancestorId]: newModifier };
  }
};

export const mapOrderMenuItemsToDomain = (
  menuParser: CompressedMenuParser,
  items: OrderItem[] = [],
): OrderMenuParsedItem[] => {
  const menuItems = items.reduce((mItems, item) => {
    const parsedItem = menuParser.getItemById(item.menuItemId);
    if (!parsedItem) {
      return mItems;
    }

    const options = item.options?.reduce(
      (parsedOptions, option) => {
        const parsedOption = buildParsedOption(
          menuParser,
          option.id,
          option.quantity,
        );
        if (parsedOption) {
          parsedOptions[option.id] = parsedOption;
        }
        return parsedOptions;
      },
      {} as Record<string, OrderMenuItemOption>,
    );

    const menuItem: OrderMenuItem = {
      description: parsedItem.description,
      id: item.menuItemId,
      options,
      spokenName: parsedItem.spokenName,
      quantity: item.quantity,
      cost: calculateItemCost(menuParser, item, parsedItem).toString(),
    };

    return [...mItems, menuItem];
  }, [] as OrderMenuItem[]);
  return menuItems
    .map((item) => {
      const parsedItem = menuParser.getItemById(item.id);
      if (!parsedItem) {
        return null;
      }
      return {
        ...item,
      };
    })
    .filter(isKeaItem);
};
