import { FormField, FormItemToggle } from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import * as S from './styles';

interface BypassModeFormItemProps {
  form: UseFormReturn<StoreSchema>;
}

export function BypassModeFormField(props: BypassModeFormItemProps) {
  const { form } = props;
  return (
    <FormField
      control={form.control}
      name="bypassMode"
      render={({ field }) => (
        <FormItemToggle label="Bypass mode">
          <S.ToggleContainer>
            <S.Toggle
              pressed={field.value}
              onClick={() => field.onChange(true)}
            >
              On
            </S.Toggle>
            <S.Toggle
              pressed={!field.value}
              onClick={() => field.onChange(false)}
            >
              Off
            </S.Toggle>
          </S.ToggleContainer>
        </FormItemToggle>
      )}
    />
  );
}
