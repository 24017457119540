import decamelizeKeys from 'decamelize-keys';

import { CreateAgentRequest } from '@/@types/agent/create';
import { GetPaginatedAgentsRequest } from '@/@types/agent/get';
import { UpdateAgentRequestRequest } from '@/@types/agent/update';
import { AgentService } from '@/services/@types/agent';

export const mapPaginatedAgentRequestToApi = ({
  pageSize,
  ...params
}: GetPaginatedAgentsRequest): AgentService.GetPaginatedAgentsRequest => ({
  ...params,
  page_size: pageSize,
});

export const mapUpdateAgentToApi = ({
  id: _,
  notes,
  ...params
}: UpdateAgentRequestRequest): AgentService.UpdateAgentRequest =>
  decamelizeKeys({ ...params, notes: notes ? notes : undefined });

export const mapCreateAgentToApi = (
  params: CreateAgentRequest,
): AgentService.CreateAgentRequest => decamelizeKeys(params);
