import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import { Agent, AgentBrand } from '@/@types/agent';
import { Store } from '@/@types/store';

export const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  menu {
    max-height: 300px;
    overflow-y: scroll;
  }
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Typography = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const CallButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ComboboxItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const NameEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AgentName = styled.div`
  font-weight: bold;
`;

export const Combobox = styled(ParrotUi.Combobox<Agent>)``;
export const BrandsCombobox = styled(ParrotUi.Combobox<AgentBrand>)``;

export const StoresCombobox = styled(ParrotUi.Combobox<Store>)``;
