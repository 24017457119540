import * as ParrotUi from '@kea-inc/parrot-ui';
import { Play } from 'lucide-react';
import styled from 'styled-components';

export const AudioContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const PlayIconButton = styled(ParrotUi.Button)`
  width: 2rem;
  height: 2rem;
`;

export const Skeleton = styled(ParrotUi.Skeleton)`
  width: 100%;
  height: 54px;
  padding: 1rem;
  border-radius: 30px;
`;

export const Audio = styled.audio`
  width: 100%;
`;

export const PlayIcon = styled(Play)`
  color: hsl(var(--warning));
`;
