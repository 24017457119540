import * as ParrotUi from '@kea-inc/parrot-ui';
import { Users2, Utensils } from 'lucide-react';
import styled from 'styled-components';

import { TextLeft } from '@/components/DataTable';

export const CardContentContainer = styled.div`
  overflow: auto;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const Input = styled(ParrotUi.Input)`
  width: 250px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AlertDialogDeleteAction = styled(ParrotUi.AlertDialogAction)`
  background-color: hsl(var(--error));
`;

export const EmailCell = styled(TextLeft)`
  font-weight: 500;
`;

export const Users2Icon = styled(Users2)`
  cursor: pointer;
`;

export const UtensilsIcon = styled(Utensils)`
  cursor: pointer;
`;
