import env from '@beam-australia/react-env';
import decamelizeKeys from 'decamelize-keys';

import { BrandPromo, BrandUpsell } from '@/@types/brand';
import { CreateBrandRequest } from '@/@types/brand/create';
import { UpdateBrandRequest } from '@/@types/brand/update';
import { BrandService } from '@/services/@types/brand';

export const mapCreateBrandToApi = (
  params: CreateBrandRequest,
): BrandService.CreateBrandRequest => {
  const decamelized = decamelizeKeys(params);
  return {
    ...decamelized,
    platforms: decamelizeKeys(params.platforms),
    twilioWorkspaceSid: env('TWILIO_WORKSPACE_SID'),
  };
};

export const mapUpdateBrandToApi = (
  params: UpdateBrandRequest,
): BrandService.UpdateBrandRequest => {
  const decamelized = decamelizeKeys(params);
  return {
    ...decamelized,
    platforms: params.platforms ? decamelizeKeys(params.platforms) : undefined,
    twilioWorkspaceSid: env('TWILIO_WORKSPACE_SID'),
  };
};

export const mapBrandPromoToApi = (
  brandPromo: Omit<BrandPromo, 'id'>,
): BrandService.BrandPromoPayload => ({
  code: brandPromo.code,
  description: brandPromo.description,
  name: brandPromo.name,
  prompt: brandPromo.prompt,
});

export const mapBrandUpsellToApi = (
  brandUpsell: Omit<BrandUpsell, 'id'>,
): Omit<BrandService.BrandUpsell, 'id'> => decamelizeKeys(brandUpsell);
