import * as z from 'zod';

import { PaginatedAccountsFilters } from '@/@types/account/paginatedAccount';

export type SchemaObjShape = Record<
  keyof PaginatedAccountsFilters,
  z.ZodTypeAny
>;

export const accountFilterSchema = z.object({
  ids: z.string().optional(),
  phone: z.string().optional(),
} satisfies SchemaObjShape);

export type AccountFilterSchema = z.infer<typeof accountFilterSchema>;
