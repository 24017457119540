import { dataService } from '@/services';

export type DeleteBrand = {
  input: { id: string };
  output: { id: string };
};

export const deleteBrand = async (
  input: DeleteBrand['input'],
): Promise<DeleteBrand['output']> => {
  await dataService.delete(`/brands/${input.id}`);
  return { id: input.id };
};
