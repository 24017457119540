import { AxiosError } from 'axios';
import decamelizeKeys from 'decamelize-keys';

import { BrandUpsell } from '@/@types/brand';
import { BrandService } from '@/services/@types/brand';
import { dataService } from '@/services/clients';
import {
  mapBrandUpsellToApi,
  mapBrandUpsellToDomain,
} from '@/services/useCases/brand/adapters';
import { WithSyncPayload } from '@/utils/methods';

export type GetBrandUpsells = {
  input: { brandId: string };
  output: BrandUpsell[];
};

export const getBrandUpsells = async (
  input: GetBrandUpsells['input'],
): Promise<GetBrandUpsells['output']> => {
  try {
    const { data } = await dataService.get<BrandService.BrandUpsell[]>(
      `/brands/${input.brandId}/upsells`,
    );
    return data.map(mapBrandUpsellToDomain);
  } catch (e) {
    const axiosError = e as AxiosError;
    if (axiosError.response?.status === 404) {
      return [];
    }
    throw axiosError;
  }
};

type SyncBrandUpsellsInput = {
  upsells: WithSyncPayload<BrandUpsell>;
  brandId: string;
};

export type SyncBrandUpsells = {
  input: SyncBrandUpsellsInput;
  output: void;
};

export async function syncBrandUpsells(
  input: SyncBrandUpsells['input'],
): Promise<SyncBrandUpsells['output']> {
  const { created, updated, deleted } = input.upsells;

  const createdPromises = created.map((upsell) => {
    const promoPayload = decamelizeKeys(
      mapBrandUpsellToApi(upsell),
    ) as BrandService.BrandUpsell;

    return dataService.post(
      `/brands/${input.brandId}/upsells`,
      {
        ...promoPayload,
      },
      {
        version: 'v2',
      },
    );
  });

  const updatedPromises = updated.map(({ id, ...upsell }) => {
    const promoPayload = decamelizeKeys(
      mapBrandUpsellToApi(upsell),
    ) as BrandService.BrandUpsell;

    return dataService.patch(`/brands/upsells/${id}`, promoPayload, {
      version: 'v2',
    });
  });

  const deletedPromises = deleted.map((upsell) => {
    return dataService.delete(`/brands/upsells/${upsell.id}`, {
      version: 'v2',
    });
  });

  await Promise.all(
    createdPromises.concat(updatedPromises).concat(deletedPromises),
  );
}
