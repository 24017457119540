import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/store';
import { StoreState } from '@/store';

export const prefix = 'useCases/store';

export const actions = {
  getPaginatedStores: createAsyncThunk(
    `${prefix}/getPaginatedStores`,
    async (input: api.GetPaginatedStores['input'], thunkApi) => {
      try {
        const result = await api.getPaginatedStores(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getStoreByIds: createAsyncThunk(
    `${prefix}/getStoreByIds`,
    async (input: api.GetStoreByIds['input'], thunkApi) => {
      try {
        const result = await api.getStoreByIds(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getStore: createAsyncThunk(
    `${prefix}/getStore`,
    async (input: api.GetStore['input'], thunkApi) => {
      try {
        const result = await api.getStore(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getStoreMenuVersions: createAsyncThunk(
    `${prefix}/getStoreMenuVersions`,
    async (input: api.GetStoreMenuVersions['input'], thunkApi) => {
      try {
        const result = await api.getStoreMenuVersions(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getStoresByBrandId: createAsyncThunk(
    `${prefix}/getStoresByBrandId`,
    async (input: api.GetStoresByBrandId['input'], thunkApi) => {
      try {
        const result = await api.getStoresBrandId(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createStore: createAsyncThunk(
    `${prefix}/createStore`,
    async (input: api.CreateStore['input'], thunkApi) => {
      try {
        const store = thunkApi.getState() as StoreState;
        const result = await api.createStore(input);

        return {
          ...result,
          brand: store.entities.brand.brands.entities[result.brandId],
          fleet: store.entities.fleet.fleets.entities[result.fleetId],
        };
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateStore: createAsyncThunk(
    `${prefix}/updateStore`,
    async (input: api.UpdateStore['input'], thunkApi) => {
      try {
        const result = await api.updateStore(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  batchUpdateStores: createAsyncThunk(
    `${prefix}/batchUpdateStores`,
    async (input: api.BatchUpdateStores['input'], thunkApi) => {
      try {
        const result = await api.batchUpdateStores(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteStore: createAsyncThunk(
    `${prefix}/deleteStore`,
    async (input: api.DeleteStore['input'], thunkApi) => {
      try {
        const result = await api.deleteStore(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateOnOff: createAsyncThunk(
    `${prefix}/updateOnOff`,
    async (input: api.UpdateOnOff['input'], thunkApi) => {
      try {
        const result = await api.updateOnOff(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  syncMenu: createAsyncThunk(
    `${prefix}/syncMenu`,
    async (input: api.SyncMenu['input'], thunkApi) => {
      try {
        const result = await api.syncMenu(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getPromos: createAsyncThunk(
    `${prefix}/getPromos`,
    async (input: api.GetPromos['input'], thunkApi) => {
      try {
        const result = await api.getPromos(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  syncStorePromos: createAsyncThunk(
    `${prefix}/syncStorePromos`,
    async (input: api.SyncStorePromos['input'], thunkApi) => {
      try {
        const result = await api.syncStorePromos(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getGoogleMapsInformation: createAsyncThunk(
    `${prefix}/getGoogleMapsInformation`,
    async (input: api.GetGoogleMapsInformation['input'], thunkApi) => {
      try {
        const result = await api.getGoogleMapsInformation(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  buyTwilioNumber: createAsyncThunk(
    `${prefix}/buyTwilioNumber`,
    async (input: api.BuyTwilioNumber['input'], thunkApi) => {
      try {
        const result = await api.buyTwilioNumber(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  bulkCreation: createAsyncThunk(
    `${prefix}/bulkCreation`,
    async (input: api.BulkCreation['input'], thunkApi) => {
      try {
        const result = await api.bulkCreation(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
