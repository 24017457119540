import { TypographyProps } from '@kea-inc/parrot-ui';

import * as S from './styles';

interface TimeSpanProps extends TypographyProps {
  children: string;
}

export function TimeSpan({ children, ...props }: TimeSpanProps) {
  return <S.Typography {...props}>{children}</S.Typography>;
}
