import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { PaginatedAccountsFilters } from '@/@types/account/paginatedAccount';
import {
  mapAccountFiltersToSearchParams,
  mapAccountSearchParamsToAccountFilters,
} from '@/pages/Accounts/List/adapters/domain';
import {
  AccountFilterSchema,
  accountFilterSchema,
} from '@/pages/Accounts/List/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from '../connector';

const accountFiltersSchemaDefaultValues: PaginatedAccountsFilters = {
  ids: undefined,
  phone: undefined,
};

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { actions, selectors } = useConnector();

  const { filters } = selectors;

  const form = useForm<AccountFilterSchema>({
    resolver: zodResolver(accountFilterSchema),
    defaultValues: accountFiltersSchemaDefaultValues,
  });

  useEffect(() => {
    if (filters) {
      form.reset({
        ...filters,
      });
    }
  }, [filters]);

  useEffect(() => {
    if (searchParams.size) {
      dispatch(
        actions.setFilters(
          mapAccountSearchParamsToAccountFilters(searchParams),
        ),
      );
    }
  }, [searchParams]);

  const handleFinallySubmit: Parameters<typeof form.handleSubmit>[0] = (
    values,
  ) => {
    setFilterOpen(false);
    dispatch(actions.setFilters(values));
    if (values) {
      setSearchParams(mapAccountFiltersToSearchParams(values));
    }
  };

  const handleClearFilters = () => {
    form.reset(accountFiltersSchemaDefaultValues);
    dispatch(actions.setFilters(undefined));
    setSearchParams(undefined);
  };

  return {
    form,
    filterOpen,
    setFilterOpen,
    searchParams,
    handleFinallySubmit,
    handleClearFilters,
  };
};
