import { Color } from '@kea-inc/parrot-ui/dist/types';
import React from 'react';

import * as S from './styles';

interface Props {
  title: string;
  children: React.ReactNode;
  color?: Color;
  heavy?: boolean;
}

export function Information({ title, children, color, heavy }: Props) {
  return (
    <S.Container>
      <S.Typography>{title}</S.Typography>
      <S.Typography
        color={color}
        $weight={heavy ? 'bold' : 'normal'}
        variant="h1"
      >
        {children}
      </S.Typography>
    </S.Container>
  );
}
