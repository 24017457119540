import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/platform';

export const prefix = 'useCases/platform';

export const actions = {
  getPlatforms: createAsyncThunk(
    `${prefix}/getPlatforms`,
    async (_, thunkApi) => {
      try {
        const result = await api.getPlatforms();

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createPlatform: createAsyncThunk(
    `${prefix}/createPlatform`,
    async (input: api.CreatePlatform['input'], thunkApi) => {
      try {
        const result = await api.createPlatform(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updatePlatform: createAsyncThunk(
    `${prefix}/updatePlatform`,
    async (input: api.UpdatePlatform['input'], thunkApi) => {
      try {
        const result = await api.updatePlatform(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deletePlatform: createAsyncThunk(
    `${prefix}/deletePlatform`,
    async (input: api.DeletePlatform['input'], thunkApi) => {
      try {
        const result = await api.deletePlatform(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  syncPlatformStoreId: createAsyncThunk(
    `${prefix}/syncPlatformStoreId`,
    async (input: api.SyncPlatformStoreId['input'], thunkApi) => {
      try {
        const result = await api.syncPlatformStoreId(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
