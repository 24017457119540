import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { Bpo } from '@/@types/bpo';
import { actions } from '@/store/useCases/bpo';

export const adapter = createEntityAdapter<Bpo>({
  sortComparer: (f1, f2) => f1.name.localeCompare(f2.name),
});

export type State = {
  bpos: EntityState<Bpo, string>;
};

export const initialState: State = {
  bpos: adapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/bpo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getBpos.fulfilled, (state, action) => {
      adapter.upsertMany(state.bpos, action.payload);
    });
    builder.addCase(actions.createBpo.fulfilled, (state, action) => {
      adapter.addOne(state.bpos, action.payload);
    });
    builder.addCase(actions.updateBpo.fulfilled, (state, action) => {
      adapter.updateOne(state.bpos, {
        changes: action.payload,
        id: action.payload.id,
      });
    });
    builder.addCase(actions.deleteBpo.fulfilled, (state, action) => {
      adapter.removeOne(state.bpos, action.payload.id);
    });
  },
});

export default slice;
