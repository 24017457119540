import { OrderTranscript } from '@/@types/order/transcript';
import { OrderService } from '@/services/@types/order';
import { dataService } from '@/services/clients';
import { mapOrderTranscriptsToDomain } from '@/services/useCases/order/adapters/domain/transcript';

export type GetOrderTranscripts = {
  input: { orderId: string };
  output: OrderTranscript[];
};

export const getOrderTranscripts = async (
  input: GetOrderTranscripts['input'],
): Promise<GetOrderTranscripts['output']> => {
  const { data } = await dataService.get<OrderService.OrderTranscript[]>(
    `/transcripts/${input.orderId}/findAll`,
  );

  return mapOrderTranscriptsToDomain(data);
};

export type DeleteOrderTranscripts = {
  input: { orderId: string };
  output: void;
};

export const deleteOrderTranscripts = async (
  input: DeleteOrderTranscripts['input'],
): Promise<DeleteOrderTranscripts['output']> => {
  await dataService.delete(`/transcripts/${input.orderId}`);
};
