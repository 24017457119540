import { Order, OrderStatus } from '@/@types/order';
import { PaginatedOrders } from '@/@types/order/paginatedOrder';
import { OrderService } from '@/services/@types/order';

export const mapOrderToDomain = (serviceOrder: OrderService.Order): Order => ({
  ...serviceOrder,
  status: serviceOrder.status.toLowerCase() as OrderStatus,
});

export const mapPaginatedOrdersToDomain = (
  serviceOrder: OrderService.PaginatedOrders,
): PaginatedOrders => ({
  items: serviceOrder.items,
  totalPages: serviceOrder.total_pages,
  totalRecords: serviceOrder.total_records,
});
