type SaveReturnToParams = {
  search: string;
  pathname: string;
};

export const saveReturnTo = (params: SaveReturnToParams) => {
  const { pathname, search } = params;
  if (pathname !== '/') {
    localStorage.setItem('@kea-inc/ops-console/return-to', pathname + search);
  }
};
