import { Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';

import { useConnector } from '@/pages/Training/connector';

import { Keypad } from './Keypad';
import { RingingState } from './RingingState';
import * as S from './styles';

export function Cellphone() {
  const { selectors } = useConnector();
  const { training } = selectors;
  const { isConnected, device, isRinging, isExpectingCall } = training;

  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isKeypadOpen, setIsKeypadOpen] = useState<boolean>(false);

  const handleMute = () => {
    device?.activeConnection()?.mute(true);
    setIsMuted(true);
  };

  const handleUnmute = () => {
    device?.activeConnection()?.mute(false);
    setIsMuted(false);
  };

  const handleHangup = () => {
    device?.disconnectAll();
  };

  useEffect(() => setIsMuted(false), [isConnected]);

  return (
    <S.CellphoneContainer
      $isRinging={isRinging}
      $isConnected={isConnected || isRinging}
    >
      {!isConnected && isRinging && !isExpectingCall ? <RingingState /> : null}
      {!isRinging &&
        (isKeypadOpen ? (
          <Keypad setIsKeypadOpen={setIsKeypadOpen} />
        ) : (
          <>
            <S.KeyWrapper>
              <S.Key
                $active={isMuted}
                onClick={isMuted ? handleUnmute : handleMute}
                disabled={!isConnected}
              >
                {isMuted ? <S.UnmuteIcon /> : <S.MuteIcon />}
              </S.Key>
              <Typography>{isMuted ? 'Unmute' : 'Mute'}</Typography>
            </S.KeyWrapper>

            <S.KeyWrapper>
              <S.Key
                onClick={() => setIsKeypadOpen((prev) => !prev)}
                disabled={!isConnected}
              >
                <S.KeypadIcon />
              </S.Key>
              <Typography>Keypad</Typography>
            </S.KeyWrapper>

            <S.KeyWrapper>
              <S.Key onClick={handleHangup} $danger disabled={!isConnected}>
                <S.HangupIcon />
              </S.Key>
              <Typography>Hangup</Typography>
            </S.KeyWrapper>
          </>
        ))}
    </S.CellphoneContainer>
  );
}
