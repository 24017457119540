import * as ParrotUi from '@kea-inc/parrot-ui';
import { AlertTriangle, CheckCircle2, XCircle } from 'lucide-react';
import styled from 'styled-components';

export * from '../styles';

export const Container = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const NumbersContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 0.25rem;
`;

export const OverrideContainer = styled.div`
  width: 400px;
`;

export const FormItemToggle = styled(ParrotUi.FormItemToggle)`
  flex: 1;
`;

export const ResultCell = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const CheckCircle2Icon = styled(CheckCircle2).attrs({ size: 24 })`
  color: hsl(var(--success));
`;
export const XCircleIcon = styled(XCircle).attrs({ size: 24 })`
  color: hsl(var(--error));
`;

export const MessageContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: 0.5rem;
`;

export const AlertTriangleIcon = styled(AlertTriangle).attrs({ size: 16 })`
  color: hsl(var(--warning));
`;

export const ResultContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
