import { Typography } from '@kea-inc/parrot-ui';

import * as S from './styles';

type AdminTab = 'users' | 'roles';

export interface NavbarProps {
  tab: AdminTab;
  onTabChange: (tab: AdminTab) => void;
}

export function Navbar(props: NavbarProps) {
  const { onTabChange, tab } = props;
  return (
    <S.Navbar>
      <Typography variant="h4">Admin</Typography>

      <S.Tabs
        value={tab}
        onValueChange={(value) => onTabChange(value as AdminTab)}
        orientation="vertical"
      >
        <S.TabsList>
          <S.TabsTrigger value="users">Users</S.TabsTrigger>
          <S.TabsTrigger value="roles">Roles</S.TabsTrigger>
        </S.TabsList>
      </S.Tabs>
    </S.Navbar>
  );
}
