import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { KeaLoading } from '@/components/KeaLoading';
import { OrderData } from '@/pages/Orders/OrderInfo/OrderData';
import { OrderTabs } from '@/pages/Orders/OrderInfo/OrderTabs';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

export function OrderInfo() {
  const dispatch = useAppDispatch();
  const { orderId = '' } = useParams<{ orderId: string }>();

  const { selectors, actions } = useConnector();

  const { orderEntities, fetching } = selectors;

  useEffect(() => {
    dispatch(actions.getBrands());
    dispatch(actions.getAgents());
  }, []);

  useEffect(() => {
    if (orderId)
      dispatch(actions.getOrder({ id: orderId }))
        .unwrap()
        .then((order) => {
          dispatch(actions.getOrderRecording({ orderId: order.id }));
          dispatch(actions.getOrderTranscripts({ orderId: order.id }));
          dispatch(actions.getOrderCallFlow({ orderId: order.id }));
          dispatch(actions.getStore({ id: order.storeId }))
            .unwrap()
            .then((store) =>
              dispatch(actions.getOrderMenuItems({ order, store })),
            );
          dispatch(actions.getAccount({ id: order.accountId }));
        });
  }, [orderId]);

  const order = orderId ? orderEntities[orderId] : undefined;

  if (fetching) {
    return <KeaLoading />;
  }
  if (!order) {
    return null;
  }

  return (
    <S.PageCard>
      <OrderData order={order} />
      <OrderTabs order={order} />
    </S.PageCard>
  );
}
