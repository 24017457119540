import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export * from '../styles';

export const SpecialsContainer = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const NewSpecialContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 0.25rem;
`;

export const SpeechButton = styled(ParrotUi.Button)`
  margin-top: 0px !important;
`;
