import { Brand, BrandUpsell } from '@/@types/brand';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';
import { BrandUpsellSchema } from '@/pages/Brands/BrandForm/schema/upsell';

const mapUpsellToFormValues = (upsell: BrandUpsell): BrandUpsellSchema => ({
  ...upsell,
  chainIds: upsell.chainIds.join(','),
});

export const mapBrandToFormValues = (
  brand: Brand,
): BrandSchema | undefined => ({
  ...brand,
  priority: Number(brand.priority) ?? 0,
  upsells: brand.upsells?.map(mapUpsellToFormValues),
  triageHandoffMethods: brand.triageHandoffMethods ?? {
    curbside: false,
    delivery: false,
    dinein: false,
    pickup: false,
  },
});
