import { Users2Icon } from 'lucide-react';
import React from 'react';

import { Information } from '@/components/Information';

import * as S from './styles';

interface QueueMetricProps {
  customersInQueue: React.ReactNode;
  maxWaitTime: React.ReactNode;
  avgWaitTime: React.ReactNode;
}

export function QueueMetric(props: QueueMetricProps) {
  const { avgWaitTime, customersInQueue, maxWaitTime } = props;
  return (
    <S.MetricContainer>
      <S.MetricTitleContainer>
        <Users2Icon />
        <S.MetricTitle>Queue Metric</S.MetricTitle>
      </S.MetricTitleContainer>
      <S.MetricContent>
        <Information title="In-Queue" color="warning" heavy>
          {customersInQueue}
        </Information>
        <Information title="Max wait time" color="secondary">
          {maxWaitTime}
        </Information>
        <Information title="Average wait time" color="secondary">
          {avgWaitTime}
        </Information>
      </S.MetricContent>
    </S.MetricContainer>
  );
}
