import { Input } from '@kea-inc/parrot-ui';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { ReducedAgentsMetrics } from '@/@types/agent';

import * as S from './styles';

interface RoleWeightColumnProps {
  reducedAgent: ReducedAgentsMetrics;
  onRoleWeightSave: (rW: string, rAgent: ReducedAgentsMetrics) => void;
}

export function RoleWeightColumn(props: RoleWeightColumnProps) {
  const { reducedAgent, onRoleWeightSave } = props;

  const [roleWeight, setRoleWeight] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isEditing && reducedAgent) {
      setRoleWeight(reducedAgent.roleWeight);
    }
  }, [reducedAgent]);

  return (
    <S.Container>
      <Input
        value={roleWeight}
        onChange={(event) =>
          setRoleWeight(event.target.value.replace(/\D/g, ''))
        }
        onFocus={() => setIsEditing(true)}
        onBlur={() => _.debounce(() => setIsEditing(false), 100)}
      />
      {isEditing ? (
        <S.Button
          variant="link"
          size="sm"
          onClick={() => onRoleWeightSave(roleWeight, reducedAgent)}
        >
          Save
        </S.Button>
      ) : null}
    </S.Container>
  );
}
