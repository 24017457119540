import styled from 'styled-components';

import * as Styles from '@/styles';

export const Form = Styles.Form;

export const CardContent = styled.div`
  display: flex;
  gap: 1rem;
`;
