import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { Order } from '@/@types/order';
import { PaginatedOrderEntity } from '@/@types/order/paginatedOrder';
import { actions } from '@/store/useCases/order';

export const adapter = createEntityAdapter<Order>({
  sortComparer: () => 0,
});

export const adapterWithFilter = createEntityAdapter<PaginatedOrderEntity>({
  sortComparer: (s1, s2) =>
    JSON.stringify(s1.id).localeCompare(JSON.stringify(s2.id)),
});

export type State = {
  orders: EntityState<Order, string>;
  ordersWithFilter: EntityState<PaginatedOrderEntity, string>;
};

export const initialState: State = {
  orders: adapter.getInitialState(),
  ordersWithFilter: adapterWithFilter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getOrder.fulfilled, (state, action) => {
      adapter.upsertOne(state.orders, action.payload);
    });
    builder.addCase(actions.getOrderMenuItems.fulfilled, (state, action) => {
      const { order } = action.meta.arg;
      adapter.updateOne(state.orders, {
        id: order.id,
        changes: { menuItems: action.payload },
      });
    });
    builder.addCase(actions.getOrderTranscripts.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;
      adapter.updateOne(state.orders, {
        id: orderId,
        changes: { transcripts: action.payload },
      });
    });
    builder.addCase(actions.getOrderSurveys.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;
      adapter.updateOne(state.orders, {
        id: orderId,
        changes: { surveys: action.payload },
      });
    });
    builder.addCase(actions.getOrderCallFlow.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;
      adapter.updateOne(state.orders, {
        id: orderId,
        changes: { callFlow: action.payload },
      });
    });
    builder.addCase(actions.getPaginatedOrders.fulfilled, (state, action) => {
      const { items } = action.payload;

      if (action.meta.arg?.filters) {
        const id = JSON.stringify(action.meta.arg.filters);
        const orderIds = state.ordersWithFilter.entities[id]?.orderIds;
        if (orderIds) {
          adapterWithFilter.updateOne(state.ordersWithFilter, {
            id,
            changes: {
              orderIds: _.uniq(orderIds.concat(items.map((item) => item.id))),
            },
          });
        } else {
          adapterWithFilter.addOne(state.ordersWithFilter, {
            id,
            orderIds: items.map((item) => item.id),
          });
        }
      }
      adapter.upsertMany(state.orders, items);
    });
    builder.addCase(actions.getOrderRecording.fulfilled, (state, action) => {
      const orderId = action.meta.arg.orderId;

      adapter.updateOne(state.orders, {
        id: orderId,
        changes: { recordingUrls: action.payload.urls },
      });
    });
    builder.addCase(
      actions.deleteOrderTranscripts.fulfilled,
      (state, action) => {
        const orderId = action.meta.arg.orderId;

        adapter.updateOne(state.orders, {
          id: orderId,
          changes: { transcripts: undefined },
        });
      },
    );
    builder.addCase(actions.deleteOrderRecording.fulfilled, (state, action) => {
      const orderId = action.meta.arg.orderId;

      adapter.updateOne(state.orders, {
        id: orderId,
        changes: { recordingUrls: undefined },
      });
    });
  },
});

export default slice;
