import { isValidPhoneNumber } from 'libphonenumber-js';
import * as z from 'zod';

import { BulkCreationManualAssignment } from '@/@types/store/bulkCreation';
import { STORE_STATUSES } from '@/constants/store';

type SchemaObjShape = Record<keyof BulkCreationManualAssignment, z.ZodTypeAny>;

export const manualAssignmentSchema = z.object({
  cashLimit: z
    .string()
    .refine((value) => !Number.isNaN(Number(value)), {
      message: 'Invalid number',
    })
    .optional(),
  twilioNumber: z
    .string()
    .refine(isValidPhoneNumber, 'Invalid phone number')
    .optional(),
  bypassMode: z.boolean().optional(),
  storeDetails: z.string().optional(),
  notes: z.string().nullable().optional(),
  activationDate: z.date().nullable().optional(),
  syncStoreHours: z.boolean().optional(),
  isTestStore: z.boolean().optional(),
  shouldTip: z.boolean().nullable().optional(),
  inStoreOrdering: z.boolean().optional(),
  useDefaultOptionsToMaxQuantity: z.boolean().nullable().optional(),
  isProductionMirror: z.boolean().optional(),
  storeStatus: z.enum(STORE_STATUSES).optional(),
} satisfies SchemaObjShape);

export type ManualAssignmentSchema = z.infer<typeof manualAssignmentSchema>;
