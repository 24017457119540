import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import * as GlobalStyles from '@/styles';

export const SectionContainer = GlobalStyles.SectionContainer;

export const SectionTitle = GlobalStyles.SectionTitle;

export const FormFieldContainer = GlobalStyles.FormFieldContainer;

export const Toggle = GlobalStyles.Toggle;
export const ToggleContainer = GlobalStyles.ToggleContainer;

export const FormItemLabelContainer = GlobalStyles.FormItemLabelContainer;

export const NewFieldContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;

export const FieldArrayContainer = GlobalStyles.FieldArrayContainer;
export const FieldArrayItemContainer = GlobalStyles.FieldArrayItemContainer;

export const FormItemToggle = styled(ParrotUi.FormItemToggle)`
  flex: 1;
`;

export const AddDeleteButton = styled(ParrotUi.Button)`
  margin-top: 0px !important;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;
