import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/worksite';

export const prefix = 'useCases/worksite';

export const actions = {
  getWorksites: createAsyncThunk(
    `${prefix}/getWorksites`,
    async (_, thunkApi) => {
      try {
        const result = await api.getWorksites();

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
