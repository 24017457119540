import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface State {
  searches: Record<string, string>;
}

export const initialState: State = {
  searches: {},
};

const slice = createSlice({
  name: 'ui/search',
  initialState,
  reducers: {
    addSearch: (state, action: PayloadAction<Record<string, string>>) => {
      state.searches = _.merge(state.searches, action.payload);
    },
  },
});

export default slice;
