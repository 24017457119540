import { FormField, FormItemTextArea } from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import { SpecialsFormField } from './SpecialsFormField';
import * as S from './styles';

interface CallInfoProps {
  form: UseFormReturn<StoreSchema>;
  id: HTMLDivElement['id'];
}

export function CallInfo(props: CallInfoProps) {
  const { form, id } = props;
  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Call info</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="area"
          render={({ field }) => (
            <FormItemTextArea field={field} label="Area" />
          )}
        />
        <FormField
          control={form.control}
          name="storeDetails"
          render={({ field }) => (
            <FormItemTextArea field={field} label="Store details" />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItemTextArea field={field} label="Notes" />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <SpecialsFormField form={form} />
      </S.FormFieldContainer>
    </S.SectionContainer>
  );
}
