import * as ParrotUi from '@kea-inc/parrot-ui';
import {
  Copy,
  Pencil,
  Power,
  Settings,
  Trash,
  Users2,
  Utensils,
} from 'lucide-react';
import styled from 'styled-components';

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const IconButton = styled(ParrotUi.Button).attrs({ size: 'icon' })`
  width: 2rem;
  height: 2rem;
`;
export const DropdownMenuItem = styled(ParrotUi.DropdownMenuItem)`
  gap: 0.5rem;
`;
export const DropdownMenuSubTrigger = styled(ParrotUi.DropdownMenuSubTrigger)`
  gap: 0.5rem;
`;

export const CopyIcon = styled(Copy).attrs({ size: 14 })``;

export const PowerIcon = styled(Power).attrs({ size: 14 })``;

export const Users2Icon = styled(Users2)`
  cursor: pointer;
`;

export const UtensilsIcon = styled(Utensils)`
  cursor: pointer;
`;

export const SettingsIcon = styled(Settings)`
  width: 1rem;
  height: 1rem;
  color: hsl(var(--warning));
`;

export const PencilIcon = styled(Pencil)`
  width: 1rem;
  height: 1rem;
  color: hsl(var(--primary));
`;

export const TrashIcon = styled(Trash)`
  width: 1rem;
  height: 1rem;
  color: hsl(var(--error));
`;
