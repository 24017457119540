import { UseFormReturn } from 'react-hook-form';

export const isValidForm = <
  Schema extends Record<string, unknown>,
  Form extends UseFormReturn<Schema>,
>(
  form: Form,
) => {
  return !Object.values(form.formState.errors).length;
};
