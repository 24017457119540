import { combineReducers } from '@reduxjs/toolkit';

import {
  initialState as accountInitialState,
  reducer as account,
} from './account';
import {
  initialState as dataServiceOrderInitialState,
  reducer as dataServiceOrder,
} from './dataServiceOrder';
import { initialState as menuInitialState, reducer as menu } from './menu';
import {
  initialState as notificationInitialState,
  reducer as notification,
} from './notification';
import { initialState as orderInitialState, reducer as order } from './order';
import {
  initialState as searchInitialState,
  reducer as search,
} from './search';
import { initialState as storeInitialState, reducer as store } from './store';
import { initialState as themeInitialState, reducer as theme } from './theme';
import {
  initialState as trainingInitialState,
  reducer as training,
} from './training';
import { initialState as userInitialState, reducer as user } from './user';

export const initialState = {
  theme: themeInitialState,
  notification: notificationInitialState,
  training: trainingInitialState,
  store: storeInitialState,
  user: userInitialState,
  menu: menuInitialState,
  order: orderInitialState,
  dataServiceOrder: dataServiceOrderInitialState,
  account: accountInitialState,
  search: searchInitialState,
};

export type UIState = typeof initialState;

export default combineReducers({
  theme,
  notification,
  training,
  store,
  user,
  menu,
  order,
  dataServiceOrder,
  account,
  search,
} satisfies Record<keyof UIState, unknown>);
