import { Button, ButtonProps } from '@kea-inc/parrot-ui';
import { ArrowLeftIcon } from 'lucide-react';

interface BackButtonProps extends ButtonProps {}

export const BackButton = (props: BackButtonProps) => {
  return (
    <Button {...props} size="icon" variant="ghost" type="button">
      <ArrowLeftIcon />
    </Button>
  );
};
