import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MenuCategory,
  MenuItem,
  MenuModifier,
  MenuOption,
} from '@/@types/menu';

export type MenuEntry = MenuCategory | MenuItem | MenuModifier | MenuOption;
export interface State {
  brandId?: string;
  storeId?: string;
  menuEntries: (MenuCategory | MenuItem | MenuModifier | MenuOption)[];
}

export const initialState: State = {
  menuEntries: [],
};

const slice = createSlice({
  name: 'ui/menu',
  initialState,
  reducers: {
    setBrandId: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload === state.brandId) {
        return;
      }
      state.brandId = action.payload;
      state.storeId = undefined;
    },
    setStoreId: (state, action: PayloadAction<string | undefined>) => {
      state.storeId = action.payload;
    },
    cleanMenuEntry: (state) => {
      state.menuEntries = [];
    },
    addMenuEntry: (state, action: PayloadAction<MenuEntry>) => {
      state.menuEntries.unshift(action.payload);
    },
    removeMenuEntry: (state, action: PayloadAction<MenuEntry>) => {
      const entry = state.menuEntries.find(
        (etr) => action.payload.id === etr.id,
      );

      state.menuEntries = state.menuEntries.filter(
        (etr) => etr.id !== entry?.id,
      );
    },
  },
});

export default slice;
