import { Brand } from '@/@types/brand';
import { CreateBrandRequest } from '@/@types/brand/create';
import { dataService } from '@/services';
import { BrandService } from '@/services/@types/brand';
import {
  mapBrandToDomain,
  mapCreateBrandToApi,
} from '@/services/useCases/brand/adapters';

export type CreateBrand = {
  input: CreateBrandRequest;
  output: Brand;
};

export const createBrand = async (
  input: CreateBrand['input'],
): Promise<CreateBrand['output']> => {
  const { data } = await dataService.post<BrandService.Brand>(
    `/brands`,
    mapCreateBrandToApi(input),
  );
  return mapBrandToDomain(data);
};
