import styled from 'styled-components';

import { LabelValue as LabelValueComponent } from '@/components/LabelValue';

export const Container = styled.div`
  flex: 4;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid hsl(var(--border));
  gap: 1rem;
  overflow: auto;
  height: 100%;
`;

export const ContentContainer = styled.div`
  flex: 1 1 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
export const LabelValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 0.25rem;
`;

export const LabelValue = styled(LabelValueComponent)`
  flex: 1 1 33%;
  margin-bottom: 1rem;
`;
