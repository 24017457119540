import * as ParrotUi from '@kea-inc/parrot-ui';
import { MoveRight } from 'lucide-react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Input = styled(ParrotUi.Input)`
  flex: 1;
`;
export const Select = styled(ParrotUi.Select)`
  flex: 1;
`;

export const MoveRightIcon = styled(MoveRight)`
  flex: 0.075;
`;
