import { TabsList } from '@kea-inc/parrot-ui';
import { useState } from 'react';

import { Order } from '@/@types/order';
import { CallFlow } from '@/pages/Orders/OrderInfo/OrderTabs/CallFlow';
import { Cart } from '@/pages/Orders/OrderInfo/OrderTabs/Cart';
import { Survey } from '@/pages/Orders/OrderInfo/OrderTabs/Survey';
import { Transcript } from '@/pages/Orders/OrderInfo/OrderTabs/Transcript';

import * as S from './styles';

interface OrderTabsProps {
  order: Order;
}

type Tab = 'cart' | 'transcript' | 'flow' | 'survey';

export function OrderTabs(props: OrderTabsProps) {
  const { order } = props;
  const [tab, setTab] = useState<Tab>('cart');

  return (
    <S.Container>
      <S.Tabs value={tab} onValueChange={(value) => setTab(value as Tab)}>
        <TabsList>
          <S.TabsTrigger value="cart">Cart</S.TabsTrigger>
          <S.TabsTrigger value="transcript">Transcript</S.TabsTrigger>
          <S.TabsTrigger value="flow">Flow</S.TabsTrigger>
          <S.TabsTrigger value="survey">Survey</S.TabsTrigger>
        </TabsList>
        <S.TabsContentContainer>
          <S.TabsContent value="cart">
            <Cart order={order} />
          </S.TabsContent>
          <S.TabsContent value="transcript">
            <Transcript order={order} />
          </S.TabsContent>
          <S.TabsContent value="flow">
            <CallFlow order={order} />
          </S.TabsContent>
          <S.TabsContent value="survey">
            <Survey order={order} />
          </S.TabsContent>
        </S.TabsContentContainer>
      </S.Tabs>
    </S.Container>
  );
}
