import { RouteObject } from 'react-router-dom';
import { IoProvider } from 'socket.io-react-hook';

import { Metrics } from './Metrics';

export default function MetricsPage() {
  return (
    <IoProvider>
      <Metrics />
    </IoProvider>
  );
}

export const metricsRouter: RouteObject[] = [
  {
    path: '/metrics',
    element: <MetricsPage />,
    index: true,
  },
];
