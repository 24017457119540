import { combineReducers } from '@reduxjs/toolkit';

import {
  initialState as accountInitialState,
  reducer as account,
} from './account';
import { initialState as agentInitialState, reducer as agent } from './agent';
import { initialState as bpoInitialState, reducer as bpo } from './bpo';
import { initialState as brandInitialState, reducer as brand } from './brand';
import { initialState as fleetInitialState, reducer as fleet } from './fleet';
import { initialState as menuInitialState, reducer as menu } from './menu';
import { initialState as orderInitialState, reducer as order } from './order';
import {
  initialState as platformInitialState,
  reducer as platform,
} from './platform';
import { initialState as roleInitialState, reducer as role } from './role';
import { initialState as storeInitialState, reducer as store } from './store';
import {
  initialState as trainingInitialState,
  reducer as training,
} from './training';
import { initialState as userInitialState, reducer as user } from './user';
import {
  initialState as worksiteInitialState,
  reducer as worksite,
} from './worksite';

export const initialState = {
  fleet: fleetInitialState,
  training: trainingInitialState,
  brand: brandInitialState,
  agent: agentInitialState,
  store: storeInitialState,
  platform: platformInitialState,
  bpo: bpoInitialState,
  worksite: worksiteInitialState,
  user: userInitialState,
  role: roleInitialState,
  menu: menuInitialState,
  order: orderInitialState,
  account: accountInitialState,
};

export type UCState = typeof initialState;

export default combineReducers({
  agent,
  bpo,
  brand,
  fleet,
  training,
  platform,
  store,
  worksite,
  user,
  role,
  menu,
  order,
  account,
} satisfies Record<keyof UCState, unknown>);
