import { StoreState } from '@/store';

export const getTotalRecords = (state: StoreState) =>
  state.ui.order.totalRecords;

export const getDebugMode = (state: StoreState) => state.ui.order.debugMode;

export const getFilters = (state: StoreState) => state.ui.order.filters;

export const getTimestamp = (state: StoreState) => state.ui.order.timestamp;
