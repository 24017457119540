import { PhoneIcon } from 'lucide-react';

import * as S from './styles';

export function PartipantConnection() {
  return (
    <S.ParticipantConnection>
      <S.PhoneIconButton variant="success" size="icon">
        <PhoneIcon size={16} />
      </S.PhoneIconButton>
    </S.ParticipantConnection>
  );
}
