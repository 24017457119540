import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Platform } from '@/@types/platform';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useNotification } from '@/hooks/useNotification';
import { usePlatformColumnDefs } from '@/pages/Platforms/List/usePlatformColumnDefs';
import { useConnector } from '@/pages/Platforms/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

type DeleteDialogState = {
  platform: Platform | null;
};

export function List() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useNotification();

  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    platform: null,
  });

  const { columns } = usePlatformColumnDefs({
    onDeleteClick: (platform) => setDeleteDialog({ platform }),
    onUpdateClick: (platform) => navigate(`/platforms/${platform.id}`),
  });

  const { actions, selectors } = useConnector();

  useEffect(() => {
    dispatch(actions.getPlatforms());
  }, []);

  const { fetching, platforms, deleting } = selectors;

  const {
    filteredItems: filteredPlatforms,
    searchTerm,
    setSearchTerm,
  } = useFuseSearch<Platform>({
    itemKeys: ['id', 'name'],
    items: platforms,
  });

  const handleDeleteStore = () => {
    if (deleteDialog.platform)
      dispatch(actions.deletePlatform({ id: deleteDialog.platform.id }))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'Platform deleted successfully',
          });
          setDeleteDialog({ platform: null });
        });
  };

  return (
    <PageCard
      title="Platforms"
      headerContent={
        <S.InputButtonContainer>
          <S.PageHeaderInput
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder="Search"
          />
          <Button onClick={() => navigate('/platforms/new')}>
            Add Platform
          </Button>
        </S.InputButtonContainer>
      }
    >
      {fetching && filteredPlatforms.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={filteredPlatforms}
          stickyHeader
          onRowClick={(row) => navigate(`/platforms/${row.original.id}`)}
        />
      )}
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to delete <b>{deleteDialog.platform?.name}</b>
            ?
          </Typography>
        }
        open={!!deleteDialog.platform}
        onOpenChange={(open) =>
          setDeleteDialog({ platform: open ? deleteDialog.platform : null })
        }
        onSubmit={handleDeleteStore}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </PageCard>
  );
}
