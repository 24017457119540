import { useEffect } from 'react';
import { useSocketEvent } from 'socket.io-react-hook';

import { UseSocketReturnType } from '@/@types';
import { QueuedBrandsChangeData } from '@/@types/metrics';
import { QueuedBrandSendMessage } from '@/@types/metrics/socket';
import { useNotification } from '@/hooks/useNotification';

export function useQueuedBrandsSocket(socketConfig: UseSocketReturnType<any>) {
  const { socket, error, connected } = socketConfig;

  const notification = useNotification();

  const {
    lastMessage: queuedBrandsChanges,
    sendMessage: sendQueuedBrandChange,
  } = useSocketEvent<QueuedBrandsChangeData>(socket, 'queuedBrandsChange');

  useEffect(() => {
    if (error) {
      notification.error('Failed to get queued brands changes', {
        autoClose: false,
        description: 'You are no longer receiving updates',
      });
    }
  }, [error]);

  useEffect(
    () => () => {
      socket.disconnect();
    },
    [],
  );

  return {
    connected,
    queuedBrandsChanges,
    sendQueuedBrandChange: sendQueuedBrandChange as QueuedBrandSendMessage,
    error,
    socket,
  };
}
