import { Color } from '@kea-inc/parrot-ui/dist/types';
import dayjs from 'dayjs';

import {
  ReducedMetricsData,
  ReducedBrandsMetrics,
  Status,
} from '@/@types/agent';
import { Activity, MetricsData } from '@/@types/metrics';

export function reduceMetrics(
  metrics?: MetricsData,
): ReducedMetricsData | null {
  if (!metrics) {
    return null;
  }

  const reducedMetrics: ReducedMetricsData = {
    maxWaitTime: formatDuration(metrics.queue.maxWaitTime),
    avgWaitTime: formatDuration(metrics.queue.avgWaitTime),
    maxIdleTime: formatDuration(metrics.agent.maxIdleTime),
    avgIdleTime: formatDuration(metrics.agent.avgIdleTime),
    longestTaskWaitingTime: formatDuration(
      metrics.queue.longestTaskWaitingTime,
    ),
    activeAgents: metrics.agent.activeAgents,
    customersInQueue: metrics.queue.customersInQueue,
  };

  return reducedMetrics;
}

function formatDuration(durationInSeconds: number): string {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  return `${minutes}m ${String(seconds).padStart(2, '0')}s`;
}

export function reduceBrands(metrics?: MetricsData): ReducedBrandsMetrics[] {
  if (!metrics) {
    return [];
  }

  return metrics.agent.byBrand
    .map((agentBrand) => {
      const queueBrand = metrics.queue.byBrand.find(
        ({ name }) => name === agentBrand.name,
      );
      return {
        name: agentBrand.name,
        activeAgents: agentBrand.activeAgents,
        tasksEntered: agentBrand.tasksEntered,
        tasksCompleted: agentBrand.tasksCompleted,
        customersInQueue: queueBrand?.customersInQueue ?? 0,
        maxWaitTime: formatDuration(queueBrand?.maxWaitTime ?? 0),
        avgWaitTime: formatDuration(queueBrand?.avgWaitTime ?? 0),
        longestTaskWaitingTime: formatDuration(
          queueBrand?.longestTaskWaitingTime ?? 0,
        ),
      };
    })
    .sort((a, b) => b.tasksEntered - a.tasksEntered);
}

export function reduceAgents(metrics?: MetricsData, brandFilterId?: string) {
  if (!metrics) {
    return [];
  }

  let filteredAgents = [...metrics.allAgents];

  if (brandFilterId && brandFilterId !== 'all') {
    filteredAgents = filteredAgents.filter((agent) =>
      Object.keys(agent.queuedBrands).includes(brandFilterId),
    );
  }

  return filteredAgents
    .map((agent) => ({
      name: `${agent.firstName} ${agent.lastName}`,
      status: formatActivity(agent.activity),
      statusColor: getActivityColor(agent.activity),
      timeSpanInSeconds: formatTimeSpanInSeconds(agent.dateStatusChanged),
      id: agent.id,
      roleWeight: String(agent.roleWeight),
      username: agent.username,
      timeSpan: formatTimeSpan(agent.dateStatusChanged),
      brands: Object.entries(agent.queuedBrands).map(([id, name]) => ({
        name,
        id,
        inCall: agent.currentCallBrandId === id,
      })),
      description: getActivityDescription(agent.activity),
    }))
    .sort((a, b) => {
      if (a.status === b.status) {
        return a.timeSpanInSeconds > b.timeSpanInSeconds ? 1 : -1;
      }
      return a.status.localeCompare(b.status);
    });
}

function formatActivity(activity: Activity): Status {
  switch (activity) {
    case 'Busy':
      return 'In call';
    case 'Idle':
      return 'Idle';
    case 'Offline':
      return 'Offline';
    case 'Break':
      return 'Break';
    case 'Lunch':
      return 'Lunch';
    case 'Bio_Break':
      return 'Bio-Break';
    case 'Training':
      return 'Training';
    case 'Coaching':
      return 'Coaching';
    case 'Absent':
      return 'Absent';
    case 'Network_Issue':
      return 'Network Issue';

    default:
      return 'Unknown';
  }
}

function getActivityColor(activity: Activity): Color {
  switch (activity) {
    case 'Busy':
      return 'success';
    case 'Idle':
      return 'accent_2';
    case 'Offline':
      return 'error';
    case 'Break':
      return 'primary';
    case 'Lunch':
      return 'primary';
    case 'Bio_Break':
      return 'accent_2';
    case 'Training':
      return 'accent_1';
    case 'Coaching':
      return 'black';
    case 'Absent':
      return 'error';
    case 'Network_Issue':
      return 'black';
    default:
      return 'black';
  }
}

function getActivityDescription(activity: Activity): string {
  switch (activity) {
    case 'Busy':
      return 'The agent is currently on a call with a customer.';
    case 'Idle':
      return 'The agent is available and ready to take calls.';
    case 'Offline':
      return 'The agent is not clocked in or is unavailable.';
    case 'Break':
      return 'Scheduled break time designated by Operations for agents to rest during their shift.';
    case 'Lunch':
      return 'Designated lunch period set by Operations. Clicked when agent starts their lunch break.';
    case 'Bio_Break':
      return 'Quick bathroom break. Informs Operations that you`ve stepped away momentarily.';
    case 'Training':
      return 'Participating in a training session. Time for cross-training or onboarding activities.';
    case 'Coaching':
      return 'One-on-one feedback session with a supervisor or Quality Control for performance improvement or review.';
    case 'Absent':
      return 'Marked as absent after missing a live queued call. Status indicates non-availability.';
    case 'Network_Issue':
      return 'Agent with a network issue';
    default:
      return '';
  }
}

function formatTimeSpan(lastUpdated: string): string {
  const lastUpdateDate = dayjs(lastUpdated);
  const now = dayjs();
  const diff = now.diff(lastUpdateDate);
  const diffDuration = dayjs.duration(diff);

  // If the last update was less than a day ago, return HH:mm:ss
  if (now.diff(lastUpdateDate, 'days') < 1) {
    return `${diffDuration.hours()}h ${diffDuration.minutes()}m ${diffDuration.seconds()}s`;
  }

  // If the last update was less than a week ago, return d HH:mm
  if (now.diff(lastUpdateDate, 'day') < 7) {
    return `${diffDuration.days()}d ${diffDuration.hours()}h ${diffDuration.minutes()}m`;
  }

  // If the last update was less than a month ago, return w d
  if (now.diff(lastUpdateDate, 'day') < 30) {
    return `${diffDuration.weeks()}w ${diffDuration.hours()}h`;
  }

  // If the last update was less than a year ago, return m w
  if (now.diff(lastUpdateDate, 'day') < 365) {
    return `${diffDuration.months()}m ${diffDuration.weeks()}w`;
  }

  // If the last update was more than a year ago, return y m

  return `${diffDuration.years()}y ${diffDuration.months()}m`;
}

function formatTimeSpanInSeconds(lastUpdated: string): number {
  const lastUpdateDate = dayjs(lastUpdated);
  const now = dayjs();

  const diff = now.diff(lastUpdateDate, 'second');
  return Number(diff.toFixed());
}
