import env from '@beam-australia/react-env';

import { dataService } from '@/services';

export type DeleteAgent = {
  input: { id: string };
  output: { id: string };
};

export const deleteAgent = async (
  input: DeleteAgent['input'],
): Promise<DeleteAgent['output']> => {
  await dataService.delete(
    `/agents/${input.id}/workspace/${env('TWILIO_WORKSPACE_SID')}`,
  );
  return { id: input.id };
};
