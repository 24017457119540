import env from '@beam-australia/react-env';
import axios from 'axios';
import { GraphQLClient } from 'graphql-request';

import { getSdk } from '@/graphql/schema';

import { makeClientWithVersion } from '../util/client';

export const client = makeClientWithVersion(
  axios.create({
    baseURL: env('MENU_SERVICE'),
  }),
  {
    defaultVersion: 'v1',
  },
);

export function setDefaultJwtTokenForMenuService(token: string) {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const graphqlClient = new GraphQLClient(
  `${env('MENU_SERVICE')}/graphql`,
);

export const graphqlSDK = getSdk(graphqlClient);
