import { useAppSelector } from '@/store';
import { selectors as bpoSelectors } from '@/store/entities/bpo';
import {
  actions as bpoUCActions,
  selectors as bpoUcSelectors,
} from '@/store/useCases/bpo';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(bpoUcSelectors.isUcFetching('getBpos')),
    updating: useAppSelector(bpoUcSelectors.isUcFetching('updateBpo')),
    creating: useAppSelector(bpoUcSelectors.isUcFetching('createBpo')),
    deleting: useAppSelector(bpoUcSelectors.isUcFetching('deleteBpo')),
    bpos: useAppSelector(bpoSelectors.getBpos),
    bpoEntities: useAppSelector(bpoSelectors.getBpoEntities),
  };

  const actions = {
    getBpos: bpoUCActions.getBpos,
    createBpo: bpoUCActions.createBpo,
    updateBpo: bpoUCActions.updateBpo,
    deleteBpo: bpoUCActions.deleteBpo,
  };

  return { actions, selectors };
};
