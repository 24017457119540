import { Input, Label } from '@kea-inc/parrot-ui';

import { Order } from '@/@types/order';

import * as S from './styles';

interface SurveyProps {
  order: Order;
}

export function Survey(props: SurveyProps) {
  const { order } = props;

  const mostRecentSurvey = order.surveys?.at(-1);

  return (
    <S.Container>
      <S.InputContainer>
        <Label>Why the order was not placed?</Label>
        <Input
          readOnly
          defaultValue={mostRecentSurvey?.surveyData?.primaryDisposition}
        />
      </S.InputContainer>
      {mostRecentSurvey?.surveyData?.primaryDisposition ? (
        <S.InputContainer>
          <Label>{`Which kind of "${mostRecentSurvey.surveyData.primaryDisposition}"?`}</Label>
          <Input
            readOnly
            defaultValue={
              mostRecentSurvey?.surveyData?.secondaryDisposition ?? 'N/A'
            }
          />
        </S.InputContainer>
      ) : null}
      <S.InputContainer>
        <Label>Any comments?</Label>
        <Input
          readOnly
          defaultValue={mostRecentSurvey?.surveyData?.comments ?? 'N/A'}
        />
      </S.InputContainer>
    </S.Container>
  );
}
