import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const DialogContent = styled(ParrotUi.DialogContent)`
  min-width: 30%;
  height: 50%;
  display: flex;
  flex-direction: column;
`;

export const DataTableContainer = styled.div`
  overflow: auto;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  padding: 1rem 0.5rem 0.5rem 0;

  overflow-y: auto;
  width: 100%;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eff1f3;
  padding: 0.5rem;
  font-size: 0.8rem;
`;

export * from '../styles';
