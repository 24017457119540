import camelcaseKeys from 'camelcase-keys';

import { TwilioNumber } from '@/@types/store/twilio';
import { StoreService } from '@/services/@types/store';
import { dataService } from '@/services/clients';

export type BuyTwilioNumber = {
  input: { primaryPhone: string };
  output: TwilioNumber;
};

export const buyTwilioNumber = async (
  input: BuyTwilioNumber['input'],
): Promise<BuyTwilioNumber['output']> => {
  const areaCode = input.primaryPhone.slice(4, 7);

  const { data } = await dataService.post<StoreService.TwilioNumber>(
    '/stores/buyDid',
    {
      area_code: areaCode,
    },
  );

  return camelcaseKeys(data);
};
