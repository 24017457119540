import { useAppSelector } from '@/store';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as storeSelectors } from '@/store/entities/store';
import { actions as brandUcActions } from '@/store/useCases/brand';

export const useConnector = () => {
  const selectors = {
    storesEntities: useAppSelector(storeSelectors.getStoreEntities),
    brandsEntities: useAppSelector(brandSelectors.getBrandsEntities),
  };

  const actions = {
    getBrand: brandUcActions.getBrand,
  };

  return { actions, selectors };
};
