import { Bpo } from '@/@types/bpo';
import { dataService } from '@/services';
import { BpoService } from '@/services/@types/bpo';
import { mapBpoToDomain } from '@/services/useCases/bpo/adapters/domain';

export type GetBpos = {
  input: void;
  output: Bpo[];
};

export const getBpos = async (): Promise<GetBpos['output']> => {
  const { data } = await dataService.get<BpoService.Bpo[]>('/bpos');
  return data.filter(({ id }) => Boolean(id)).map(mapBpoToDomain);
};
