export const INIT_STORE_HOURS = {
  SUNDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'SUNDAY',
  },
  MONDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'MONDAY',
  },
  TUESDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'TUESDAY',
  },
  WEDNESDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'WEDNESDAY',
  },
  THURSDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'THURSDAY',
  },
  FRIDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'FRIDAY',
  },
  SATURDAY: {
    from: '00:00:00',
    fromAmPm: 'AM',
    to: '00:00:00',
    toAmPm: 'AM',
    active: true,
    timezone: 'America/Los_Angeles',
    dayOfWeek: 'SATURDAY',
  },
} as const;
