import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { Auth0Role, Permission } from '@/@types/user';
import { actions } from '@/store/useCases/role';

export const adapter = createEntityAdapter<Auth0Role>({
  sortComparer: (r1, r2) => r1.name.localeCompare(r2.name),
});

export const permissionAdapter = createEntityAdapter<Permission>({
  sortComparer: (p1, p2) => p1.name.localeCompare(p2.name),
});

export type State = {
  roles: EntityState<Auth0Role, string>;
  permissions: EntityState<Permission, string>;
};

export const initialState: State = {
  roles: adapter.getInitialState(),
  permissions: permissionAdapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getPermissions.fulfilled, (state, action) => {
      permissionAdapter.addMany(state.permissions, action.payload);
    });
    builder.addCase(actions.getRoles.fulfilled, (state, action) => {
      adapter.upsertMany(state.roles, action.payload);
    });
    builder.addCase(actions.getRole.fulfilled, (state, action) => {
      adapter.upsertOne(state.roles, {
        id: action.payload.roleName,
        permissions: action.payload.permissions,
        name: action.payload.roleName,
        description: '',
      });
    });
    builder.addCase(actions.updateRole.fulfilled, (state, action) => {
      adapter.updateOne(state.roles, {
        id: action.payload.name,
        changes: action.payload,
      });
    });
  },
});

export default slice;
