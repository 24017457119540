import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import * as GlobalStyles from '@/styles';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const SectionsContainer = GlobalStyles.SectionsContainer;
export const ToggleContainer = GlobalStyles.ToggleContainer;
export const Toggle = GlobalStyles.Toggle;

export const FormItemToggle = styled(ParrotUi.FormItemToggle)`
  flex: 0 1 33%;
`;

export const FormItemDatePicker = styled(ParrotUi.FormItemDatePicker)`
  flex: 0 1 33%;
` as typeof ParrotUi.FormItemDatePicker;

export const FormItemInput = styled(ParrotUi.FormItemInput)`
  flex: 0 1 33%;
` as typeof ParrotUi.FormItemInput;

export const FormItemSelect = styled(ParrotUi.FormItemSelect)`
  flex: 0 1 33%;
` as typeof ParrotUi.FormItemSelect;
