import { Avatar, AvatarFallback, Typography } from '@kea-inc/parrot-ui';

import * as S from './styles';

interface ParticipantCardProps {
  name?: string;
  isAgent?: boolean;
  className?: string;
}

export function ParticipantCard({
  name,
  isAgent,
  className,
}: ParticipantCardProps) {
  const initials: string | undefined = name
    ?.split(' ')
    .map((word: string) => word.at(0))
    .join('');

  return (
    <S.Card className={className} $isAgent={isAgent}>
      <Avatar>
        <AvatarFallback>{initials}</AvatarFallback>
      </Avatar>
      <S.NameContainer $isAgent={isAgent}>
        <S.NameTypography size="sm">{name}</S.NameTypography>
        <Typography size="sm" muted>
          {isAgent ? 'Agent' : 'Trainer'}
        </Typography>
      </S.NameContainer>
    </S.Card>
  );
}
