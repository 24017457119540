import { RouteObject } from 'react-router-dom';

import { Create } from '@/pages/BPOs/Create';
import { Update } from '@/pages/BPOs/Update';

import { List } from './List';

export const bposRouter: RouteObject[] = [
  {
    path: '/bpos',
    element: <List />,
    index: true,
  },
  {
    path: '/bpos/new',
    element: <Create />,
  },
  {
    path: '/bpos/:bpoId',
    element: <Update />,
  },
];
