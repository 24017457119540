import * as z from 'zod';

import { PaginatedStoresFilter } from '@/@types/store';

export type SchemaObjShape = Record<keyof PaginatedStoresFilter, z.ZodTypeAny>;

export const storeFilterSchema = z.object({
  storeId: z.string().optional(),
  franchiseStoreNumber: z.string().optional(),
  brandId: z.string().optional(),
  primaryPhone: z.string().optional(),
  secondaryPhone: z.string().optional(),
  twilioNumber: z.string().optional(),
  location: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  area: z.string().optional(),
  fleetId: z.string().optional(),
  isOn: z.string().optional(),
  timezone: z.string().optional(),
} satisfies SchemaObjShape);

export type StoreFilterSchema = z.infer<typeof storeFilterSchema>;
