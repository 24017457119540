import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import _ from 'lodash';
import { FilterIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { AgentsDialog } from '@/pages/Orders/List/AgentsDialog';
import { FilterDialog } from '@/pages/Orders/List/FilterDialog';
import { mapFilterFormValuesToApi } from '@/pages/Orders/List/adapters';
import { mapOrderSearchParamsToOrderFilters } from '@/pages/Orders/List/adapters/domain';
import { OrderFilterSchema } from '@/pages/Orders/List/schema';
import { useFiltersForm } from '@/pages/Orders/List/useFiltersForm';
import { useConnector } from '@/pages/Orders/connector';
import { useAppDispatch } from '@/store';

import { useOrdersColumnDefs } from './useOrdersColumnDefs';

const pageSize = 15;

type DeleteDialogState = {
  type?: 'transcripts' | 'recordings';
  orderId?: string;
};

export function List() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [lastPage, setLastPage] = useState(1);

  const [agentIds, setAgentIds] = useState<string[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({});

  const { actions, selectors } = useConnector();

  const { fetching, totalRecords, ordersWithFilters, filters, deleting } =
    selectors;

  const orders = filters ? ordersWithFilters ?? [] : selectors.orders;

  const { columns } = useOrdersColumnDefs({
    onAgentDialogClick: setAgentIds,
    onDeleteRecordings: (orderId) =>
      setDeleteDialog({ type: 'recordings', orderId }),
    onDeleteTranscripts: (orderId) =>
      setDeleteDialog({ type: 'transcripts', orderId }),
  });

  const {
    form,
    filterOpen,
    searchParams,
    setFilterOpen,
    handleFinallySubmit,
    handleClearFilters,
  } = useFiltersForm();

  const handlePaginatedOrders = useCallback(
    _.debounce(
      (sParams?: URLSearchParams) =>
        dispatch(
          actions.getPaginatedOrders({
            page: lastPage,
            pageSize,
            filters: sParams?.size
              ? mapOrderSearchParamsToOrderFilters(sParams)
              : undefined,
          }),
        )
          .unwrap()
          .then((res) => {
            setLastPage(lastPage + 1);
            dispatch(actions.setTotalRecords(res.totalRecords));
            dispatch(
              actions.getStoreByIds({
                ids: res.items.map((item) => item.storeId),
              }),
            );
            dispatch(
              actions.getAccountByIds({
                ids: res.items.map((item) => item.accountId),
              }),
            );
          }),
      500,
    ),
    [searchParams],
  );

  useEffect(() => {
    dispatch(actions.getBrands());
    dispatch(actions.getAgents());
  }, []);

  useEffect(() => {
    if (!fetching) {
      handlePaginatedOrders(searchParams);
    }
  }, [searchParams]);

  const handleLoadMore = () => {
    if (!fetching)
      dispatch(
        actions.getPaginatedOrders({ page: lastPage, pageSize, filters }),
      )
        .unwrap()
        .then((res) => {
          setLastPage(lastPage + 1);
          if (res.items.length < pageSize) {
            dispatch(actions.setTotalRecords(orders.length));
          }
          dispatch(
            actions.getStoreByIds({
              ids: res.items.map((item) => item.storeId),
            }),
          );
          dispatch(
            actions.getAccountByIds({
              ids: res.items.map((item) => item.accountId),
            }),
          );
        });
  };

  const handleSubmit = (values: OrderFilterSchema) => {
    const filteredValues = mapFilterFormValuesToApi(values);
    dispatch(
      actions.getPaginatedOrders({
        page: 1,
        pageSize,
        filters: filteredValues,
      }),
    )
      .unwrap()
      .then((res) => {
        setLastPage(2);
        dispatch(actions.setTotalRecords(res.totalRecords));
      })
      .finally(() => {
        handleFinallySubmit(filteredValues);
      });
  };

  const handleDeleteUser = () => {
    if (!deleteDialog.orderId) {
      return;
    }
    if (deleteDialog.type === 'recordings') {
      dispatch(actions.deleteOrderRecording({ orderId: deleteDialog.orderId }))
        .unwrap()
        .then(() => {
          setDeleteDialog({});
        });
    }
    if (deleteDialog.type === 'transcripts') {
      dispatch(
        actions.deleteOrderTranscripts({ orderId: deleteDialog.orderId }),
      )
        .unwrap()
        .then(() => {
          setDeleteDialog({});
        });
    }
  };

  const PageCardHeader = (
    <>
      <FlexGrow />
      {filters ? (
        <Button onClick={handleClearFilters} variant="ghost">
          Clear filters
        </Button>
      ) : null}

      <Button onClick={() => setFilterOpen(true)}>
        <FilterIcon size={14} /> Filter
      </Button>
    </>
  );

  return (
    <PageCard title="Orders" headerContent={PageCardHeader}>
      {fetching && orders.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={orders}
          stickyHeader
          onRowClick={(row) => {
            navigate(`/orders/${row.original.id}`);
          }}
          virtualizationProps={{ estimateSize: () => 90, overscan: 5 }}
          infiniteLoadProps={{
            onLoadMore: handleLoadMore,
            totalRecords,
          }}
        />
      )}
      <AgentsDialog
        agentIds={agentIds}
        open={!!agentIds.length}
        onOpenChange={(open) => (!open ? setAgentIds([]) : undefined)}
      />
      <FilterDialog
        open={filterOpen}
        onOpenChange={setFilterOpen}
        onSubmit={handleSubmit}
        form={form}
      />
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to delete the {deleteDialog?.type}?
          </Typography>
        }
        open={!!deleteDialog.orderId}
        onOpenChange={(open) =>
          setDeleteDialog({
            orderId: open ? deleteDialog.orderId : undefined,
            type: deleteDialog.type,
          })
        }
        onSubmit={handleDeleteUser}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </PageCard>
  );
}
