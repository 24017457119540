import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { getFilters } from '@/store/ui/account/selectors';

import { adapter, adapterWithFilter } from './slice';
import { StoreState } from '../..';

export const getAccounts = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.account.account,
).selectAll;

export const getAccountEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.account.account,
).selectEntities;

export const getAccountsWithFilter = adapterWithFilter.getSelectors<StoreState>(
  (stt) => stt.entities.account.accountsWithFilter,
).selectById;

export const getAccountsWithFilters = createDraftSafeSelector(
  getAccountEntities,
  (stt: StoreState) =>
    getAccountsWithFilter(stt, JSON.stringify(getFilters(stt))),
  (accountEntities, accountsWithFilters) =>
    accountsWithFilters?.accountIds.map(
      (accountId) => accountEntities[accountId],
    ),
);
