import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { STORE_FIELDS } from '@/constants/store';
import { StoreState } from '@/store';

export const getFilters = (state: StoreState) => state.ui.store.filters;

export const getTotalRecords = (state: StoreState) =>
  state.ui.store.totalRecords;

export const getBulkCreation = (state: StoreState) =>
  state.ui.store.bulkCreation;

export const getRemainingRequiredStoreFields = createDraftSafeSelector(
  getBulkCreation,
  ({ selectedFields }) => {
    const requiredStoreFields = STORE_FIELDS.filter(
      (storeField) => storeField.isRequired && !storeField.defaultValue,
    );

    const selectedValues = Object.values(selectedFields);

    return requiredStoreFields.filter(
      (requiredStoreField) =>
        selectedValues.indexOf(requiredStoreField.name) === -1,
    );
  },
);
export const getRemainingStoreFields = createDraftSafeSelector(
  getBulkCreation,
  ({ selectedFields }) => {
    const notHiddenStoreFields = STORE_FIELDS.filter(
      (storeField) => !storeField.isHidden,
    );

    const selectedValues = Object.values(selectedFields);

    return notHiddenStoreFields.filter(
      (requiredStoreField) =>
        selectedValues.indexOf(requiredStoreField.name) === -1,
    );
  },
);

export const getAvailableStoreFields = createDraftSafeSelector(
  getBulkCreation,
  ({ selectedFields }) => {
    const selectedFieldsValues = Object.values(selectedFields);
    return STORE_FIELDS.filter(
      (storeFields) => selectedFieldsValues.indexOf(storeFields.name) === -1,
    );
  },
);
