import { Bpo } from '@/@types/bpo';
import { BpoService } from '@/services/@types/bpo';

export const mapBpoToDomain = ({
  work_sites,
  ...bpo
}: BpoService.Bpo): Bpo => ({
  ...bpo,
  worksites: work_sites,
});
