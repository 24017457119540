import styled from 'styled-components';

import { TextLeft } from '@/components/DataTable';

export const StatusBadgeContainer = styled.div`
  cursor: pointer;
`;

export const ActionsCell = styled(TextLeft)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
