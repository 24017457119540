import { Brand } from '@/@types/brand';
import { UpdateBrandRequest } from '@/@types/brand/update';
import { dataService } from '@/services';
import { BrandService } from '@/services/@types/brand';
import {
  mapBrandToDomain,
  mapUpdateBrandToApi,
} from '@/services/useCases/brand/adapters';

export type UpdateBrand = {
  input: UpdateBrandRequest;
  output: Brand;
};

export const updateBrand = async (
  input: UpdateBrand['input'],
): Promise<UpdateBrand['output']> => {
  const { id, ...brand } = input;
  const { data } = await dataService.patch<BrandService.Brand>(
    `/brands/${id}`,
    mapUpdateBrandToApi(brand),
  );
  return mapBrandToDomain(data);
};
