import { Fleet } from '@/@types/fleet';
import { dataService } from '@/services';
import { FleetService } from '@/services/@types/fleet';
import { mapFleetToDomain } from '@/services/useCases/fleet/adapters/domain';

export type GetFleets = {
  input: void;
  output: Fleet[];
};

export const getFleets = async (): Promise<GetFleets['output']> => {
  const fleets = (await dataService.get<FleetService.Fleet[]>('/fleets')).data;

  return fleets.map(mapFleetToDomain);
};

export type GetFleet = {
  input: string;
  output: Fleet;
};

export const getFleet = async (
  input: GetFleet['input'],
): Promise<GetFleet['output']> => {
  const fleet = (await dataService.get<FleetService.Fleet>(`/fleets/${input}`))
    .data;

  return mapFleetToDomain(fleet);
};

export type RemoveFleet = {
  input: string;
  output: string;
};

export const removeFleet = async (input: RemoveFleet['input']) => {
  await dataService.delete(`/fleets/${input}`);

  return input;
};
