import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const DialogContent = styled(ParrotUi.DialogContent)`
  min-width: 60vw;
`;

export const FormItemDatePicker = styled(ParrotUi.FormItemDatePicker)`
  flex: 0 1 39.5%;
` as typeof ParrotUi.FormItemDatePicker;

export const FieldItemsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1rem;
`;

export const ComboboxItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const NameEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AgentName = styled.div`
  font-weight: bold;
`;
