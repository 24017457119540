import { z } from 'zod';

import { User } from '@/@types/user';

type UserSchemaProperties = Pick<
  User,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'roles'
  | 'fleets'
  | 'bpoId'
  | 'permissions'
>;

type SchemaObjShape = Record<keyof UserSchemaProperties, z.ZodTypeAny>;

export const userSchema = z.object({
  id: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z
    .string()
    .email('This is not a valid email.')
    .refine((aME) => aME.includes('@kea.cloud'), {
      message: `Please use user's @kea.cloud email address`,
    }),
  roles: z.string().array(),
  fleets: z.string().array(),
  bpoId: z.string().nullable().optional(),
  permissions: z
    .object({
      name: z.string(),
      description: z.string(),
      sources: z
        .object({
          type: z.enum(['ROLE', 'DIRECT'] as const),
          roleName: z.string().optional(),
        })
        .array(),
    })
    .array(),
} satisfies SchemaObjShape);

export type UserSchema = z.infer<typeof userSchema>;
