import { RouteObject } from 'react-router-dom';

import { PlatformForm } from '@/pages/Platforms/PlatformForm/PlatformForm';

import { List } from './List/List';

export const platformsRouter: RouteObject[] = [
  {
    path: '/platforms',
    element: <List />,
    index: true,
  },
  {
    path: '/platforms/new',
    element: <PlatformForm type="create" />,
  },
  {
    path: '/platforms/:platformId',
    element: <PlatformForm type="update" />,
  },
];
