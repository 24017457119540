import { Order } from '@/@types/order';
import { TranscriptMessage } from '@/pages/Orders/OrderInfo/OrderTabs/Transcript/TranscriptMessage';

import * as S from './styles';
interface TranscriptProps {
  order: Order;
}

export function Transcript(props: TranscriptProps) {
  const { order } = props;

  return (
    <S.Container>
      {order.transcripts?.map((transcript) => (
        <TranscriptMessage key={transcript.id} transcript={transcript} />
      ))}
    </S.Container>
  );
}
