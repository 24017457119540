import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { KeaLoading } from '@/components/KeaLoading';

type Auth0RedirectProps = {
  children: React.ReactNode;
};

export function Auth0Redirect({ children }: Auth0RedirectProps) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading]);

  if (isLoading || !isAuthenticated) {
    return <KeaLoading />;
  }

  return children;
}
