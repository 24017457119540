import { useAppSelector } from '@/store';
import { selectors as accountSelectors } from '@/store/entities/account';
import { selectors as agentSelectors } from '@/store/entities/agent';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as orderSelectors } from '@/store/entities/order';
import { selectors as storeSelectors } from '@/store/entities/store';
import {
  actions as orderUiActions,
  selectors as orderUiSelectors,
} from '@/store/ui/order';
import { selectors as themeUiSelectors } from '@/store/ui/theme';
import {
  actions as accountUcActions,
  selectors as accountUcSelectors,
} from '@/store/useCases/account';
import {
  actions as agentUcActions,
  selectors as agentUcSelectors,
} from '@/store/useCases/agent';
import {
  actions as brandUcActions,
  selectors as brandUcSelectors,
} from '@/store/useCases/brand';
import {
  selectors as orderUcSelectors,
  actions as orderUcActions,
} from '@/store/useCases/order';
import {
  actions as storeUcActions,
  selectors as storeUcSelectors,
} from '@/store/useCases/store';

const isFetchingUcs = [
  orderUcSelectors.isUcFetching('getOrder'),
  orderUcSelectors.isUcFetching('getOrderMenuItems'),
  orderUcSelectors.isUcFetching('getOrderTranscripts'),
  orderUcSelectors.isUcFetching('getOrderRecording'),
  brandUcSelectors.isUcFetching('getBrands'),
  agentUcSelectors.isUcFetching('getAgents'),
  storeUcSelectors.isUcFetching('getStore'),
  accountUcSelectors.isUcFetching('getAccount'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    orderEntities: useAppSelector(orderSelectors.getOrderEntities),
    agentEntities: useAppSelector(agentSelectors.getAgentEntities),
    storeEntities: useAppSelector(storeSelectors.getStoreEntities),
    brandEntities: useAppSelector(brandSelectors.getBrandsEntities),
    accountEntities: useAppSelector(accountSelectors.getAccountEntities),
    theme: useAppSelector(themeUiSelectors.getTheme),
    debugMode: useAppSelector(orderUiSelectors.getDebugMode),
  };

  const actions = {
    getOrder: orderUcActions.getOrder,
    getOrderMenuItems: orderUcActions.getOrderMenuItems,
    getOrderTranscripts: orderUcActions.getOrderTranscripts,
    getOrderRecording: orderUcActions.getOrderRecording,
    getOrderCallFlow: orderUcActions.getOrderCallFlow,
    getOrderSurveys: orderUcActions.getOrderSurveys,
    getBrands: brandUcActions.getBrands,
    getAgents: agentUcActions.getAgents,
    getStore: storeUcActions.getStore,
    getAccount: accountUcActions.getAccount,
    toggleDebugMode: orderUiActions.toggleDebugMode,
    setTimestamp: orderUiActions.setTimestamp,
  };

  return { actions, selectors };
};
