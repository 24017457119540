import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Agent } from '@/@types/agent';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useNotification } from '@/hooks/useNotification';
import { useAgentColumnDefs } from '@/pages/Agents/List/useAgentColumnDefs';
import { useConnector } from '@/pages/Agents/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

type DeleteDialogState = {
  agent: Agent | null;
};

export function List() {
  const navigate = useNavigate();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const { actions, selectors } = useConnector();
  const { fetching, agents, deleting } = selectors;

  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    agent: null,
  });

  useEffect(() => {
    dispatch(actions.getAgents());
  }, []);

  const { columns } = useAgentColumnDefs({
    onDeleteClick: (agent) => setDeleteDialog({ agent }),
    onUpdateClick: (agent) => navigate(`/agents/${agent.id}`),
  });

  const {
    filteredItems: filteredAgents,
    searchTerm,
    setSearchTerm,
  } = useFuseSearch({
    itemKeys: ['id', 'firstName', 'lastName', 'username', 'email'],
    items: agents,
  });

  const handleDeleteStore = () => {
    if (deleteDialog.agent)
      dispatch(actions.deleteAgent({ id: deleteDialog.agent?.id }))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'Agent deleted successfully!',
          });
          setDeleteDialog({ agent: null });
        });
  };

  return (
    <PageCard
      title="Agents"
      headerContent={
        <>
          <FlexGrow />
          <S.Input
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder="Search"
          />
          <Button onClick={() => navigate('/agents/new')}>Add Agent</Button>
        </>
      }
    >
      {fetching && filteredAgents.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={filteredAgents}
          stickyHeader
          onRowClick={(agent) => navigate(`/agents/${agent.original.id}`)}
        />
      )}
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to delete{' '}
            <b>{deleteDialog.agent?.username}</b>?
          </Typography>
        }
        open={!!deleteDialog.agent}
        onOpenChange={(open) =>
          setDeleteDialog({ agent: open ? deleteDialog.agent : null })
        }
        onSubmit={handleDeleteStore}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </PageCard>
  );
}
