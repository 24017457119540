import { z } from 'zod';

import { AgentBrand } from '@/@types/agent';

type AgentBrandSchemaProperties = Pick<
  AgentBrand,
  'trained' | 'queued' | 'key' | 'name'
>;

type SchemaObjShape = Record<keyof AgentBrandSchemaProperties, z.ZodTypeAny>;

export const agentBrandSchema = z.object({
  trained: z.boolean(),
  queued: z.boolean(),
  key: z.string(),
  name: z.string(),
} satisfies SchemaObjShape);

export type AgentBrandSchema = z.infer<typeof agentBrandSchema>;
