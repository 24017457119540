import { Select } from '@kea-inc/parrot-ui';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

import { StoreField } from '@/constants/store';
import { FormItemYesNo } from '@/pages/Stores/BulkCreation/ManualAssignment/FormItemYesNo';

import * as S from './styles';

export const mapStoreFieldToFormItemComponent = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  storeField: StoreField,
  field: ControllerRenderProps<TFieldValues, TName>,
) => {
  if (storeField.type === 'boolean') {
    return <FormItemYesNo field={field} label={storeField.label} />;
  }
  if (storeField.type === 'date') {
    return <S.FormItemDatePicker field={field} label={storeField.label} />;
  }
  if (storeField.type === 'number' || storeField.type === 'text') {
    return (
      <S.FormItemInput
        field={field}
        label={storeField.label}
        maskOptions={storeField.mask ? { mask: storeField.mask } : undefined}
      />
    );
  }
  if (storeField.type === 'enum') {
    return (
      <S.FormItemSelect field={field} label={storeField.label}>
        {storeField.allowedValues?.map((value) => (
          <Select.Item key={value} value={value}>
            {value}
          </Select.Item>
        ))}
      </S.FormItemSelect>
    );
  }

  return <div />;
};
