import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Auth0Redirect } from '@/auth/Auth0Redirect';
import { KeaLogo } from '@/components/KeaLogo/KeaLogo';
import { saveReturnTo } from '@/utils/returnTo';

import { MainNav } from './MainNav';
import { UserMenu } from './UserMenu';
import * as S from './styles';

export function RootLayout() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const { pathname, search } = useLocation();
  useEffect(() => {
    saveReturnTo({ pathname, search });
  }, [pathname]);

  useEffect(() => {
    const returnTo = localStorage.getItem('@kea-inc/ops-console/return-to');
    if (isAuthenticated && returnTo) {
      navigate(returnTo);
    }
  }, [isAuthenticated]);

  return (
    <Auth0Redirect>
      <S.Header>
        <S.HeaderContent>
          <KeaLogo size="sm" />
          <MainNav />
          <UserMenu />
        </S.HeaderContent>
      </S.Header>

      <S.PagesContainer>
        <Outlet />
      </S.PagesContainer>
    </Auth0Redirect>
  );
}
