import {
  Dialog,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Typography,
} from '@kea-inc/parrot-ui';
import _ from 'lodash';

import { Store } from '@/@types/store';

import * as S from './styles';

type ExtendedProps = DialogProps;

interface StoreFieldsDialogProps extends ExtendedProps {
  store: Partial<Store> | null;
}

export function StoreFieldsDialog(props: StoreFieldsDialogProps) {
  const { store, ...dialogProps } = props;
  return (
    <Dialog {...dialogProps}>
      <S.DialogContent>
        <DialogHeader>
          <DialogTitle>
            {store?.franchiseStoreNumber} - {store?.address}
          </DialogTitle>
        </DialogHeader>
        <S.List>
          {Object.entries(store ?? {}).map(([key, storeData]) => (
            <S.ListItem key={key}>
              <S.StoreKeyTypography>{_.startCase(key)}</S.StoreKeyTypography>:{' '}
              <Typography>{storeData?.toString() ?? ''}</Typography>
            </S.ListItem>
          ))}
        </S.List>
      </S.DialogContent>
    </Dialog>
  );
}
