import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CSVContainer = styled.div`
  flex: 1;
  margin-top: 2rem;
`;

export const CSVFile = styled(ParrotUi.File)``;

export const CSVFileDropzone = styled(ParrotUi.FileDropzone)``;
