import {
  MenuCategory,
  MenuItem,
  MenuModifier,
  MenuOption,
} from '@/@types/menu';
import {
  GetCategoriesQueryVariables,
  GetItemsFromCategoryQueryVariables,
  GetModifiersFromParentQueryVariables,
  GetOptionsFromParentQueryVariables,
} from '@/graphql/schema';
import { graphqlSDK } from '@/services/clients/menuService';
import {
  mapItemsToDomain,
  mapMenuCategoriesToDomain,
  mapModifierToDomain,
  mapOptionToDomain,
} from '@/services/useCases/menu/adapters/domain';

type GetCategoriesInput = GetCategoriesQueryVariables;

export type GetCategories = {
  input: GetCategoriesInput;
  output: MenuCategory[];
};

export const getCategories = async (
  input: GetCategories['input'],
): Promise<GetCategories['output']> => {
  const result = await graphqlSDK.GetCategories(input);
  return mapMenuCategoriesToDomain(result);
};

type GetItemsInput = GetItemsFromCategoryQueryVariables;

export type GetItems = {
  input: GetItemsInput;
  output: MenuItem[];
};

export const getItems = async (
  input: GetItems['input'],
): Promise<GetItems['output']> => {
  const result = await graphqlSDK.GetItemsFromCategory(input);
  return mapItemsToDomain(result);
};

type GetModifiersInput = GetModifiersFromParentQueryVariables;
export type GetModifiers = {
  input: GetModifiersInput;
  output: MenuModifier[];
};

export const getModifiers = async (
  input: GetModifiers['input'],
): Promise<GetModifiers['output']> => {
  const result = await graphqlSDK.GetModifiersFromParent(input);
  return mapModifierToDomain(result);
};

type GetOptionsInput = GetOptionsFromParentQueryVariables;

export type GetOptions = {
  input: GetOptionsInput;
  output: MenuOption[];
};

export const getOptions = async (
  input: GetOptions['input'],
): Promise<GetOptions['output']> => {
  const result = await graphqlSDK.GetOptionsFromParent(input);
  return mapOptionToDomain(result);
};
