import { z } from 'zod';

export const roleSchema = z.object({
  permissions: z
    .object({
      name: z.string(),
      description: z.string(),
    })
    .array(),
});

export type RoleSchema = z.infer<typeof roleSchema>;
