import {
  Badge,
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarLabel,
  MenubarMenu,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from '@kea-inc/parrot-ui';

import { PaginatedStoresFilter, Store } from '@/@types/store';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/store';
import { mapStoreStatusToVariant } from '@/utils/color';

import { useConnector } from './FilterDialog/connector';

interface BatchUpdateMenuProps {
  stores: Store[];
  filters?: PaginatedStoresFilter;
}

export function BatchUpdateMenu(props: BatchUpdateMenuProps) {
  const { stores, filters } = props;
  const { actions } = useConnector();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const handleBatchUpdateStore =
    (params: Partial<Pick<Store, 'isOn' | 'storeStatus'>>) => () => {
      if (filters)
        dispatch(
          actions.batchUpdateStores({
            isOn: params.isOn,
            storeStatus: params.storeStatus,
            storeIds: stores.map((store) => store.storeId),
            filters,
          }),
        )
          .unwrap()
          .then(() =>
            notification.success('Success', {
              description: 'Stores have been updated',
            }),
          );
    };
  return (
    <Menubar>
      <MenubarMenu>
        <MenubarTrigger>Batch Update</MenubarTrigger>
        <MenubarContent>
          <MenubarLabel>{stores.length} stores will be updated</MenubarLabel>
          <MenubarSub>
            <MenubarSubTrigger>Status</MenubarSubTrigger>
            <MenubarSubContent>
              <MenubarItem
                onClick={handleBatchUpdateStore({ storeStatus: 'ACTIVE' })}
              >
                <Badge variant={mapStoreStatusToVariant('ACTIVE')}>
                  ACTIVE
                </Badge>
              </MenubarItem>
              <MenubarItem
                onClick={handleBatchUpdateStore({ storeStatus: 'INACTIVE' })}
              >
                <Badge variant={mapStoreStatusToVariant('INACTIVE')}>
                  INACTIVE
                </Badge>
              </MenubarItem>
              <MenubarItem
                onClick={handleBatchUpdateStore({ storeStatus: 'DELETED' })}
              >
                <Badge variant={mapStoreStatusToVariant('DELETED')}>
                  DELETED
                </Badge>
              </MenubarItem>
            </MenubarSubContent>
          </MenubarSub>
          <MenubarSub>
            <MenubarSubTrigger>ON/OFF</MenubarSubTrigger>
            <MenubarSubContent>
              <MenubarItem onClick={handleBatchUpdateStore({ isOn: true })}>
                <Badge variant="primary">Turn ON</Badge>
              </MenubarItem>
              <MenubarItem onClick={handleBatchUpdateStore({ isOn: false })}>
                <Badge variant="error">Turn OFF</Badge>
              </MenubarItem>
            </MenubarSubContent>
          </MenubarSub>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
}
