import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Bpo } from '@/@types/bpo';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useNotification } from '@/hooks/useNotification';
import { AgentWorksiteDialog } from '@/pages/BPOs/List/AgentWorksiteDialog';
import { useAppDispatch } from '@/store';

import * as S from './styles';
import { useBpoColumnDefs } from './useBpoColumnDefs';
import { useConnector } from '../connector';

type DialogState = {
  open: boolean;
  items: string[];
  title: string;
};

type DeleteDialogState = {
  open: boolean;
  bpo?: Bpo;
};

export function List() {
  const navigate = useNavigate();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const { actions, selectors } = useConnector();
  const { bpos, fetching, deleting } = selectors;

  useEffect(() => {
    dispatch(actions.getBpos());
  }, []);

  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    open: false,
  });

  const {
    filteredItems: filteredBpos,
    searchTerm,
    setSearchTerm,
  } = useFuseSearch({ itemKeys: ['id', 'name'], items: bpos });

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    items: [],
    title: '',
  });

  const { columns } = useBpoColumnDefs({
    onAgentsClick: (bpo) =>
      setDialogState({
        items: bpo.agents ?? [],
        open: true,
        title: `${bpo.name} agents`,
      }),
    onWorksitesClick: (bpo) =>
      setDialogState({
        items: bpo.worksites ?? [],
        open: true,
        title: `${bpo.name} worksites`,
      }),
    onUpdateClick: (bpo) => navigate(`/bpos/${bpo.id}`),
    onDeleteClick: (bpo) => setDeleteDialog({ open: true, bpo }),
  });

  const handleDeleteBpo = () => {
    if (deleteDialog.bpo)
      dispatch(actions.deleteBpo({ id: deleteDialog.bpo.id }))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'BPO deleted successfully',
          });
          setDeleteDialog({ open: false });
        });
  };

  return (
    <PageCard
      title="BPOs"
      headerContent={
        <>
          <FlexGrow />
          <S.Input
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder="Search"
          />
          <Button onClick={() => navigate('/bpos/new')}>Add Bpo</Button>
        </>
      }
    >
      {fetching && bpos.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={filteredBpos}
          stickyHeader
          onRowClick={(agent) => navigate(`/bpos/${agent.original.id}`)}
        />
      )}
      <AgentWorksiteDialog
        items={dialogState.items}
        title={dialogState.title}
        open={dialogState.open}
        onOpenChange={(open) => setDialogState({ ...dialogState, open })}
      />
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to delete <b>{deleteDialog.bpo?.name}</b>?
          </Typography>
        }
        open={deleteDialog.open}
        onOpenChange={(open) => setDeleteDialog({ ...deleteDialog, open })}
        onSubmit={handleDeleteBpo}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </PageCard>
  );
}
