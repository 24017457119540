import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField, FormItemInput } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import { FormType } from '@/constants/form';
import { useNotification } from '@/hooks/useNotification';
import {
  PlatformSchema,
  platformSchema,
} from '@/pages/Platforms/PlatformForm/schema';
import { useAppDispatch } from '@/store';

import * as S from './styles';
import { useConnector } from '../connector';

interface PlatformFormProps {
  type: FormType;
}

export function PlatformForm(props: PlatformFormProps) {
  const { type } = props;
  const navigate = useNavigate();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const { platformId = '' } = useParams<{ platformId: string }>();

  const { actions, selectors } = useConnector();
  const { fetching, platformEntities, creatingUpdating } = selectors;

  const platform = platformId ? platformEntities[platformId] : undefined;

  useEffect(() => {
    if (!platform && platformId) {
      dispatch(actions.getPlatforms());
    }
  }, [platform, platformId]);

  const form = useForm<PlatformSchema>({
    resolver: zodResolver(platformSchema),
  });

  useEffect(() => {
    if (platform) {
      form.reset(platform);
    }
  }, [platform]);

  const handleSuccessSave = () => {
    notification.success('Success', {
      description: 'Platform created/updated successfully!',
    });
    navigate('/platforms');
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    if (type === 'create')
      dispatch(actions.createPlatform(values)).unwrap().then(handleSuccessSave);

    if (type === 'update' && platform) {
      dispatch(actions.updatePlatform({ id: platform.id, name: values.name }))
        .unwrap()
        .then(handleSuccessSave);
    }
  };

  if (fetching) {
    return <KeaLoading />;
  }
  if (type === 'update' && !platform) {
    return null;
  }

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <PageCard
          backButton={<BackButton onClick={() => navigate('/platforms')} />}
          title={platform ? platform.name : 'Create a new platform'}
          footerContent={
            <UpdateCreateButton type="submit" loading={creatingUpdating} />
          }
        >
          <S.SectionsContainer>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItemInput field={field} label="Name" />
              )}
            />
          </S.SectionsContainer>
        </PageCard>
      </S.Form>
    </Form>
  );
}
