import { useAppSelector } from '@/store';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as fleetSelectors } from '@/store/entities/fleet';
import {
  actions as storeActions,
  selectors as storeUcSelectors,
} from '@/store/useCases/store';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(
      storeUcSelectors.isUcFetching('getPaginatedStores'),
    ),
    brands: useAppSelector(brandSelectors.getBrands),
    fleets: useAppSelector(fleetSelectors.getFleets),
  };

  const actions = {
    batchUpdateStores: storeActions.batchUpdateStores,
  };

  return { selectors, actions };
};
