import { Link as ReactRouterDomLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(ReactRouterDomLink)`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
    opacity: 0;
    transition: 0.1s all ease;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
