import { PaginatedOrdersFilters } from '@/@types/order/paginatedOrder';
import { OrderService } from '@/services/@types/order';
import { upperFirst } from '@/utils/methods';

export const mapGetOrdersFiltersToApi = (
  filters?: Partial<PaginatedOrdersFilters>,
): OrderService.GetOrdersFilters | undefined =>
  filters
    ? {
        id: filters.orderId,
        status: filters.status ? upperFirst(filters.status) : undefined,
        accountId: filters.accountId,
        accountPhone: filters.accountPhone,
        agentId: filters.agentId,
        handoffMode: filters.handoffMode?.toLowerCase(),
        paymentType: filters.paymentType,
        source: filters.orderApplication,
        storeId: filters.storeId,
        brandId: filters.brandId,
        startTimeGte: filters.startTimeGte,
        startTimeLte: filters.startTimeLte,
      }
    : undefined;
