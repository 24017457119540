import env from '@beam-australia/react-env';
import { useMemo, useState } from 'react';

import { KeaLoading } from '@/components/KeaLoading';

import { MetricsCard } from './MetricsCard';
import { AgentsTable } from './MetricsCard/AgentsTable';
import { BrandsTable } from './MetricsCard/BrandsTable';
import { useMetricsSocket } from './hooks/useMetricsSocket';
import { useSocket } from './hooks/useSocket';
import * as S from './styles';
import { reduceMetrics } from './utils';

const metricsServiceHost = env('METRICS_SERVICE');

interface MetricsProps {}

export function Metrics(_: MetricsProps) {
  const socketConfig = useSocket(metricsServiceHost);
  const [minutes, setMinutes] = useState<number>(10);
  const { metrics } = useMetricsSocket(socketConfig, {
    pastMinutes: minutes,
  });

  const reducedMetrics = useMemo(() => reduceMetrics(metrics), [metrics]);

  if (reducedMetrics === null) {
    return <KeaLoading />;
  }

  return (
    <S.MetricsContainer>
      <MetricsCard
        reducedMetrics={reducedMetrics}
        minutes={minutes.toString()}
        onMinutesChange={(mins) => setMinutes(Number(mins))}
      />
      <BrandsTable metrics={metrics} />
      <AgentsTable metrics={metrics} />
    </S.MetricsContainer>
  );
}
