import { useAppSelector } from '@/store';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as fleetSelectors } from '@/store/entities/fleet';
import { selectors as platformSelectors } from '@/store/entities/platform';
import { selectors as storeSelectors } from '@/store/entities/store';
import {
  actions as brandUcActions,
  selectors as brandUcSelectors,
} from '@/store/useCases/brand';
import {
  actions as fleetUcActions,
  selectors as fleetUcSelectors,
} from '@/store/useCases/fleet';
import {
  actions as platformUcActions,
  selectors as platformUcSelectors,
} from '@/store/useCases/platform';
import {
  actions as storeUcActions,
  selectors as storeUcSelectors,
} from '@/store/useCases/store';

const isFetchingUcs = [
  storeUcSelectors.isUcFetching('getStore'),
  platformUcSelectors.isUcFetching('getPlatforms'),
  fleetUcSelectors.isUcFetching('getFleets'),
  brandUcSelectors.isUcFetching('getBrands'),
];

const isCreatingUpdatingStoreUcs = [
  storeUcSelectors.isUcFetching('createStore'),
  storeUcSelectors.isUcFetching('updateStore'),
  storeUcSelectors.isUcFetching('syncStorePromos'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    creatingUpdation: useAppSelector((state) =>
      isCreatingUpdatingStoreUcs
        .map((ucSelector) => ucSelector(state))
        .some(Boolean),
    ),

    buyingTwilioNumber: useAppSelector(
      storeUcSelectors.isUcFetching('buyTwilioNumber'),
    ),
    syncingStoreId: useAppSelector(
      platformUcSelectors.isUcFetching('syncPlatformStoreId'),
    ),
    storesEntities: useAppSelector(storeSelectors.getStoreEntities),
    platforms: useAppSelector(platformSelectors.getPlatforms),
    brands: useAppSelector(brandSelectors.getBrands),
    fleets: useAppSelector(fleetSelectors.getFleets),
  };

  const actions = {
    getPlatforms: platformUcActions.getPlatforms,
    getStore: storeUcActions.getStore,
    getStoreMenuVersions: storeUcActions.getStoreMenuVersions,
    createStore: storeUcActions.createStore,
    syncStorePromos: storeUcActions.syncStorePromos,
    updateStore: storeUcActions.updateStore,
    getPromos: storeUcActions.getPromos,
    getGoogleMapsInformation: storeUcActions.getGoogleMapsInformation,
    syncPlatformStoreId: platformUcActions.syncPlatformStoreId,
    getBrands: brandUcActions.getBrands,
    getBrand: brandUcActions.getBrand,
    getFleets: fleetUcActions.getFleets,
    buyTwilioNumber: storeUcActions.buyTwilioNumber,
  };

  return { actions, selectors };
};
