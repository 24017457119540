import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '@/@types/user';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { useNotification } from '@/hooks/useNotification';
import { useUsersColumnDefs } from '@/pages/Admin/List/Users/useUsersColumnDefs';
import { useConnector } from '@/pages/Admin/List/connector';
import { useAppDispatch, useAppSelector } from '@/store';
import { selectors as userSelectors } from '@/store/entities/user';

import * as S from './styles';

type DeleteDialogState = {
  user: User | null;
};

const initDeleteDialog: DeleteDialogState = {
  user: null,
};

export const USERS_PAGE_SIZE = 30;

export function Users() {
  const navigate = useNavigate();
  const notification = useNotification();

  const [lastPage, setLastPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialog, setDeleteDialog] =
    useState<DeleteDialogState>(initDeleteDialog);

  const { actions, selectors } = useConnector();
  const dispatch = useAppDispatch();

  const usersWithFilter = useAppSelector((state) =>
    userSelectors.getUsersWithFilter(state, searchTerm),
  )?.users;

  const users = searchTerm ? usersWithFilter ?? [] : selectors.users;

  const { fetching, totalRecords, deleting } = selectors;

  useEffect(() => {
    dispatch(actions.getRoles());
    if (!fetching)
      dispatch(
        actions.getPaginatedUsers({
          page: lastPage,
          pageSize: USERS_PAGE_SIZE,
          searchTerm,
        }),
      )
        .unwrap()
        .then((res) => {
          setLastPage(lastPage + 1);
          dispatch(actions.setTotalRecords(res.totalRecords));
        });
  }, []);

  const { columns } = useUsersColumnDefs({
    onDeleteClick: (user) => setDeleteDialog({ user }),
    onUpdateClick: (user) => navigate(`/admin/users/${user.id}`),
  });

  const handlePaginatedUsers = useCallback(
    _.debounce(
      (sTerm: string) =>
        dispatch(
          actions.getPaginatedUsers({
            page: 1,
            pageSize: USERS_PAGE_SIZE,
            searchTerm: sTerm,
          }),
        )
          .unwrap()
          .then((res) => {
            setLastPage(2);
            dispatch(actions.setTotalRecords(res.totalRecords));
          }),
      500,
    ),
    [],
  );

  useEffect(() => {
    if (searchTerm) {
      handlePaginatedUsers(searchTerm);
    }
  }, [searchTerm]);

  const handleDeleteUser = () => {
    if (deleteDialog.user)
      dispatch(actions.deleteUser({ id: deleteDialog.user.id }))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'User deleted successfully!',
          });
          setDeleteDialog(initDeleteDialog);
        });
  };

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title variant="h4">Users</S.Title>
        <FlexGrow />
        <S.Input
          value={searchTerm}
          onChange={(evt) => setSearchTerm(evt.target.value)}
          placeholder="Search"
        />
        <Button onClick={() => navigate('/admin/users/new')}>Add User</Button>
      </S.TitleContainer>
      {fetching && users.length === 0 ? (
        <KeaLoading />
      ) : (
        <S.DataTableContainer>
          <DataTable
            columns={columns}
            data={users}
            stickyHeader
            onRowClick={(row) => navigate(`/admin/users/${row.original.id}`)}
            virtualizationProps={{ estimateSize: () => 68 }}
            infiniteLoadProps={{
              onLoadMore: () => {
                if (!fetching)
                  dispatch(
                    actions.getPaginatedUsers({
                      page: lastPage,
                      pageSize: USERS_PAGE_SIZE,
                      searchTerm,
                    }),
                  )
                    .unwrap()
                    .then(() => setLastPage(lastPage + 1));
              },
              totalRecords,
            }}
          />
        </S.DataTableContainer>
      )}
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to delete{' '}
            <b>{deleteDialog.user?.firstName}</b>?
          </Typography>
        }
        open={!!deleteDialog.user}
        onOpenChange={(open) =>
          setDeleteDialog({ user: open ? deleteDialog.user : null })
        }
        onSubmit={handleDeleteUser}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </S.Container>
  );
}
