import * as z from 'zod';

import { StorePromo } from '@/@types/store';

type StorePromotionFormValues = Pick<
  StorePromo,
  'id' | 'code' | 'name' | 'description' | 'prompt'
> & { isNew: boolean; deleted: boolean };

type SchemaObjShape = Record<keyof StorePromotionFormValues, z.ZodTypeAny>;

export const promoSchema = z.object({
  id: z.string().optional(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
  prompt: z.string(),
  deleted: z.boolean().optional(),
  isNew: z.boolean().optional(),
} satisfies SchemaObjShape);

export type StorePromoSchema = z.infer<typeof promoSchema>;
