import { useAppSelector } from '@/store';
import { selectors as brandSelectors } from '@/store/entities/brand';
import {
  actions as brandUcActions,
  selectors as brandUcSelectors,
} from '@/store/useCases/brand';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(brandUcSelectors.isUcFetching('getBrands')),
    deleting: useAppSelector(brandUcSelectors.isUcFetching('deleteBrand')),
    brands: useAppSelector(brandSelectors.getBrands),
  };

  const actions = {
    getBrands: brandUcActions.getBrands,
    deleteBrand: brandUcActions.deleteBrand,
  };

  return { actions, selectors };
};
