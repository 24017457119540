import {
  FormControl,
  FormField,
  FormMessage,
  FormItemInput,
  FormItemToggle,
} from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import * as S from './styles';

interface ManualStoreHoursFormFieldsProps {
  form: UseFormReturn<StoreSchema>;
}

export function ManualStoreHoursFormFields(
  props: ManualStoreHoursFormFieldsProps,
) {
  const { form } = props;

  const isManual = form.getValues('syncStoreHours');

  return (
    <S.ManualStoreHoursContainer>
      {Object.values(form.getValues('storeHours') ?? {}).map(
        (storeHour, index) => {
          const disabled = isManual ?? !storeHour.active;
          return (
            <S.StoreHourFieldsContainer key={index}>
              <FormField
                control={form.control}
                name={`storeHours.${storeHour.dayOfWeek}.from`}
                render={({ field }) => (
                  <FormItemInput
                    disabled={disabled}
                    maskOptions={{ mask: '99:99' }}
                    field={field}
                  />
                )}
              />
              <FormField
                control={form.control}
                name={`storeHours.${storeHour.dayOfWeek}.fromAmPm`}
                render={({ field }) => (
                  <FormItemToggle>
                    <S.ToggleContainer>
                      <S.AmPmToggle
                        pressed={field.value === 'AM'}
                        onClick={() => field.onChange('AM')}
                        disabled={disabled}
                      >
                        AM
                      </S.AmPmToggle>
                      <S.AmPmToggle
                        pressed={field.value === 'PM'}
                        onClick={() => field.onChange('PM')}
                        disabled={disabled}
                      >
                        PM
                      </S.AmPmToggle>
                    </S.ToggleContainer>
                  </FormItemToggle>
                )}
              />
              <FormField
                control={form.control}
                name={`storeHours.${storeHour.dayOfWeek}.active`}
                render={({ field }) => (
                  <FormControl>
                    <S.StoreHoursFormItem>
                      <S.DayOfWeekBadge
                        variant={
                          isManual
                            ? 'outline'
                            : storeHour.active
                              ? 'primary'
                              : 'outline'
                        }
                        onClick={() => field.onChange(!field.value)}
                        $disabled={isManual}
                      >
                        {storeHour.dayOfWeek}
                      </S.DayOfWeekBadge>
                      <FormMessage />
                    </S.StoreHoursFormItem>
                  </FormControl>
                )}
              />
              <FormField
                control={form.control}
                name={`storeHours.${storeHour.dayOfWeek}.to`}
                render={({ field }) => (
                  <FormItemInput
                    disabled={disabled}
                    maskOptions={{ mask: '99:99' }}
                    field={field}
                  />
                )}
              />
              <FormField
                control={form.control}
                name={`storeHours.${storeHour.dayOfWeek}.toAmPm`}
                render={({ field }) => (
                  <FormItemToggle>
                    <S.ToggleContainer>
                      <S.AmPmToggle
                        pressed={field.value === 'AM'}
                        onClick={() => field.onChange('AM')}
                        disabled={disabled}
                      >
                        AM
                      </S.AmPmToggle>
                      <S.AmPmToggle
                        pressed={field.value === 'PM'}
                        onClick={() => field.onChange('PM')}
                        disabled={disabled}
                      >
                        PM
                      </S.AmPmToggle>
                    </S.ToggleContainer>
                  </FormItemToggle>
                )}
              />
            </S.StoreHourFieldsContainer>
          );
        },
      )}
    </S.ManualStoreHoursContainer>
  );
}
