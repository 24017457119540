import styled from 'styled-components';

import { PageCard as PageCardComponent } from '@/components/PageCard';

export const PageCard = styled(PageCardComponent).attrs({
  cardContentClassName: 'cardContentClassName',
})`
  padding-bottom: 0px;
  .cardContentClassName {
    padding-bottom: 0px;
    flex-direction: row;
    overflow: hidden;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  width: 500px;
`;

export const ContentContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;
