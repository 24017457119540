import { Typography } from '@kea-inc/parrot-ui';
import { useEffect, useMemo } from 'react';

import { Store } from '@/@types/store';
import { useConnector } from '@/pages/Training/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

const getStoreItemLabel = (store?: Store | null) => {
  const phone =
    store?.primaryPhone ?? store?.secondaryPhone ?? store?.twilioNumber;
  let itemLabel = `${store?.area ?? ''}`;

  if (phone) {
    itemLabel += ` (${phone})`;
  }
  return itemLabel;
};

export function StoresSelect() {
  const { actions, selectors } = useConnector();

  const { training, stores, fetchingAgents: fetching } = selectors;
  const { isCreatingTask, selectedAgentId, selectedBrandId, selectedStoreId } =
    training;
  const { setSelectedStoreId, getPaginatedStores } = actions;

  const storesWithNumber = useMemo(
    () =>
      stores?.filter(
        (store) => !!store.twilioNumber && selectedBrandId === store.brandId,
      ),
    [selectedBrandId, stores],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedBrandId) {
      dispatch(getPaginatedStores({ pageSize: 9999 }));
    }
  }, [selectedBrandId]);

  const handleStoreChange = (store: Store) => {
    const selectedStore = storesWithNumber?.find(
      (storeWithNumber) => storeWithNumber.id === store.id,
    );
    if (selectedStore) {
      dispatch(setSelectedStoreId(selectedStore.id));
    }
  };

  return (
    <S.StoresCombobox
      placeholder="Select a store"
      popoverContentProps={{ align: 'start' }}
      items={storesWithNumber ?? []}
      disabled={
        !stores ||
        fetching ||
        !selectedBrandId ||
        !selectedAgentId ||
        isCreatingTask
      }
      selectedItem={storesWithNumber?.find(
        (store) => store.id === selectedStoreId,
      )}
      onChangeItem={handleStoreChange}
      emptyLabel="No stores found."
      isItemEqualToSelected={(store, selStore) => store.id === selStore?.id}
      getItemLabel={getStoreItemLabel}
      renderItem={(store) => (
        <S.ComboboxItem key={store.id}>
          <Typography>
            {store?.area ?? store?.twilioNumber} {`(${store.primaryPhone})`}
          </Typography>
        </S.ComboboxItem>
      )}
    />
  );
}
