import {
  FormField,
  FormItemDatePicker,
  Select,
  FormItemInput,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { STORE_STATUSES } from '@/constants/store';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import { ManualStoreHoursFormFields } from './ManualStoreHoursFormFields';
import { SyncStoreHoursFormField } from './SyncStoreHoursFormField';
import * as S from './styles';

interface ScheduleProps {
  form: UseFormReturn<StoreSchema>;
  id: HTMLDivElement['id'];
}

export function Schedule(props: ScheduleProps) {
  const { form, id } = props;
  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Schedule</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="activationDate"
          render={({ field }) => (
            <FormItemDatePicker field={field} label="Activation date" />
          )}
        />
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItemSelect label="Status" field={field}>
              {STORE_STATUSES.map((status) => (
                <Select.Item value={status} key={status}>
                  {status}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="timezone"
          render={({ field }) => (
            <FormItemInput field={field} label="Store timezone" disabled />
          )}
        />
        <SyncStoreHoursFormField form={form} />
        <FormField
          control={form.control}
          name="storeAutoScheduleThreshold"
          render={({ field }) => (
            <FormItemInput
              field={field}
              label="Auto-schedule Threshold (minutes)"
            />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <ManualStoreHoursFormFields form={form} />
      </S.FormFieldContainer>
    </S.SectionContainer>
  );
}
