import { ColumnDef } from '@kea-inc/parrot-ui';

import { FleetUser } from '@/@types/fleet';
import { Copy } from '@/components/Copy';
import { TextLeft } from '@/components/DataTable';
import { LinkWithIcon } from '@/components/LinkWithIcon';

export const useFleetUsersColumnDefs = () => {
  const columns: ColumnDef<FleetUser>[] = [
    {
      accessorKey: 'first_name',
      header: () => <TextLeft>Name</TextLeft>,
      cell: ({ row }) => {
        const firstName = row.original.firstName;
        const lastName = row.original.lastName;

        return (
          <LinkWithIcon to={`/admin/users/${row.original.userId}`}>
            <div className="text-left font-medium">{`${firstName} ${lastName}`}</div>
          </LinkWithIcon>
        );
      },
    },
    {
      accessorKey: 'email',
      header: () => <div>Email</div>,
      cell: ({ row }) => <Copy>{row.original.email}</Copy>,
    },
  ];
  return { columns };
};
