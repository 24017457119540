import { Store } from '@/@types/store';
import { BulkCreationResponse } from '@/@types/store/bulkCreation';
import { StoreService } from '@/services/@types/store';
import { dataService } from '@/services/clients';
import { mapCreateUpdateStoreToApi } from '@/services/useCases/store/adapters/api';

export type BulkCreation = {
  input: { stores: Partial<Store>[] };
  output: BulkCreationResponse;
};

export const bulkCreation = async (
  input: BulkCreation['input'],
): Promise<BulkCreation['output']> => {
  const { stores } = input;

  const { data } = await dataService.post<StoreService.BulkCreationResponse>(
    `/stores/bulk`,
    { stores: stores.map(mapCreateUpdateStoreToApi) },
  );

  return data;
};
