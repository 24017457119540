import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { actions } from '@/store/useCases/store';

export interface State {
  totalRecords: number;
}

export const initialState: State = {
  totalRecords: 0,
};

const slice = createSlice({
  name: 'ui/user',
  initialState,
  reducers: {
    setTotalRecords: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.createStore.fulfilled, (state) => {
      state.totalRecords = state.totalRecords + 1;
    });
    builder.addCase(actions.deleteStore.fulfilled, (state) => {
      state.totalRecords = state.totalRecords - 1;
    });
  },
});

export default slice;
