import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { PaginatedStoresFilter } from '@/@types/store';
import {
  mapPaginatedStoreFiltersToSearchParams,
  mapSearchParamsToPaginatedStoreFilters,
} from '@/pages/Stores/List/adapters';
import {
  StoreFilterSchema,
  storeFilterSchema,
} from '@/pages/Stores/List/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from '../connector';

const storeFiltersSchemaDefaultValues: PaginatedStoresFilter = {
  storeId: undefined,
  franchiseStoreNumber: undefined,
  brandId: undefined,
  primaryPhone: undefined,
  secondaryPhone: undefined,
  twilioNumber: undefined,
  location: undefined,
  address: undefined,
  city: undefined,
  area: undefined,
  fleetId: undefined,
  isOn: undefined,
  timezone: undefined,
};

export const useFiltersForm = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { actions, selectors } = useConnector();
  const { filters } = selectors;

  const dispatch = useAppDispatch();

  const form = useForm<StoreFilterSchema>({
    resolver: zodResolver(storeFilterSchema),
    defaultValues: storeFiltersSchemaDefaultValues,
  });

  useEffect(() => {
    if (searchParams.size) {
      dispatch(
        actions.setFilters(
          mapSearchParamsToPaginatedStoreFilters(searchParams),
        ),
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (filters) {
      form.reset({ ...filters });
      setSearchParams(mapPaginatedStoreFiltersToSearchParams(filters));
    }
  }, [filters]);

  const handleFinallySubmit = (values: StoreFilterSchema) => {
    setFilterOpen(false);
    dispatch(actions.setFilters(values));
    setSearchParams(mapPaginatedStoreFiltersToSearchParams(values));
  };

  const handleClearFilters = () => {
    form.reset(storeFiltersSchemaDefaultValues);
    dispatch(actions.setFilters(undefined));
    setSearchParams(undefined);
  };

  return {
    form,
    handleFinallySubmit,
    filterOpen,
    searchParams,
    setFilterOpen,
    handleClearFilters,
  };
};
