import env from '@beam-australia/react-env';
import {
  GeocodeResponseData,
  TimeZoneResponseData,
} from '@googlemaps/google-maps-services-js';
import axios from 'axios';

const GOOGLE_MAPS_API_KEY = env('GOOGLE_PLACES_API_KEY');

const googleMaps = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api',
});

export const client = {
  geocode: async (address: string, country?: string) => {
    const response = await googleMaps.get<GeocodeResponseData>(
      '/geocode/json',
      {
        params: {
          address,
          key: GOOGLE_MAPS_API_KEY,
          componentRestrictions: {
            country: country ?? 'US',
          },
          language: 'en',
        },
      },
    );

    return response.data;
  },

  timezone: async (lat: string, lng: string) => {
    const response = await googleMaps.get<TimeZoneResponseData>(
      '/timezone/json',
      {
        params: {
          location: `${lat},${lng}`,
          timestamp: new Date().getTime() / 1000,
          key: GOOGLE_MAPS_API_KEY,
        },
      },
    );

    return response.data;
  },
};
