import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { Account } from '@/@types/account';
import { PaginatedAccountEntity } from '@/@types/account/paginatedAccount';
import { actions } from '@/store/useCases/account';

export const adapter = createEntityAdapter<Account>({
  sortComparer: (s1, s2) => s1.id.localeCompare(s2.id),
});

export const adapterWithFilter = createEntityAdapter<PaginatedAccountEntity>({
  sortComparer: (s1, s2) =>
    JSON.stringify(s1.id).localeCompare(JSON.stringify(s2.id)),
});

export type State = {
  account: EntityState<Account, string>;
  accountsWithFilter: EntityState<PaginatedAccountEntity, string>;
};

export const initialState: State = {
  account: adapter.getInitialState(),
  accountsWithFilter: adapterWithFilter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getAccount.fulfilled, (state, action) => {
      const currentStore = state.account.entities[action.payload.id];

      if (currentStore) {
        adapter.updateOne(state.account, {
          id: action.payload.id,
          changes: action.payload,
        });
      } else {
        adapter.addOne(state.account, action.payload);
      }
    });
    builder.addCase(actions.getAccountByIds.fulfilled, (state, action) => {
      adapter.upsertMany(state.account, action.payload);
    });
    builder.addCase(actions.getPaginatedAccounts.fulfilled, (state, action) => {
      const { items } = action.payload;

      if (action.meta.arg?.filters) {
        const id = JSON.stringify(action.meta.arg.filters);
        const accountIds = state.accountsWithFilter.entities[id]?.accountIds;
        if (accountIds) {
          adapterWithFilter.updateOne(state.accountsWithFilter, {
            id,
            changes: {
              accountIds: _.uniq(
                accountIds.concat(items.map((item) => item.id)),
              ),
            },
          });
        } else {
          adapterWithFilter.addOne(state.accountsWithFilter, {
            id,
            accountIds: items.map((item) => item.id),
          });
        }
      }
      adapter.upsertMany(state.account, items);
    });
  },
});

export default slice;
