import env from '@beam-australia/react-env';

import { Agent } from '@/@types/agent';
import { UpdateAgentRequestRequest } from '@/@types/agent/update';
import { dataService } from '@/services';
import { AgentService } from '@/services/@types/agent';
import { mapUpdateAgentToApi } from '@/services/useCases/agent/adapters/api';
import { mapAgentToDomain } from '@/services/useCases/agent/adapters/domain';

export type UpdateAgent = {
  input: UpdateAgentRequestRequest;
  output: Agent;
};

export const updateAgent = async (
  input: UpdateAgent['input'],
): Promise<UpdateAgent['output']> => {
  const { data } = await dataService.patch<
    AgentService.Agent,
    AgentService.UpdateAgentRequest
  >(
    `/agents/${input.id}/workspace/${env('TWILIO_WORKSPACE_SID')}`,
    mapUpdateAgentToApi(input),
  );
  return { ...mapAgentToDomain(data), brands: input.brands };
};
