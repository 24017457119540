import { Auth0Role } from '@/@types/user';
import { dataService } from '@/services';
import { RoleService } from '@/services/@types/role';

export type UpdateRole = {
  input: Auth0Role;
  output: Auth0Role;
};

export const updateRole = async (
  input: UpdateRole['input'],
): Promise<UpdateRole['output']> => {
  const { name, permissions } = input;
  const { data } =
    await dataService.patch<RoleService.GetAuth0RolePermissionsResponse>(
      `/roles/${name}`,
      {
        permissions: permissions?.map((permission) => permission.name) ?? [],
      },
    );

  return {
    ...input,
    permissions: data?.items?.map((permission) => ({
      ...permission,
      id: permission.name,
    })),
  };
};
