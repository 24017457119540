import { Avatar, AvatarFallback, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useMemo } from 'react';

import { Agent } from '@/@types/agent';
import { useConnector } from '@/pages/Training/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

export function AgentsSelect() {
  const { actions, selectors } = useConnector();
  const { training, agents, fetchingAgents: fetching } = selectors;
  const { isCreatingTask, selectedAgentId } = training;

  const dispatch = useAppDispatch();

  const sortedAgents = useMemo(
    () =>
      (agents ?? []).sort((a, b) => {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      }),
    [agents],
  );

  const handleAgentChange = (agent: Agent) => {
    dispatch(actions.setSelectedAgentId(agent.id));
  };

  useEffect(() => {
    if (selectedAgentId) {
      dispatch(actions.getAgent({ id: selectedAgentId }));
    }
  }, [selectedAgentId]);

  return (
    <S.Combobox
      popoverContentProps={{ align: 'start' }}
      items={sortedAgents}
      disabled={fetching ?? isCreatingTask}
      selectedItem={sortedAgents.find((sA) => sA.id === selectedAgentId)}
      onChangeItem={handleAgentChange}
      emptyLabel="No agents found."
      placeholder={fetching ? 'Loading...' : 'Search agent...'}
      isItemEqualToSelected={(agent, selAgent) => agent.id === selAgent?.id}
      getItemLabel={(agent) => `${agent?.firstName} ${agent?.lastName}`}
      renderItem={(agent) => (
        <S.ComboboxItem key={agent.email}>
          <Avatar>
            <AvatarFallback>
              {`${agent.firstName.at(0)}${agent.lastName.at(0)}`.toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <S.NameEmailContainer>
            <S.AgentName>{`${agent.firstName} ${agent.lastName}`}</S.AgentName>
            <Typography muted>{agent.email}</Typography>
          </S.NameEmailContainer>
        </S.ComboboxItem>
      )}
    />
  );
}
