import { z } from 'zod';

import { Brand, BrandPromo } from '@/@types/brand';
import { brandPlatformSchema } from '@/pages/Brands/BrandForm/schema/platform';
import { promotionSchema } from '@/pages/Brands/BrandForm/schema/promotion';
import { triageHandoffMethodsSchema } from '@/pages/Brands/BrandForm/schema/triageHandoffMethods';
import { brandUpsellSchema } from '@/pages/Brands/BrandForm/schema/upsell';

type BrandSchemaProperties = Omit<Brand, 'models' | 'nluModels'> & {
  promos: BrandPromo[];
};

type SchemaObjShape = Record<keyof BrandSchemaProperties, z.ZodTypeAny>;

export const brandSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  spokenName: z.string().optional(),
  key: z.string().optional(),
  cashLimit: z.string().optional(),
  voice: z.string().optional(),
  transcriptionServiceProvider: z.string().optional(),
  shouldTip: z.boolean().optional(),
  tipPercentage: z.coerce.number().optional(),
  deepgramModel: z.string().optional(),
  deepgramModelVersion: z.string().optional(),
  nluModel: z.string().optional(),
  triageHandoffMethods: triageHandoffMethodsSchema,
  priority: z.coerce.number().optional(),
  platforms: brandPlatformSchema.array().optional(),
  useDefaultOptionsToMaxQuantity: z.boolean().optional(),
  autoScheduleThreshold: z.coerce.number().optional(),
  promos: promotionSchema.array().optional(),
  upsells: brandUpsellSchema.array().optional(),
  nerModel: z.string().optional(),
  agentlessStoreForwarding: z.boolean().optional(),
} satisfies SchemaObjShape);

export type BrandSchema = z.infer<typeof brandSchema>;
