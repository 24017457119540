import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import { TextLeft } from '@/components/DataTable';
import { LinkWithIcon as LinkWithIconComponent } from '@/components/LinkWithIcon';

export const LinkWithIcon = styled(LinkWithIconComponent)`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const CallerCellTypography = styled(ParrotUi.Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media only screen and (min-height: 720px) and (max-width: 1280px) {
    max-width: 100px;
  }
`;

export const ActionsCell = styled(TextLeft)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HeaderNoWrap = styled.div`
  white-space: nowrap;
`;
