import {
  Dialog,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Typography,
} from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { LinkWithIcon } from '@/components/LinkWithIcon';

import * as S from './styles';
import { useConnector } from '../../connector';

type ExtendedProps = DialogProps;

interface UsersDialogProps extends ExtendedProps {
  agentIds?: string[];
}

export function AgentsDialog(props: UsersDialogProps) {
  const { agentIds, ...dialogProps } = props;

  const { selectors } = useConnector();

  const { agentEntities } = selectors;

  const agents = useMemo(
    () => agentIds?.map((agentId) => agentEntities?.[agentId]),
    [agentEntities, agentIds],
  );

  return (
    <Dialog {...dialogProps}>
      <S.DialogContent>
        <DialogHeader>
          <DialogTitle>Agents</DialogTitle>
        </DialogHeader>
        <S.List>
          {agents?.map((agent) => (
            <S.ListItem key={agent.id}>
              <LinkWithIcon to={`/agents/${agent.id}`}>
                {`${agent.firstName} ${agent.lastName}`}
              </LinkWithIcon>
              <Typography>[{agent.username}]</Typography>
              <Typography>
                {dayjs(agent.createdAt).format('MM/DD/YYYY hh:mm A (z)')}
              </Typography>
            </S.ListItem>
          ))}
        </S.List>
      </S.DialogContent>
    </Dialog>
  );
}
