import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/fleet';

export const prefix = 'useCases/fleet';

export const actions = {
  getFleets: createAsyncThunk(`${prefix}/getFleets`, async (_, thunkApi) => {
    try {
      const result = await api.getFleets();

      return result;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }),
  getFleet: createAsyncThunk(
    `${prefix}/getFleet`,
    async (input: api.GetFleet['input'], thunkApi) => {
      try {
        const result = await api.getFleet(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getFleetStoresByFleetId: createAsyncThunk(
    `${prefix}/getFleetStoresByFleetId`,
    async (input: api.GetFleetStoresByFleetId['input'], thunkApi) => {
      try {
        const result = await api.getFleetStoresByFleetId(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getFleetUsersByFleetId: createAsyncThunk(
    `${prefix}/getFleetUsersByFleetId`,
    async (input: api.GetFleetUsersByFleetId['input'], thunkApi) => {
      try {
        const result = await api.getFleetUsersByFleetId(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createFleet: createAsyncThunk(
    `${prefix}/createFleet`,
    async (input: api.CreateFleet['input'], thunkApi) => {
      try {
        const result = await api.createFleet(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateFleet: createAsyncThunk(
    `${prefix}/updateFleet`,
    async (input: api.UpdateFleet['input'], thunkApi) => {
      try {
        const result = await api.updateFleet(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteFleet: createAsyncThunk(
    `${prefix}/deleteFleet`,
    async (input: api.RemoveFleet['input'], thunkApi) => {
      try {
        const result = await api.removeFleet(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
