import dayjs from 'dayjs';
import _ from 'lodash';

import { Store, StoreHour } from '@/@types/store';
import { DAYS_OF_WEEK, DayOfWeek } from '@/constants/daysOfWeek';
import { StoreHourSchema } from '@/pages/Stores/StoreForm/schema/storeHours';
import { getStoreHourFromToOnOff } from '@/utils/storeHours';

const getDeactiveStoreHourOn = (dayOfWeek: string): StoreHour => ({
  action: 'TURN_ON',
  actionTime: '00:00:00',
  dayOfWeek,
  timezone: 'America/Los_Angeles',
  active: false,
});
const getDeactiveStoreHourOff = (dayOfWeek: string): StoreHour => ({
  action: 'TURN_OFF',
  actionTime: '00:00:00',
  dayOfWeek,
  timezone: 'America/Los_Angeles',
  active: false,
});

export const getAmPm = (time: string) => {
  const splitted = time.split(':');

  const today = dayjs().hour(Number(splitted.at(0)));

  return today.hour() >= 12 ? 'PM' : 'AM';
};

export const mapStoresHourToFormValues = (
  storeHourOn: StoreHour,
  storeHourOff: StoreHour,
) => {
  const { todayFrom, todayTo } = getStoreHourFromToOnOff(
    storeHourOn.actionTime,
    storeHourOff.actionTime,
  );
  return {
    from: todayFrom.format('hh:mm'),
    fromAmPm: getAmPm(storeHourOn.actionTime),
    to: todayTo.format('hh:mm'),
    toAmPm: getAmPm(storeHourOff.actionTime),
    active: storeHourOn.active ?? true,
    timezone: storeHourOn.timezone,
    dayOfWeek: storeHourOn.dayOfWeek as DayOfWeek,
  };
};

export const mapStoreHoursToFormValues = (
  storeHours: Store['storeHours'],
): StoreHourSchema => {
  const groupedStoreHoursByDayOfWeek = _.groupBy(storeHours, 'dayOfWeek');
  return DAYS_OF_WEEK.reduce((formValues, dayOfWeek) => {
    const storeHour = groupedStoreHoursByDayOfWeek[dayOfWeek];
    if (storeHour?.length) {
      const storeHourOn = storeHour.find(
        (storeHr) => storeHr.action === 'TURN_ON',
      );
      const storeHourOff = storeHour.find(
        (storeHr) => storeHr.action === 'TURN_OFF',
      );
      if (storeHourOn && storeHourOff)
        return {
          ...formValues,
          [dayOfWeek]: mapStoresHourToFormValues(storeHourOn, storeHourOff),
        };
      return formValues;
    } else {
      return {
        ...formValues,
        [dayOfWeek]: mapStoresHourToFormValues(
          getDeactiveStoreHourOn(dayOfWeek),
          getDeactiveStoreHourOff(dayOfWeek),
        ),
      };
    }
  }, {} as StoreHourSchema);
};
