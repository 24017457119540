import { useState } from 'react';

import { Navbar, NavbarProps } from '@/pages/Admin/List/Navbar';
import { Roles } from '@/pages/Admin/List/Roles/Roles';
import { Users } from '@/pages/Admin/List/Users';

import * as S from './styles';

export function List() {
  const [tab, setTab] = useState<NavbarProps['tab']>('users');
  return (
    <S.PageCard>
      <Navbar tab={tab} onTabChange={setTab} />
      <S.ContentContainer>
        {tab === 'users' ? <Users /> : null}
        {tab === 'roles' ? <Roles /> : null}
      </S.ContentContainer>
    </S.PageCard>
  );
}
