import { dataService } from '@/services';

export type DeletePlatform = {
  input: { id: string };
  output: { id: string };
};

export const deletePlatform = async (
  input: DeletePlatform['input'],
): Promise<DeletePlatform['output']> => {
  await dataService.delete(`/platforms/${input.id}`);

  return { id: input.id };
};
