import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useMemo } from 'react';

import { ROUTES, Route, RoutesType } from '@/routes';
import { applyJwtToken } from '@/services/middlewares/applyJwtToken';

const ROLES_KEY = 'https://kea.cloud/v2/roles';

interface DecodedJwt {
  [ROLES_KEY]: string[];
}

const useRoles = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const [userTokenLoaded, setUserTokenLoaded] = useState<boolean>(false);
  const { user, getAccessTokenSilently } = useAuth0();

  const userHasOneOf = (comparisonRoles: string[]) =>
    (roles ?? []).some((role) => comparisonRoles.includes(role));

  const availableRoutes = useMemo(() => {
    const available: Route[] = [];
    Object.keys(ROUTES).forEach((route) => {
      const routeObj = ROUTES[route as keyof RoutesType];
      if (userHasOneOf(routeObj.roles)) {
        available.push({ ...routeObj, path: route });
      }
    });
    return available;
  }, [roles]);

  const retrieveUser = async () => {
    if (!user) {
      return setUserTokenLoaded(true);
    }
    setUserTokenLoaded(false);
    const token = await getAccessTokenSilently();
    const decoded = jwtDecode<DecodedJwt>(token);
    setRoles(decoded[ROLES_KEY]);
    applyJwtToken(token, user.email);
    setUserTokenLoaded(true);
  };

  useEffect(() => {
    retrieveUser();
  }, [user]);

  return { roles, availableRoutes, userTokenLoaded };
};

export { useRoles };
