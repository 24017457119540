import camelcaseKeys from 'camelcase-keys';

import { Fleet, FleetStore, FleetUser } from '@/@types/fleet';
import { FleetService } from '@/services/@types/fleet';

export const mapFleetToDomain = (fleet: FleetService.Fleet): Fleet =>
  camelcaseKeys(fleet);

export const mapFleetUseToDomain = (fleet: FleetService.FleetUser): FleetUser =>
  camelcaseKeys(fleet);

export const mapFleetStoreToDomain = (
  fleet: FleetService.FleetStore,
): FleetStore => camelcaseKeys(fleet);
