import _ from 'lodash';

import { BrandUpsell } from '@/@types/brand';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';
import { BrandUpsellSchema } from '@/pages/Brands/BrandForm/schema/upsell';
import { SyncBrandUpsells } from '@/services/useCases';

export const mapBrandUpsellFormValuesToRequest = (
  brandUpsell: BrandUpsellSchema,
): BrandUpsell => {
  const upsell = _.omitBy(
    _.omit({ ...brandUpsell, chainIds: brandUpsell.chainIds.split(',') }, [
      'isNew',
      'deleted',
    ]),
    (v) => v === '',
  ) as BrandUpsell;

  return upsell;
};

export const mapBrandUpsellsFormValuesToRequest = (
  brandUpsells: BrandSchema['upsells'],
): SyncBrandUpsells['input']['upsells'] => {
  const filteredBrandUpsells =
    brandUpsells?.filter(
      (brandUpsell) => !(brandUpsell.isNew && brandUpsell.deleted),
    ) ?? [];
  return {
    created: filteredBrandUpsells
      .filter((upsell) => upsell.isNew)
      .map(mapBrandUpsellFormValuesToRequest),
    updated: filteredBrandUpsells
      .filter((upsell) => !upsell.isNew && !upsell.deleted)
      .map(mapBrandUpsellFormValuesToRequest),
    deleted: filteredBrandUpsells
      .filter((upsell) => upsell.deleted)
      .map(mapBrandUpsellFormValuesToRequest),
  };
};
