import { useParams } from 'react-router-dom';

import { KeaLoading } from '@/components/KeaLoading';
import { AccountData } from '@/pages/Accounts/AccountInfo/AccountData';

import { useConnector } from './connector';
import * as S from './styles';

export function AccountInfo() {
  const { accountId = '' } = useParams<{ accountId: string }>();

  const { selectors } = useConnector();

  const { accountEntities, fetching } = selectors;

  const account = accountId ? accountEntities[accountId] : undefined;

  if (fetching) {
    return <KeaLoading />;
  }

  if (!account) {
    return null;
  }

  return (
    <S.PageCard>
      <AccountData account={account} />
    </S.PageCard>
  );
}
