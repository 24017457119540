import { z } from 'zod';

import { BrandPlatform } from '@/@types/brand';

type SchemaObjShape = Record<keyof BrandPlatform, z.ZodTypeAny>;

export const brandPlatformSchema = z.object({
  platformId: z.string(),
  platformApiKey: z.string().optional(),
  platformName: z.string().optional(),
  sharedSecretKey: z.string().optional(),
} satisfies SchemaObjShape);

export type BrandPlatformSchema = z.infer<typeof brandPlatformSchema>;
