import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const PageHeaderInputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const PageHeaderInput = styled(ParrotUi.Input)`
  width: 250px;
`;
