import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/account';

export const prefix = 'useCases/account';

export const actions = {
  getAccountByIds: createAsyncThunk(
    `${prefix}/getAccountByIds`,
    async (input: api.GetAccountByIds['input'], thunkApi) => {
      try {
        const result = await api.getAccountByIds(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getAccount: createAsyncThunk(
    `${prefix}/getAccount`,
    async (input: api.GetAccount['input'], thunkApi) => {
      try {
        const result = await api.getAccount(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getPaginatedAccounts: createAsyncThunk(
    `${prefix}/getPaginatedAccounts`,
    async (input: api.GetPaginatedAccounts['input'], thunkApi) => {
      try {
        const result = await api.getPaginatedAccounts(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
