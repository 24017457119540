import { Input, Label } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { useConnector } from '@/pages/BPOs/connector';
import { useAppDispatch } from '@/store';

import * as S from '../styles';

export function Update() {
  const { bpoId = '' } = useParams<{ bpoId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const [bpoName, setBpoName] = useState('');

  const { selectors, actions } = useConnector();
  const { bpoEntities, updating } = selectors;

  const { updateBpo } = actions;

  const bpo = bpoEntities[bpoId];

  useEffect(() => {
    if (bpo) {
      setBpoName(bpo.name);
    }
  }, [bpo]);

  const handleUpdateBpo = () =>
    dispatch(updateBpo({ bpoId, bpoName }))
      .unwrap()
      .then(() => {
        notification.success('Success', {
          description: getCreateUpdateDescription('Bpo'),
        });
        navigate('/bpos');
      });

  if (!bpo) {
    return null;
  }

  return (
    <PageCard
      backButton={<BackButton onClick={() => navigate('/bpos')} />}
      title="Update bpo"
      footerContent={
        <UpdateCreateButton loading={updating} onClick={handleUpdateBpo} />
      }
    >
      <S.InputContainer>
        <Label>Code</Label>
        <Input
          value={bpoName}
          onChange={(evt) => setBpoName(evt.target.value)}
        />
      </S.InputContainer>
    </PageCard>
  );
}
