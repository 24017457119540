import { Typography } from '@kea-inc/parrot-ui';

import { TabTypes } from '@/pages/Training/TrainingTabs/TrainingTabs';

import * as S from './styles';

export interface NavbarProps {
  tab: TabTypes;
  setTab: React.Dispatch<React.SetStateAction<TabTypes>>;
}
export function Navbar({ tab, setTab }: NavbarProps) {
  return (
    <S.Navbar>
      <Typography variant="h4">Training</Typography>

      <S.Tabs
        value={tab}
        onValueChange={(value) => setTab(value as TabTypes)}
        orientation="vertical"
      >
        <S.TabsList>
          <S.TabsTrigger value={TabTypes.CALL}>Call</S.TabsTrigger>
        </S.TabsList>
      </S.Tabs>
    </S.Navbar>
  );
}
