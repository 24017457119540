import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Fleet } from '@/@types/fleet';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/store';

import { StoresDialog } from './StoresDialog';
import { UsersDialog } from './UsersDialog';
import { useConnector } from './connector';
import * as S from './styles';
import { useFleetsColumnDefs } from './useFleetsColumnDefs';

type DialogState = {
  open: boolean;
  fleet?: Fleet;
};

export function List() {
  const navigate = useNavigate();

  const notification = useNotification();

  const { actions, selectors } = useConnector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.getFleets());
  }, []);

  const { fetching, fleets, deleting } = selectors;

  const [usersDialog, setUsersDialog] = useState<DialogState>({
    open: false,
  });
  const [storesDialog, setStoresDialog] = useState<DialogState>({
    open: false,
  });
  const [deleteDialog, setDeleteDialog] = useState<DialogState>({
    open: false,
  });

  const { columns } = useFleetsColumnDefs({
    onFleetStoresClick: (fleet) => setStoresDialog({ open: true, fleet }),
    onFleetUsersClick: (fleet) => setUsersDialog({ open: true, fleet }),
    onDeleteClick: (fleet) => setDeleteDialog({ open: true, fleet }),
  });

  const {
    filteredItems: filteredFleets,
    searchTerm,
    setSearchTerm,
  } = useFuseSearch<Fleet>({
    itemKeys: ['id', 'name', 'accountManager', 'accountManagerEmail'],
    items: fleets,
  });

  const handleDeleteFleet = () => {
    if (deleteDialog.fleet)
      dispatch(actions.deleteFleet(deleteDialog.fleet.id))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'Fleet deleted successfully',
          });
          setDeleteDialog({ ...deleteDialog, open: false });
        });
  };

  return (
    <PageCard
      title="Fleets"
      headerContent={
        <S.InputButtonContainer>
          <S.Input
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder="Search"
          />
          <Button onClick={() => navigate('/fleets/new')}>Add Fleet</Button>
        </S.InputButtonContainer>
      }
    >
      {fetching ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={filteredFleets}
          stickyHeader
          onRowClick={(fleet) => navigate(`/fleets/${fleet.original.id}`)}
        />
      )}
      <UsersDialog
        open={usersDialog.open}
        onOpenChange={(open) => setUsersDialog({ ...usersDialog, open })}
        fleetId={usersDialog.fleet?.id}
      />
      <StoresDialog
        open={storesDialog.open}
        onOpenChange={(open) => setStoresDialog({ ...storesDialog, open })}
        fleetId={storesDialog.fleet?.id}
      />
      <ConfirmationDialog
        description={
          <Typography>
            {`This will permanently delete `}
            <b>{deleteDialog.fleet?.name}</b> fleet
          </Typography>
        }
        open={deleteDialog.open}
        onOpenChange={(open) => setDeleteDialog({ ...deleteDialog, open })}
        onSubmit={handleDeleteFleet}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </PageCard>
  );
}
