import { User } from '@auth0/auth0-react';
import env from '@beam-australia/react-env';

import { Agent } from '@/@types/agent';
import { Store } from '@/@types/store';
import { Token } from '@/@types/training';

import { coreService } from '..';

export type GetDeviceToken = {
  input?: User;
  output: Token;
};

export async function getDeviceToken(
  input: GetDeviceToken['input'],
): Promise<GetDeviceToken['output']> {
  if (!input) {
    return { token: '' };
  }

  const { data } = await coreService.post<Token>(
    '/twilio/devices/trainer_token',
    {
      application_sid: env('TWILIO_APPLICATION_SID'),
      trainer_nickname: `trainer-${input.nickname}`,
    },
  );
  return data;
}

type CreateTrainingInput = {
  store: Store;
  agent: Agent;
  brandId: string;
  handoffMode: string | null;
  user?: User;
};

export type CreateTrainingTask = {
  input: CreateTrainingInput;
  output: void;
};

export async function createTrainingTask(input: CreateTrainingTask['input']) {
  const { agent, brandId, handoffMode, store, user } = input;

  await coreService.post('/twilio/tasks/trainingTask', {
    workspace_sid: env('TWILIO_WORKSPACE_SID'),
    workflow_sid: env('TWILIO_TRAINING_WORKFLOW_SID'),
    trainer_nickname: `trainer-${user?.nickname}`,
    store_id: store.id,
    agent,
    brand_id: brandId,
    handoff_mode: handoffMode,
  });
}
