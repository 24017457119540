import { useAppSelector } from '@/store';
import { selectors as roleSelectors } from '@/store/entities/role';
import {
  actions as roleUcActions,
  selectors as roleUcSelectors,
} from '@/store/useCases/role';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(roleUcSelectors.isUcFetching('getRoles')),

    roles: useAppSelector(roleSelectors.getRoles),
  };

  const actions = {
    getRoles: roleUcActions.getRoles,
  };

  return { actions, selectors };
};
