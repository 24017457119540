import { useAppSelector } from '@/store';
import { selectors as agentSelectors } from '@/store/entities/agent';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as storeSelectors } from '@/store/entities/store';
import { selectors as orderUcSelectors } from '@/store/useCases/order';
import {
  actions as storeUcActions,
  selectors as storeUcSelectors,
} from '@/store/useCases/store';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(
      orderUcSelectors.isUcFetching('getPaginatedOrders'),
    ),
    fetchingStores: useAppSelector(
      storeUcSelectors.isUcFetching('getStoresByBrandId'),
    ),
    brands: useAppSelector(brandSelectors.getBrands),
    stores: useAppSelector(storeSelectors.getStores),
    agents: useAppSelector(agentSelectors.getAgents),
  };

  const actions = {
    batchUpdateStores: storeUcActions.batchUpdateStores,
    getStoresByBrandId: storeUcActions.getStoresByBrandId,
  };

  return { selectors, actions };
};
