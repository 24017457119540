import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { Agent } from '@/@types/agent';
import { actions } from '@/store/useCases/agent';

export const adapter = createEntityAdapter<Agent>({
  sortComparer: (f1, f2) =>
    `${f1.firstName} ${f1.lastName}`.localeCompare(
      `${f2.firstName} ${f2.lastName}`,
    ),
});

export type State = {
  agents: EntityState<Agent, string>;
};

export const initialState: State = {
  agents: adapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/agent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getAgents.fulfilled, (state, action) => {
      adapter.upsertMany(state.agents, action.payload);
    });
    builder.addCase(actions.getAgent.fulfilled, (state, action) => {
      const currentAgent = state.agents.entities[action.payload.id];

      if (currentAgent) {
        adapter.updateOne(state.agents, {
          changes: action.payload,
          id: action.payload.id,
        });
      } else {
        adapter.addOne(state.agents, action.payload);
      }
    });
    builder.addCase(actions.updateAgent.fulfilled, (state, action) => {
      adapter.updateOne(state.agents, {
        changes: _.omitBy(action.payload, _.isUndefined),
        id: action.payload.id,
      });
    });
    builder.addCase(actions.createAgent.fulfilled, (state, action) => {
      adapter.addOne(state.agents, action.payload);
    });
    builder.addCase(actions.getPaginatedAgents.fulfilled, (state, action) => {
      adapter.upsertMany(state.agents, action.payload.items);
    });
    builder.addCase(actions.deleteAgent.fulfilled, (state, action) => {
      adapter.removeOne(state.agents, action.payload.id);
    });
  },
});

export default slice;
