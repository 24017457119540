import { useEffect, useRef, useState } from 'react';

import { Order } from '@/@types/order';
import { useInterval } from '@/hooks/useInterval';

import { useConnector } from './connector';

export type RecordingTime = {
  minutes: number;
  seconds: number;
};

export type RecordingState = {
  isPlaying: boolean;
  progress: number;
  muted: boolean;
  speed: number;
  timestamp: number;
  trackerTimes: RecordingTime;
  audioDuration: RecordingTime;
};

const initialState: RecordingState = {
  isPlaying: false,
  progress: 0,
  speed: 1,
  muted: false,
  trackerTimes: {
    minutes: 0,
    seconds: 0,
  },
  audioDuration: {
    minutes: 0,
    seconds: 0,
  },
  timestamp: 0,
};

export const getRecordingTime = (duration: number): RecordingTime => {
  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor(duration / 60);

  return { minutes, seconds };
};

type UseRecordingOpts = {
  order: Order;
};

export const useRecording = (opts: UseRecordingOpts) => {
  const { order } = opts;

  const audioRef = useRef<HTMLAudioElement>(null);
  const [recordingState, setRecordingState] = useState(initialState);
  const [selectedRecording, setSelectedRecording] = useState('');

  const { selectors } = useConnector();

  const { timestamp } = selectors;

  const { isPlaying } = recordingState;

  useEffect(() => {
    if (order.recordingUrls?.length) {
      setSelectedRecording(order.recordingUrls.at(0) as string);
    }
  }, [order.recordingUrls]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, audioRef.current]);

  useInterval(() => {
    if (isPlaying && audioRef.current) {
      const { currentTime, duration } = audioRef.current;

      handleRecordingStateChange({
        progress: (currentTime * 100) / duration,
        trackerTimes: getRecordingTime(currentTime),
      });
    }
  }, 100);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.crossOrigin = 'anonymous';
      audioRef.current.src = selectedRecording;
      audioRef.current.pause();

      handleRecordingStateChange({
        audioDuration: getRecordingTime(audioRef.current.duration),
        progress: 0,
        isPlaying: false,
        trackerTimes: { minutes: 0, seconds: 0 },
      });
    }
  }, [selectedRecording]);

  useEffect(() => {
    if (audioRef.current) {
      const duration = timestamp;
      audioRef.current.currentTime = duration;

      handleRecordingStateChange({
        trackerTimes: getRecordingTime(duration),
        isPlaying: true,
      });
    }
  }, [timestamp]);

  const handleRecordingStateChange = (newState: Partial<RecordingState>) => {
    setRecordingState({ ...recordingState, ...newState });
  };

  return {
    recordingState,
    handleRecordingStateChange,
    audioRef,
    selectedRecording,
    setSelectedRecording,
  };
};
