import { RouteObject } from 'react-router-dom';

import { FleetForm } from '@/pages/Fleets/FleetForm/FleetForm';

import { List } from './List/List';

export const fleetsRouter: RouteObject[] = [
  {
    path: '/fleets',
    element: <List />,
    index: true,
  },
  {
    path: '/fleets/new',
    element: <FleetForm type="create" />,
  },
  {
    path: '/fleets/:fleetId',
    element: <FleetForm type="update" />,
  },
];
