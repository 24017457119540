import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField, FormItemInput } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import { FormType } from '@/constants/form';

import { useConnector } from './connector';
import { FleetSchema, fleetSchema } from './schema';
import * as S from './styles';

interface FleetFormProps {
  type: FormType;
}

export function FleetForm(props: FleetFormProps) {
  const { type } = props;

  const { fleetId = '' } = useParams<{ fleetId: string }>();
  const navigate = useNavigate();

  const form = useForm<FleetSchema>({
    resolver: zodResolver(fleetSchema),
  });

  const { handleCreateFleet, handleUpdateFleet, handleGetFleet, selectors } =
    useConnector();

  const { fleetEntities, creating, updating, fetching } = selectors;

  useEffect(() => {
    if (type === 'update') {
      handleGetFleet(fleetId);
    }
  }, [fleetId]);

  const fleet = fleetEntities[fleetId];

  useEffect(() => {
    if (fleet) {
      form.reset(fleet);
    }
  }, [fleet]);

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    if (type === 'create') {
      handleCreateFleet(values);
    }
    if (type === 'update' && fleet)
      handleUpdateFleet({ ...values, id: fleet.id });
  };

  if (fetching) {
    return <KeaLoading />;
  }

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <PageCard
          backButton={<BackButton onClick={() => navigate('/fleets')} />}
          title={
            type === 'update' && fleet
              ? `${fleet.name} [${fleet?.id.slice(-5)}]`
              : 'Create a new fleet'
          }
          footerContent={
            <UpdateCreateButton type="submit" loading={creating ?? updating} />
          }
        >
          <S.CardContent>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItemInput field={field} label="Name" />
              )}
            />
            <FormField
              control={form.control}
              name="accountManager"
              render={({ field }) => (
                <FormItemInput field={field} label="Account Manager" />
              )}
            />
            <FormField
              control={form.control}
              name="accountManagerEmail"
              render={({ field }) => (
                <FormItemInput field={field} label="Account Manager Email" />
              )}
            />
          </S.CardContent>
        </PageCard>
      </S.Form>
    </Form>
  );
}
