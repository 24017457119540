import { Typography } from '@kea-inc/parrot-ui';
import { useMemo } from 'react';

import { AgentBrand } from '@/@types/agent';
import { useConnector } from '@/pages/Training/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

export function BrandsSelect() {
  const dispatch = useAppDispatch();

  const { selectors, actions } = useConnector();
  const { training, fetchingAgent, agentEntities } = selectors;
  const { isCreatingTask, selectedBrandId, selectedAgentId } = training;

  const queuedBrands = useMemo(
    () =>
      selectedAgentId
        ? agentEntities[selectedAgentId]?.brands?.filter(
            (brand) => brand.queued,
          )
        : [],
    [selectedAgentId, agentEntities],
  );

  const handleChangeBrand = (brnd: AgentBrand) => {
    const selectedBrand = queuedBrands?.find((brand) => brand.id === brnd.id);
    if (selectedBrand) {
      dispatch(actions.setSelectedBrandId(selectedBrand.id));
    }
  };

  return (
    <S.BrandsCombobox
      placeholder="Select a brand"
      popoverContentProps={{ align: 'start' }}
      items={queuedBrands ?? []}
      disabled={(!selectedAgentId || fetchingAgent) ?? isCreatingTask}
      loading={fetchingAgent}
      selectedItem={queuedBrands?.find(
        (queuedBrand) => queuedBrand.id === selectedBrandId,
      )}
      onChangeItem={handleChangeBrand}
      emptyLabel="No brands found."
      isItemEqualToSelected={(brand, selBrand) => brand.id === selBrand?.id}
      getItemLabel={(brand) => brand?.name ?? ''}
      renderItem={(brand) => (
        <S.ComboboxItem key={brand.id}>
          <Typography>{brand.name}</Typography>
        </S.ComboboxItem>
      )}
    />
  );
}
