import * as z from 'zod';

import { DAYS_OF_WEEK, DayOfWeek } from '@/constants/daysOfWeek';
import { getStoreHourFromToOnOff } from '@/utils/storeHours';

const getDayOfWeekZodObject = () =>
  z
    .object({
      from: z.string(),
      to: z.string(),
      dayOfWeek: z.enum(DAYS_OF_WEEK),
      active: z.boolean(),
      fromAmPm: z.enum(['AM', 'PM']),
      toAmPm: z.enum(['AM', 'PM']),
    })
    .refine((value) => {
      const { todayFrom, todayTo } = getStoreHourFromToOnOff(
        value.from,
        value.to,
      );

      return todayFrom.isValid() && todayTo.isValid();
    })
    .optional();

export type DayOfWeekSchema = ReturnType<typeof getDayOfWeekZodObject>;
export const hoursSchema = z
  .object(
    DAYS_OF_WEEK.reduce(
      (schema, dayOfWeek) => ({
        ...schema,
        [dayOfWeek]: getDayOfWeekZodObject(),
      }),
      {},
    ) as Record<DayOfWeek, DayOfWeekSchema>,
  )
  .optional();

export type StoreHourSchema = z.infer<typeof hoursSchema>;
