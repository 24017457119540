import env from '@beam-australia/react-env';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { accountsRouter } from '@/pages/Accounts';
import { adminRouter } from '@/pages/Admin';
import { agentRouter } from '@/pages/Agents';
import { bposRouter } from '@/pages/BPOs';
import { brandsRouter } from '@/pages/Brands';
import { menuRouter } from '@/pages/MenuTool';
import { ordersRouter } from '@/pages/Orders';
import { platformsRouter } from '@/pages/Platforms';
import { storesRouter } from '@/pages/Stores';
import { trainingRouter } from '@/pages/Training';
import { ROUTES, Route, RoutesType } from '@/routes';

import { RootLayout } from './RootLayout/RootLayout';
import { fleetsRouter } from '..';
import { metricsRouter } from '../Metrics';

const ENVIRONMENT = env('ENVIRONMENT') ?? 'develop';

const getAvailableRoutes = () => {
  const indexRoute = {
    path: '/',
    index: true,
    element: <Navigate to="/fleets" replace />,
  };
  const allRoutes = [
    ...fleetsRouter,
    ...storesRouter,
    ...bposRouter,
    ...agentRouter,
    ...brandsRouter,
    ...ordersRouter,
    ...platformsRouter,
    ...adminRouter,
    ...accountsRouter,
    ...trainingRouter,
    ...metricsRouter,
    ...menuRouter,
  ];
  return [
    indexRoute,
    ...allRoutes.filter((route) => {
      const routePath = `/${route.path?.split('/').at(1)}` as keyof RoutesType;
      const routeObj: Route = ROUTES[routePath];
      if (routeObj) {
        const routeEnvs = routeObj.envs;
        if (routeEnvs) {
          return routeEnvs.includes(ENVIRONMENT);
        }
      }
      return true;
    }),
  ];
};

export const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: getAvailableRoutes(),
  },
]);

export function PagesRoutes() {
  return <RouterProvider router={browserRouter} />;
}
