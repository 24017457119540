import decamelizeKeys from 'decamelize-keys';

import { CreateUserRequest } from '@/@types/user/create';
import { UpdateUserRequest } from '@/@types/user/update';
import { UserService } from '@/services/@types/user';

export const mapCreateUserRequestToApi = (
  userRequest: CreateUserRequest,
): UserService.CreateUserRequest => {
  const request = decamelizeKeys(userRequest);
  const permissions = userRequest.permissions.map(
    (permission) => permission.name,
  );
  return { ...request, permissions };
};
export const mapUpdateUserRequestToApi = (
  userRequest: UpdateUserRequest,
): UserService.UpdateUserRequest => {
  const { id: _, email: __, ...rest } = userRequest;
  const request = decamelizeKeys(rest);
  const permissions =
    rest.permissions?.map((permission) => permission.name) ?? [];
  return { ...request, permissions };
};
