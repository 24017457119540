import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/agent';

export const prefix = 'useCases/agent';

export const actions = {
  updateRoleWeight: createAsyncThunk(
    `${prefix}/updateRoleWeight`,
    async (input: api.UpdateRoleWeight['input'], thunkApi) => {
      try {
        const result = await api.updateRoleWeight(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getAgents: createAsyncThunk(
    `${prefix}/getAgents`,
    async (_: api.GetAgents['input'], thunkApi) => {
      try {
        const result = await api.getAgents();

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getAgent: createAsyncThunk(
    `${prefix}/getAgent`,
    async (input: api.GetAgent['input'], thunkApi) => {
      try {
        const result = await api.getAgent(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createAgent: createAsyncThunk(
    `${prefix}/createAgent`,
    async (input: api.CreateAgent['input'], thunkApi) => {
      try {
        const result = await api.createAgent(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateAgent: createAsyncThunk(
    `${prefix}/updateAgent`,
    async (input: api.UpdateAgent['input'], thunkApi) => {
      try {
        const result = await api.updateAgent(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteAgent: createAsyncThunk(
    `${prefix}/deleteAgent`,
    async (input: api.DeleteAgent['input'], thunkApi) => {
      try {
        const result = await api.deleteAgent(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getPaginatedAgents: createAsyncThunk(
    `${prefix}/getPaginatedAgents`,
    async (input: api.GetPaginatedAgents['input'], thunkApi) => {
      try {
        const result = await api.getPaginatedAgents(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateAgentStatus: createAsyncThunk(
    `${prefix}/updateAgentStatus`,
    async (input: api.UpdateAgentStatus['input'], thunkApi) => {
      try {
        const result = await api.updateAgentStatus(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
