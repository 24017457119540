import { ColumnDef, DataTableSortHeader, Typography } from '@kea-inc/parrot-ui';

import { Brand } from '@/@types/brand';
import { TextCenter } from '@/components/DataTable';
import { TableActions } from '@/components/TableActions';

type UseBrandColumnDefsOpts = {
  onUpdateClick: (bpo: Brand) => void;
  onDeleteClick: (bpo: Brand) => void;
};

export const useBrandColumnDefs = (opts: UseBrandColumnDefsOpts) => {
  const { onDeleteClick, onUpdateClick } = opts;

  const columns: ColumnDef<Brand>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Name" />,
      cell: ({ row }) => <Typography>{row.original.name}</Typography>,
    },
    {
      accessorKey: 'key',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Key" />,
      cell: ({ row }) => <Typography>{row.original.key}</Typography>,
    },
    {
      accessorKey: 'voice',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Voice" />,
      cell: ({ row }) => <Typography>{row.original.voice}</Typography>,
    },
    {
      accessorKey: 'transcriptionServiceProvider',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Transcription service" />
      ),
      cell: ({ row }) => (
        <Typography>{row.original.transcriptionServiceProvider}</Typography>
      ),
    },
    {
      accessorKey: 'priority',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Priority" />,
      cell: ({ row }) => (
        <Typography>{row.original.priority ?? 'N/A'}</Typography>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => (
        <TableActions
          onDeleteClick={() => onDeleteClick(row.original)}
          onUpdateClick={() => onUpdateClick(row.original)}
        />
      ),
    },
  ];

  return { columns };
};
