import { AxiosError } from 'axios';
import decamelizeKeys from 'decamelize-keys';

import { BrandPromo } from '@/@types/brand';
import { BrandService } from '@/services/@types/brand';
import { dataService } from '@/services/clients';
import {
  mapBrandPromoToApi,
  mapBrandPromoToDomain,
} from '@/services/useCases/brand/adapters';
import { WithSyncPayload } from '@/utils/methods';

export type GetBrandPromos = {
  input: { brandId: string };
  output: BrandPromo[];
};

export const getBrandPromos = async (
  input: GetBrandPromos['input'],
): Promise<GetBrandPromos['output']> => {
  try {
    const { data } = await dataService.get<BrandService.BrandPromo[]>(
      `/brands/${input.brandId}/promos`,
    );
    return data.map(mapBrandPromoToDomain);
  } catch (e) {
    const axiosError = e as AxiosError;
    if (axiosError.response?.status === 404) {
      return [];
    }
    throw axiosError;
  }
};

type SyncBrandPromosInput = {
  promos: WithSyncPayload<BrandPromo>;
  brandId: string;
};

export type SyncBrandPromos = {
  input: SyncBrandPromosInput;
  output: void;
};

export async function syncBrandPromos(
  input: SyncBrandPromos['input'],
): Promise<SyncBrandPromos['output']> {
  const { created, updated, deleted } = input.promos;

  const createdPromises = created.map(({ brandId, ...promo }) => {
    const promoPayload = decamelizeKeys(
      mapBrandPromoToApi(promo),
    ) as BrandService.BrandPromo;

    return dataService.post(
      `/brands/promos`,
      {
        brand_id: brandId ?? input.brandId,
        ...promoPayload,
      },
      {
        version: 'v2',
      },
    );
  });

  const updatedPromises = updated.map(({ id, ...promo }) => {
    const promoPayload = decamelizeKeys(
      mapBrandPromoToApi(promo),
    ) as BrandService.BrandPromo;

    return dataService.patch(`/brands/promos/${id}`, promoPayload, {
      version: 'v2',
    });
  });

  const deletedPromises = deleted.map(({ id }) => {
    return dataService.delete(`/brands/promos/${id}`, {
      version: 'v2',
    });
  });

  await Promise.all(
    createdPromises.concat(updatedPromises).concat(deletedPromises),
  );
}
