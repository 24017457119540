import { useEffect } from 'react';
import { useSocketEvent } from 'socket.io-react-hook';

import { UseSocketReturnType } from '@/@types';
import { RoleWeightChangesData } from '@/@types/metrics';
import { RoleWeightSendMessage } from '@/@types/metrics/socket';
import { useNotification } from '@/hooks/useNotification';

export function useRoleWeightSocket(socketConfig: UseSocketReturnType<any>) {
  const { socket, error, connected } = socketConfig;

  const notification = useNotification();

  const { lastMessage: roleWeightChanges, sendMessage: sendRoleWeightChange } =
    useSocketEvent<RoleWeightChangesData>(socket, 'roleWeightChange');

  useEffect(() => {
    if (error) {
      notification.error('Failed to get role weight changes', {
        autoClose: false,
        description: 'You are no longer receiving updates',
      });
    }
  }, [error]);

  useEffect(
    () => () => {
      socket.disconnect();
    },
    [],
  );

  return {
    connected,
    roleWeightChanges,
    sendRoleWeightChange: sendRoleWeightChange as RoleWeightSendMessage,
    error,
    socket,
  };
}
