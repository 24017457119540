import env from '@beam-australia/react-env';
import axios from 'axios';

import { makeClientWithVersion } from '@/services/util/client';

export const client = makeClientWithVersion(
  axios.create({
    baseURL: env('ANALYTICS_SERVICE'),
  }),
  {
    defaultVersion: 'v1',
  },
);

export function setDefaultJwtTokenForAnalyticsService(token: string) {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
}
