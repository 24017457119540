import { Button } from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router-dom';

import { Order } from '@/@types/order';
import { BackButton } from '@/components/BackButton';
import { FlexGrow } from '@/components/FlexGrow';
import { OrderDataValues } from '@/pages/Orders/OrderInfo/OrderData/OrderDataValues';
import { Recording } from '@/pages/Orders/OrderInfo/OrderData/Recording';
import { useAppDispatch } from '@/store';

import * as S from './styles';
import { useConnector } from '../connector';

interface OrderDataProps {
  order: Order;
}

export function OrderData(props: OrderDataProps) {
  const { order } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectors, actions } = useConnector();

  const { theme, debugMode } = selectors;

  const handleClickLogs = () => {
    const start = dayjs(order.startTime).add(-1, 'day').unix();
    const now = dayjs().unix();
    const url = `https://app.datadoghq.com/logs?query=service:uoa @order_id:${order.id}&from_ts=${start}&to_ts=${now}&live=true`;
    window.open(url, '_blank');
  };

  const getFilteredDebugOrder = () => {
    const filteredDebugOrder = { ...order };
    delete filteredDebugOrder.callFlow;
    delete filteredDebugOrder.menuItems;
    delete filteredDebugOrder.transcripts;
    delete filteredDebugOrder.surveys;

    return filteredDebugOrder;
  };

  return (
    <S.Container>
      <S.HeaderContainer>
        <BackButton onClick={() => navigate('/orders')} />
        <FlexGrow />
        <Button
          variant={debugMode ? 'error' : 'default'}
          onClick={() => dispatch(actions.toggleDebugMode())}
        >
          {debugMode ? 'Exit' : ''} Debug mode
        </Button>
        {debugMode ? <Button onClick={handleClickLogs}>Logs</Button> : null}
      </S.HeaderContainer>
      <S.ContentContainer>
        {debugMode ? (
          <ReactJson
            src={{ order: getFilteredDebugOrder() }}
            theme={theme === 'dark' ? 'ashes' : undefined}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <>
            <OrderDataValues order={order} />
            <Recording order={order} />
          </>
        )}
      </S.ContentContainer>
    </S.Container>
  );
}
