import { OrderSurvey } from '@/@types/order/survey';
import { OrderService } from '@/services/@types/order';
import { orderService } from '@/services/clients';

export type GetOrderSurveys = {
  input: { orderId: string };
  output: OrderSurvey[];
};

export const getOrderSurveys = async (
  input: GetOrderSurveys['input'],
): Promise<GetOrderSurveys['output']> => {
  const { data } = await orderService.get<
    OrderService.OrderSurveyItemResponse[]
  >(`/orders/${input.orderId}/surveys`);

  return data;
};
