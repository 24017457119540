import { useState, useTransition } from 'react';

type UseScrollTabOpts<T extends string> = {
  initTab: T;
};

export const useScrollTab = <T extends string>(opts: UseScrollTabOpts<T>) => {
  const { initTab } = opts;

  const [, startTransition] = useTransition();
  const [tab, setTab] = useState<T>(initTab);

  const handleChangeTab = (value: string) => {
    document.getElementById(value)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    startTransition(() => {
      setTab(value as T);
    });
  };
  const handleScroll: React.WheelEventHandler<HTMLDivElement> = (evt) => {
    const containerElem = evt.currentTarget as HTMLDivElement;
    const containerRect = containerElem.getBoundingClientRect();

    const nearestNode = Array.from(containerElem.childNodes)
      .filter((node) => {
        const divSectionElem = node as HTMLDivElement;
        const rect = divSectionElem.getBoundingClientRect();

        return (
          rect.top === containerRect.top ||
          rect.bottom === containerRect.bottom ||
          (divSectionElem.id &&
            rect.top - containerRect.top >= 0 &&
            rect.top <= containerRect.bottom)
        );
      })
      .at(evt.deltaY > 0 ? -1 : 0) as HTMLDivElement;

    startTransition(() => {
      if (nearestNode) {
        setTab(nearestNode.id as T);
      }
    });
  };

  return { handleChangeTab, handleScroll, tab };
};
