import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { State } from './slice';
import type { StoreState } from '../..';

export const isFetching = createDraftSafeSelector(
  (state: StoreState) => state.useCases.fleet.fetching,
  (fetching) => Object.values(fetching).some((ft) => ft),
);

export const isUcFetching = (ucName: keyof State['fetching']) =>
  createDraftSafeSelector(
    (state: StoreState) => state.useCases.account.fetching,
    (fetching) => fetching[ucName],
  );
