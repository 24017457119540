import * as ParrotUi from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SectionContent = styled.div`
  display: flex;
  border: 1px solid hsl(var(--border));
  border-radius: 6px;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap;
`;

export const IconButton = styled(ParrotUi.Button)<{ $disabled: boolean }>`
  padding: 0.5rem;
  ${(props) =>
    props.$disabled
      ? css`
          opacity: 0.25;
        `
      : undefined}
`;
