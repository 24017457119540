import { CreateFleetRequest, Fleet } from '@/@types/fleet';
import { dataService } from '@/services';
import { FleetService } from '@/services/@types/fleet';
import { mapCreateFleetToApi } from '@/services/useCases/fleet/adapters/api';
import { mapFleetToDomain } from '@/services/useCases/fleet/adapters/domain';

export type CreateFleet = {
  input: CreateFleetRequest;
  output: Fleet;
};

export const createFleet = async (
  input: CreateFleet['input'],
): Promise<CreateFleet['output']> => {
  const fleet = (
    await dataService.post<FleetService.Fleet>(
      `/fleets`,
      mapCreateFleetToApi(input),
    )
  ).data;

  return mapFleetToDomain(fleet);
};
