import { DataTable } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useRolesColumnDefs } from '@/pages/Admin/List/Roles/useRolesColumnDefs';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

export function Roles() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { actions, selectors } = useConnector();

  const { roles, fetching } = selectors;

  useEffect(() => {
    dispatch(actions.getRoles());
  }, []);

  const {
    filteredItems: filteredRoles,
    searchTerm,
    setSearchTerm,
  } = useFuseSearch({
    itemKeys: ['name', 'description'],
    items: roles,
  });

  const { columns } = useRolesColumnDefs({
    onUpdateClick: (role) => navigate(`/admin/roles/${role.name}`),
  });

  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title variant="h4">Roles</S.Title>
        <FlexGrow />
        <S.Input
          value={searchTerm}
          onChange={(evt) => setSearchTerm(evt.target.value)}
          placeholder="Search"
        />
      </S.TitleContainer>
      {fetching ? (
        <KeaLoading />
      ) : (
        <S.DataTableContainer>
          <DataTable
            columns={columns}
            data={filteredRoles}
            stickyHeader
            onRowClick={(row) => navigate(`/admin/roles/${row.original.name}`)}
          />
        </S.DataTableContainer>
      )}
    </S.Container>
  );
}
