import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  overflow: auto;
`;

export const IconButton = styled(ParrotUi.Button).attrs({
  size: 'icon',
  variant: 'ghost',
})`
  width: 2rem;
  height: 2rem;
`;
