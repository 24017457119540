import { selectors as agentSelectors } from '@/store/entities/agent';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as storeSelectors } from '@/store/entities/store';
import {
  actions as trainingUiActions,
  selectors as trainingUiSelectors,
} from '@/store/ui/training';
import {
  actions as agentUcActions,
  selectors as agentUcSelectors,
} from '@/store/useCases/agent';
import { actions as brandUcActions } from '@/store/useCases/brand';
import { actions as storeUcActions } from '@/store/useCases/store';
import { actions as trainingUcActions } from '@/store/useCases/training';

import { useAppSelector } from '../../store';

export const useConnector = () => {
  const selectors = {
    training: useAppSelector(trainingUiSelectors.getTrainingState),
    fetchingAgents: useAppSelector(agentUcSelectors.isUcFetching('getAgents')),
    fetchingAgent: useAppSelector(agentUcSelectors.isUcFetching('getAgent')),
    agents: useAppSelector(agentSelectors.getActivedAgents),
    agentEntities: useAppSelector(agentSelectors.getAgentEntities),
    brands: useAppSelector(brandSelectors.getBrands),
    brandEntities: useAppSelector(brandSelectors.getBrandsEntities),
    stores: useAppSelector(storeSelectors.getStores),
    storeEntities: useAppSelector(storeSelectors.getStoreEntities),
  };

  const actions = {
    mergeState: trainingUiActions.mergeState,
    setSelectedAgentId: trainingUiActions.setSelectedAgentId,
    setSelectedBrandId: trainingUiActions.setSelectedBrandId,
    setSelectedStoreId: trainingUiActions.setSelectedStoreId,
    setSelectedHandoffMode: trainingUiActions.setSelectedHandoffMode,
    getPaginatedStores: storeUcActions.getPaginatedStores,
    getAgents: agentUcActions.getAgents,
    getBrands: brandUcActions.getBrands,
    getAgent: agentUcActions.getAgent,
    getDeviceToken: trainingUcActions.getDeviceToken,
  };

  return {
    actions,
    selectors,
  };
};
