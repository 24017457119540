import _ from 'lodash';

import { PaginatedStoresFilter } from '@/@types/store';

export const mapSearchParamsToPaginatedStoreFilters = (
  searchParams: URLSearchParams,
): PaginatedStoresFilter => {
  const filters: PaginatedStoresFilter = Object.fromEntries(searchParams);

  return filters;
};

export const mapPaginatedStoreFiltersToSearchParams = (
  filters: PaginatedStoresFilter,
): URLSearchParams => {
  const searchParams = new URLSearchParams(_.omitBy(filters, _.isUndefined));

  return searchParams;
};
