import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@kea-inc/parrot-ui';
import { useEffect, useRef, useState } from 'react';

import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';
interface PlayRecordingProps {
  orderId: string;
}

export function PlayRecording(props: PlayRecordingProps) {
  const dispatch = useAppDispatch();
  const { orderId } = props;

  const [open, setOpen] = useState(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { actions, selectors } = useConnector();

  const { fetching } = selectors;

  useEffect(() => {
    if (open)
      dispatch(actions.getOrderRecording({ orderId }))
        .unwrap()
        .then(({ urls }) => {
          if (audioRef.current && urls.length > 0) {
            audioRef.current.crossOrigin = 'anonymous';
            audioRef.current.src = urls.at(0) ?? '';
            audioRef.current?.play();
          }
        });

    return () => audioRef.current?.pause();
  }, [open]);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <S.PlayIconButton size="icon" variant="ghost">
          <S.PlayIcon size={14} />
        </S.PlayIconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Recording</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <S.AudioContainer>
          {fetching ? (
            <S.Skeleton />
          ) : (
            <S.Audio controls ref={audioRef} title={`ORDER_${orderId}`} />
          )}
        </S.AudioContainer>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
