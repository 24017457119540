import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useSocket as useSocketHook } from 'socket.io-react-hook';

import { UseSocketReturnType } from '@/@types';

export function useSocket(host: string): UseSocketReturnType<any> {
  const { user, isAuthenticated } = useAuth0();

  const token = localStorage.getItem('@kea-inc/ops-console/token');

  const useSocketReturn = useSocketHook(host, {
    auth: {
      nickname: user?.nickname,
      name: user?.name,
      picture: user?.picture,
      email: user?.email,
      token,
    },
    transports: ['websocket'],
  });

  useEffect(
    () => () => {
      useSocketReturn.socket.disconnect();
    },
    [],
  );

  if (!token) {
    return {} as UseSocketReturnType<any>;
  }

  if (!isAuthenticated || !user) {
    return {} as UseSocketReturnType<any>;
  }

  return useSocketReturn;
}
