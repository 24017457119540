import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import { TextLeft } from '@/components/DataTable';
import { LinkWithIcon as LinkWithIconComponent } from '@/components/LinkWithIcon';

export const CardContentContainer = styled.div`
  overflow: auto;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const Input = styled(ParrotUi.Input)`
  width: 250px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AlertDialogDeleteAction = styled(ParrotUi.AlertDialogAction)`
  background-color: hsl(var(--error));
`;

export const LinkWithIcon = styled(LinkWithIconComponent)`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-width: 200px;
`;

export const StoreCell = styled(TextLeft)`
  @media only screen and (min-height: 720px) and (max-width: 1280px) {
    max-width: 200px;
  }
`;

export const SourceCell = styled(TextLeft)`
  @media only screen and (min-height: 720px) and (max-width: 1280px) {
    max-width: 80px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AgentCell = styled(TextLeft)`
  display: flex;
  flex-direction: column;
`;

export const AgentsNameCell = styled(TextLeft)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const CallerCellTypography = styled(ParrotUi.Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media only screen and (min-height: 720px) and (max-width: 1280px) {
    max-width: 100px;
  }
`;

export const StartedAtCell = styled(TextLeft)`
  display: flex;
  flex-direction: column;
`;

export const ActionsCell = styled(TextLeft)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const AgentIconButton = styled(ParrotUi.Button)`
  width: 24px;
  height: 24px;
`;

export const HeaderNoWrap = styled.div`
  white-space: nowrap;
`;

export const StoreCellTypography = styled(ParrotUi.Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  @media only screen and (min-height: 720px) and (max-width: 1280px) {
    max-width: 100px;
  }
`;
