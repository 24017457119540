import { googleMapsService } from '@/services/clients';

type GetGoogleMapsInformationOutput = {
  state?: string;
  address?: string;
  city?: string;
  storeLocation?: string;
  timezone: string;
  lat: string;
  lng: string;
};

export type GetGoogleMapsInformation = {
  input: { zipcode: string; country?: string };
  output: GetGoogleMapsInformationOutput;
};

export const getGoogleMapsInformation = async (
  input: GetGoogleMapsInformation['input'],
): Promise<GetGoogleMapsInformation['output']> => {
  const { zipcode, country } = input;
  const { results } = await googleMapsService.geocode(zipcode, country);

  const [mostConfidentAddress] = results;
  const lat = mostConfidentAddress.geometry.location.lat.toString();
  const lng = mostConfidentAddress.geometry.location.lng.toString();

  const { timeZoneId } = await googleMapsService.timezone(lat, lng);

  const state = mostConfidentAddress.address_components.find(
    (aC) => !!aC.types.find((type) => type === 'administrative_area_level_1'),
  )?.short_name;

  const city = mostConfidentAddress.address_components.find(
    (aC) => !!aC.types.find((type) => type === 'locality'),
  )?.long_name;

  const streetName = mostConfidentAddress.address_components.find(
    (aC) => !!aC.types.find((type) => type === 'route'),
  )?.long_name;

  const neighborhood = mostConfidentAddress.address_components.find(
    (aC) => !!aC.types.find((type) => type === 'neighborhood'),
  )?.long_name;

  return {
    address: mostConfidentAddress.formatted_address,
    state,
    city,
    storeLocation: streetName ?? neighborhood,
    timezone: timeZoneId,
    lat,
    lng,
  };
};
