import { FleetStore } from '@/@types/fleet';
import { dataService } from '@/services';
import { FleetService } from '@/services/@types/fleet';
import { mapFleetStoreToDomain } from '@/services/useCases/fleet/adapters/domain';

export type GetFleetStoresByFleetId = {
  input: { fleetId: string };
  output: FleetStore[];
};

export const getFleetStoresByFleetId = async (
  input: GetFleetStoresByFleetId['input'],
): Promise<GetFleetStoresByFleetId['output']> => {
  const { data } = await dataService.get<FleetService.FleetStore[]>(
    `/fleets/${input.fleetId}/stores`,
  );

  return data.map(mapFleetStoreToDomain);
};
