import _ from 'lodash';

import { BrandPromo } from '@/@types/brand';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';
import { BrandPromotionSchema } from '@/pages/Brands/BrandForm/schema/promotion';
import { SyncBrandPromos } from '@/services/useCases';

export const mapBrandPromoFormValuesToRequest = (
  brandPromo: BrandPromotionSchema,
): BrandPromo => {
  const promo = _.omitBy(
    _.omit(brandPromo, ['isNew', 'deleted']),
    (v) => v === '',
  ) as BrandPromo;

  return promo;
};

export const mapBrandPromosFormValuesToRequest = (
  brandPromos: BrandSchema['promos'],
): SyncBrandPromos['input']['promos'] => {
  const filteredBrandUpsells =
    brandPromos?.filter(
      (brandUpsell) => !(brandUpsell.isNew && brandUpsell.deleted),
    ) ?? [];
  return {
    created: filteredBrandUpsells
      .filter((upsell) => upsell.isNew)
      .map(mapBrandPromoFormValuesToRequest),
    updated: filteredBrandUpsells
      .filter((upsell) => !upsell.isNew && !upsell.deleted)
      .map(mapBrandPromoFormValuesToRequest),
    deleted: filteredBrandUpsells
      .filter((upsell) => upsell.deleted)
      .map(mapBrandPromoFormValuesToRequest),
  };
};
