import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled(ParrotUi.Typography)`
  color: hsl(var(--gray));
  font-weight: 800;
`;
