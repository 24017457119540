import * as ParrotUi from '@kea-inc/parrot-ui';
import {
  BanIcon,
  CheckIcon,
  ChevronLeftIcon,
  KeyboardIcon,
  MicIcon,
  MicOffIcon,
  PhoneMissedIcon,
} from 'lucide-react';
import styled, { css } from 'styled-components';

type CellphoneContainerProps = {
  $isConnected?: boolean;
  $isRinging?: boolean;
};

export const CellphoneContainer = styled.div<CellphoneContainerProps>`
  width: 50%;
  padding: 2rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.$isConnected ? 1 : 0.5)};
`;

export const KeyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

type KeyProps = {
  $danger?: boolean;
  $active?: boolean;
};

export const Key = styled.button<KeyProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid hsl(var(--gray));
  background: none;
  transition: 0.2s all ease;
  margin-bottom: 0.125rem;
  font-size: 16px;
  color: hsl(var(--gray));

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
  }

  ${(props) =>
    props.$danger &&
    css`
      background: hsl(var(--error));
      border: none;
    `}

  ${(props) =>
    props.$active &&
    css`
      background: hsl(var(--gray));

      svg {
        color: hsl(var(--white));
      }
    `}
`;

export const MuteIcon = styled(MicIcon)`
  font-size: 2rem;
  color: hsl(var(--gray));
  transform: translateY(2px);
`;

export const UnmuteIcon = styled(MicOffIcon)`
  font-size: 2rem;
  color: hsl(var(--gray));
  transform: translateY(2px);
`;

export const KeypadIcon = styled(KeyboardIcon)`
  font-size: 2rem;
  color: hsl(var(--gray));
  transform: translateY(2px);
`;

export const HangupIcon = styled(PhoneMissedIcon)`
  font-size: 2rem;
  color: hsl(var(--white));
`;

export const RingingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  button:first-child {
    background: hsl(var(--success));
  }
`;

export const AcceptIcon = styled(CheckIcon)``;
export const DeclineIcon = styled(BanIcon)``;
export const PhoneButton = styled(ParrotUi.Button)`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: hsl(var(--success));
`;

export const KeypadContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
`;

export const KeypadLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

export const BackIcon = styled(ChevronLeftIcon)``;

export const KeypadDigitis = styled(ParrotUi.Typography)`
  margin-bottom: 1rem;
  overflow: hidden;
  max-width: 30ch;
`;
