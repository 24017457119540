import styled from 'styled-components';

import { PageCard as PageCardComponent } from '@/components/PageCard';

export const PageCard = styled(PageCardComponent)`
  height: auto;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  width: 500px;
`;
