import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
    opacity: 0;
    transition: 0.1s all ease;
    cursor: pointer;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
