import * as ParrotUi from '@kea-inc/parrot-ui';
import { Pencil } from 'lucide-react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

export const DropdownMenu = styled(ParrotUi.DropdownMenu)``;
export const DropdownMenuContent = styled(ParrotUi.DropdownMenuContent)``;

export const ScrollArea = styled.div`
  max-height: 350px;
  overflow: auto;
`;
export const Badge = styled(ParrotUi.Badge)`
  display: flex;
  gap: 0.3rem;
  cursor: pointer;
`;

export const PencilIcon = styled(Pencil)`
  color: hsl(var(--primary));
`;

export const SaveButton = styled(ParrotUi.Button)`
  height: 2rem;
`;
export const CancelButton = styled(ParrotUi.Button)`
  height: 2rem;
`;

export const SaveBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
`;
