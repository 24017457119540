import { Button, Typography } from '@kea-inc/parrot-ui';
import { PhoneIcon } from 'lucide-react';
import { useMemo } from 'react';

import { useConnector } from '@/pages/Training/connector';
import { useTraining } from '@/pages/Training/hooks/useTraining';

import * as S from './styles';

export function RingingState() {
  const { selectors } = useConnector();
  const { handleAccept, handleDecline } = useTraining();
  const { training, agentEntities } = selectors;
  const { selectedAgentId } = training;

  const selectedAgent = useMemo(
    () => (selectedAgentId ? agentEntities[selectedAgentId] : undefined),
    [selectedAgentId, agentEntities],
  );

  const agentName = `${selectedAgent?.firstName} ${selectedAgent?.lastName}`;

  return (
    <S.RingingState>
      <S.PhoneButton size="icon" variant="outline">
        <PhoneIcon size={42} color="white" />
      </S.PhoneButton>

      <Typography variant="p">{agentName} is calling you back</Typography>

      <S.ButtonContainer>
        <Button onClick={handleAccept}>
          <S.AcceptIcon />
          Accept
        </Button>
        <Button onClick={handleDecline} color="error">
          <S.DeclineIcon />
          Decline
        </Button>
      </S.ButtonContainer>
    </S.RingingState>
  );
}
