import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Connection, Device } from 'twilio-client';

import { DeviceEvents, Token } from '@/@types/training';
import { useConnector } from '@/pages/Training/connector';

import { useAppDispatch } from '../../../store';

export const useSetupDevice = () => {
  const dispatch = useAppDispatch();

  const { actions, selectors } = useConnector();
  const { getDeviceToken } = actions;

  const { user } = useAuth0();

  function onIncoming() {
    dispatch(actions.mergeState({ isRinging: true }));
  }

  function onConnect() {
    dispatch(
      actions.mergeState({
        isConnected: true,
        isRinging: false,
        connectedAt: new Date(),
        isExpectingCall: false,
      }),
    );
  }

  function onDisconnect() {
    dispatch(actions.mergeState({ isConnected: false }));
  }

  function onReady(readyDevice: Device) {
    dispatch(actions.mergeState({ device: readyDevice, isReady: true }));
  }

  function setupDevice(data: Token) {
    const { training } = selectors;
    const { device, isReady } = training;
    if (!data?.token || isReady || device !== null) {
      return;
    }
    const twilioDevice = new Device();

    twilioDevice.setup(data.token as string, {
      codecPreferences: [Connection.Codec.Opus, Connection.Codec.PCMU],
      fakeLocalDTMF: true,
      enableRingingState: true,
      debug: true,
      warnings: false,
      forceAggressiveIceNomination: true,
      appName: `OC-Training-${user?.nickname}`,
      backoffMaxMs: 6000,
    });
    twilioDevice.on(DeviceEvents.READY, onReady);
    twilioDevice.on(DeviceEvents.INCOMING, onIncoming);
    twilioDevice.on(DeviceEvents.CONNECT, onConnect);
    twilioDevice.on(DeviceEvents.DISCONNECT, onDisconnect);
  }

  useEffect(() => {
    dispatch(getDeviceToken(user))
      .unwrap()
      .then((data) => setupDevice(data));
  }, []);
};
