import React, { useState } from 'react';

import { useConnector } from '@/pages/Training/connector';

import * as S from './styles';

interface KeypadProps {
  setIsKeypadOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Keypad({ setIsKeypadOpen }: KeypadProps) {
  const [allDigits, setAllDigits] = useState<string>('');
  const { selectors } = useConnector();
  const { training } = selectors;
  const { device } = training;

  const sendDigit = (digit: string) => {
    device?.activeConnection()?.sendDigits(digit);
    setAllDigits((prev) => {
      if (prev.length > 30) {
        return '';
      }

      return prev + digit;
    });
  };

  return (
    <S.KeypadContainer>
      <S.KeypadDigitis>{allDigits}</S.KeypadDigitis>
      <S.KeypadLine>
        <S.Key onClick={() => sendDigit('1')}>1</S.Key>
        <S.Key onClick={() => sendDigit('2')}>2</S.Key>
        <S.Key onClick={() => sendDigit('3')}>3</S.Key>
      </S.KeypadLine>
      <S.KeypadLine>
        <S.Key onClick={() => sendDigit('4')}>4</S.Key>
        <S.Key onClick={() => sendDigit('5')}>5</S.Key>
        <S.Key onClick={() => sendDigit('6')}>6</S.Key>
      </S.KeypadLine>
      <S.KeypadLine>
        <S.Key onClick={() => sendDigit('7')}>7</S.Key>
        <S.Key onClick={() => sendDigit('8')}>8</S.Key>
        <S.Key onClick={() => sendDigit('9')}>9</S.Key>
      </S.KeypadLine>
      <S.KeypadLine>
        <S.Key onClick={() => sendDigit('*')}>*</S.Key>
        <S.Key onClick={() => sendDigit('0')}>0</S.Key>
        <S.Key onClick={() => sendDigit('#')}>#</S.Key>
      </S.KeypadLine>
      <S.KeypadLine>
        <S.Key onClick={() => setIsKeypadOpen(false)}>
          <S.BackIcon />
        </S.Key>
      </S.KeypadLine>
    </S.KeypadContainer>
  );
}
