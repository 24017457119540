import { PaginatedOrdersFilters } from '@/@types/order/paginatedOrder';
import { OrderFilterSchema } from '@/pages/Orders/List/schema';

export const mapFilterFormValuesToApi = ({
  dateRange,
  ...schema
}: OrderFilterSchema): PaginatedOrdersFilters => ({
  ...schema,
  startTimeGte: dateRange?.from?.toISOString(),
  startTimeLte: dateRange?.to?.toISOString(),
});
