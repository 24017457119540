import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 1rem;
  align-items: center;
`;

export const TabsContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Tabs = styled(ParrotUi.Tabs)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const TabsTrigger = styled(ParrotUi.TabsTrigger)`
  flex: 1;
`;

export const TabsContent = styled(ParrotUi.TabsContent)`
  width: 100%;
  height: 100%;
  overflow: auto;
  flex: 1 1 0px;
`;
