import { Auth0Role, Permission } from '@/@types/user';
import { dataService } from '@/services';
import { RoleService } from '@/services/@types/role';

export type GetRoles = {
  input: void;
  output: Auth0Role[];
};

export const getRoles = async (): Promise<GetRoles['output']> => {
  const { data } = await dataService.get<RoleService.Auth0Role[]>(`/roles`);
  return data.map((role) => ({ ...role, id: role.name }));
};

export type GetRole = {
  input: { name: string };
  output: { roleName: string; permissions: Permission[] };
};

export const getRole = async (
  input: GetRole['input'],
): Promise<GetRole['output']> => {
  const { data } =
    await dataService.get<RoleService.GetAuth0RolePermissionsResponse>(
      `/roles/${input.name}`,
    );

  return {
    roleName: input.name,
    permissions: data.items.map((permission) => ({
      ...permission,
      id: permission.name,
    })),
  };
};

export type GetPermissions = {
  input: void;
  output: Permission[];
};

export const getPermissions = async (): Promise<GetPermissions['output']> => {
  const { data } =
    await dataService.get<RoleService.Permission[]>(`/permissions`);
  return data.map((permission) => ({
    ...permission,
    id: permission.name,
    sources: [],
  }));
};
