import camelcaseKeys from 'camelcase-keys';
import dayjs from 'dayjs';

import { StoreMenuVersion } from '@/@types/order/menu';
import {
  CallerWhitelist,
  PaginatedStores,
  Store,
  StorePromo,
} from '@/@types/store';
import { BatchUpdateStoresOutput } from '@/@types/store/update';
import { StoreService } from '@/services/@types/store';
import { mapBrandToDomain } from '@/services/useCases/brand/adapters';

export const mapStoreToDomain = (serviceStore: StoreService.Store): Store => {
  const camelcased = camelcaseKeys(serviceStore);

  return {
    ...camelcased,
    storeHours: camelcased.hours?.map((hour) => camelcaseKeys(hour)) ?? [],
    callerWhitelist: camelcased.callerWhitelist?.map<CallerWhitelist>((cW) =>
      camelcaseKeys(cW),
    ),
    brand: camelcased.brand ? mapBrandToDomain(camelcased.brand) : undefined,
    specials: camelcased.specials?.map((special) => ({ value: special })) ?? [],
    promos: [],
    country: camelcased.country ?? 'US',
    activationDate: dayjs(camelcased.activationDate).isValid()
      ? dayjs(camelcased.activationDate).toDate()
      : undefined,
    agentlessStoreForwarding: camelcased.storeAgentlessStoreForwarding,
  } as Store;
};

export const mapPaginatedStoreToDomain = (
  pStore: StoreService.PaginatedStoresResponse,
): PaginatedStores => ({
  items: pStore.items.map(mapStoreToDomain),
  totalPages: pStore.total_pages,
  totalRecords: pStore.total_records,
});

export const mapStorePromoToDomain = (
  promo: StoreService.StorePromo,
): StorePromo => camelcaseKeys(promo);

export const mapBatchUpdateToDomain = (
  service: StoreService.BatchUpdateResponse[0],
): BatchUpdateStoresOutput[0] => ({
  storeId: service.store_id,
  isOn: service.is_on,
  status: service.store_status,
});

export const mapStoreMenuVersionToDomain = (
  serviceMenuVersion: StoreService.StoreMenuVersion,
): StoreMenuVersion => camelcaseKeys(serviceMenuVersion);
