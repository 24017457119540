import styled from 'styled-components';

export * from '../styles';

export const QuantityPriorityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 0.3;
`;
