import { useNavigate } from 'react-router-dom';

import { Account } from '@/@types/account';
import { BackButton } from '@/components/BackButton';
import { FlexGrow } from '@/components/FlexGrow';
import { AccountDataValues } from '@/pages/Accounts/AccountInfo/AccountData/AccountDataValues';

import * as S from './styles';

interface AccountDataProps {
  account: Account;
}

export function AccountData(props: AccountDataProps) {
  const { account } = props;
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.HeaderContainer>
        <BackButton onClick={() => navigate('/accounts')} />
        <FlexGrow />
      </S.HeaderContainer>
      <S.ContentContainer>
        <AccountDataValues account={account} />
      </S.ContentContainer>
    </S.Container>
  );
}
