import { isValidPhoneNumber } from 'libphonenumber-js';
import * as z from 'zod';

import { Store } from '@/@types/store';
import { STORE_STATUSES } from '@/constants/store';
import { handoffMethodsSchema } from '@/pages/Stores/StoreForm/schema/handoffMethods';
import { promoSchema } from '@/pages/Stores/StoreForm/schema/promos';
import { whitelistedNumbersSchema } from '@/pages/Stores/StoreForm/schema/whitelistedNumbers';

import { hoursSchema } from './storeHours';

type StoreSchemaProperties = Omit<
  Store,
  | 'brand'
  | 'currency'
  | 'delayedRing'
  | 'fleet'
  | 'id'
  | 'isOn'
  | 'legalEntity'
  | 'menuPreview'
  | 'storeId'
  | 'storeStatus'
  | 'syncCashLimit'
  | 'useStoreHours'
  | 'isProductionMirror'
  | 'menuVersions'
  | 'autoScheduleThreshold'
>;

type SchemaObjShape = Record<keyof StoreSchemaProperties, z.ZodTypeAny>;

export const createEditStoreSchema = z.object({
  brandId: z.string(),
  fleetId: z.string(),
  franchiseStoreNumber: z.string(),
  cashLimit: z.string().refine((value) => !Number.isNaN(Number(value)), {
    message: 'Invalid number',
  }),
  taxRate: z.string().refine((value) => !Number.isNaN(Number(value)), {
    message: 'Invalid number',
  }),
  zipcode: z.string(),
  country: z.enum(['US', 'CA']).optional(),
  timezone: z.string(),
  latitude: z.string().optional(),
  longitude: z.string().optional(),
  state: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  location: z.string().optional(),
  primaryPhone: z.string().refine(isValidPhoneNumber, 'Invalid phone number'),
  secondaryPhone: z.string().refine(isValidPhoneNumber, 'Invalid phone number'),
  twilioNumber: z.string().refine(isValidPhoneNumber, 'Invalid phone number'),
  sipUri: z.string().optional(),
  bypassMode: z.boolean().optional(),
  oloMock: z.boolean().optional(),
  platformId: z.string(),
  platformStoreId: z.string(),
  area: z.string(),
  storeDetails: z.string().optional(),
  notes: z.string().nullable().optional(),
  specials: z.object({ value: z.string() }).array().optional(),
  activationDate: z.date().nullable().optional(),
  status: z.enum(STORE_STATUSES).optional(),
  syncStoreHours: z.boolean().optional(),
  storeHours: hoursSchema,
  promos: promoSchema.array().optional(),
  callerWhitelist: whitelistedNumbersSchema,
  isTestStore: z.boolean().optional(),
  shouldTip: z.boolean().nullable().optional(),
  inStoreOrdering: z.boolean().optional(),
  useDefaultOptionsToMaxQuantity: z.boolean().nullable().optional(),
  triageHandoffMethods: handoffMethodsSchema.optional(),
  menuVersion: z.string().optional(),
  storeAutoScheduleThreshold: z.coerce.number().optional(),
  agentlessStoreForwarding: z.boolean().nullable().optional(),
} satisfies SchemaObjShape);

export type StoreSchema = z.infer<typeof createEditStoreSchema>;
