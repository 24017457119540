import { DateRange } from '@kea-inc/parrot-ui';
import * as z from 'zod';

import { PaginatedOrdersFilters } from '@/@types/order/paginatedOrder';
import {
  APPLICATIONS,
  ORDER_FILTER_HANDOFF_MODES,
  ORDER_STATUS,
  PAYMENT_TYPES,
} from '@/constants/order';

export type SchemaObjShape = Record<
  keyof Omit<PaginatedOrdersFilters, 'startTimeGte' | 'startTimeLte'>,
  z.ZodTypeAny
> & { dateRange: z.ZodTypeAny };

export const orderFilterSchema = z.object({
  orderId: z.string().optional(),
  accountId: z.string().optional(),
  accountPhone: z.string().optional(),
  brandId: z.string().optional(),
  storeId: z.string().optional(),
  agentId: z.string().optional(),
  handoffMode: z.enum(ORDER_FILTER_HANDOFF_MODES).optional(),
  status: z.enum(ORDER_STATUS).optional(),
  paymentType: z.enum(PAYMENT_TYPES).optional(),
  orderApplication: z.enum(APPLICATIONS).optional(),
  dateRange: z
    .object({
      from: z.date().optional(),
      to: z.date().optional(),
    } satisfies Record<keyof DateRange, z.ZodOptional<z.ZodDate>>)
    .optional(),
} satisfies SchemaObjShape);

export type OrderFilterSchema = z.infer<typeof orderFilterSchema>;
