import { dataService } from '@/services';

export type DeleteBpo = {
  input: { id: string };
  output: { id: string };
};

export const deleteBpo = async (
  input: DeleteBpo['input'],
): Promise<DeleteBpo['output']> => {
  const { id } = input;
  await dataService.delete(`/bpos/${id}`);

  return { id };
};
