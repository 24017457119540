import { useAppSelector } from '@/store';
import { getTheme } from '@/store/ui/theme/selectors';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const theme = useAppSelector(getTheme);

  document.documentElement.className = theme;

  return children;
}
