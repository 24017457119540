import { useAppSelector } from '@/store';
import { selectors as accountSelectors } from '@/store/entities/account';
import {
  actions as accountUiActions,
  selectors as accountUiSelectors,
} from '@/store/ui/account';
import {
  actions as accountUcActions,
  selectors as accountUcSelectors,
} from '@/store/useCases/account';

const isFetchingUcs = [accountUcSelectors.isUcFetching('getPaginatedAccounts')];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    accounts: useAppSelector(accountSelectors.getAccounts),
    totalRecords: useAppSelector(accountUiSelectors.getTotalRecords),
    accountEntities: useAppSelector(accountSelectors.getAccountEntities),
    accountsWithFilters: useAppSelector(
      accountSelectors.getAccountsWithFilters,
    ),
    filters: useAppSelector(accountUiSelectors.getFilters),
  };

  const actions = {
    setTotalRecords: accountUiActions.setTotalRecords,
    getPaginatedAccounts: accountUcActions.getPaginatedAccounts,
    setFilters: accountUiActions.setFilters,
  };

  return { actions, selectors };
};
