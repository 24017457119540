import { createDraftSafeSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getBrandsEntities } from '@/store/entities/brand/selectors';
import { getFleetEntities } from '@/store/entities/fleet/selectors';
import { getFilters } from '@/store/ui/store/selectors';

import { adapter, adapterWithFilter } from './slice';
import { StoreState } from '../..';

export const selectAllStores = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.store.stores,
).selectAll;

export const selectStoreEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.store.stores,
).selectEntities;

export const selectStoresWithFilterById =
  adapterWithFilter.getSelectors<StoreState>(
    (stt) => stt.entities.store.storesWithFilter,
  ).selectById;

export const getStoresWithFilter = createDraftSafeSelector(
  selectStoreEntities,
  (stt: StoreState) =>
    selectStoresWithFilterById(stt, JSON.stringify(getFilters(stt))),
  (storeEntities, storesWithFilters) =>
    storesWithFilters?.storeIds.map((storeId) => storeEntities[storeId]),
);

export const getAvailableStores = createDraftSafeSelector(
  selectAllStores,
  (stores) => stores.filter((store) => store.status && store.fleetId),
);

export const getStores = createDraftSafeSelector(
  getAvailableStores,
  getBrandsEntities,
  getFleetEntities,
  (stores, brandEntities, fleetEntities) =>
    stores.map((store) => ({
      ...store,
      brand: brandEntities[store.brandId],
      fleet: fleetEntities[store.fleetId],
    })),
);

export const getStoreEntities = createDraftSafeSelector(
  selectStoreEntities,
  getBrandsEntities,
  getFleetEntities,
  (stores, brandEntities, fleetEntities) =>
    _.keyBy(
      Object.values(stores).map((store) => ({
        ...store,
        brand: brandEntities[store.brandId],
        fleet: fleetEntities[store.fleetId],
      })),
      (store) => store.id,
    ),
);
