import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { KeaLoading } from './components/KeaLoading';
import { ThemeProvider } from './components/ThemeProvider';
import { ToastNotification } from './hooks/useNotification';
import { useRoles } from './hooks/useRoles';
import { applyJwtToken } from './services/middlewares/applyJwtToken';

interface AppProviderProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient();

export function AppProvider({ children }: AppProviderProps) {
  const { isLoading, getAccessTokenSilently, user, isAuthenticated } =
    useAuth0();
  const { roles, availableRoutes } = useRoles();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const mountedRef = useRef(true);

  async function getToken() {
    if (!isAuthenticated) {
      return setIsTokenSet(true);
    }

    if (mountedRef.current) {
      setIsTokenSet(false);
      const token = await getAccessTokenSilently();
      applyJwtToken(token);
      setIsTokenSet(true);
    }
  }

  useEffect(() => {
    getToken();

    return () => {
      mountedRef.current = false;
      localStorage.removeItem('@kea-inc/ops-console/token');
    };
  }, [user]);

  if (isLoading || roles === undefined || !availableRoutes || !isTokenSet) {
    return (
      <div className="w-screen h-screen">
        <KeaLoading />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <div className="bg-background h-screen">{children}</div>
        <ToastNotification />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
