import {
  Dialog,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from '@kea-inc/parrot-ui';
import { useEffect } from 'react';

import { KeaLoading } from '@/components/KeaLoading';
import { LinkWithIcon } from '@/components/LinkWithIcon';
import { useAppDispatch } from '@/store';

import * as S from './styles';
import { useConnector } from '../connector';

type ExtendedProps = DialogProps;

interface StoresDialogProps extends ExtendedProps {
  fleetId?: string;
}

export function StoresDialog(props: StoresDialogProps) {
  const { fleetId, ...dialogProps } = props;

  const { actions, selectors } = useConnector();

  useEffect(() => {
    if (fleetId) {
      dispatch(actions.getFleetStoresByFleetId({ fleetId }));
    }
  }, [fleetId]);

  const { fetchingFleetStores, fleetEntities } = selectors;

  const fleet = fleetId ? fleetEntities[fleetId] : undefined;

  const dispatch = useAppDispatch();

  return (
    <Dialog {...dialogProps}>
      <S.DialogContent>
        {fetchingFleetStores ? (
          <KeaLoading />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>{`Stores in ${fleet?.name} `}</DialogTitle>
            </DialogHeader>
            <S.List>
              {fleet?.stores?.map((store) => (
                <S.ListItem key={store.id}>
                  <LinkWithIcon to={`/stores/${store.id}`}>
                    {`${store.brandName} [${store.franchiseStoreNumber}]`}
                  </LinkWithIcon>
                </S.ListItem>
              ))}
            </S.List>
          </>
        )}
      </S.DialogContent>
    </Dialog>
  );
}
