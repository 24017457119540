import * as selectors from './selectors';
import theme from './slice';

export * from './slice';

const { actions, reducer } = theme;

export { actions, reducer, selectors };

export default theme;
