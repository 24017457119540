import * as ParrotUi from '@kea-inc/parrot-ui';
import { CircleCheck } from 'lucide-react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Card = styled(ParrotUi.Card)`
  width: 700px;
`;

export const CardContent = styled(ParrotUi.CardContent)`
  height: 125px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SuccessTypography = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const CircleCheckIcon = styled(CircleCheck)`
  color: hsl(var(--success));
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
