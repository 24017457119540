import * as ParrotUi from '@kea-inc/parrot-ui';
import { CheckCircle } from 'lucide-react';
import styled from 'styled-components';

import * as GlobalStyles from '@/styles';

export const SectionContainer = GlobalStyles.SectionContainer;
export const SectionTitle = GlobalStyles.SectionTitle;

export const FormFieldContainer = GlobalStyles.FormFieldContainer;

export const ToggleContainer = GlobalStyles.ToggleContainer;
export const Toggle = styled(ParrotUi.Toggle)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const CheckCircleIcon = styled(CheckCircle)`
  color: hsl(var(--success));
`;

export const BrandsFormFieldContainer = styled.div``;

export const BrandContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px hsl(var(--border));
  align-items: center;
`;

export const BrandToggleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
