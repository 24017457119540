import { Input, Label } from '@kea-inc/parrot-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { useConnector } from '@/pages/BPOs/connector';
import { useAppDispatch } from '@/store';

import * as S from '../styles';

export function Create() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const [bpoName, setBpoName] = useState('');

  const { selectors, actions } = useConnector();
  const { creating } = selectors;

  const { createBpo } = actions;

  const handleCreateBpo = () =>
    dispatch(createBpo({ bpoName }))
      .unwrap()
      .then(() => {
        notification.success('Success', {
          description: getCreateUpdateDescription('Bpo'),
        });
        navigate('/bpos');
      });

  return (
    <PageCard
      title="Create bpo"
      backButton={<BackButton onClick={() => navigate('/bpos')} />}
      footerContent={
        <UpdateCreateButton loading={creating} onClick={handleCreateBpo} />
      }
    >
      <S.InputContainer>
        <Label>Code</Label>
        <Input
          value={bpoName}
          onChange={(evt) => setBpoName(evt.target.value)}
        />
      </S.InputContainer>
    </PageCard>
  );
}
