import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormField,
  FormItemInput,
  Select,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import { FormType } from '@/constants/form';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { PermissionsFormItem } from '@/pages/Admin/AdminForm/PermissionsFormItem';
import { Fleets } from '@/pages/Admin/AdminForm/UserForm/Sections/Fleets';
import { Roles } from '@/pages/Admin/AdminForm/UserForm/Sections/Roles';
import {
  UserSchema,
  userSchema,
} from '@/pages/Admin/AdminForm/UserForm/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

interface UserFormProps {
  type: FormType;
}

export function UserForm(props: UserFormProps) {
  const { type } = props;

  const navigate = useNavigate();
  const notification = useNotification();
  const { userId = '' } = useParams<{ userId: string }>();
  const dispatch = useAppDispatch();

  const { actions, selectors } = useConnector();
  const { userEntities, fetching, creating, updating, bpos, permissions } =
    selectors;

  useEffect(() => {
    if (userId) {
      dispatch(actions.getUser({ id: userId }));
    }
  }, [userId]);

  useEffect(() => {
    dispatch(actions.getRoles());
    dispatch(actions.getFleets());
    dispatch(actions.getBpos());
    dispatch(actions.getPermissions());
  }, []);
  const user = userEntities[userId];

  const form = useForm<UserSchema>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      roles: [],
      fleets: [],
      permissions: [],
      bpoId: user?.bpoId === null ? 'null' : user?.bpoId,
    },
  });

  useEffect(() => {
    if (user) {
      form.reset(user);
    }
  }, [user]);

  if (fetching) {
    return <KeaLoading />;
  }
  if (type === 'update' && !user) {
    return null;
  }

  const handleSuccessSave = () => {
    notification.success('Success', {
      description: getCreateUpdateDescription('User'),
    });
    navigate('/admin');
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    if (type === 'create') {
      dispatch(actions.createUser(values)).unwrap().then(handleSuccessSave);
    }
    if (user) {
      if (type === 'update') {
        dispatch(
          actions.updateUser({
            ...values,
            id: user.id,
            bpoId: values.bpoId === 'null' ? null : user.bpoId ?? null,
          }),
        )
          .unwrap()
          .then(handleSuccessSave);
      }
    }
  };

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <S.PageCard
          backButton={<BackButton onClick={() => navigate('/admin')} />}
          title={
            user
              ? `${user.firstName} ${user.lastName} [${user.email}]`
              : 'Create a new user'
          }
          footerContent={
            <UpdateCreateButton type="submit" loading={creating ?? updating} />
          }
        >
          <S.FormFieldContainer>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItemInput field={field} label="First name" />
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItemInput field={field} label="Last name" />
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItemInput field={field} label="Email" />
              )}
            />
          </S.FormFieldContainer>
          <Roles form={form} />
          <ProtectedComponent allowedPermissions={['write:user_permissions']}>
            <PermissionsFormItem
              form={form}
              permissions={permissions}
              type="user"
            />
          </ProtectedComponent>
          <S.FormFieldContainer>
            <FormField
              control={form.control}
              name="bpoId"
              render={({ field }) => (
                <FormItemSelect field={field} label="BPO">
                  <Select.Item value="null">No BPO (see all)</Select.Item>
                  {bpos.map((bpo) => (
                    <Select.Item value={bpo.id} key={bpo.id}>
                      {bpo.name}
                    </Select.Item>
                  ))}
                </FormItemSelect>
              )}
            />
          </S.FormFieldContainer>
          <Fleets form={form} />
        </S.PageCard>
      </S.Form>
    </Form>
  );
}
