import _ from 'lodash';

import { PaginatedStores, PaginatedStoresFilter, Store } from '@/@types/store';
import { dataService, menuService } from '@/services';
import { StoreService } from '@/services/@types/store';
import {
  mapPaginatedStoreToDomain,
  mapStoreToDomain,
} from '@/services/useCases/store/adapters/domain';

import { mapPaginatedStoresFilterToApi } from './adapters/api';

type GetPaginatedStoresInput = {
  page?: number;
  pageSize?: number;
  filters?: PaginatedStoresFilter;
};

export type GetPaginatedStores = {
  input?: GetPaginatedStoresInput;
  output: PaginatedStores;
};

export async function getPaginatedStores(
  input: GetPaginatedStores['input'],
): Promise<GetPaginatedStores['output']> {
  const { data } = await dataService.get<StoreService.PaginatedStoresResponse>(
    '/stores',
    {
      params: {
        page: input?.page,
        pageSize: input?.pageSize,
        ...mapPaginatedStoresFilterToApi(input?.filters),
      },
      version: 'v3',
    },
  );

  return mapPaginatedStoreToDomain(data);
}

export type GetStore = {
  input: { id: string };
  output: Store;
};

export async function getStore(
  input: GetStore['input'],
): Promise<GetStore['output']> {
  const { data } = await dataService.get<StoreService.Store>(
    `/stores/${input.id}`,
  );

  return mapStoreToDomain(data);
}

export type GetStoreByIds = {
  input: { ids: string[] };
  output: Store[];
};

export async function getStoreByIds(
  input: GetStoreByIds['input'],
): Promise<GetStoreByIds['output']> {
  const { data } = await dataService.get<StoreService.Store[]>(
    '/stores/by-ids',
    {
      params: new URLSearchParams({
        ids: _.uniq(input.ids).join(','),
      }),
    },
  );

  return data.map(mapStoreToDomain);
}

type UpdateOnOffInput = {
  id: string;
  isOn: boolean;
};
type UpdateOnOffOutput = {
  id: string;
  isOn: boolean;
};

export type UpdateOnOff = {
  input: UpdateOnOffInput;
  output: UpdateOnOffOutput;
};

export async function updateOnOff(
  input: UpdateOnOff['input'],
): Promise<UpdateOnOff['output']> {
  const { id, isOn } = input;
  await dataService.patch(`/stores/${id}`, {
    is_on: isOn,
  });

  return { id, isOn };
}

type SyncMenuInput = {
  brandKey: string;
  storeId: string;
};

export type SyncMenu = {
  input: SyncMenuInput;
  output: void;
};

export async function syncMenu(
  input: SyncMenu['input'],
): Promise<SyncMenu['output']> {
  await menuService.post('/menus/import', input);
}

export type GetStoresByBrandId = {
  input: { brandId: string };
  output: Store[];
};

export const getStoresBrandId = async (
  input: GetStoresByBrandId['input'],
): Promise<GetStoresByBrandId['output']> => {
  const { brandId } = input;
  const { data } = await dataService.get<StoreService.Store[]>('/stores', {
    params: { brand_id: brandId },
  });
  return data.map(mapStoreToDomain);
};
