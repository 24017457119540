import camelcaseKeys from 'camelcase-keys';
import _ from 'lodash';
import Papa from 'papaparse';
import { useEffect } from 'react';

import { Store } from '@/@types/store';
import { STORE_FIELDS } from '@/constants/store';
import { useNotification } from '@/hooks/useNotification';
import { useConnector } from '@/pages/Stores/BulkCreation/connector';
import { useAppDispatch } from '@/store';

type UseBulkCreationOpts = {
  csvFile: File | null;
};

export const useBulkCreation = (opts: UseBulkCreationOpts) => {
  const { csvFile } = opts;

  const notification = useNotification();

  const dispatch = useAppDispatch();

  const { actions, selectors } = useConnector();

  const {
    remainingRequiredStoreFields,
    remainingStoreFields,
    bulkCreation,
    creating,
  } = selectors;

  const { csvDataFields, isParsing } = bulkCreation;

  useEffect(() => {
    dispatch(actions.getBrands());
    dispatch(actions.getPlatforms());
  }, []);

  const bulkCreationSubmit = (stores: Partial<Store>[]) => {
    return dispatch(actions.bulkCreation({ stores })).unwrap();
  };

  useEffect(() => {
    const storeFieldsAssignment = csvDataFields.fields.reduce(
      (assignment, field) => {
        const storeFieldsByName = _.keyBy(
          STORE_FIELDS,
          (storeField) => storeField.name,
        );

        if (storeFieldsByName[field.toLowerCase()]) {
          assignment[field] = storeFieldsByName[field.toLowerCase()].name;
          return assignment;
        }

        const assignedField = STORE_FIELDS.find((storeField) => {
          const isMatch = field
            .replace('_', ' ')
            .toLowerCase()
            .includes(storeField.label.toLowerCase());
          const isReverseMatch = storeField.label
            .toLowerCase()
            .includes(field.replace('_', ' ').toLowerCase());

          return isMatch || isReverseMatch;
        });
        if (assignedField) {
          assignment[field] = assignedField.name;
        }
        return assignment;
      },
      {} as Record<string, string>,
    );
    dispatch(actions.setBulkCreationSelectedFields(storeFieldsAssignment));
  }, [csvDataFields.fields]);

  useEffect(() => {
    if (csvFile) {
      dispatch(actions.setBulkCreationIsParsing(true));
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          dispatch(actions.setBulkCreationIsParsing(false));

          if (!results.data || !results.meta.fields) {
            notification.error('Error parsing CSV file', {
              description: 'Please contact support for more information',
            });
            return;
          }

          dispatch(
            actions.setBulkCreationCsvDataFields({
              data: camelcaseKeys(results.data as Record<string, string>[]),
              fields: results.meta.fields ?? [],
            }),
          );
        },
        error: (error) => {
          notification.error('Error parsing CSV file', {
            description: error.message,
          });

          dispatch(actions.setBulkCreationIsParsing(false));
        },
      });
    }
  }, [csvFile]);

  return {
    isParsing,
    csvDataFields,
    remainingStoreFields,
    remainingRequiredStoreFields,
    bulkCreationSubmit,
    creating,
  };
};
