import { HeadphonesIcon } from 'lucide-react';
import React from 'react';

import { Information } from '@/components/Information';

import * as S from './styles';

interface AgentMetricProps {
  activeAgents: React.ReactNode;
  maxIdleTime: React.ReactNode;
  avgIdleTime: React.ReactNode;
}

export function AgentMetric(props: AgentMetricProps) {
  const { activeAgents, avgIdleTime, maxIdleTime } = props;
  return (
    <S.MetricContainer>
      <S.MetricTitleContainer>
        <HeadphonesIcon />
        <S.MetricTitle>Agent Metric</S.MetricTitle>
      </S.MetricTitleContainer>
      <S.MetricContent>
        <Information title="Active" color="primary" heavy>
          {activeAgents}
        </Information>
        <Information title="Max idle time" color="secondary">
          {maxIdleTime}
        </Information>
        <Information title="Average idle time" color="secondary">
          {avgIdleTime}
        </Information>
      </S.MetricContent>
    </S.MetricContainer>
  );
}
