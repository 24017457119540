import { Button } from '@kea-inc/parrot-ui';
import { PhoneIcon } from 'lucide-react';

import { useConnector } from '@/pages/Training/connector';
import { useTraining } from '@/pages/Training/hooks/useTraining';

import { AgentsSelect } from './AgentsSelect';
import { BrandsSelect } from './BrandsSelect';
import { HandoffModeSelect } from './HandoffModeSelect';
import { StoresSelect } from './StoresSelect';
import * as S from './styles';

export function StartCall() {
  const { createTrainingTask } = useTraining();
  const { selectors } = useConnector();
  const { training } = selectors;
  const {
    isCreatingTask,
    selectedAgentId,
    selectedBrandId,
    selectedStoreId,
    selectedHandoffMode,
  } = training;

  const isCallAvailable =
    selectedHandoffMode &&
    selectedAgentId &&
    selectedBrandId &&
    selectedStoreId;

  return (
    <S.CallContainer>
      <S.Header>
        <S.Typography>Start a training order </S.Typography>
      </S.Header>
      <AgentsSelect />
      <BrandsSelect />
      <StoresSelect />
      <HandoffModeSelect />
      <S.CallButtonContainer>
        <Button
          onClick={() => createTrainingTask(selectedHandoffMode)}
          disabled={!isCallAvailable}
          loading={isCreatingTask}
        >
          Call
          <PhoneIcon size={16} />
        </Button>
      </S.CallButtonContainer>
    </S.CallContainer>
  );
}
