import camelcaseKeys from 'camelcase-keys';

import { Agent } from '@/@types/agent';
import { AgentService } from '@/services/@types/agent';
import { mapAgentBrandToDomain } from '@/services/useCases/brand/adapters';

export const mapAgentToDomain = (serviceAgent: AgentService.Agent): Agent => {
  const camelcased = camelcaseKeys(serviceAgent);
  return {
    ...camelcased,
    brands: serviceAgent.brands?.map(mapAgentBrandToDomain),
  };
};
