import { z } from 'zod';

import { Agent } from '@/@types/agent';
import { agentBrandSchema } from '@/pages/Agents/AgentForm/schema/brand';

type AgentSchemaProperties = Pick<
  Agent,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'username'
  | 'bpoId'
  | 'workSiteId'
  | 'notes'
  | 'status'
  | 'roleWeight'
  | 'brands'
>;

type SchemaObjShape = Record<keyof AgentSchemaProperties, z.ZodTypeAny>;

export const agentSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  bpoId: z.string(),
  username: z.string(),
  workSiteId: z.string(),
  notes: z.string().optional(),
  status: z.enum(['ACTIVE', 'TRAINING', 'NO_LONGER_ACTIVE']),
  roleWeight: z.coerce.number(),
  brands: agentBrandSchema.array(),
} satisfies SchemaObjShape);

export type AgentSchema = z.infer<typeof agentSchema>;
