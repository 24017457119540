import { Typography } from '@kea-inc/parrot-ui';
import React from 'react';

import * as S from './styles';
interface LabelValueProps {
  label: string;
  value: React.ReactNode;
  className?: string;
}

export function LabelValue(props: LabelValueProps) {
  const { label, value, className } = props;
  return (
    <S.Container className={className}>
      <S.Label size="sm">{label}</S.Label>
      <Typography size="lg">{value}</Typography>
    </S.Container>
  );
}
