import { useNavigate } from 'react-router-dom';

import { CreateFleetRequest, UpdateFleetRequest } from '@/@types/fleet';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '@/store';
import { selectors as entsSelectors } from '@/store/entities/fleet';
import {
  actions as fleetUCActions,
  selectors as ucSelectors,
} from '@/store/useCases/fleet';

export const useConnector = () => {
  const selectors = {
    creating: useAppSelector(ucSelectors.isUcFetching('createFleet')),
    updating: useAppSelector(ucSelectors.isUcFetching('updateFleet')),
    fetching: useAppSelector(ucSelectors.isUcFetching('getFleets')),
    fleetEntities: useAppSelector(entsSelectors.getFleetEntities),
  };

  const dispatch = useAppDispatch();
  const notification = useNotification();
  const navigate = useNavigate();

  const handleGetFleet = (id: string) => {
    dispatch(fleetUCActions.getFleet(id));
  };

  const handleCreateFleet = (fleet: CreateFleetRequest) => {
    dispatch(fleetUCActions.createFleet(fleet))
      .unwrap()
      .then(() => {
        notification.success('Success', {
          description: getCreateUpdateDescription('Fleet'),
        });
        navigate(`/fleets`);
      })
      .catch(() =>
        notification.error('Error', {
          description: 'Fleet not updated. Please try again later.',
        }),
      );
  };
  const handleUpdateFleet = (flt: UpdateFleetRequest) => {
    dispatch(fleetUCActions.updateFleet(flt))
      .unwrap()
      .then(() => {
        notification.success('Success', {
          description: getCreateUpdateDescription('Fleet'),
        });
        navigate(`/fleets`);
      })
      .catch(() =>
        notification.error('Error', {
          description: 'Fleet not updated. Please try again later.',
        }),
      );
  };

  return { handleCreateFleet, handleUpdateFleet, handleGetFleet, selectors };
};
