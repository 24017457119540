import { OrderCallFlow } from '@/@types/order/callFlow';
import { OrderService } from '@/services/@types/order';
import { analyticsService } from '@/services/clients';
import { mapCallFlowResponseToDomain } from '@/services/useCases/order/adapters/domain/callFlow';

export type GetOrderCallFlow = {
  input: { orderId: string };
  output: OrderCallFlow[];
};

export const getOrderCallFlow = async (input: GetOrderCallFlow['input']) => {
  const { data } =
    await analyticsService.get<OrderService.OrderCallFlowResponse>(
      `/timeline/${input.orderId}`,
      {
        version: 'v1',
      },
    );

  return mapCallFlowResponseToDomain(data);
};
