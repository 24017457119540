import { useAppSelector } from '@/store';
import { selectors as agentSelectors } from '@/store/entities/platform';
import {
  actions as platformUcActions,
  selectors as platformUcSelectors,
} from '@/store/useCases/platform';

const isCreatingUpdatingUcs = [
  platformUcSelectors.isUcFetching('createPlatform'),
  platformUcSelectors.isUcFetching('updatePlatform'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(platformUcSelectors.isUcFetching('getPlatforms')),
    creatingUpdating: useAppSelector((state) =>
      isCreatingUpdatingUcs
        .map((ucSelector) => ucSelector(state))
        .some(Boolean),
    ),
    deleting: useAppSelector(
      platformUcSelectors.isUcFetching('deletePlatform'),
    ),
    platforms: useAppSelector(agentSelectors.getPlatforms),
    platformEntities: useAppSelector(agentSelectors.getPlatformsEntities),
  };

  const actions = {
    getPlatforms: platformUcActions.getPlatforms,
    createPlatform: platformUcActions.createPlatform,
    updatePlatform: platformUcActions.updatePlatform,
    deletePlatform: platformUcActions.deletePlatform,
  };

  return { actions, selectors };
};
