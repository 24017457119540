import { Account } from '@/@types/account';
import { Copy } from '@/components/Copy';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import { formatUUID } from '@/utils/uuid';

import * as S from './styles';

interface AccountDataValuesProps {
  account: Account;
}

export function AccountDataValues(props: AccountDataValuesProps) {
  const { account } = props;

  if (!account) {
    return null;
  }

  return (
    <S.LabelValuesContainer>
      <S.LabelValue
        label="Caller ID"
        value={
          <ProtectedComponent allowedPermissions={['read:accounts']}>
            <Copy value={account?.id}>{formatUUID(account?.id)}</Copy>
          </ProtectedComponent>
        }
      />
      <S.LabelValue
        label="Caller"
        value={
          <ProtectedComponent allowedPermissions={['read:account-details']}>
            {account?.firstName} {account?.lastName}
          </ProtectedComponent>
        }
      />

      <S.LabelValue
        label="Phone"
        value={
          <ProtectedComponent allowedPermissions={['read:account-details']}>
            {account?.phone}
          </ProtectedComponent>
        }
      />
    </S.LabelValuesContainer>
  );
}
