import { Tooltip, TypographyProps } from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';
import ReactJson from 'react-json-view';

import {
  OrderTranscript,
  OrderTranscriptActor,
} from '@/@types/order/transcript';
import { useAppDispatch } from '@/store';

import * as S from './styles';
import { getActorIcon, getTranscriptMessageName } from './utils';
import { useConnector } from '../../connector';
interface MessageProps {
  transcript: OrderTranscript;
}

export function TranscriptMessage(props: MessageProps) {
  const { transcript } = props;
  const { actions, selectors } = useConnector();

  const dispatch = useAppDispatch();

  const { debugMode, theme } = selectors;
  const ActorIcon = getActorIcon(transcript.actor);

  const getTranscriptMessageContentBackgroundColorProps = () => {
    const actorToBackground: Record<
      OrderTranscriptActor,
      S.TranscriptMessageContentBackground
    > = {
      inkbot: 'muted',
      kea_bot: 'muted',
      caller: 'primary',
      customer: 'primary',
      kea_vbx: 'muted',
    };

    const $background = actorToBackground[transcript.actor];

    const actorToColor: Record<OrderTranscriptActor, TypographyProps['color']> =
      {
        caller: 'white',
        customer: 'white',
        inkbot: 'primary',
        kea_bot: 'primary',
        kea_vbx: 'primary',
      };

    const color =
      theme === 'light' ? actorToColor[transcript.actor] : undefined;

    return { $background, color };
  };

  const handleTimestampClick = () => {
    dispatch(actions.setTimestamp(transcript.recordingTimestamp));
  };

  return (
    <S.TranscriptMessageContainer>
      <S.Typography muted>{getTranscriptMessageName(transcript)}</S.Typography>
      <S.TranscriptMessageContentContainer>
        <S.TranscriptTimestampContainer>
          <S.Avatar>
            <S.AvatarFallback>
              <ActorIcon color="hsl(var(--primary))" />
            </S.AvatarFallback>
          </S.Avatar>
          <Tooltip content="Click to play the call recording from this time.">
            <div onClick={handleTimestampClick}>
              <S.TranscriptTimestamp>
                {dayjs
                  .duration(transcript.recordingTimestamp, 'seconds')
                  .format('mm:ss')}
              </S.TranscriptTimestamp>
            </div>
          </Tooltip>
        </S.TranscriptTimestampContainer>
        {debugMode ? (
          <ReactJson
            name={transcript.dialog}
            collapsed
            src={transcript}
            theme={theme === 'dark' ? 'ashes' : undefined}
            style={{
              border: '1px solid',
              padding: '0.5rem',
              maxWidth: '500px',
              borderRadius: '10px',
            }}
          />
        ) : (
          <S.TranscriptMessageContent
            {...getTranscriptMessageContentBackgroundColorProps()}
          >
            {transcript.dialog}
          </S.TranscriptMessageContent>
        )}
      </S.TranscriptMessageContentContainer>
    </S.TranscriptMessageContainer>
  );
}
