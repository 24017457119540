import { FormField } from '@kea-inc/parrot-ui';
import _ from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { mapStoreFieldToFormItemComponent } from '@/pages/Stores/BulkCreation/ManualAssignment/mapStoreFieldToFormItemComponent';
import { ManualAssignmentSchema } from '@/pages/Stores/BulkCreation/ManualAssignment/schema';
import { useConnector } from '@/pages/Stores/BulkCreation/connector';

import * as S from './styles';

type ManualAssignmentProps = {
  form: UseFormReturn<ManualAssignmentSchema>;
};

export function ManualAssignment(props: ManualAssignmentProps) {
  const { form } = props;

  const { selectors } = useConnector();

  const { remainingStoreFields } = selectors;

  return (
    <S.Container>
      {remainingStoreFields.map((storeField) => {
        return (
          <FormField
            key={storeField.name}
            control={form.control}
            name={_.camelCase(storeField.name) as keyof ManualAssignmentSchema}
            render={({ field }) => {
              return mapStoreFieldToFormItemComponent(storeField, field);
            }}
          />
        );
      })}
    </S.Container>
  );
}
