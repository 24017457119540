import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import * as GlobalStyles from '@/styles';

export const Form = GlobalStyles.Form;
export const SectionsContainer = GlobalStyles.SectionsContainer;

export const LocationFormItemInput = styled(ParrotUi.FormItemInput)`
  flex: 2;
`;

export const QrCodeButton = styled(ParrotUi.Button)`
  margin-top: 0px !important;
`;

export const QrCodeDialogContent = styled(ParrotUi.DialogContent)`
  display: flex;
  align-items: center;
`;
