import { StoreMenuVersion } from '@/@types/order/menu';
import { StoreService } from '@/services/@types/store';
import { dataService } from '@/services/clients';
import { mapStoreMenuVersionToDomain } from '@/services/useCases/store/adapters/domain';

export type GetStoreMenuVersions = {
  input: { storeId: string };
  output: StoreMenuVersion[];
};

export async function getStoreMenuVersions(
  input: GetStoreMenuVersions['input'],
): Promise<GetStoreMenuVersions['output']> {
  const { data } = await dataService.get<StoreService.StoreMenuVersion[]>(
    `/stores/${input.storeId}/menuVersions`,
    {
      version: 'v2',
    },
  );

  return data.map(mapStoreMenuVersionToDomain);
}
