import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuTrigger,
} from '@kea-inc/parrot-ui';
import { LucideIcon, LucideProps } from 'lucide-react';
import React from 'react';

import * as S from './styles';

export type DropdownMenuItemType = {
  label: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  Icon: React.ReactElement<LucideIcon>;
  disable?: boolean;
};

type DropdownSubMenuItems = {
  label: string;
  Icon: React.ReactElement<LucideIcon>;
  subMenus: DropdownMenuItemType[];
};

interface TableActionsProps {
  onUpdateClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteClick?: React.MouseEventHandler<HTMLButtonElement>;
  dropDownMenuLabel?: string;
  dropdownMenuItems?: DropdownMenuItemType[];
  dropdownSubMenuItems?: DropdownSubMenuItems[];
  className?: string;
}

export const TableActions = (props: TableActionsProps) => {
  const {
    dropDownMenuLabel,
    onUpdateClick,
    onDeleteClick,
    dropdownMenuItems,
    dropdownSubMenuItems,
    className,
  } = props;
  return (
    <S.ActionContainer className={className}>
      {dropdownMenuItems ?? dropdownSubMenuItems ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <S.IconButton variant="ghost">
              <S.SettingsIcon />
            </S.IconButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>{dropDownMenuLabel}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {dropdownSubMenuItems?.map((subMenu, idx) => (
              <DropdownMenuSub key={`${subMenu.label}-${idx}`}>
                <S.DropdownMenuSubTrigger>
                  {React.cloneElement(subMenu.Icon, {
                    ...subMenu.Icon.props,
                    size: 14,
                  } as LucideProps)}
                  {subMenu.label}
                </S.DropdownMenuSubTrigger>
                <DropdownMenuSubContent>
                  {subMenu.subMenus.map((dropdownItem, index) => (
                    <S.DropdownMenuItem
                      onClick={(evt) => {
                        evt.stopPropagation();
                        dropdownItem.onClick(evt);
                      }}
                      key={dropdownItem.label + index}
                    >
                      {React.cloneElement(dropdownItem.Icon, {
                        ...dropdownItem.Icon.props,
                        size: 14,
                      } as LucideProps)}
                      {dropdownItem.label}
                    </S.DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuSub>
            ))}
            {dropdownMenuItems?.map((dropdownItem, index) => (
              <S.DropdownMenuItem
                onClick={(evt) => {
                  evt.stopPropagation();
                  dropdownItem.onClick(evt);
                }}
                key={dropdownItem.label + index}
                disabled={dropdownItem.disable}
              >
                {React.cloneElement(dropdownItem.Icon, {
                  ...dropdownItem.Icon.props,
                  size: 14,
                } as LucideProps)}
                {dropdownItem.label}
              </S.DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null}
      {onUpdateClick ? (
        <S.IconButton
          variant="ghost"
          onClick={(evt) => {
            evt.stopPropagation();
            onUpdateClick(evt);
          }}
        >
          <S.PencilIcon />
        </S.IconButton>
      ) : null}
      {onDeleteClick ? (
        <S.IconButton
          variant="ghost"
          onClick={(evt) => {
            evt.stopPropagation();
            onDeleteClick(evt);
          }}
        >
          <S.TrashIcon />
        </S.IconButton>
      ) : null}
    </S.ActionContainer>
  );
};
