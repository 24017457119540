import { useAppSelector } from '@/store';
import { selectors as platformSelectors } from '@/store/entities/platform';
import { selectors as storeSelectors } from '@/store/entities/store';
import {
  selectors as storeUiSelectors,
  actions as storeUiActions,
} from '@/store/ui/store';
import { actions as brandUcActions } from '@/store/useCases/brand';
import { actions as fleetUcActions } from '@/store/useCases/fleet';
import { actions as platformUcActions } from '@/store/useCases/platform';
import {
  actions as storeUCActions,
  selectors as ucSelectors,
} from '@/store/useCases/store';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(ucSelectors.isUcFetching('getPaginatedStores')),
    deleting: useAppSelector(ucSelectors.isUcFetching('deleteStore')),
    syncing: useAppSelector(ucSelectors.isUcFetching('syncMenu')),
    stores: useAppSelector(storeSelectors.getStores),
    storesEntities: useAppSelector(storeSelectors.getStoreEntities),
    totalRecords: useAppSelector(storeUiSelectors.getTotalRecords),
    platforms: useAppSelector(platformSelectors.getPlatforms),
    storesWithFilter: useAppSelector(storeSelectors.getStoresWithFilter),
    filters: useAppSelector(storeUiSelectors.getFilters),
  };

  const actions = {
    getPaginatedStores: storeUCActions.getPaginatedStores,
    getPlatforms: platformUcActions.getPlatforms,
    updateOnOff: storeUCActions.updateOnOff,
    deleteStore: storeUCActions.deleteStore,
    syncMenu: storeUCActions.syncMenu,
    setTotalRecords: storeUiActions.setTotalRecords,
    getBrands: brandUcActions.getBrands,
    getFleets: fleetUcActions.getFleets,
    setFilters: storeUiActions.setFilters,
  };

  return { actions, selectors };
};
