import { useAppSelector } from '@/store';
import { selectors as accountSelectors } from '@/store/entities/account';
import { selectors as orderSelectors } from '@/store/entities/order';
import { selectors as themeUiSelectors } from '@/store/ui/theme';
import {
  actions as accountUcActions,
  selectors as accountUcSelectors,
} from '@/store/useCases/account';
import {
  selectors as orderUcSelectors,
  actions as orderUcActions,
} from '@/store/useCases/order';

const isFetchingUcs = [
  orderUcSelectors.isUcFetching('getOrder'),
  accountUcSelectors.isUcFetching('getAccount'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    orderEntities: useAppSelector(orderSelectors.getOrderEntities),
    accountEntities: useAppSelector(accountSelectors.getAccountEntities),
    theme: useAppSelector(themeUiSelectors.getTheme),
  };

  const actions = {
    getOrder: orderUcActions.getOrder,
    getAccount: accountUcActions.getAccount,
  };

  return { actions, selectors };
};
