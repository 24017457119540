import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaginatedStoresFilter } from '@/@types/store';
import { actions } from '@/store/useCases/store';

export type CSVDataFields = {
  data: Record<string, string>[];
  fields: string[];
};

export type BulkCreationState = {
  isParsing: boolean;
  csvDataFields: CSVDataFields;
  selectedFields: Record<string, string | null>;
};

const initBulkCreation: BulkCreationState = {
  csvDataFields: { data: [], fields: [] },
  isParsing: false,
  selectedFields: {},
};

export interface State {
  totalRecords: number;
  filters?: PaginatedStoresFilter;
  bulkCreation: BulkCreationState;
}

export const initialState: State = {
  totalRecords: 0,
  bulkCreation: initBulkCreation,
};

const slice = createSlice({
  name: 'ui/store',
  initialState,
  reducers: {
    setTotalRecords: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload;
    },
    setFilters: (state, action: PayloadAction<State['filters']>) => {
      state.filters = action.payload;
    },
    setBulkCreationIsParsing: (state, action: PayloadAction<boolean>) => {
      state.bulkCreation.isParsing = action.payload;
    },
    setBulkCreationCsvDataFields: (
      state,
      action: PayloadAction<BulkCreationState['csvDataFields']>,
    ) => {
      state.bulkCreation.csvDataFields = action.payload;
    },
    setBulkCreationSelectedFields: (
      state,
      action: PayloadAction<BulkCreationState['selectedFields']>,
    ) => {
      state.bulkCreation.selectedFields = action.payload;
    },
    setBulkCreationSelectField: (
      state,
      action: PayloadAction<Record<string, string | null>>,
    ) => {
      state.bulkCreation.selectedFields = {
        ...state.bulkCreation.selectedFields,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.createStore.fulfilled, (state) => {
      state.totalRecords = state.totalRecords + 1;
    });
    builder.addCase(actions.deleteStore.fulfilled, (state) => {
      state.totalRecords = state.totalRecords - 1;
    });
  },
});

export default slice;
