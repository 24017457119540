import env from '@beam-australia/react-env';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { ReducedAgentsMetrics } from '@/@types/agent';
import { ReducedAgentBrand } from '@/@types/brand';
import { MetricsData } from '@/@types/metrics';

import { useQueuedBrandsSocket } from '../../../hooks/useQueuedBrandsSocket';
import { useRoleWeightSocket } from '../../../hooks/useRoleWeightSocket';
import { useSocket } from '../../../hooks/useSocket';
import { reduceAgents } from '../../../utils';
import { useConnector } from '../connector';

const metricsServiceHost = env('METRICS_SERVICE');

type UseReducedAgents = {
  metrics: MetricsData;
};

export const useReducedAgents = (opts: UseReducedAgents) => {
  const { metrics } = opts;

  const socketConfig = useSocket(metricsServiceHost);

  const { roleWeightChanges, sendRoleWeightChange } =
    useRoleWeightSocket(socketConfig);

  const { queuedBrandsChanges, sendQueuedBrandChange } =
    useQueuedBrandsSocket(socketConfig);

  const [brandFilterId, setBrandFilterId] = useState<string>('all');
  const [reducedAgents, setReducedAgents] = useState<ReducedAgentsMetrics[]>(
    [],
  );

  const { selectors } = useConnector();

  const { brandsEntities } = selectors;

  useEffect(() => {
    setReducedAgents((pReducedAgents) =>
      pReducedAgents.map((reducedAgent) => {
        const agentRoleWeightChanges = roleWeightChanges?.[reducedAgent.id];
        if (agentRoleWeightChanges) {
          reducedAgent.roleWeight = agentRoleWeightChanges.value;
        }

        return reducedAgent;
      }),
    );
  }, [roleWeightChanges]);

  useEffect(() => {
    if (!queuedBrandsChanges) {
      return;
    }
    setReducedAgents((pReducedAgents) =>
      pReducedAgents.filter(Boolean).map((rA) => {
        const agentBrandsChanges = queuedBrandsChanges[rA.id];
        if (agentBrandsChanges) {
          const updatedBrands: ReducedAgentBrand[] = rA.brands.filter(
            (brand) => agentBrandsChanges[brand.id] !== 'removed',
          );
          const addedBrands: ReducedAgentBrand[] = Object.entries(
            agentBrandsChanges,
          )
            .filter(
              ([id, change]) => !!brandsEntities[id] && change === 'added',
            )
            .map(([id]) => ({
              id,
              name: brandsEntities[id]?.name ?? '',
              inCall: false,
            }));

          return {
            ...rA,
            // remove duplicated brands unsing unique by id
            brands: _.uniqBy(updatedBrands.concat(addedBrands), 'id').map(
              (brand) => ({ ...brand, status: agentBrandsChanges[brand.id] }),
            ),
          };
        }

        return rA;
      }),
    );
  }, [queuedBrandsChanges]);

  useEffect(() => {
    setReducedAgents(reduceAgents(metrics, brandFilterId));
  }, [metrics, brandFilterId]);

  return {
    reducedAgents,
    brandFilterId,
    setBrandFilterId,
    sendQueuedBrandChange,
    sendRoleWeightChange,
  };
};
