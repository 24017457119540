import { useAppSelector } from '@/store';
import { selectors as agentSelectors } from '@/store/entities/agent';
import { selectors as bpoSelectors } from '@/store/entities/bpo';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as worksiteSelectors } from '@/store/entities/worksite';
import {
  actions as agentUcActions,
  selectors as agentUcSelectors,
} from '@/store/useCases/agent';
import {
  actions as bpoUcActions,
  selectors as bpoUcSelectors,
} from '@/store/useCases/bpo';
import {
  actions as brandUcActions,
  selectors as brandUcSelectors,
} from '@/store/useCases/brand';
import {
  actions as worksiteUcActions,
  selectors as worksiteUcSelectors,
} from '@/store/useCases/worksite';

const isFetchingUcs = [
  worksiteUcSelectors.isUcFetching('getWorksites'),
  bpoUcSelectors.isUcFetching('getBpos'),
  agentUcSelectors.isUcFetching('getAgent'),
  brandUcSelectors.isUcFetching('getBrands'),
];
const isCreatingUpdatingUcs = [
  agentUcSelectors.isUcFetching('createAgent'),
  agentUcSelectors.isUcFetching('updateAgent'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    creatingUpdating: useAppSelector((state) =>
      isCreatingUpdatingUcs
        .map((ucSelector) => ucSelector(state))
        .some(Boolean),
    ),
    agentEntities: useAppSelector(agentSelectors.getAgentEntities),
    bpos: useAppSelector(bpoSelectors.getBpos),
    worksites: useAppSelector(worksiteSelectors.getWorksites),
    brands: useAppSelector(brandSelectors.getBrands),
  };

  const actions = {
    getAgent: agentUcActions.getAgent,
    createAgent: agentUcActions.createAgent,
    updateAgent: agentUcActions.updateAgent,
    getBpos: bpoUcActions.getBpos,
    getWorksites: worksiteUcActions.getWorksites,
    getBrands: brandUcActions.getBrands,
  };

  return { actions, selectors };
};
