import { useEffect, useState } from 'react';

import { KeaLoading } from '@/components/KeaLoading';
import { Cellphone } from '@/pages/Training/TrainingTabs/Cellphone';
import { useConnector } from '@/pages/Training/connector';
import { useSetupDevice } from '@/pages/Training/hooks/useSetupDevice';
import { useAppDispatch } from '@/store';

import { CallTab } from './CallTab';
import { Navbar } from './Navbar';
import * as S from './styles';

export enum TabTypes {
  CALL = 'call',
  FEEDBACK = 'feedback',
  HISTORY = 'history',
}

export function TrainingTabs() {
  const [tab, setTab] = useState<TabTypes>(TabTypes.CALL);

  const { selectors, actions } = useConnector();
  const { training } = selectors;
  const { isReady, device, isRinging, isExpectingCall } = training;
  useSetupDevice();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actions.getAgents());
    dispatch(actions.getBrands());
  }, []);

  useEffect(() => {
    if (!isRinging || !device) {
      return;
    }
    if (isExpectingCall && device?.activeConnection()) {
      device.activeConnection()?.accept();
      dispatch(
        actions.mergeState({
          isExpectingCall: false,
          isConnected: true,
        }),
      );
    }
  }, [isRinging, device?.activeConnection()]);

  return (
    <S.Card>
      <S.CardContent>
        <Navbar tab={tab} setTab={setTab} />
        <S.Content>
          {tab === TabTypes.CALL && (!isReady ? <KeaLoading /> : <CallTab />)}
        </S.Content>
        <Cellphone />
      </S.CardContent>
    </S.Card>
  );
}
