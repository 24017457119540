import { useAppSelector } from '@/store';
import { selectors as bpoSelectors } from '@/store/entities/bpo';
import { selectors as fleetSelectors } from '@/store/entities/fleet';
import { selectors as roleSelectors } from '@/store/entities/role';
import { selectors as userSelectors } from '@/store/entities/user';
import { selectors as userUiSelectors } from '@/store/ui/user';
import {
  actions as bpoUcActions,
  selectors as bpoUcSelectors,
} from '@/store/useCases/bpo';
import {
  actions as fleetUcActions,
  selectors as fleetUcSelectors,
} from '@/store/useCases/fleet';
import {
  actions as roleUcActions,
  selectors as roleUcSelectors,
} from '@/store/useCases/role';
import {
  actions as userUcActions,
  selectors as userUcSelectors,
} from '@/store/useCases/user';

const isFetchingUcs = [
  roleUcSelectors.isUcFetching('getRoles'),
  roleUcSelectors.isUcFetching('getPermissions'),
  fleetUcSelectors.isUcFetching('getFleets'),
  bpoUcSelectors.isUcFetching('getBpos'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    creating: useAppSelector(userUcSelectors.isUcFetching('createUser')),
    updating: useAppSelector(userUcSelectors.isUcFetching('updateUser')),
    users: useAppSelector(userSelectors.getUsers),
    userEntities: useAppSelector(userSelectors.getUserEntities),
    totalRecords: useAppSelector(userUiSelectors.getTotalRecords),
    roles: useAppSelector(roleSelectors.getRoles),
    fleets: useAppSelector(fleetSelectors.getFleets),
    bpos: useAppSelector(bpoSelectors.getBpos),
    permissions: useAppSelector(roleSelectors.getPermissions),
  };

  const actions = {
    createUser: userUcActions.createUser,
    updateUser: userUcActions.updateUser,
    getUser: userUcActions.getUser,
    getRoles: roleUcActions.getRoles,
    getFleets: fleetUcActions.getFleets,
    getBpos: bpoUcActions.getBpos,
    getPermissions: roleUcActions.getPermissions,
  };

  return { actions, selectors };
};
