import env from '@beam-australia/react-env';
import axios from 'axios';

import { makeClientWithVersion } from '../util/client';

export const client = makeClientWithVersion(
  axios.create({
    baseURL: env('OPERATOR_SERVICE'),
  }),
  {
    defaultVersion: 'v0',
  },
);

export function setDefaultJwtTokenForOperatorService(token: string) {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
}
