import { useAppSelector } from '@/store';
import { selectors as fleetSelectors } from '@/store/entities/fleet';
import {
  actions as fleetUCActions,
  selectors as ucSelectors,
} from '@/store/useCases/fleet';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(ucSelectors.isUcFetching('getFleets')),
    deleting: useAppSelector(ucSelectors.isUcFetching('deleteFleet')),
    fleets: useAppSelector(fleetSelectors.getFleets),
    fleetEntities: useAppSelector(fleetSelectors.getFleetEntities),
    fetchingFleetStores: useAppSelector(
      ucSelectors.isUcFetching('getFleetStoresByFleetId'),
    ),
    fetchingFleetUsers: useAppSelector(
      ucSelectors.isUcFetching('getFleetUsersByFleetId'),
    ),
  };

  const actions = {
    getFleets: fleetUCActions.getFleets,
    deleteFleet: fleetUCActions.deleteFleet,
    getFleetStoresByFleetId: fleetUCActions.getFleetStoresByFleetId,
    getFleetUsersByFleetId: fleetUCActions.getFleetUsersByFleetId,
  };

  return { actions, selectors };
};
