import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
  padding-bottom: 1rem;
`;

export const Card = styled(ParrotUi.Card)`
  width: 100%;
`;

export const CardFooter = styled(ParrotUi.CardFooter)``;

export const JsonContainer = styled.div`
  max-height: 500px;
  overflow: auto;
  width: 100%;
`;

export const MenuItemTitle = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const MenuItemPrice = styled(ParrotUi.Typography)`
  font-weight: bold;
  font-size: 20px;
`;

export const Description = styled(ParrotUi.Typography)`
  font-weight: bold;
  width: 90%;
`;

export const MenuTitleContent = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-between;
`;
