import _ from 'lodash';

import { CreateBrandRequest } from '@/@types/brand/create';
import { UpdateBrandRequest } from '@/@types/brand/update';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';

export const mapBrandFormValuesToCreateRequest = (
  brandFormValues: BrandSchema,
): CreateBrandRequest => {
  const request = _.omitBy(
    _.omit(brandFormValues, ['promos', 'upsells']),
    (v) => _.isNull(v) || _.isUndefined(v) || v === '',
  ) as CreateBrandRequest;
  return { ...request, priority: request.priority?.toString() };
};

export const mapBrandFormValuesToUpdateRequest = (
  brandFormValues: BrandSchema,
): UpdateBrandRequest => {
  const request = _.omitBy(
    _.omit(brandFormValues, ['promos', 'upsells']),
    (v) => _.isNull(v) || _.isUndefined(v) || v === '',
  ) as UpdateBrandRequest;
  return { ...request, priority: request.priority?.toString() };
};
