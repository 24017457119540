import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Typography,
} from '@kea-inc/parrot-ui';

import { useAppDispatch, useAppSelector } from '@/store';
import { actions } from '@/store/ui/theme';
import { getInitials } from '@/utils/getInitials';

import * as S from './styles';

export function UserMenu() {
  const { user, logout } = useAuth0();
  const initials = getInitials(user?.name);

  return (
    <S.UserMenuContainer>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <S.Avatar>
            <AvatarImage src={user?.picture} />
            <AvatarFallback>{initials}</AvatarFallback>
          </S.Avatar>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <S.DropdownMenuLabel>
            <Avatar>
              <AvatarImage src={user?.picture} />
              <AvatarFallback>{initials}</AvatarFallback>
            </Avatar>
            <S.UserContainer>
              <S.UserName>{user?.name ?? ''}</S.UserName>
              <S.UserEmail>{user?.email}</S.UserEmail>
            </S.UserContainer>
          </S.DropdownMenuLabel>

          <DropdownMenuSeparator />

          <ThemeSwitcher />
          <DropdownMenuItem>
            <S.SettingsIcon size={18} />
            <Typography>Settings</Typography>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              if (import.meta.env.MODE === 'production')
                window.open(
                  `http://old.${window.location.host}${window.location.pathname}`,
                );
            }}
          >
            <S.ArrowUpRightFromSquareIcon size={18} />
            Back to old version
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => logout()}>
            <S.LogOutIcon size={18} />
            <Typography>Logout</Typography>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </S.UserMenuContainer>
  );
}

function ThemeSwitcher() {
  const theme = useAppSelector((state) => state.ui.theme.value);
  const dispatch = useAppDispatch();

  const toggleTheme = () => {
    dispatch(actions.switchTheme());
  };

  return (
    <DropdownMenuItem onClick={toggleTheme}>
      {theme === 'light' ? (
        <>
          <S.MoonIcon size={18} />
          <Typography>Dark mode</Typography>
        </>
      ) : (
        <>
          <S.SunIcon size={18} />
          <Typography>Light mode</Typography>
        </>
      )}
    </DropdownMenuItem>
  );
}
