import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/bpo';

export const prefix = 'useCases/bpo';

export const actions = {
  getBpos: createAsyncThunk(`${prefix}/getBpos`, async (_, thunkApi) => {
    try {
      const result = await api.getBpos();

      return result;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }),
  createBpo: createAsyncThunk(
    `${prefix}/createBpo`,
    async (input: api.CreateBpo['input'], thunkApi) => {
      try {
        const result = await api.createBpo(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateBpo: createAsyncThunk(
    `${prefix}/updateBpo`,
    async (input: api.UpdateBpo['input'], thunkApi) => {
      try {
        const result = await api.updateBpo(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteBpo: createAsyncThunk(
    `${prefix}/deleteBpo`,
    async (input: api.DeleteBpo['input'], thunkApi) => {
      try {
        const result = await api.deleteBpo(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
