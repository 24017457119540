import { DataTable } from '@kea-inc/parrot-ui';
import { useState } from 'react';

import { Store } from '@/@types/store';
import { useBulkCreationColumnDefs } from '@/pages/Stores/BulkCreation/Review/useBulkCreationColumnDefs';
import { StoreFieldsDialog } from '@/pages/Stores/BulkCreation/StoreFieldsDialog';

import * as S from './styles';

interface ReviewProps {
  stores: Partial<Store>[];
}

export function Review(props: ReviewProps) {
  const { stores } = props;
  const [selectedStore, setSelectedStore] = useState<Partial<Store> | null>(
    null,
  );

  const { columns } = useBulkCreationColumnDefs({
    onOpenDialog: (store) => setSelectedStore(store),
  });

  return (
    <S.Container>
      <DataTable
        columns={columns}
        data={stores}
        stickyHeader
        virtualizationProps={{ estimateSize: () => 68 }}
      />
      <StoreFieldsDialog
        open={!!selectedStore}
        store={selectedStore}
        onOpenChange={(open) => (!open ? setSelectedStore(null) : undefined)}
      />
    </S.Container>
  );
}
