import { ColumnDef, Typography } from '@kea-inc/parrot-ui';
import { MenuIcon } from 'lucide-react';

import { Store } from '@/@types/store';
import { useConnector } from '@/pages/Stores/BulkCreation/connector';

import * as S from './styles';

type UseBulkCreationColumnDefsOpts = {
  onOpenDialog: (store: Partial<Store>) => void;
};

export const useBulkCreationColumnDefs = (
  opts: UseBulkCreationColumnDefsOpts,
) => {
  const { selectors } = useConnector();
  const { brandEntities, platformEntities } = selectors;
  const columns: ColumnDef<Partial<Store>>[] = [
    {
      accessorKey: 'brandId',
      header: () => <div>Brand</div>,
      cell: ({ row }) => {
        if (!row.original.brandId) {
          return null;
        }
        const brand = brandEntities[row.original.brandId];
        if (!brand) {
          return null;
        }
        return <Typography>{brand.name}</Typography>;
      },
    },
    {
      accessorKey: 'platformId',
      header: () => <div>Platform</div>,
      cell: ({ row }) => {
        if (!row.original.platformId) {
          return null;
        }
        const platform = platformEntities[row.original.platformId];
        if (!platform) {
          return null;
        }
        return <Typography>{platform.name}</Typography>;
      },
    },
    {
      accessorKey: 'franchiseStoreNumber',
      header: () => <div>Store Number</div>,
      cell: ({ row }) => (
        <Typography>{row.original.franchiseStoreNumber}</Typography>
      ),
    },
    {
      accessorKey: 'address',
      header: () => <div>Address</div>,
      cell: ({ row }) => <Typography>{row.original.address}</Typography>,
    },
    {
      accessorKey: 'area',
      header: () => <div>Area</div>,
      cell: ({ row }) => <Typography>{row.original.area}</Typography>,
    },
    {
      accessorKey: 'actions',
      header: () => <div />,
      cell: ({ row }) => (
        <S.IconButton onClick={() => opts.onOpenDialog(row.original)}>
          <MenuIcon />
        </S.IconButton>
      ),
    },
  ];

  return { columns };
};
