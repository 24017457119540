import { FormField, Typography } from '@kea-inc/parrot-ui';
import { BanIcon } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';

import { UserSchema } from '@/pages/Admin/AdminForm/UserForm/schema';
import { USER_ROLE_TO_ICON } from '@/utils/user';

import * as S from './styles';
import { useConnector } from '../connector';
interface RolesProps {
  form: UseFormReturn<UserSchema>;
}

export function Roles(props: RolesProps) {
  const { form } = props;

  const { selectors } = useConnector();
  const { roles } = selectors;

  return (
    <S.SectionContainer>
      <Typography>Roles</Typography>
      <S.SectionContent>
        <FormField
          control={form.control}
          name="roles"
          render={({ field }) => (
            <>
              {roles.map((role) => {
                const Icon = USER_ROLE_TO_ICON[role.name]?.Icon;
                const userHasRole = !!field.value.find(
                  (userRole) => userRole === role.name,
                );
                return (
                  <S.IconButton
                    key={role.id}
                    $disabled={!userHasRole}
                    size="sm"
                    type="button"
                    onClick={() =>
                      field.onChange(
                        userHasRole
                          ? field.value.filter(
                              (userRole) => userRole !== role.name,
                            )
                          : [...field.value, role.name],
                      )
                    }
                  >
                    {Icon ? <Icon size={18} /> : <BanIcon />}
                    {role.description}
                  </S.IconButton>
                );
              })}
            </>
          )}
        />
      </S.SectionContent>
    </S.SectionContainer>
  );
}
