import {
  Dialog,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from '@kea-inc/parrot-ui';

import * as S from './styles';

interface AgentWorksiteDialogProps extends DialogProps {
  items: string[];
  title: string;
}

export function AgentWorksiteDialog(props: AgentWorksiteDialogProps) {
  const { items, title, ...dialogProps } = props;
  return (
    <Dialog {...dialogProps}>
      <S.DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <S.List>
          {items.map((label, index) => (
            <S.ItemContainer key={label + index}>{label}</S.ItemContainer>
          ))}
        </S.List>
      </S.DialogContent>
    </Dialog>
  );
}
