import * as ParrotUi from '@kea-inc/parrot-ui';
import { Menu, Trash, Users2 } from 'lucide-react';
import styled from 'styled-components';

export const Users2Icon = styled(Users2)`
  cursor: pointer;
`;

export const MenuIcon = styled(Menu)`
  cursor: pointer;
`;

export const TrashIcon = styled(Trash)`
  cursor: pointer;
`;

export const Input = styled(ParrotUi.Input)`
  width: 250px;
`;
