type Config = {
  start?: boolean;
  end?: boolean;
};

export function formatUUID(uuid: string | null, config?: Config) {
  if (!uuid) {
    return '';
  }
  const defaultConfig: Config = {
    start: false,
    end: true,
  };

  const { start, end } = { ...defaultConfig, ...config };

  let formattedUUID = '';

  if (start) {
    formattedUUID += uuid.substring(0, 5);
  }

  if (start && end) {
    formattedUUID += '...';
  }

  if (end) {
    formattedUUID += uuid.substring(uuid.length - 5);
  }

  return formattedUUID;
}
