import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Device } from 'twilio-client';

const AGENT_LOCAL_STORAGE_KEY = '@kea/agent';
const BRAND_LOCAL_STORAGE_KEY = '@kea/brand';
const STORE_LOCAL_STORAGE_KEY = '@kea/store';
const HANDOFF_MODE_LOCAL_STORAGE_KEY = '@kea/handoff-mode';

const selectedAgentId = localStorage.getItem(AGENT_LOCAL_STORAGE_KEY);
const selectedBrandId = localStorage.getItem(BRAND_LOCAL_STORAGE_KEY);
const selectedStoreId = localStorage.getItem(STORE_LOCAL_STORAGE_KEY);
const selectedHandoffMode = localStorage.getItem(
  HANDOFF_MODE_LOCAL_STORAGE_KEY,
);
export interface State {
  isReady: boolean;
  isExpectingCall: boolean;
  isRinging: boolean;
  isConnected: boolean;
  isCreatingTask: boolean;
  connectedAt: Date | null;
  selectedAgentId: string | null;
  selectedStoreId: string | null;
  selectedBrandId: string | null;
  selectedHandoffMode: string | null;
  device: Device | null;
}

export const initialState: State = {
  isReady: false,
  isExpectingCall: false,
  isRinging: false,
  isConnected: false,
  isCreatingTask: false,
  connectedAt: null,
  selectedAgentId,
  selectedBrandId,
  selectedStoreId,
  selectedHandoffMode,
  device: null,
};

const slice = createSlice({
  name: 'ui/training',
  initialState,
  reducers: {
    mergeState: (state, action: PayloadAction<Partial<State>>) =>
      _.merge(state, action.payload),
    setSelectedAgentId: (state, action: PayloadAction<string>) => {
      state.selectedAgentId = action.payload;
      state.selectedBrandId = null;
      state.selectedStoreId = null;

      localStorage.setItem(AGENT_LOCAL_STORAGE_KEY, action.payload);
      localStorage.removeItem(BRAND_LOCAL_STORAGE_KEY);
      localStorage.removeItem(STORE_LOCAL_STORAGE_KEY);
    },
    setSelectedBrandId: (state, action: PayloadAction<string>) => {
      state.selectedBrandId = action.payload;
      state.selectedStoreId = null;

      localStorage.setItem(BRAND_LOCAL_STORAGE_KEY, action.payload);
      localStorage.removeItem(STORE_LOCAL_STORAGE_KEY);
    },
    setSelectedStoreId: (state, action: PayloadAction<string>) => {
      state.selectedStoreId = action.payload;
      localStorage.setItem(STORE_LOCAL_STORAGE_KEY, action.payload);
    },
    setSelectedHandoffMode: (state, action: PayloadAction<string>) => {
      state.selectedHandoffMode = action.payload;
      localStorage.setItem(HANDOFF_MODE_LOCAL_STORAGE_KEY, action.payload);
    },
  },
});

export default slice;
