import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100px;
  position: relative;
`;

export const Button = styled(ParrotUi.Button)`
  position: absolute;
  right: 8px;
  top: 2px;
  bottom: 0px;
`;
