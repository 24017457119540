import _ from 'lodash';

import { Agent, AgentBrand } from '@/@types/agent';
import { CreateAgentRequest } from '@/@types/agent/create';
import { UpdateAgentRequestRequest } from '@/@types/agent/update';
import { Brand } from '@/@types/brand';
import { AgentBrandSchema } from '@/pages/Agents/AgentForm/schema/brand';
import { AgentSchema } from '@/pages/Agents/AgentForm/schema/schema';

export const mapFormValuesBrandToBrandsParam = (
  formBrands: AgentSchema['brands'],
  brands: Brand[] | AgentBrand[] = [],
): AgentBrand[] => {
  const groupedByValuesBrands = _.groupBy(formBrands, (brand) => brand.key);

  return brands.map((brand) =>
    _.merge(_.clone(brand), _.clone(groupedByValuesBrands[brand.key].at(0))),
  ); // merge brands from agents store and form values
};

export const mapFormValuesToUpdateRequest = (
  values: AgentSchema,
  agent: Agent,
): UpdateAgentRequestRequest => {
  return _.omitBy(
    {
      id: agent.id,
      ...values,
      brands: mapFormValuesBrandToBrandsParam(values.brands, agent.brands),
    },
    (v) => v === '', // remove empty strings
  ) as UpdateAgentRequestRequest;
};

export const mapFormValuesToCreateRequest = (
  values: AgentSchema,
  brands: Brand[],
): CreateAgentRequest => ({
  ...values,
  brands: mapFormValuesBrandToBrandsParam(values.brands, brands),
});

export const mapBrandToFormValues = (brand: Brand): AgentBrandSchema => ({
  ...brand,
  trained: false,
  queued: false,
});
