import env from '@beam-australia/react-env';

import { Status } from '@/@types/agent';
import { coreService } from '@/services/clients';

const twilioWorkspaceSid = env('TWILIO_WORKSPACE_SID');

export type UpdateAgentStatus = {
  input: { status: Status; workerSid: string };
  output: void;
};

export const updateAgentStatus = async (
  input: UpdateAgentStatus['input'],
): Promise<UpdateAgentStatus['output']> => {
  const { status, workerSid } = input;
  await coreService.put(
    `/twilio/workspaces/${twilioWorkspaceSid}/workers/${workerSid}/status`,
    { status },
  );
};
