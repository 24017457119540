import { BotIcon, HeadphonesIcon, LucideIcon, PhoneIcon } from 'lucide-react';

import {
  OrderTranscript,
  OrderTranscriptActor,
} from '@/@types/order/transcript';

export const getTranscriptActorName = (actor: OrderTranscript['actor']) => {
  const translations: Record<OrderTranscriptActor, string> = {
    inkbot: 'Triage Bot',
    kea_bot: 'Order Bot',
    caller: 'Customer',
    customer: 'Customer',
    kea_vbx: 'Agent',
  };

  return translations[actor] ?? actor;
};

export const getTranscriptMessageName = (orderTranscript: OrderTranscript) =>
  `${getTranscriptActorName(orderTranscript.actor)} (${parseInt(
    String(orderTranscript.confidence * 100),
    10,
  )}%)`;

export function getActorIcon(actor: OrderTranscriptActor) {
  const icons: Record<OrderTranscriptActor, LucideIcon> = {
    inkbot: BotIcon,
    kea_bot: BotIcon,
    caller: PhoneIcon,
    customer: PhoneIcon,
    kea_vbx: HeadphonesIcon,
  };

  return icons[actor] ?? actor;
}
