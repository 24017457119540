import { DataTable, Select, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';

import { Agent } from '@/@types/agent';
import { MetricsData } from '@/@types/metrics';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/store';

import { NewBrandsDialog } from './NewBrandsDialog';
import { useConnector } from './connector';
import { useAgentColumnDefs } from './hooks/useAgentColumnDefs';
import { useAgentTable } from './hooks/useAgentTable';
import { useReducedAgents } from './hooks/useReducedAgents';
import * as S from './styles';

interface AgentsTableProps {
  metrics: MetricsData;
}

type ConfirmationClockOut = {
  agent?: Agent;
};

export function AgentsTable(props: AgentsTableProps) {
  const { metrics } = props;

  const [confirmationClockOut, setConfirmationClockOut] = useState<
    ConfirmationClockOut | undefined
  >();

  const {
    reducedAgents,
    brandFilterId,
    setBrandFilterId,
    sendQueuedBrandChange,
    sendRoleWeightChange,
  } = useReducedAgents({
    metrics,
  });

  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { actions, selectors } = useConnector();

  const { filteredItems, searchTerm, setSearchTerm } = useFuseSearch({
    items: reducedAgents,
    itemKeys: ['name', 'status', 'username'],
  });

  useEffect(() => {
    dispatch(actions.getBrands());
    dispatch(actions.getAgents());
  }, []);

  const { brands, updatingAgentStatus, agentEntities } = selectors;

  const {
    handleQueueBrands,
    handleNewBrandClick,
    selectedAgent,
    setSelectedAgent,
    handleRoleWeightSave,
  } = useAgentTable({
    brands,
    sendQueuedBrandChange,
    sendRoleWeightChange,
  });

  const { columns } = useAgentColumnDefs({
    onNewBrandClick: handleNewBrandClick,
    onRoleWeightSave: handleRoleWeightSave,
    onClockOutAgent: (agentId) => {
      const agent = agentEntities[agentId];
      if (agent) {
        setConfirmationClockOut({ agent });
      }
    },
  });

  const handleClockOutAgent = () => {
    if (confirmationClockOut?.agent) {
      dispatch(
        actions.updateAgentStatus({
          status: 'Offline',
          workerSid: confirmationClockOut.agent.twilioWorkerSid,
        }),
      )
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: `Agent "${confirmationClockOut.agent?.username}" has been clocked out!`,
          });
          setConfirmationClockOut(undefined);
        });
    }
  };

  return (
    <S.Card>
      <S.CardHeader>
        <S.CardTitle>{`Agents (${reducedAgents.length})`}</S.CardTitle>
        <FlexGrow />
        <S.SearchAgentInput
          value={searchTerm}
          onChange={(evt) => setSearchTerm(evt.target.value)}
          placeholder="Search agent by name, status"
        />
        <S.Select value={brandFilterId} onValueChange={setBrandFilterId}>
          <S.SelectTrigger>
            <Select.Value placeholder="Select a brand" />
          </S.SelectTrigger>
          <S.SelectContent>
            <Select.Group>
              <Select.Item value="all">All</Select.Item>
              {brands.map((brand) => (
                <Select.Item key={brand.id} value={brand.id}>
                  {brand.spokenName}
                </Select.Item>
              ))}
            </Select.Group>
          </S.SelectContent>
        </S.Select>
      </S.CardHeader>
      <S.CardContent>
        <DataTable
          columns={columns}
          data={filteredItems}
          stickyHeader
          virtualizationProps={{ estimateSize: () => 96 }}
        />
      </S.CardContent>
      <NewBrandsDialog
        reducedAgent={selectedAgent}
        onOpenChange={(open) => (open ? undefined : setSelectedAgent(null))}
        onSave={handleQueueBrands}
      />
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to clock out{' '}
            <b>{confirmationClockOut?.agent?.username}</b>?
          </Typography>
        }
        open={!!confirmationClockOut?.agent}
        onOpenChange={(open) =>
          setConfirmationClockOut({
            agent: open ? confirmationClockOut?.agent : undefined,
          })
        }
        onSubmit={handleClockOutAgent}
        loading={updatingAgentStatus}
        variant="warning"
        confirmationLabel="Yes, clock out"
      />
    </S.Card>
  );
}
