import { ColumnDef, Typography } from '@kea-inc/parrot-ui';

import { ReducedBrandsMetrics } from '@/@types/agent';
import { TimeSpan } from '@/components/TimeSpan';

import * as S from './styles';

type UseBrandsColumnDefsOpts = {
  onHideRow: (name: string) => void;
};

export const useBrandsColumnDefs = (opts: UseBrandsColumnDefsOpts) => {
  const { onHideRow } = opts;
  const columns: ColumnDef<ReducedBrandsMetrics>[] = [
    {
      accessorKey: 'name',
      header: () => <div>Brand name</div>,
      cell: ({ row }) => {
        const brandName: string = row.getValue('name');
        return <div>{brandName}</div>;
      },
    },
    {
      accessorKey: 'activeAgents',
      header: () => <div>Idle Agents</div>,
      cell: ({ row }) => {
        const activeAgents: string = row.getValue('activeAgents');
        return <div>{activeAgents}</div>;
      },
    },
    {
      accessorKey: 'customersInQueue',
      header: () => <div>Queue</div>,
      cell: ({ row }) => {
        const customersInQueue: string = row.getValue('customersInQueue');
        return <Typography color="warning">{customersInQueue}</Typography>;
      },
    },
    {
      accessorKey: 'tasksCompleted',
      header: () => <div>Tasks (completed/entered)</div>,
      cell: ({ row }) => (
        <Typography color="primary">
          {row.original.tasksCompleted} / {row.original.tasksEntered}
        </Typography>
      ),
    },
    {
      accessorKey: 'longestTaskWaitingTime',
      header: () => <div>1st customer on hold for</div>,
      cell: ({ row }) => (
        <S.TimeSpan>{row.original.longestTaskWaitingTime}</S.TimeSpan>
      ),
    },
    {
      accessorKey: 'avgWaitTime',
      header: () => <div>Avg wait time</div>,
      cell: ({ row }) => (
        <TimeSpan color="warning">{row.original.avgWaitTime}</TimeSpan>
      ),
    },
    {
      accessorKey: 'maxWaitTime',
      header: () => <div>Max wait time</div>,
      cell: ({ row }) => (
        <TimeSpan color="error">{row.original.maxWaitTime}</TimeSpan>
      ),
    },
    {
      accessorKey: 'hide',
      header: () => <div />,
      cell: ({ row }) => (
        <S.HiddenButton
          variant="ghost"
          onClick={() => onHideRow(row.original.name)}
        >
          Hide
        </S.HiddenButton>
      ),
    },
  ];

  return { columns };
};
