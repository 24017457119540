import { Fleet, UpdateFleetRequest } from '@/@types/fleet';
import { dataService } from '@/services';
import { FleetService } from '@/services/@types/fleet';
import { mapUpdateFleetToApi } from '@/services/useCases/fleet/adapters/api';
import { mapFleetToDomain } from '@/services/useCases/fleet/adapters/domain';

export type UpdateFleet = {
  input: UpdateFleetRequest;
  output: Fleet;
};

export const updateFleet = async (
  input: UpdateFleet['input'],
): Promise<UpdateFleet['output']> => {
  const { id, ...rest } = mapUpdateFleetToApi(input);
  const fleet = (
    await dataService.patch<FleetService.Fleet>(`/fleets/${id}`, rest)
  ).data;

  return mapFleetToDomain(fleet);
};
