import React from 'react';

export const preserveCaretPosition: React.ChangeEventHandler<
  HTMLInputElement
> = (event) => {
  const caret = event.target.selectionStart;
  const element = event.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export type ValueOf<T> = T[keyof T];

export const formatPhone = (phone = '') => (phone.length ? `+${phone}` : '');

export const extractNumbers = (str = '') => str.replace(/(\D*)/g, '');

export const upperFirst = (string = '') => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export type WithSyncPayload<T extends Record<string, unknown>> = {
  created: T[];
  deleted: T[];
  updated: T[];
};

/**
 * @description The NestedArrayKeyOf TypeScript type generates string keys representing nested arrays
 * and objects within an object type. It enables type-safe access to deeply nested properties by recursively
 * constructing keys for arrays and objects within the given object type. Circular objects are not allowed, it
 */

export type NestedArrayKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends
    | object[]
    | undefined
    ? `${Key}[${number}].${NestedArrayKeyOf<
        NonNullable<ObjectType[Key]>[number]
      >}`
    : ObjectType[Key] extends object | undefined
      ? `${Key}.${NestedArrayKeyOf<NonNullable<ObjectType[Key]>>}`
      : `${Key}`;
}[keyof ObjectType & (string | number)];
