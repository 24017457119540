import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSONObject: { input: any; output: any };
  ObjectID: { input: any; output: any };
};

export type BrandCategory = {
  __typename?: 'BrandCategory';
  aliases?: Maybe<Scalars['String']['output'][]>;
  chainId: Scalars['String']['output'];
  customWhich?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasItemsAvailable?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPromptRequired?: Maybe<Scalars['Boolean']['output']>;
  isSelectionRequired?: Maybe<Scalars['Boolean']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shouldPluralize?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  spokenName?: Maybe<Scalars['String']['output']>;
  storeLevelIds: StoreLevelIds[];
};

export type BrandCategoryConnection = {
  __typename?: 'BrandCategoryConnection';
  edges: BrandCategoryEdge[];
  pageInfo: PageInfo;
};

export type BrandCategoryEdge = {
  __typename?: 'BrandCategoryEdge';
  cursor: Scalars['String']['output'];
  node: BrandCategory;
};

export type BrandItem = {
  __typename?: 'BrandItem';
  aliases?: Maybe<Scalars['String']['output'][]>;
  article?: Maybe<Scalars['String']['output']>;
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']['output']>;
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId: Scalars['String']['output'];
  cost?: Maybe<Scalars['Float']['output']>;
  customWhich?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasStockAvailable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isUpsellTarget?: Maybe<Scalars['Boolean']['output']>;
  linkedSpecialItemId?: Maybe<Scalars['String']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxTotalQuantity?: Maybe<Scalars['Float']['output']>;
  minQuantity?: Maybe<Scalars['Float']['output']>;
  minTotalQuantity?: Maybe<Scalars['Float']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentChainId: Scalars['String']['output'];
  partOfSpeech?: Maybe<Scalars['Float']['output']>;
  preposition?: Maybe<Scalars['String']['output']>;
  quantityInterval?: Maybe<Scalars['Float']['output']>;
  shouldPluralize?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  spokenName?: Maybe<Scalars['String']['output']>;
  storeLevelIds: StoreLevelIds[];
  unavailableHandoffModes?: Maybe<Scalars['String']['output'][]>;
  upsellPhrase?: Maybe<Scalars['String']['output']>;
  upsellPriority?: Maybe<Scalars['Float']['output']>;
};

export type BrandItemConnection = {
  __typename?: 'BrandItemConnection';
  edges: BrandItemEdge[];
  pageInfo: PageInfo;
};

export type BrandItemEdge = {
  __typename?: 'BrandItemEdge';
  cursor: Scalars['String']['output'];
  node: BrandItem;
};

export type BrandModifier = {
  __typename?: 'BrandModifier';
  aliases?: Maybe<Scalars['String']['output'][]>;
  ancestorIds?: Maybe<Scalars['String']['output'][]>;
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']['output']>;
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId: Scalars['String']['output'];
  customWhich?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  explanationText?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPromptRequired?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  isSelectionRequired?: Maybe<Scalars['Boolean']['output']>;
  maxQuantityPerOption?: Maybe<Scalars['Float']['output']>;
  maxTotalQuantity?: Maybe<Scalars['Float']['output']>;
  maxUniqueOptions?: Maybe<Scalars['Float']['output']>;
  minQuantityPerOption?: Maybe<Scalars['Float']['output']>;
  minTotalQuantity?: Maybe<Scalars['Float']['output']>;
  minUniqueOptions?: Maybe<Scalars['Float']['output']>;
  mlModifierType?: Maybe<Scalars['String']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentChainId: Scalars['String']['output'];
  quantityInterval?: Maybe<Scalars['Float']['output']>;
  shouldForceSpecify?: Maybe<Scalars['Boolean']['output']>;
  shouldPluralize?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  spokenName?: Maybe<Scalars['String']['output']>;
  storeLevelIds: StoreLevelIds[];
  supportsQuantities?: Maybe<Scalars['Boolean']['output']>;
};

export type BrandModifierConnection = {
  __typename?: 'BrandModifierConnection';
  edges: BrandModifierEdge[];
  pageInfo: PageInfo;
};

export type BrandModifierEdge = {
  __typename?: 'BrandModifierEdge';
  cursor: Scalars['String']['output'];
  node: BrandModifier;
};

export type BrandOption = {
  __typename?: 'BrandOption';
  adjustItemPrice?: Maybe<Scalars['Boolean']['output']>;
  aliases?: Maybe<Scalars['String']['output'][]>;
  ancestorIds?: Maybe<Scalars['String']['output'][]>;
  article?: Maybe<Scalars['String']['output']>;
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']['output']>;
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId: Scalars['String']['output'];
  cost?: Maybe<Scalars['Float']['output']>;
  customWhich?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasStockAvailable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isExclusive?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isImplicitItem?: Maybe<Scalars['Boolean']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentChainId: Scalars['String']['output'];
  partOfSpeech?: Maybe<Scalars['Float']['output']>;
  preposition?: Maybe<Scalars['String']['output']>;
  shouldPluralize?: Maybe<Scalars['Boolean']['output']>;
  shouldRepeatDefaultOptions?: Maybe<Scalars['Boolean']['output']>;
  shouldRepeatOptionRemoval?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Float']['output']>;
  spokenName?: Maybe<Scalars['String']['output']>;
  storeLevelIds: StoreLevelIds[];
};

export type BrandOptionConnection = {
  __typename?: 'BrandOptionConnection';
  edges: BrandOptionEdge[];
  pageInfo: PageInfo;
};

export type BrandOptionEdge = {
  __typename?: 'BrandOptionEdge';
  cursor: Scalars['String']['output'];
  node: BrandOption;
};

export type CategoryConnectionV1Deprecated = {
  __typename?: 'CategoryConnectionV1Deprecated';
  edges: CategoryEdgeV1Deprecated[];
  pageInfo: PageInfo;
};

export type CategoryConnectionV2 = {
  __typename?: 'CategoryConnectionV2';
  edges: CategoryEdgeV2[];
  pageInfo: PageInfo;
};

export type CategoryEdgeV1Deprecated = {
  __typename?: 'CategoryEdgeV1Deprecated';
  cursor: Scalars['String']['output'];
  node: CategoryV1Deprecated;
};

export type CategoryEdgeV2 = {
  __typename?: 'CategoryEdgeV2';
  cursor: Scalars['String']['output'];
  node: CategoryV2;
};

export type CategoryV1Deprecated = {
  __typename?: 'CategoryV1Deprecated';
  _id: Scalars['ObjectID']['output'];
  chainId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasItemsAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  menuItems: ItemConnectionV1Deprecated;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  name: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
  utcoffset: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type CategoryV1DeprecatedMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
};

export type CategoryV2 = {
  __typename?: 'CategoryV2';
  aliases: Scalars['String']['output'][];
  chainId: Scalars['String']['output'];
  customWhich?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasItemsAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isUpsellTarget?: Maybe<Scalars['Boolean']['output']>;
  itemIds: Scalars['String']['output'][];
  itemsV2: ItemConnectionV2;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
  soundboardEntries?: Maybe<Scalars['String']['output'][]>;
  spokenName: Scalars['String']['output'];
  upsellPhrase?: Maybe<Scalars['String']['output']>;
  upsellPriority?: Maybe<Scalars['Float']['output']>;
  version: Scalars['String']['output'];
};

export type ItemConnectionV1Deprecated = {
  __typename?: 'ItemConnectionV1Deprecated';
  edges: ItemEdgeV1Deprecated[];
  pageInfo: PageInfo;
};

export type ItemConnectionV2 = {
  __typename?: 'ItemConnectionV2';
  edges: ItemEdgeV2[];
  pageInfo: PageInfo;
};

export type ItemEdgeV1Deprecated = {
  __typename?: 'ItemEdgeV1Deprecated';
  cursor: Scalars['String']['output'];
  node: ItemV1Deprecated;
};

export type ItemEdgeV2 = {
  __typename?: 'ItemEdgeV2';
  cursor: Scalars['String']['output'];
  node: ItemV2;
};

export type ItemV1Deprecated = {
  __typename?: 'ItemV1Deprecated';
  _id: Scalars['ObjectID']['output'];
  article: Scalars['String']['output'];
  availability: Scalars['JSONObject']['output'];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule: Scalars['JSONObject']['output'];
  availabilityStart?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['String']['output']>;
  cost: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  hasStockAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  maxBasketQuantity?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  minBasketQuantity?: Maybe<Scalars['Float']['output']>;
  minQuantity?: Maybe<Scalars['Float']['output']>;
  modifierIds: Scalars['String']['output'][];
  modifiers: ModifierConnectionV1Deprecated;
  name: Scalars['String']['output'];
  partOfSpeech: Scalars['Float']['output'];
  preposition: Scalars['String']['output'];
  quantityInterval: Scalars['Float']['output'];
  sortOrder: Scalars['Float']['output'];
  unavailableHandoffMode: Scalars['JSONObject']['output'];
  unavailableHandoffModes: Scalars['String']['output'][];
  utcoffset: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type ItemV2 = {
  __typename?: 'ItemV2';
  aliases: Scalars['String']['output'][];
  article: Scalars['String']['output'];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule: Scalars['JSONObject']['output'];
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId: Scalars['String']['output'];
  cost: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  hasStockAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isUpsellTarget: Scalars['Boolean']['output'];
  linkedSpecialItemId?: Maybe<Scalars['String']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxTotalQuantity?: Maybe<Scalars['Float']['output']>;
  minQuantity?: Maybe<Scalars['Float']['output']>;
  minTotalQuantity?: Maybe<Scalars['Float']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  modifierIds: Scalars['String']['output'][];
  modifiers: ModifierConnectionV1Deprecated;
  name: Scalars['String']['output'];
  parent: CategoryV2;
  parentId: Scalars['String']['output'];
  partOfSpeech: Scalars['Float']['output'];
  preposition: Scalars['String']['output'];
  quantityInterval: Scalars['Float']['output'];
  sortOrder: Scalars['Float']['output'];
  soundboardEntries?: Maybe<Scalars['String']['output'][]>;
  spokenName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unavailableHandoffModes: Scalars['String']['output'][];
  upsellPhrase: Scalars['String']['output'];
  upsellPriority: Scalars['Float']['output'];
  version: Scalars['String']['output'];
};

export type ModifierConnectionV1Deprecated = {
  __typename?: 'ModifierConnectionV1Deprecated';
  edges: ModifierEdgeV1Deprecated[];
  pageInfo: PageInfo;
};

export type ModifierConnectionV2 = {
  __typename?: 'ModifierConnectionV2';
  edges: ModifierEdgeV2[];
  pageInfo: PageInfo;
};

export type ModifierEdgeV1Deprecated = {
  __typename?: 'ModifierEdgeV1Deprecated';
  cursor: Scalars['String']['output'];
  node: ModifierV1Deprecated;
};

export type ModifierEdgeV2 = {
  __typename?: 'ModifierEdgeV2';
  cursor: Scalars['String']['output'];
  node: ModifierV2;
};

export type ModifierV1Deprecated = {
  __typename?: 'ModifierV1Deprecated';
  _id: Scalars['ObjectID']['output'];
  aliases?: Maybe<Scalars['String']['output'][]>;
  availability: Scalars['JSONObject']['output'];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule: Scalars['JSONObject']['output'];
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['String']['output']>;
  customWhich: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayMode?: Maybe<OptionDisplayMode>;
  hasStockAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isAvailable: Scalars['Boolean']['output'];
  isPromptRequired?: Maybe<Scalars['Boolean']['output']>;
  isSelectionRequired?: Maybe<Scalars['Boolean']['output']>;
  maxOptions?: Maybe<Scalars['Float']['output']>;
  maxQuantity?: Maybe<Scalars['Float']['output']>;
  maxQuantityPerOption?: Maybe<Scalars['Float']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  minOptions?: Maybe<Scalars['Float']['output']>;
  minQuantity?: Maybe<Scalars['Float']['output']>;
  minQuantityPerOption?: Maybe<Scalars['Float']['output']>;
  mlModifierType?: Maybe<Scalars['String']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options: OptionsConnectionV1Deprecated;
  parentId: Scalars['String']['output'];
  quantityInterval?: Maybe<Scalars['Float']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  shouldPluralize?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  spokenName?: Maybe<Scalars['String']['output']>;
  supportOptionQuantity: Scalars['Boolean']['output'];
  utcoffset: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type ModifierV1DeprecatedOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
};

export type ModifierV2 = {
  __typename?: 'ModifierV2';
  aliases: Scalars['String']['output'][];
  ancestorIds: Scalars['String']['output'][];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule: Scalars['JSONObject']['output'];
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  explanationText?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isRequired: Scalars['Boolean']['output'];
  maxQuantityPerOption?: Maybe<Scalars['Float']['output']>;
  maxTotalQuantity?: Maybe<Scalars['Float']['output']>;
  maxUniqueOptions?: Maybe<Scalars['Float']['output']>;
  minQuantityPerOption?: Maybe<Scalars['Float']['output']>;
  minTotalQuantity?: Maybe<Scalars['Float']['output']>;
  minUniqueOptions?: Maybe<Scalars['Float']['output']>;
  mlModifierType?: Maybe<Scalars['String']['output']>;
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optionIds: Scalars['String']['output'][];
  options: OptionsV2Connection;
  quantityInterval: Scalars['Float']['output'];
  sortOrder: Scalars['Float']['output'];
  spokenName: Scalars['String']['output'];
  supportsQuantities: Scalars['Boolean']['output'];
  version: Scalars['String']['output'];
};

export enum OptionDisplayMode {
  InlineRadio = 'INLINE_RADIO',
  Nested = 'NESTED',
}

export type OptionEdgeV1Deprecated = {
  __typename?: 'OptionEdgeV1Deprecated';
  cursor: Scalars['String']['output'];
  node: OptionV1Deprecated;
};

export type OptionV1Deprecated = {
  __typename?: 'OptionV1Deprecated';
  _id: Scalars['ObjectID']['output'];
  adjustItemPrice: Scalars['Boolean']['output'];
  article: Scalars['String']['output'];
  availability: Scalars['JSONObject']['output'];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule: Scalars['JSONObject']['output'];
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['String']['output']>;
  cost: Scalars['Float']['output'];
  hasStockAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isAvailable: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  modifierIds?: Maybe<Scalars['String']['output'][]>;
  modifiers?: Maybe<ModifierConnectionV1Deprecated>;
  name: Scalars['String']['output'];
  partOfSpeech: Scalars['Float']['output'];
  preposition: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
  spokenName?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  utcoffset: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type OptionV1DeprecatedModifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
};

export type OptionV2 = {
  __typename?: 'OptionV2';
  adjustItemPrice: Scalars['Boolean']['output'];
  aliases: Scalars['String']['output'][];
  ancestorIds: Scalars['String']['output'][];
  article: Scalars['String']['output'];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule: Scalars['JSONObject']['output'];
  availabilityStart?: Maybe<Scalars['String']['output']>;
  chainId: Scalars['String']['output'];
  cost: Scalars['Float']['output'];
  hasStockAvailable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isImplicitItem: Scalars['Boolean']['output'];
  mlNameOverride?: Maybe<Scalars['String']['output']>;
  modifierIds: Scalars['String']['output'][];
  modifiers?: Maybe<ModifierConnectionV2>;
  name: Scalars['String']['output'];
  parent: ModifierV2;
  parentId: Scalars['String']['output'];
  partOfSpeech: Scalars['Float']['output'];
  preposition: Scalars['String']['output'];
  removedDefaultOptionDeterminer?: Maybe<Scalars['String']['output']>;
  shouldPluralize?: Maybe<Scalars['Boolean']['output']>;
  shouldRepeatDefaultOptions?: Maybe<Scalars['Boolean']['output']>;
  shouldRepeatOptionRemoval?: Maybe<Scalars['Boolean']['output']>;
  sortOrder: Scalars['Float']['output'];
  spokenName: Scalars['String']['output'];
  type: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type OptionV2Edge = {
  __typename?: 'OptionV2Edge';
  cursor: Scalars['String']['output'];
  node: OptionV2;
};

export type OptionsConnectionV1Deprecated = {
  __typename?: 'OptionsConnectionV1Deprecated';
  edges: OptionEdgeV1Deprecated[];
  pageInfo: PageInfo;
};

export type OptionsV2Connection = {
  __typename?: 'OptionsV2Connection';
  edges: OptionV2Edge[];
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  brandCategories: BrandCategoryConnection;
  brandItems: BrandItemConnection;
  brandModifiers: BrandModifierConnection;
  brandOptions: BrandOptionConnection;
  categories: CategoryConnectionV1Deprecated;
  categoriesV2: CategoryConnectionV2;
  items: ItemConnectionV2;
  itemsV2: ItemConnectionV2;
  menuItems: ItemConnectionV1Deprecated;
  modifiers: ModifierConnectionV1Deprecated;
  options: OptionsConnectionV1Deprecated;
  optionsV2: OptionsV2Connection;
  store: StoreConnection;
  storeHours: StoreHourConnection;
  upsell: UpsellConnection;
};

export type QueryBrandCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['String']['input'];
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  parentChainId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBrandItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['String']['input'];
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  parentChainId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBrandModifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['String']['input'];
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  parentChainId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBrandOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['String']['input'];
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  parentChainId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCategoriesV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryItemsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  chainId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryModifiersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOptionsV2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStoreArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  platformName: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type QueryStoreHoursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['String']['input'];
  day?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  platformName: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

export type QueryUpsellArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  platformName?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type Store = {
  __typename?: 'Store';
  brandId: Scalars['String']['output'];
  chainId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  storePlatform?: Maybe<Scalars['JSONObject']['output']>;
  syncStoreHours: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
};

export type StoreConnection = {
  __typename?: 'StoreConnection';
  edges: StoreEdge[];
  pageInfo: PageInfo;
};

export type StoreEdge = {
  __typename?: 'StoreEdge';
  cursor: Scalars['String']['output'];
  node: Store;
};

export type StoreHour = {
  __typename?: 'StoreHour';
  day: Scalars['String']['output'];
  endday: Scalars['String']['output'];
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type StoreHourConnection = {
  __typename?: 'StoreHourConnection';
  edges: StoreHourEdge[];
  pageInfo: PageInfo;
};

export type StoreHourEdge = {
  __typename?: 'StoreHourEdge';
  cursor: Scalars['String']['output'];
  node: StoreHour;
};

export type StoreLevelIds = {
  __typename?: 'StoreLevelIds';
  id: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  menuImport: CategoryConnectionV1Deprecated;
};

export type SubscriptionMenuImportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
};

export type Upsell = {
  __typename?: 'Upsell';
  _id: Scalars['ObjectID']['output'];
  availabilityEnd?: Maybe<Scalars['String']['output']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']['output']>;
  availabilityStart?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  hasStockAvailable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  name: Scalars['String']['output'];
  unavailableHandoffModes?: Maybe<Scalars['String']['output'][]>;
};

export type UpsellConnection = {
  __typename?: 'UpsellConnection';
  edges: UpsellEdge[];
  pageInfo: PageInfo;
};

export type UpsellEdge = {
  __typename?: 'UpsellEdge';
  cursor: Scalars['String']['output'];
  node: Upsell;
};

export type GetBrandMenuCategoriesQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
}>;

export type GetBrandMenuCategoriesQuery = {
  __typename?: 'Query';
  brandCategories: {
    __typename?: 'BrandCategoryConnection';
    edges: {
      __typename?: 'BrandCategoryEdge';
      node: {
        __typename?: 'BrandCategory';
        id?: string | null;
        aliases?: string[] | null;
        chainId: string;
        customWhich?: string | null;
        description?: string | null;
        name?: string | null;
        shouldPluralize?: boolean | null;
        sortOrder?: number | null;
        spokenName?: string | null;
      };
    }[];
  };
};

export type GetBrandMenuItemByCategoryQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  parentChainId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandMenuItemByCategoryQuery = {
  __typename?: 'Query';
  brandItems: {
    __typename?: 'BrandItemConnection';
    edges: {
      __typename?: 'BrandItemEdge';
      node: {
        __typename?: 'BrandItem';
        id: string;
        aliases?: string[] | null;
        article?: string | null;
        chainId: string;
        description?: string | null;
        hasStockAvailable?: boolean | null;
        linkedSpecialItemId?: string | null;
        name?: string | null;
        parentChainId: string;
        partOfSpeech?: number | null;
        preposition?: string | null;
        shouldPluralize?: boolean | null;
        sortOrder?: number | null;
        spokenName?: string | null;
      };
    }[];
  };
};

export type GetBrandMenuModifierFromParentQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  parentChainId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandMenuModifierFromParentQuery = {
  __typename?: 'Query';
  brandModifiers: {
    __typename?: 'BrandModifierConnection';
    edges: {
      __typename?: 'BrandModifierEdge';
      node: {
        __typename?: 'BrandModifier';
        id: string;
        aliases?: string[] | null;
        chainId: string;
        customWhich?: string | null;
        description?: string | null;
        explanationText?: string | null;
        isRequired?: boolean | null;
        name?: string | null;
        parentChainId: string;
        shouldPluralize?: boolean | null;
        sortOrder?: number | null;
        spokenName?: string | null;
      };
    }[];
  };
};

export type GetBrandMenuOptionFromParentQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
  parentChainId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBrandMenuOptionFromParentQuery = {
  __typename?: 'Query';
  brandOptions: {
    __typename?: 'BrandOptionConnection';
    edges: {
      __typename?: 'BrandOptionEdge';
      node: {
        __typename?: 'BrandOption';
        id: string;
        aliases?: string[] | null;
        article?: string | null;
        chainId: string;
        description?: string | null;
        hasStockAvailable?: boolean | null;
        isDefault?: boolean | null;
        isExclusive?: boolean | null;
        isHidden?: boolean | null;
        isImplicitItem?: boolean | null;
        name?: string | null;
        parentChainId: string;
        partOfSpeech?: number | null;
        preposition?: string | null;
        shouldPluralize?: boolean | null;
        shouldRepeatDefaultOptions?: boolean | null;
        shouldRepeatOptionRemoval?: boolean | null;
        sortOrder?: number | null;
        spokenName?: string | null;
        storeLevelIds: {
          __typename?: 'StoreLevelIds';
          parentId?: string | null;
        }[];
      };
    }[];
  };
};

export type GetCategoriesQueryVariables = Exact<{
  storeId: Scalars['String']['input'];
}>;

export type GetCategoriesQuery = {
  __typename?: 'Query';
  categories: {
    __typename?: 'CategoryConnectionV1Deprecated';
    edges: {
      __typename?: 'CategoryEdgeV1Deprecated';
      node: {
        __typename?: 'CategoryV1Deprecated';
        id: string;
        chainId?: string | null;
        description?: string | null;
        metadata?: any | null;
        name: string;
        sortOrder: number;
      };
    }[];
  };
};

export type GetItemsFromCategoryQueryVariables = Exact<{
  storeId: Scalars['String']['input'];
  categoryId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetItemsFromCategoryQuery = {
  __typename?: 'Query';
  categories: {
    __typename?: 'CategoryConnectionV1Deprecated';
    edges: {
      __typename?: 'CategoryEdgeV1Deprecated';
      node: {
        __typename?: 'CategoryV1Deprecated';
        menuItems: {
          __typename?: 'ItemConnectionV1Deprecated';
          edges: {
            __typename?: 'ItemEdgeV1Deprecated';
            node: {
              __typename?: 'ItemV1Deprecated';
              id: string;
              chainId?: string | null;
              metadata?: any | null;
              name: string;
              description: string;
              cost: number;
              sortOrder: number;
              maxQuantity?: number | null;
              minQuantity?: number | null;
              quantityInterval: number;
              minBasketQuantity?: number | null;
              maxBasketQuantity?: number | null;
              unavailableHandoffModes: string[];
              hasStockAvailable: boolean;
              categoryId?: string | null;
              isAvailable?: boolean | null;
              availabilityStart?: string | null;
              availabilityEnd?: string | null;
              modifierIds: string[];
              partOfSpeech: number;
              article: string;
              preposition: string;
            };
          }[];
        };
      };
    }[];
  };
};

export type GetModifiersFromParentQueryVariables = Exact<{
  storeId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
}>;

export type GetModifiersFromParentQuery = {
  __typename?: 'Query';
  modifiers: {
    __typename?: 'ModifierConnectionV1Deprecated';
    edges: {
      __typename?: 'ModifierEdgeV1Deprecated';
      node: {
        __typename?: 'ModifierV1Deprecated';
        id: string;
        chainId?: string | null;
        metadata?: any | null;
        name?: string | null;
        availabilityEnd?: string | null;
        availabilitySchedule: any;
        availabilityStart?: string | null;
        description?: string | null;
        maxOptions?: number | null;
        maxQuantityPerOption?: number | null;
        minOptions?: number | null;
        maxQuantity?: number | null;
        minQuantityPerOption?: number | null;
        minQuantity?: number | null;
        required?: boolean | null;
        sortOrder: number;
        supportOptionQuantity: boolean;
        spokenName?: string | null;
        quantityInterval?: number | null;
        options: {
          __typename?: 'OptionsConnectionV1Deprecated';
          edges: {
            __typename?: 'OptionEdgeV1Deprecated';
            node: {
              __typename?: 'OptionV1Deprecated';
              id: string;
              chainId?: string | null;
              metadata?: any | null;
              adjustItemPrice: boolean;
              availabilityEnd?: string | null;
              availabilitySchedule: any;
              availabilityStart?: string | null;
              hasStockAvailable: boolean;
              cost: number;
              isDefault: boolean;
              name: string;
              sortOrder: number;
              spokenName?: string | null;
              modifierIds?: string[] | null;
            };
          }[];
        };
      };
    }[];
  };
};

export type GetOptionsFromParentQueryVariables = Exact<{
  storeId: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
}>;

export type GetOptionsFromParentQuery = {
  __typename?: 'Query';
  optionsV2: {
    __typename?: 'OptionsV2Connection';
    edges: {
      __typename?: 'OptionV2Edge';
      node: {
        __typename?: 'OptionV2';
        id: string;
        chainId: string;
        name: string;
        spokenName: string;
        sortOrder: number;
      };
    }[];
  };
};

export const GetBrandMenuCategoriesDocument = gql`
  query GetBrandMenuCategories($brandId: String!) {
    brandCategories(brandId: $brandId) {
      edges {
        node {
          id
          aliases
          chainId
          customWhich
          description
          name
          shouldPluralize
          sortOrder
          spokenName
        }
      }
    }
  }
`;
export const GetBrandMenuItemByCategoryDocument = gql`
  query GetBrandMenuItemByCategory($brandId: String!, $parentChainId: String) {
    brandItems(brandId: $brandId, parentChainId: $parentChainId) {
      edges {
        node {
          id
          aliases
          article
          chainId
          description
          hasStockAvailable
          linkedSpecialItemId
          name
          parentChainId
          partOfSpeech
          preposition
          shouldPluralize
          sortOrder
          spokenName
        }
      }
    }
  }
`;
export const GetBrandMenuModifierFromParentDocument = gql`
  query GetBrandMenuModifierFromParent(
    $brandId: String!
    $parentChainId: String
  ) {
    brandModifiers(brandId: $brandId, parentChainId: $parentChainId) {
      edges {
        node {
          id
          aliases
          chainId
          customWhich
          description
          explanationText
          isRequired
          name
          parentChainId
          shouldPluralize
          sortOrder
          spokenName
        }
      }
    }
  }
`;
export const GetBrandMenuOptionFromParentDocument = gql`
  query GetBrandMenuOptionFromParent(
    $brandId: String!
    $parentChainId: String
  ) {
    brandOptions(brandId: $brandId, parentChainId: $parentChainId) {
      edges {
        node {
          id
          aliases
          article
          chainId
          description
          hasStockAvailable
          isDefault
          isExclusive
          isHidden
          isImplicitItem
          name
          parentChainId
          partOfSpeech
          preposition
          shouldPluralize
          shouldRepeatDefaultOptions
          shouldRepeatOptionRemoval
          sortOrder
          spokenName
          storeLevelIds {
            parentId
          }
        }
      }
    }
  }
`;
export const GetCategoriesDocument = gql`
  query GetCategories($storeId: String!) {
    categories(storeId: $storeId) {
      edges {
        node {
          id
          chainId
          description
          metadata
          name
          sortOrder
        }
      }
    }
  }
`;
export const GetItemsFromCategoryDocument = gql`
  query GetItemsFromCategory($storeId: String!, $categoryId: String) {
    categories(storeId: $storeId, id: $categoryId) {
      edges {
        node {
          menuItems {
            edges {
              node {
                id
                chainId
                metadata
                name
                description
                cost
                sortOrder
                maxQuantity
                minQuantity
                quantityInterval
                minBasketQuantity
                maxBasketQuantity
                unavailableHandoffModes
                hasStockAvailable
                categoryId
                isAvailable
                availabilityStart
                availabilityEnd
                modifierIds
                partOfSpeech
                article
                preposition
              }
            }
          }
        }
      }
    }
  }
`;
export const GetModifiersFromParentDocument = gql`
  query GetModifiersFromParent($storeId: String!, $parentId: String!) {
    modifiers(storeId: $storeId, parentId: $parentId) {
      edges {
        node {
          id
          chainId
          metadata
          name
          availabilityEnd
          availabilitySchedule
          availabilityStart
          description
          maxOptions
          maxQuantityPerOption
          minOptions
          maxQuantity
          minQuantityPerOption
          minQuantity
          options {
            edges {
              node {
                id
                chainId
                metadata
                adjustItemPrice
                availabilityEnd
                availabilitySchedule
                availabilityStart
                hasStockAvailable
                cost
                isDefault
                name
                sortOrder
                spokenName
                modifierIds
              }
            }
          }
          required
          sortOrder
          supportOptionQuantity
          spokenName
          quantityInterval
        }
      }
    }
  }
`;
export const GetOptionsFromParentDocument = gql`
  query GetOptionsFromParent($storeId: String!, $parentId: String!) {
    optionsV2(storeId: $storeId, parentId: $parentId) {
      edges {
        node {
          id
          chainId
          name
          spokenName
          sortOrder
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    GetBrandMenuCategories(
      variables: GetBrandMenuCategoriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBrandMenuCategoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBrandMenuCategoriesQuery>(
            GetBrandMenuCategoriesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetBrandMenuCategories',
        'query',
        variables,
      );
    },
    GetBrandMenuItemByCategory(
      variables: GetBrandMenuItemByCategoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBrandMenuItemByCategoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBrandMenuItemByCategoryQuery>(
            GetBrandMenuItemByCategoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetBrandMenuItemByCategory',
        'query',
        variables,
      );
    },
    GetBrandMenuModifierFromParent(
      variables: GetBrandMenuModifierFromParentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBrandMenuModifierFromParentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBrandMenuModifierFromParentQuery>(
            GetBrandMenuModifierFromParentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetBrandMenuModifierFromParent',
        'query',
        variables,
      );
    },
    GetBrandMenuOptionFromParent(
      variables: GetBrandMenuOptionFromParentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBrandMenuOptionFromParentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBrandMenuOptionFromParentQuery>(
            GetBrandMenuOptionFromParentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetBrandMenuOptionFromParent',
        'query',
        variables,
      );
    },
    GetCategories(
      variables: GetCategoriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetCategoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCategoriesQuery>(GetCategoriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetCategories',
        'query',
        variables,
      );
    },
    GetItemsFromCategory(
      variables: GetItemsFromCategoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetItemsFromCategoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetItemsFromCategoryQuery>(
            GetItemsFromCategoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetItemsFromCategory',
        'query',
        variables,
      );
    },
    GetModifiersFromParent(
      variables: GetModifiersFromParentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetModifiersFromParentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetModifiersFromParentQuery>(
            GetModifiersFromParentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetModifiersFromParent',
        'query',
        variables,
      );
    },
    GetOptionsFromParent(
      variables: GetOptionsFromParentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetOptionsFromParentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetOptionsFromParentQuery>(
            GetOptionsFromParentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetOptionsFromParent',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
