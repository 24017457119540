import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/order';

export const prefix = 'useCases/order';

export const actions = {
  getOrder: createAsyncThunk(
    `${prefix}/getOrder`,
    async (input: api.GetOrder['input'], thunkApi) => {
      try {
        const result = await api.getOrder(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getPaginatedOrders: createAsyncThunk(
    `${prefix}/getPaginatedOrders`,
    async (input: api.GetPaginatedOrders['input'], thunkApi) => {
      try {
        const result = await api.getPaginatedOrders(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getOrderMenuItems: createAsyncThunk(
    `${prefix}/getOrderMenuItems`,
    async (input: api.GetOrderMenuItems['input'], thunkApi) => {
      try {
        const result = await api.getOrderMenuItems(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getOrderRecording: createAsyncThunk(
    `${prefix}/getOrderRecording`,
    async (input: api.GetOrderRecording['input'], thunkApi) => {
      try {
        const result = await api.getOrderRecording(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getOrderTranscripts: createAsyncThunk(
    `${prefix}/getOrderTranscripts`,
    async (input: api.GetOrderTranscripts['input'], thunkApi) => {
      try {
        const result = await api.getOrderTranscripts(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getOrderCallFlow: createAsyncThunk(
    `${prefix}/getOrderCallFlow`,
    async (input: api.GetOrderCallFlow['input'], thunkApi) => {
      try {
        const result = await api.getOrderCallFlow(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getOrderSurveys: createAsyncThunk(
    `${prefix}/getOrderSurveys`,
    async (input: api.GetOrderSurveys['input'], thunkApi) => {
      try {
        const result = await api.getOrderSurveys(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteOrderTranscripts: createAsyncThunk(
    `${prefix}/deleteOrderTranscripts`,
    async (input: api.DeleteOrderTranscripts['input'], thunkApi) => {
      try {
        const result = await api.deleteOrderTranscripts(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteOrderRecording: createAsyncThunk(
    `${prefix}/deleteOrderRecording`,
    async (input: api.DeleteOrderRecording['input'], thunkApi) => {
      try {
        const result = await api.deleteOrderRecording(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
