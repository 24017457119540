import { createSlice } from '@reduxjs/toolkit';

import { prefix, actions } from './actions';

export interface State {
  fetching: Partial<Record<keyof typeof actions, boolean>>;
}

export const initialState: State = {
  fetching: {},
};

export default createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    Object.entries(actions).forEach(([ucType, actionCreator]) => {
      const uc = ucType as keyof typeof actions;
      builder.addCase(actionCreator.fulfilled, (state) => {
        state.fetching[uc] = false;
      });
      builder.addCase(actionCreator.pending, (state) => {
        state.fetching[uc] = true;
      });
      builder.addCase(actionCreator.rejected, (state) => {
        state.fetching[uc] = false;
      });
    });
  },
});
