import { DataTable, FormField, Tooltip, Typography } from '@kea-inc/parrot-ui';
import { CircleHelpIcon } from 'lucide-react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import { WhitelistedNumbersFormField } from './WhitelistedNumbersFormField';
import * as S from './styles';
import { useColumnDefs } from './useColumnDefs';

interface OtherDetailsProps {
  form: UseFormReturn<StoreSchema>;
  id: HTMLDivElement['id'];
}

export function OtherDetails(props: OtherDetailsProps) {
  const { form, id } = props;

  const { fields } = useFieldArray({
    control: form.control,
    name: 'triageHandoffMethods',
  });

  const { columns } = useColumnDefs({ form });

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Other details</S.SectionTitle>
      <WhitelistedNumbersFormField form={form} />
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="isTestStore"
          render={({ field }) => (
            <S.FormItemToggle label="Test store">
              <S.ToggleContainer>
                <S.Toggle
                  pressed={!field.value}
                  onClick={() => field.onChange(true)}
                >
                  Real
                </S.Toggle>
                <S.Toggle
                  pressed={field.value}
                  onClick={() => field.onChange(false)}
                >
                  Test
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="shouldTip"
          render={({ field }) => (
            <S.FormItemToggle label="Tip">
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value === null}
                  onClick={() => field.onChange(null)}
                >
                  Brand setting
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === true}
                  onClick={() => field.onChange(true)}
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === false}
                  onClick={() => field.onChange(false)}
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="inStoreOrdering"
          render={({ field }) => (
            <S.FormItemToggle label="In store ordering">
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value}
                  onClick={() => field.onChange(true)}
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={!field.value}
                  onClick={() => field.onChange(false)}
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="useDefaultOptionsToMaxQuantity"
          render={({ field }) => (
            <S.FormItemToggle label="Use maximum option quantity">
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value === null}
                  onClick={() => field.onChange(null)}
                >
                  Brand setting
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === true}
                  onClick={() => field.onChange(true)}
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === false}
                  onClick={() => field.onChange(false)}
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="agentlessStoreForwarding"
          render={({ field }) => (
            <S.FormItemToggle
              label={
                <S.FormItemLabelContainer>
                  <Typography>Agentless Store Forwarding</Typography>
                  <Tooltip content="This will enable forwarding calls to the store if automated ordering fails and there are no queued agents that are idle to take a call.">
                    <CircleHelpIcon size={16} />
                  </Tooltip>
                </S.FormItemLabelContainer>
              }
            >
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value === null || field.value === undefined}
                  onClick={() => field.onChange(null)}
                >
                  Brand setting
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === true}
                  onClick={() => field.onChange(true)}
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === false}
                  onClick={() => field.onChange(false)}
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
      </S.FormFieldContainer>

      <DataTable columns={columns} data={fields} />
      <S.MessageContainer>
        <S.AlertTriangleIcon />
        <Typography color="warning">
          The handoff mode will only be offered if it is available in the POS.
        </Typography>
      </S.MessageContainer>
    </S.SectionContainer>
  );
}
