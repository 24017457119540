import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { PaginatedUserEntity, User } from '@/@types/user';
import { actions } from '@/store/useCases/user';

export const adapter = createEntityAdapter<User>({
  sortComparer: (u1, u2) => u1.id.localeCompare(u2.id),
});

export const adapterWithFilter = createEntityAdapter<PaginatedUserEntity>({
  sortComparer: (s1, s2) => s1.id.localeCompare(s2.id),
});

export type State = {
  users: EntityState<User, string>;
  usersWithFilter: EntityState<PaginatedUserEntity, string>;
};

export const initialState: State = {
  users: adapter.getInitialState(),
  usersWithFilter: adapterWithFilter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getPaginatedUsers.fulfilled, (state, action) => {
      const searchTerm = action.meta.arg.searchTerm;

      if (searchTerm) {
        adapterWithFilter.upsertOne(state.usersWithFilter, {
          id: searchTerm,
          users: action.payload.items,
        });
      } else {
        adapter.upsertMany(state.users, action.payload.items);
      }
    });
    builder.addCase(actions.getUser.fulfilled, (state, action) => {
      adapter.upsertOne(state.users, action.payload);
    });
    builder.addCase(actions.createUser.fulfilled, (state, action) => {
      adapter.addOne(state.users, action.payload);
    });
    builder.addCase(actions.updateUser.fulfilled, (state, action) => {
      adapter.updateOne(state.users, {
        id: action.payload.id,
        changes: _.omitBy(action.payload, _.isUndefined),
      });
    });
    builder.addCase(actions.deleteUser.fulfilled, (state, action) => {
      adapter.removeOne(state.users, action.payload.id);
    });
  },
});

export default slice;
