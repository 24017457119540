import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaginatedAccountsFilters } from '@/@types/account/paginatedAccount';

export interface State {
  totalRecords: number;
  filters?: PaginatedAccountsFilters;
}

export const initialState: State = {
  totalRecords: 0,
};

const slice = createSlice({
  name: 'ui/account',
  initialState,
  reducers: {
    setTotalRecords: (state, action: PayloadAction<number | undefined>) => {
      state.totalRecords = action.payload ?? 0;
    },
    setFilters: (state, action: PayloadAction<State['filters']>) => {
      state.filters = action.payload;
    },
  },
});

export default slice;
