import {
  Badge,
  ColumnDef,
  DataTableSortHeader,
  Typography,
} from '@kea-inc/parrot-ui';
import { Color } from '@kea-inc/parrot-ui/dist/types';
import {
  AlignJustifyIcon,
  BookOpenIcon,
  CopyIcon,
  MapPinIcon,
  PowerIcon,
  RefreshCcwIcon,
} from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Store } from '@/@types/store';
import { Copy } from '@/components/Copy';
import { TextCenter, TextLeft } from '@/components/DataTable';
import { LinkWithIcon } from '@/components/LinkWithIcon';
import { TableActions } from '@/components/TableActions';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/store';
import { mapStoreStatusToVariant } from '@/utils/color';

import { useConnector } from '../connector';

export type StoreHandlers = (
  store: Store,
) => React.MouseEventHandler<HTMLDivElement>;

type UseStoresColumnDefsOpts = {
  onSyncMenu: (store: Store) => void;
  onDeleteClick: (store: Store) => void;
};

export const useStoresColumnDefs = (opts: UseStoresColumnDefsOpts) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useNotification();

  const { actions } = useConnector();

  const handleCopyIDToClipboard = (store: Store) => {
    navigator.clipboard.writeText(store.id);
    notification.success('', { description: 'Text copied to clipboard!' });
  };

  const handleUpdateOnOff: StoreHandlers = (store) => (evt) => {
    const { id, isOn, brand, franchiseStoreNumber } = store;
    evt.stopPropagation();
    dispatch(
      actions.updateOnOff({
        id,
        isOn: !isOn,
      }),
    )
      .unwrap()
      .then((res) =>
        notification.success('Success', {
          description: `${brand?.name} ${franchiseStoreNumber} was turned ${
            res.isOn ? 'on' : 'off'
          }`,
        }),
      )
      .catch(() => {
        notification.error('Error', {
          description: `${brand?.name} ${franchiseStoreNumber} was not turned ${
            !isOn ? 'on' : 'off'
          }. Contact the engineering team.`,
        });
      });
  };

  const handleRedirectToGoogleMaps: StoreHandlers = (store) => (evt) => {
    evt.stopPropagation();
    const { brand, zipcode, address, state, city } = store;
    const query = `${brand?.name} ${zipcode} ${address} ${state} ${city}`;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${query}`,
      '_blank',
    );
  };

  const handleRedirectToOrders: StoreHandlers = (store) => (evt) => {
    evt.stopPropagation();
    navigate(
      `/orders?brand_id=${store.brandId}&franchiseStoreNumber=${store.franchiseStoreNumber}`,
    );
  };
  const handleRedirectToMenu: StoreHandlers = (store) => (evt) => {
    const { brand, id } = store;
    evt.stopPropagation();

    if (brand) {
      navigate(`/menu-tool?brandId=${brand?.id}&storeId=${id}`);
    }
  };

  const columns: ColumnDef<Store>[] = [
    {
      accessorKey: 'brandId',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Brand" />,
      cell: ({ row }) => (
        <LinkWithIcon to={`/brands/${row.original.brandId}`}>
          {row.original.brand?.name}
        </LinkWithIcon>
      ),
    },
    {
      accessorKey: 'fleetId',
      header: () => <TextLeft>Fleet</TextLeft>,
      cell: ({ row }) => (
        <LinkWithIcon to={`/fleets/${row.original.fleetId}`}>
          {row.original.fleet?.name}
        </LinkWithIcon>
      ),
    },
    {
      accessorKey: 'franchiseStoreNumber',
      header: () => <TextLeft>Store Number</TextLeft>,
      cell: ({ row }) => (
        <Typography>{row.original.franchiseStoreNumber}</Typography>
      ),
    },
    {
      accessorKey: 'primaryPhone',
      header: () => <TextLeft>Primary Phone</TextLeft>,
      cell: ({ row }) => <Copy>{row.original.primaryPhone}</Copy>,
    },
    {
      accessorKey: 'status',
      header: () => <TextLeft>Status</TextLeft>,
      cell: ({ row }) => (
        <Badge variant={mapStoreStatusToVariant(row.original.status as Color)}>
          {row.original.status}
        </Badge>
      ),
    },
    {
      accessorKey: 'isOn',
      header: () => <TextLeft>On</TextLeft>,
      cell: ({ row }) => (
        <Badge variant={row.original.isOn ? 'primary' : 'error'}>
          {row.original.isOn ? 'On' : 'Off'}
        </Badge>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => (
        <TableActions
          dropDownMenuLabel={row.original.brand?.name ?? ''}
          onUpdateClick={(evt) => {
            evt.stopPropagation();
            navigate(`/stores/${row.original.id}`);
          }}
          onDeleteClick={(evt) => {
            evt.stopPropagation();
            opts.onDeleteClick(row.original);
          }}
          dropdownMenuItems={[
            {
              label: `Turn ${row.original.isOn ? 'off' : 'on'}`,
              Icon: <PowerIcon />,
              onClick: handleUpdateOnOff(row.original),
            },
            {
              label: 'Map Location',
              Icon: <MapPinIcon />,
              onClick: handleRedirectToGoogleMaps(row.original),
            },
            {
              label: 'See orders',
              Icon: <AlignJustifyIcon />,
              onClick: handleRedirectToOrders(row.original),
            },
            {
              label: 'Copy ID',
              Icon: <CopyIcon />,
              onClick: (evt) => {
                evt.stopPropagation();
                handleCopyIDToClipboard(row.original);
              },
            },
            {
              label: 'Sync menu',
              Icon: <RefreshCcwIcon />,
              onClick: (evt) => {
                evt.stopPropagation();
                opts.onSyncMenu(row.original);
              },
            },
            {
              label: 'Open menu',
              Icon: <BookOpenIcon />,
              onClick: handleRedirectToMenu(row.original),
            },
          ]}
        />
      ),
    },
  ];

  return { columns };
};
