import { useAppSelector } from '@/store';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as platformSelectors } from '@/store/entities/platform';
import {
  actions as brandUcActions,
  selectors as brandUcSelectors,
} from '@/store/useCases/brand';
import {
  actions as platformUcActions,
  selectors as platformUcSelectors,
} from '@/store/useCases/platform';

const isFetchingUcs = [
  brandUcSelectors.isUcFetching('getBrands'),
  brandUcSelectors.isUcFetching('getBrand'),
  brandUcSelectors.isUcFetching('getBrandPromos'),
  brandUcSelectors.isUcFetching('getBrandUpsells'),
  brandUcSelectors.isUcFetching('getBrandModels'),
  brandUcSelectors.isUcFetching('getBrandNluModels'),
  platformUcSelectors.isUcFetching('getPlatforms'),
];
const isCreatingUpdatingUcs = [
  brandUcSelectors.isUcFetching('createBrand'),
  brandUcSelectors.isUcFetching('updateBrand'),
  brandUcSelectors.isUcFetching('syncBrandPromos'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    creatingUpdating: useAppSelector((state) =>
      isCreatingUpdatingUcs
        .map((ucSelector) => ucSelector(state))
        .some(Boolean),
    ),
    brands: useAppSelector(brandSelectors.getBrands),
    brandEntities: useAppSelector(brandSelectors.getBrandsEntities),
    platforms: useAppSelector(platformSelectors.getPlatforms),
  };

  const actions = {
    getBrands: brandUcActions.getBrands,
    getBrand: brandUcActions.getBrand,
    createBrand: brandUcActions.createBrand,
    updateBrand: brandUcActions.updateBrand,
    syncBrandPromos: brandUcActions.syncBrandPromos,
    syncBrandUpsells: brandUcActions.syncBrandUpsells,
    getBrandPromos: brandUcActions.getBrandPromos,
    getPlatforms: platformUcActions.getPlatforms,
    getBrandUpsells: brandUcActions.getBrandUpsells,
    getBrandModels: brandUcActions.getBrandModels,
    getBrandNluModels: brandUcActions.getBrandNluModels,
  };

  return { actions, selectors };
};
