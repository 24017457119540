import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Navbar = styled.nav`
  width: 30%;
  border-right: solid 1px hsl(var(--gray));
  padding: 1.5rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  > div {
    margin-top: 1rem;

    .tab {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const Content = styled.div`
  padding: 1.75rem 1.5rem;
  width: 100%;
  border-right: solid 1px hsl(var(--gray));
`;

export const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    margin-top: 1rem;
  }
`;

export const Card = styled(ParrotUi.Card)`
  display: flex;
  flex: 1 1 0px;
  border: none;
`;
export const CardContent = styled(ParrotUi.CardContent)`
  display: flex;
  flex: 1 1 0px;
  padding: 0px;
`;
export const Tabs = styled(ParrotUi.Tabs)`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`;

export const TabsList = styled(ParrotUi.TabsList)`
  display: flex;
  flex-direction: column;
  width: 7rem;
  height: auto;
  width: 100%;
  align-items: flex-start;
`;

export const TabsTrigger = styled(ParrotUi.TabsTrigger)`
  width: 100%;
  justify-content: flex-start;
`;
