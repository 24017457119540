import { z } from 'zod';

import { Platform } from '@/@types/platform';

type SchemaObjShape = Record<keyof Omit<Platform, 'id'>, z.ZodTypeAny>;

export const platformSchema = z.object({
  name: z.string(),
} satisfies SchemaObjShape);

export type PlatformSchema = z.infer<typeof platformSchema>;
