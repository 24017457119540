import { Badge } from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';

import { Order } from '@/@types/order';
import { Copy } from '@/components/Copy';
import { LinkWithIcon } from '@/components/LinkWithIcon';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import {
  mapOrderPaymentTypeToVariant,
  mapOrderStatusToVariant,
} from '@/utils/color';
import { getDuration } from '@/utils/date';
import { formatCurrency } from '@/utils/format';
import { formatUUID } from '@/utils/uuid';

import * as S from './styles';
import { useConnector } from '../connector';

interface OrderDataValuesProps {
  order: Order;
}

export function OrderDataValues(props: OrderDataValuesProps) {
  const { order } = props;

  const { selectors } = useConnector();

  const { agentEntities, storeEntities, brandEntities, accountEntities } =
    selectors;

  if (!order) {
    return null;
  }

  const firstAgentId = order.mostRecentAgentId;
  const firstAgent = firstAgentId ? agentEntities?.[firstAgentId] : undefined;

  const store = storeEntities[order.storeId];
  if (!store) {
    return null;
  }
  const brand = brandEntities[store.brandId];
  const account = accountEntities?.[order.accountId];

  return (
    <S.LabelValuesContainer>
      <S.LabelValue
        label="Caller ID"
        value={
          <ProtectedComponent allowedPermissions={['read:account-details']}>
            <Copy value={order?.accountId}>{formatUUID(order?.accountId)}</Copy>
          </ProtectedComponent>
        }
      />
      <S.LabelValue
        label="Caller"
        value={
          <ProtectedComponent allowedPermissions={['read:account-details']}>
            {account?.firstName} {account?.lastName}
            <div>{account?.phone}</div>
          </ProtectedComponent>
        }
      />
      <S.LabelValue
        label="Handoff mode"
        value={order.handoffMode?.toUpperCase()}
      />
      <S.LabelValue
        label="Status"
        value={
          <Badge variant={mapOrderStatusToVariant(order.status)}>
            {order.status.toUpperCase()}
          </Badge>
        }
      />
      <S.LabelValue
        label="Order ID"
        value={
          <Copy value={order.id}>
            {formatUUID(order.id, {
              start: true,
            })}
          </Copy>
        }
      />
      <S.LabelValue label="Source" value={order.source} />
      <S.LabelValue
        label="Agent"
        value={
          firstAgent ? `${firstAgent.firstName} ${firstAgent.lastName}` : ''
        }
      />
      <S.LabelValue label="Subtotal" value={formatCurrency(order.subtotal)} />
      <S.LabelValue
        label="Tax / Tip / Discount"
        value={`${formatCurrency(order.tax)} / ${formatCurrency(
          order.tip,
        )} / ${formatCurrency(order.discount)}`}
      />
      <S.LabelValue label="Total" value={formatCurrency(order.total)} />
      <S.LabelValue
        label="Brand"
        value={
          <LinkWithIcon to={`/brands/${brand?.id}`}>{brand?.name}</LinkWithIcon>
        }
      />
      <S.LabelValue
        label="Store number"
        value={
          <LinkWithIcon to={`/stores/${store.id}`}>
            {store.franchiseStoreNumber}
          </LinkWithIcon>
        }
      />
      <S.LabelValue label="Area" value={store.area} />
      <S.LabelValue
        label="Call Start/End"
        value={`${
          order.startTime ? dayjs(order.startTime).format('hh:mm A (z)') : ''
        } - ${order.endTime ? dayjs(order.endTime).format('hh:mm A (z)') : ''}`}
      />
      <S.LabelValue
        label="Date"
        value={
          order.startTime
            ? dayjs(order.startTime).format('MMM DD, YYYY (ddd)')
            : '-'
        }
      />
      <S.LabelValue
        label="Duration"
        value={
          order.startTime && order.endTime
            ? getDuration(order.startTime, order.endTime)
            : '-'
        }
      />
      <S.LabelValue
        label="Time"
        value={
          order.scheduledTime
            ? dayjs(order.scheduledTime).format('hh:mm A (z)')
            : 'ASAP'
        }
      />
      <S.LabelValue
        label="Payment"
        value={
          <Badge variant={mapOrderPaymentTypeToVariant(order.paymentType)}>
            {order.paymentType?.toUpperCase()}
          </Badge>
        }
      />
      <S.LabelValue
        label="Bypass"
        value={order.cancellationReason === 'bypass_mode' ? 'Yes' : 'No'}
      />
    </S.LabelValuesContainer>
  );
}
