import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Card = styled(ParrotUi.Card)`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: none;
`;

export const CardHeader = styled(ParrotUi.CardHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const CardTitle = styled(ParrotUi.CardTitle)`
  margin-top: 0px !important;
`;

export const CardContent = styled(ParrotUi.CardContent)`
  overflow: auto;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
`;

export const CardFooter = styled(ParrotUi.CardFooter)`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
