import { useAppSelector } from '@/store';
import { selectors as accountUcSelectors } from '@/store/useCases/account';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(
      accountUcSelectors.isUcFetching('getPaginatedAccounts'),
    ),
  };

  return { selectors };
};
