import {
  MenuCategory,
  MenuItem,
  MenuModifier,
  MenuOption,
} from '@/@types/menu';
import {
  GetBrandMenuOptionFromParentQuery,
  GetBrandMenuCategoriesQuery,
  GetCategoriesQuery,
  GetItemsFromCategoryQuery,
  GetModifiersFromParentQuery,
  GetBrandMenuItemByCategoryQuery,
  GetBrandMenuModifierFromParentQuery,
  GetOptionsFromParentQuery,
} from '@/graphql/schema';

const sortMenu = (
  a: Partial<Pick<MenuCategory, 'sortOrder' | 'name'>>,
  b: Partial<Pick<MenuCategory, 'sortOrder' | 'name'>>,
) => {
  if (a.sortOrder && b.sortOrder) {
    return a.sortOrder - b.sortOrder;
  }
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }

  if (a.sortOrder ?? a.name) {
    return 1;
  }
  if (b.sortOrder ?? b.name) {
    return -1;
  }

  return 0;
};

// Category
export const mapBrandCategoriesToDomain = (
  response: GetBrandMenuCategoriesQuery,
): MenuCategory[] =>
  response.brandCategories.edges
    .map(({ node }) => ({
      id: node.id ?? '',
      chainId: node.chainId,
      name: node.name ?? node.spokenName ?? node.description ?? '',
      items: [],
      sortOrder: node.sortOrder ?? 0,
    }))
    .sort(sortMenu);

export const mapMenuCategoriesToDomain = (
  response: GetCategoriesQuery,
): MenuCategory[] =>
  response.categories.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId ?? '',
      name: node.name ?? '',
      items: [],
      sortOrder: node.sortOrder ?? 0,
      metadata: node.metadata,
    }))
    .sort(sortMenu);

// Item
export const mapBrandItemsToDomain = (
  response: GetBrandMenuItemByCategoryQuery,
): MenuItem[] =>
  response.brandItems.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId ?? '',
      name: node.name ?? node.spokenName ?? node.description ?? '',
      options: [],
      sortOrder: node.sortOrder ?? 0,
    }))
    .sort(sortMenu);

export const mapItemsToDomain = (
  response: GetItemsFromCategoryQuery,
): MenuItem[] =>
  response.categories.edges[0].node.menuItems.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId ?? '',
      name: node.name,
      options: [],
      sortOrder: node.sortOrder ?? 0,
      metadata: node.metadata,
    }))
    .sort(sortMenu);

// Modifier
export const mapBrandModifiersToDomain = (
  response: GetBrandMenuModifierFromParentQuery,
): MenuModifier[] =>
  response.brandModifiers.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId,
      name: node.name ?? node.spokenName ?? node.description ?? '',
      options: [],
      sortOrder: node.sortOrder ?? 0,
    }))
    .sort(sortMenu);

export const mapModifierToDomain = (
  response: GetModifiersFromParentQuery,
): MenuModifier[] =>
  response.modifiers.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId ?? '',
      name: node.name ?? node.spokenName ?? node.description ?? '',
      options: [],
      sortOrder: node.sortOrder ?? 0,
      metadata: node.metadata,
    }))
    .sort(sortMenu);

// Option
export const mapBrandOptionsToDomain = (
  response: GetBrandMenuOptionFromParentQuery,
): MenuOption[] =>
  response.brandOptions.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId,
      parentChainId: node.parentChainId,
      name: node.name ?? node.spokenName ?? node.description ?? '',
      modifiers: [],
      sortOrder: node.sortOrder ?? 0,
    }))
    .sort(sortMenu);

export const mapOptionToDomain = (
  response: GetOptionsFromParentQuery,
): MenuOption[] =>
  response.optionsV2.edges
    .map(({ node }) => ({
      id: node.id,
      chainId: node.chainId ?? '',
      name: node.name ?? node.spokenName ?? '',
      modifiers: [],
      sortOrder: node.sortOrder ?? 0,
    }))
    .sort(sortMenu);
