import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type State = {
  title: string;
  open: boolean;
  description?: string;
  autoClose?: boolean;
};

export const initialState: State = {
  description: '',
  title: '',
  open: false,
  autoClose: true,
};

const slice = createSlice({
  name: 'ui/notification',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setAutoClose: (state, action: PayloadAction<boolean | undefined>) => {
      state.autoClose = action.payload;
    },
    setTitleDescription: (
      state,
      action: PayloadAction<Pick<State, 'title' | 'description'>>,
    ) => {
      const { title, description } = action.payload;
      state.title = title;
      state.description = description;
    },
    reset: () => initialState,
  },
});

export default slice;
