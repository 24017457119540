import { useAppSelector } from '@/store';
import { selectors as agentSelectors } from '@/store/entities/agent';
import { selectors as brandSelectors } from '@/store/entities/brand';
import {
  actions as agentUCActions,
  selectors as agentUCSelectors,
} from '@/store/useCases/agent';
import {
  actions as brandUCActions,
  selectors as brandUCSelectors,
} from '@/store/useCases/brand';

export type QueueBrandPatchPayload = {
  id: string;
  trained?: boolean;
  queued: boolean;
  key: string;
};

export const useConnector = () => {
  const selectors = {
    brands: useAppSelector(brandSelectors.getBrands),
    brandsEntities: useAppSelector(brandSelectors.getBrandsEntities),
    updating: useAppSelector(
      brandUCSelectors.isUcFetching('updateAgentBrands'),
    ),
    updatingRoleWeight: useAppSelector(
      agentUCSelectors.isUcFetching('updateRoleWeight'),
    ),
    updatingAgentStatus: useAppSelector(
      agentUCSelectors.isUcFetching('updateAgentStatus'),
    ),
    agentEntities: useAppSelector(agentSelectors.getAgentEntities),
  };

  const actions = {
    getBrands: brandUCActions.getBrands,
    updateAgentBrands: brandUCActions.updateAgentBrands,
    getAgents: agentUCActions.getAgents,
    updateRoleWeight: agentUCActions.updateRoleWeight,
    updateAgentStatus: agentUCActions.updateAgentStatus,
  };

  return { actions, selectors };
};
