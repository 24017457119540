import { Store } from '@/@types/store';
import {
  BatchUpdateStoresInput,
  BatchUpdateStoresOutput,
} from '@/@types/store/update';
import { dataService } from '@/services';
import { StoreService } from '@/services/@types/store';
import { mapCreateUpdateStoreToApi } from '@/services/useCases/store/adapters/api';
import { mapBatchUpdateToDomain } from '@/services/useCases/store/adapters/domain';

export type UpdateStore = {
  input: Partial<Store>;
  output: { store: Partial<Store> };
};

export async function updateStore(
  input: UpdateStore['input'],
): Promise<UpdateStore['output']> {
  await dataService.patch<
    StoreService.CreateUpdateResponse,
    StoreService.CreateUpdateRequest
  >(`/stores/${input.id}`, mapCreateUpdateStoreToApi(input));

  return { store: input };
}

export type BatchUpdateStores = {
  input: BatchUpdateStoresInput;
  output: BatchUpdateStoresOutput;
};

export async function batchUpdateStores(
  input: BatchUpdateStores['input'],
): Promise<BatchUpdateStores['output']> {
  const { data } = await dataService.patch<StoreService.BatchUpdateResponse>(
    `/stores`,
    {
      store_ids: input.storeIds,
      is_on: input.isOn,
      store_status: input.storeStatus,
    },
    {
      version: 'v3',
    },
  );

  return data.map(mapBatchUpdateToDomain);
}
