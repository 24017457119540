import { useAppSelector } from '@/store';
import { selectors as accountSelectors } from '@/store/entities/account';
import { selectors as agentSelectors } from '@/store/entities/agent';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as orderSelectors } from '@/store/entities/order';
import { selectors as storeSelectors } from '@/store/entities/store';
import {
  actions as orderUiActions,
  selectors as orderUiSelectors,
} from '@/store/ui/order';
import { actions as accountUcActions } from '@/store/useCases/account';
import {
  actions as agentdUcActions,
  selectors as agentdUcSelectors,
} from '@/store/useCases/agent';
import {
  actions as brandUcActions,
  selectors as brandUcSelectors,
} from '@/store/useCases/brand';
import {
  actions as orderUcActions,
  selectors as orderUcSelectors,
} from '@/store/useCases/order';
import { actions as storeUcActions } from '@/store/useCases/store';

const isFetchingUcs = [
  orderUcSelectors.isUcFetching('getPaginatedOrders'),
  brandUcSelectors.isUcFetching('getBrands'),
  agentdUcSelectors.isUcFetching('getAgents'),
];
const isDeletingUcs = [
  orderUcSelectors.isUcFetching('deleteOrderRecording'),
  orderUcSelectors.isUcFetching('deleteOrderTranscripts'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    deleting: useAppSelector((state) =>
      isDeletingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    orders: useAppSelector(orderSelectors.getOrders),
    brandEntities: useAppSelector(brandSelectors.getBrandsEntities),
    agentEntities: useAppSelector(agentSelectors.getAgentEntities),
    ordersWithFilters: useAppSelector(orderSelectors.getOrdersWithFilters),
    totalRecords: useAppSelector(orderUiSelectors.getTotalRecords),
    storeEntities: useAppSelector(storeSelectors.getStoreEntities),
    accountEntities: useAppSelector(accountSelectors.getAccountEntities),
    filters: useAppSelector(orderUiSelectors.getFilters),
  };

  const actions = {
    getPaginatedOrders: orderUcActions.getPaginatedOrders,
    getOrderRecording: orderUcActions.getOrderRecording,
    setTotalRecords: orderUiActions.setTotalRecords,
    deleteOrderRecording: orderUcActions.deleteOrderRecording,
    deleteOrderTranscripts: orderUcActions.deleteOrderTranscripts,
    getBrands: brandUcActions.getBrands,
    getAgents: agentdUcActions.getAgents,
    getStoreByIds: storeUcActions.getStoreByIds,
    getAccountByIds: accountUcActions.getAccountByIds,
    setFilters: orderUiActions.setFilters,
  };

  return { actions, selectors };
};
