import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const List = styled.div`
  padding: 1rem 0.5rem 0.5rem 0;

  overflow-y: auto;
  width: 100%;
`;

export const ListItem = styled.div`
  border: 1px solid #eff1f3;
  padding: 0.5rem;
  font-size: 0.8rem;
`;

export const DialogContent = styled(ParrotUi.DialogContent)`
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export * from '../styles';
