import './index.css';
import '@kea-inc/parrot-ui/dist/styles.css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import { store } from '@/store/index.ts';

import App from './App.tsx';

dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
);
