import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: calc(100% - 40px);
  position: relative;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 520px;
`;

export const CallFlowContainer = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Card = styled(ParrotUi.Card)`
  width: 100%;
`;

export const CollapseButton = styled(ParrotUi.Button)`
  position: absolute;
  top: 0px;
  right: 5px;
`;

export const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;
export const Choice = styled.div``;
