import { CardContent, CardHeader, Typography } from '@kea-inc/parrot-ui';
import ReactJson from 'react-json-view';

import { Order } from '@/@types/order';
import { MenuItemOption } from '@/pages/Orders/OrderInfo/OrderTabs/Cart/MenuItemOption';
import { formatCurrency } from '@/utils/format';

import * as S from './styles';
import { useConnector } from '../../connector';
interface CartProps {
  order: Order;
}

export function Cart(props: CartProps) {
  const { order } = props;

  const { selectors } = useConnector();

  const { debugMode, theme } = selectors;

  return (
    <S.Container>
      {order.menuItems?.map((menuItem, index) => {
        const menuOptions = Object.values(menuItem.options ?? {});

        return (
          <S.Card key={`${menuItem.id}-${index}`}>
            <CardHeader>
              <S.MenuTitleContent>
                <S.MenuItemTitle>
                  {menuItem.quantity}x {menuItem.spokenName}
                </S.MenuItemTitle>
                <S.MenuItemPrice>
                  {formatCurrency(
                    Number(menuItem.cost) * Number(menuItem.quantity),
                  )}
                </S.MenuItemPrice>
              </S.MenuTitleContent>
              <S.Description>{menuItem.description}</S.Description>
              <Typography color="gray">ID: {menuItem.id}</Typography>
            </CardHeader>
            <CardContent>
              {menuOptions.length > 0 ? (
                <div>
                  {menuOptions.map((option) => (
                    <MenuItemOption
                      option={option}
                      marginLeft={0}
                      key={option.id}
                    />
                  ))}
                </div>
              ) : null}
            </CardContent>
            <S.CardFooter>
              <S.JsonContainer>
                {debugMode ? (
                  <ReactJson
                    name={menuItem.spokenName}
                    collapsed
                    src={menuItem}
                    theme={theme === 'dark' ? 'ashes' : undefined}
                  />
                ) : null}
              </S.JsonContainer>
            </S.CardFooter>
          </S.Card>
        );
      })}
    </S.Container>
  );
}
