import {
  FormField,
  Select,
  Typography,
  FormItemInput,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { AGENT_STATUS } from '@/constants/agent';
import { AgentSchema } from '@/pages/Agents/AgentForm/schema/schema';

import * as S from './styles';

interface StatusProps {
  form: UseFormReturn<AgentSchema>;
  id: HTMLDivElement['id'];
}

export function Status(props: StatusProps) {
  const { form, id } = props;

  const { fields, update } = useFieldArray({
    control: form.control,
    name: 'brands',
  });

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Status</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItemSelect field={field} label="Status">
              {AGENT_STATUS.map((status) => (
                <Select.Item value={status} key={status}>
                  {status}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="roleWeight"
          render={({ field }) => (
            <FormItemInput field={field} label="Role weight" />
          )}
        />
      </S.FormFieldContainer>
      <S.BrandsFormFieldContainer>
        <Typography variant="h4">Brands</Typography>
        {fields.map((field, index) => {
          if (!field) {
            return null;
          }
          return (
            <S.BrandContainer key={field.key}>
              <Typography>{field.name}</Typography>
              <S.BrandToggleContainer>
                <S.Toggle
                  pressed={field.trained}
                  onClick={() =>
                    update(index, {
                      ...field,
                      trained: !field.trained,
                    })
                  }
                >
                  {field.trained ? <S.CheckCircleIcon size={16} /> : null}{' '}
                  Trained
                </S.Toggle>
                <S.Toggle
                  pressed={field.queued}
                  onClick={() =>
                    update(index, {
                      ...field,
                      queued: !field.queued,
                    })
                  }
                >
                  {field.queued ? <S.CheckCircleIcon size={16} /> : null} Queued
                </S.Toggle>
              </S.BrandToggleContainer>
            </S.BrandContainer>
          );
        })}
      </S.BrandsFormFieldContainer>
    </S.SectionContainer>
  );
}
