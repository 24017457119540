import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';

import { ReducedAgentsMetrics } from '@/@types/agent';

import * as S from './styles';
import { useConnector } from '../connector';

type ExtendedProps = DialogProps;

interface NewBrandDialogProps extends ExtendedProps {
  reducedAgent: ReducedAgentsMetrics | null;
  onSave: (newBrands: Record<string, boolean>) => () => void;
}

export function NewBrandsDialog(props: NewBrandDialogProps) {
  const { reducedAgent, onSave, ...dialogProps } = props;

  const { selectors } = useConnector();

  const { brands } = selectors;

  const [checkedBrands, setCheckedBrands] = useState<Record<string, boolean>>(
    {},
  );

  useEffect(() => {
    if (reducedAgent)
      setCheckedBrands(
        brands.reduce(
          (cBrands, brand) => {
            const isChecked = !!reducedAgent?.brands.find(
              (rdcdAgent) => rdcdAgent.id === brand.id,
            );
            return { ...cBrands, [brand.id]: isChecked };
          },
          {} as Record<string, boolean>,
        ),
      );
  }, [reducedAgent]);

  return (
    <Dialog open={!!reducedAgent} {...dialogProps}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add brands to {reducedAgent?.name}</DialogTitle>
        </DialogHeader>
        <S.CheckboxContainer>
          {brands.map((brand) => (
            <S.Checkbox
              key={brand.id}
              checked={checkedBrands[brand.id]}
              label={brand.name}
              onCheckedChange={(checked) =>
                setCheckedBrands({
                  ...checkedBrands,
                  [brand.id]: !!checked,
                })
              }
            />
          ))}
        </S.CheckboxContainer>
        <DialogFooter>
          <Button type="submit" onClick={onSave(checkedBrands)}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
