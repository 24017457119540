import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Tabs, TabsList, TabsTrigger } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import { FormType } from '@/constants/form';
import { useNotification } from '@/hooks/useNotification';
import { useScrollTab } from '@/hooks/useScrollTab';
import { ConnectorInfo, StoreInfo } from '@/pages/Stores/StoreForm/Sections';
import { CallInfo } from '@/pages/Stores/StoreForm/Sections/CallInfo';
import { OtherDetails } from '@/pages/Stores/StoreForm/Sections/OtherDetails';
import { Promotions } from '@/pages/Stores/StoreForm/Sections/Promotions';
import { Schedule } from '@/pages/Stores/StoreForm/Sections/Schedule';
import {
  mapFormValuesToStore,
  mapStoreToFormValues,
} from '@/pages/Stores/StoreForm/adapters';
import { mapStorePromosFormValuesToRequest } from '@/pages/Stores/StoreForm/adapters/promos';
import { getFormDefaultValues } from '@/pages/Stores/StoreForm/form';
import {
  StoreSchema,
  createEditStoreSchema,
} from '@/pages/Stores/StoreForm/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

type Tab =
  | 'storeInfo'
  | 'connectorInfo'
  | 'callInfo'
  | 'schedule'
  | 'promotions'
  | 'otherDetails';

const TABS = {
  'Store info': 'storeInfo',
  'Connector info': 'connectorInfo',
  'Call info': 'callInfo',
  Schedule: 'schedule',
  Promotions: 'promotions',
  'Other details': 'otherDetails',
} satisfies Record<string, Tab>;

interface StoreFormProps {
  type: FormType;
}

export const StoreForm = (props: StoreFormProps) => {
  const { type } = props;

  const navigate = useNavigate();
  const { storeId = '' } = useParams<{ storeId: string }>();

  const { handleChangeTab, handleScroll, tab } = useScrollTab<Tab>({
    initTab: 'storeInfo',
  });

  const { selectors, actions } = useConnector();
  const { storesEntities, fetching, creatingUpdation } = selectors;

  const dispatch = useAppDispatch();
  const notification = useNotification();

  useEffect(() => {
    dispatch(actions.getPlatforms());
    dispatch(actions.getBrands());
    dispatch(actions.getFleets());
  }, []);

  useEffect(() => {
    if (type === 'update' && storeId) {
      dispatch(actions.getStore({ id: storeId }))
        .unwrap()
        .then((store) => {
          if (store.brandId) {
            dispatch(actions.getBrand({ id: store.brandId }));
          }
          dispatch(actions.getStoreMenuVersions({ storeId: store.id }));
          dispatch(actions.getPromos({ storeId }));
        });
    }
  }, [storeId, type]);

  const store = storesEntities[storeId];

  const form = useForm<StoreSchema>({
    resolver: zodResolver(createEditStoreSchema),
    defaultValues: getFormDefaultValues(store),
  });

  useEffect(() => {
    if (store) {
      form.reset(mapStoreToFormValues(store));
    }
  }, [store]);

  if (fetching) {
    return <KeaLoading />;
  }
  if (type === 'update' && !store) {
    return null;
  }

  const handleSuccessSave = () => {
    notification.success('Success', {
      description: 'Store created/updated successfully!',
    });
    navigate('/stores');
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    if (type === 'create') {
      dispatch(actions.createStore(mapFormValuesToStore(values)))
        .unwrap()
        .then((newStore) => {
          dispatch(
            actions.syncStorePromos({
              storeId: newStore.id,
              promos: mapStorePromosFormValuesToRequest(values.promos),
            }),
          );
        })
        .finally(handleSuccessSave);
    }
    if (store) {
      if (type === 'update') {
        dispatch(
          actions.syncStorePromos({
            storeId: store.id,
            promos: mapStorePromosFormValuesToRequest(values.promos),
          }),
        );
        dispatch(actions.updateStore(mapFormValuesToStore(values, store)))
          .unwrap()
          .finally(handleSuccessSave);
      }
    }
  };

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <PageCard
          backButton={<BackButton onClick={() => navigate('/stores')} />}
          headerContent={
            <Tabs value={tab} onValueChange={handleChangeTab}>
              <TabsList>
                {Object.entries(TABS).map(([label, value]) => (
                  <TabsTrigger key={value} value={value}>
                    {label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          }
          title={
            store
              ? `${store.brand?.name} - ${store.franchiseStoreNumber}`
              : 'Create a new store'
          }
          footerContent={
            <UpdateCreateButton type="submit" loading={creatingUpdation} />
          }
        >
          <S.SectionsContainer onWheel={handleScroll}>
            <StoreInfo form={form} id={TABS['Store info']} />
            <ConnectorInfo form={form} id={TABS['Connector info']} />
            <CallInfo form={form} id={TABS['Call info']} />
            <Schedule form={form} id={TABS['Schedule']} />
            <Promotions form={form} id={TABS['Promotions']} />
            <OtherDetails form={form} id={TABS['Other details']} />
          </S.SectionsContainer>
        </PageCard>
      </S.Form>
    </Form>
  );
};
