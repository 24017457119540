import { Badge, CardContent, CardHeader, CardTitle } from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';
import { useState } from 'react';
import ReactJson from 'react-json-view';

import { Order } from '@/@types/order';
import { ProductCart } from '@/pages/Orders/OrderInfo/OrderTabs/CallFlow/ProductCart';

import * as S from './styles';
import { addAgentUsernames, getCardHeaderLabel } from './utils';
import { useConnector } from '../../connector';

interface CallFlowProps {
  order: Order;
}

export function CallFlow(props: CallFlowProps) {
  const { order } = props;

  const [collapsed, setCollapsed] = useState(true);

  const { selectors } = useConnector();

  const { theme, agentEntities } = selectors;

  return (
    <S.Container>
      <S.CollapseButton
        onClick={() => setCollapsed(!collapsed)}
        variant="ghost"
      >
        {collapsed ? 'Expand all' : 'Collapse all'}
      </S.CollapseButton>
      <S.CallFlowContainer>
        {order.callFlow?.map((call, index) => {
          return (
            <S.CardContainer key={call.id}>
              <Badge size="sm">
                {dayjs(call.createdAt).format('MMM DD, YYYY - hh:mm:ss a')}
              </Badge>
              <S.Card>
                <CardHeader>
                  <CardTitle>{getCardHeaderLabel(call)}</CardTitle>
                </CardHeader>
                <CardContent>
                  {call.eventName === 'order_cart_changed' ? (
                    <ProductCart
                      callFlow={order.callFlow ?? []}
                      event={call}
                      index={index}
                    />
                  ) : (
                    <ReactJson
                      name="details"
                      collapsed={collapsed}
                      src={addAgentUsernames(call, agentEntities) ?? {}}
                      theme={theme === 'dark' ? 'ashes' : undefined}
                    />
                  )}
                </CardContent>
              </S.Card>
            </S.CardContainer>
          );
        })}
      </S.CallFlowContainer>
    </S.Container>
  );
}
