import { useEffect, useRef, useState } from 'react';

import { ReducedAgentsMetrics } from '@/@types/agent';
import { Brand } from '@/@types/brand';
import {
  QueuedBrandSendMessage,
  RoleWeightSendMessage,
} from '@/@types/metrics/socket';
import { useNotification } from '@/hooks/useNotification';
import { useAppDispatch } from '@/store';

import { QueueBrandPatchPayload, useConnector } from '../connector';

type UseQueueUnqueueBrand = {
  sendQueuedBrandChange: QueuedBrandSendMessage;
  sendRoleWeightChange: RoleWeightSendMessage;
  brands: Brand[];
};

export const useAgentTable = (opts: UseQueueUnqueueBrand) => {
  const { sendQueuedBrandChange, sendRoleWeightChange, brands } = opts;
  const { actions } = useConnector();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const [selectedAgent, setSelectedAgent] =
    useState<ReducedAgentsMetrics | null>(null);

  const handleNewBrandClick = (reducedAgent: ReducedAgentsMetrics) => {
    setSelectedAgent(reducedAgent);
  };

  const handleRoleWeightSave = async (
    roleWeight: string,
    rAgent: ReducedAgentsMetrics,
  ) => {
    dispatch(actions.updateRoleWeight({ agentId: rAgent.id, roleWeight }))
      .unwrap()
      .then(() => {
        sendRoleWeightChange({
          agentId: rAgent.id,
          value: roleWeight,
          type: 'save',
        });
      });
  };

  const agentBrandReadyToRemove = useRef<ReducedAgentsMetrics | null>(null);

  useEffect(() => {
    if (selectedAgent) {
      selectedAgent.brands.forEach((brand) =>
        sendQueuedBrandChange({
          agentId: selectedAgent.id,
          brandId: brand.id,
          type: 'draft',
        }),
      );
      agentBrandReadyToRemove.current = selectedAgent;
    } else if (agentBrandReadyToRemove.current)
      agentBrandReadyToRemove.current.brands.forEach((brand) =>
        sendQueuedBrandChange({
          agentId: agentBrandReadyToRemove.current?.id,
          brandId: brand.id,
          type: 'idle',
        }),
      );
  }, [selectedAgent]);

  const handleQueueBrands = (checkedBrands: Record<string, boolean>) => () => {
    if (selectedAgent) {
      const newBrands: QueueBrandPatchPayload[] = brands
        .filter((brand) => checkedBrands[brand.id])
        .map((brand) => ({
          id: brand.id,
          queued: true,
          key: brand.key,
        }));

      dispatch(
        actions.updateAgentBrands({
          agent: selectedAgent,
          brands: newBrands,
        }),
      )
        .unwrap()
        .then(() => {
          Object.entries(checkedBrands).forEach(([brandId, change]) => {
            sendQueuedBrandChange({
              agentId: selectedAgent.id,
              brandId,
              type: change ? 'added' : 'removed',
            });
          });
          notification.success('Success', {
            autoClose: true,
            description: `${newBrands.length} brand(s) added to queue`,
          });
        })
        .catch(() =>
          notification.error('Error', {
            autoClose: true,
            description: 'There was an error removing the brand from the agent',
          }),
        )
        ?.finally(() => setSelectedAgent(null));
    }
  };

  return {
    selectedAgent,
    setSelectedAgent,
    handleQueueBrands,
    handleNewBrandClick,
    handleRoleWeightSave,
  };
};
