import { PaginatedAccountsFilters } from '@/@types/account/paginatedAccount';
import { AccountService } from '@/services/@types/account';

export const mapGetAccountsFiltersToApi = (
  filters?: Partial<PaginatedAccountsFilters>,
): AccountService.GetAccountsFilters | undefined =>
  filters
    ? {
        ids: filters.ids,
        phone: filters.phone,
      }
    : undefined;
