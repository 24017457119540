import { Avatar } from '@kea-inc/parrot-ui';

import * as S from './styles';

interface StepProps {
  currentStep: number;
  step: number;
  label: string;
  onClick: (currentStep: number) => void;
}

export function Step(props: StepProps) {
  const { currentStep, label, step, onClick } = props;
  const active = currentStep >= step;
  return (
    <S.Step onClick={() => onClick(step)}>
      <Avatar>
        <S.StepAvatarFallback $active={active}>{step + 1}</S.StepAvatarFallback>
      </Avatar>
      <S.StepTypography muted={!active}>{label}</S.StepTypography>
    </S.Step>
  );
}
