import * as z from 'zod';

import { STORE_HANDOFF_MODES } from '@/constants/triageHandoffMethods';

export const handoffMethodsSchema = z
  .object({
    id: z.string().optional(),
    method: z.enum(STORE_HANDOFF_MODES),
    override: z.boolean().nullable(),
  })
  .array();

export type HandoffMethodSchema = z.infer<typeof handoffMethodsSchema>;
