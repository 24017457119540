import { Typography, TypographyProps } from '@kea-inc/parrot-ui';
import { CopyIcon } from 'lucide-react';
import { useState } from 'react';

import { useAppDispatch } from '@/store';
import { actions as notificationActions } from '@/store/ui/notification';

import * as S from './styles';

interface CopyProps extends TypographyProps {
  children: string;
  value?: string;
}

export function Copy({ children, value, ...rest }: CopyProps) {
  const dispatch = useAppDispatch();
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyToClipboard: React.MouseEventHandler<SVGSVGElement> = (
    evt,
  ) => {
    evt.stopPropagation();
    navigator.clipboard.writeText(value ?? children);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    dispatch(notificationActions.setOpen(true));
    dispatch(notificationActions.setAutoClose(true));
    dispatch(
      notificationActions.setTitleDescription({
        title: '',
        description: 'Text copied to clipboard!',
      }),
    );
  };

  return (
    <S.Container>
      {isCopied ? (
        <Typography {...rest}>Copied!</Typography>
      ) : (
        <>
          <Typography {...rest}>{children}</Typography>
          <CopyIcon onClick={handleCopyToClipboard} size={14} />
        </>
      )}
    </S.Container>
  );
}
