import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { StoreState } from '@/store';

import { State } from './slice';

export const isFetching = createDraftSafeSelector(
  (state: State) => state.fetching,
  (fetching) => Object.values(fetching).some((ft) => ft),
);

export const isUcFetching = (ucName: keyof State['fetching']) =>
  createDraftSafeSelector(
    (state: StoreState) => state.useCases.worksite.fetching,
    (fetching) => fetching[ucName],
  );
