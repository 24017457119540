import React from 'react';

import { FlexGrow } from '@/components/FlexGrow';

import * as S from './styles';

type PageCardProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  backButton?: React.ReactNode;
  className?: string;
  cardContentClassName?: string;
};

export const PageCard = (props: PageCardProps) => {
  const {
    title,
    children,
    headerContent,
    footerContent,
    backButton,
    className,
    cardContentClassName,
  } = props;

  const shouldRenderHeader = !!(backButton ?? title ?? headerContent);
  return (
    <S.Container className={className}>
      <S.Card>
        {shouldRenderHeader ? (
          <S.CardHeader>
            {backButton}
            {title ? <S.CardTitle>{title}</S.CardTitle> : null}
            <FlexGrow />
            {headerContent}
          </S.CardHeader>
        ) : null}
        <S.CardContent className={cardContentClassName}>
          {children}
        </S.CardContent>
        {footerContent ? <S.CardFooter>{footerContent}</S.CardFooter> : null}
      </S.Card>
    </S.Container>
  );
};
