import { FormField, Typography } from '@kea-inc/parrot-ui';
import { LockKeyholeIcon } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';

import { Permission } from '@/@types/user';

import * as S from './styles';
interface PermissionsFormItemProps<
  FV extends { permissions: Omit<Permission, 'id'>[] },
> {
  form: UseFormReturn<FV>;
  permissions: Permission[];
  type: 'user' | 'role';
}

export function PermissionsFormItem<
  FV extends { permissions: Omit<Permission, 'id'>[] },
>(props: PermissionsFormItemProps<FV>) {
  const { form, permissions, type } = props;

  return (
    <S.SectionContainer>
      {type === 'user' ? <Typography>Permissions</Typography> : null}
      <S.PermissionsContainer>
        <FormField<{ permissions: Permission[] }>
          control={form.control as any}
          name="permissions"
          render={({ field }) => (
            <>
              {permissions.map((permission) => {
                const permissionValue = field.value as Permission[];
                const hasPermission = !!permissionValue?.find(
                  (perm) => perm.name === permission.name,
                );
                return (
                  <S.Button
                    key={permission.id}
                    $disabled={!hasPermission}
                    type="button"
                    onClick={() =>
                      field.onChange(
                        hasPermission
                          ? permissionValue.filter(
                              (perm) => perm.name !== permission.name,
                            )
                          : [...permissionValue, permission],
                      )
                    }
                  >
                    <LockKeyholeIcon size={18} />
                    {permission.name}
                  </S.Button>
                );
              })}
            </>
          )}
        />
      </S.PermissionsContainer>
    </S.SectionContainer>
  );
}
