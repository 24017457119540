import { SoundBoardEntry } from '@/@types/menu';
import { menuService } from '@/services';
import { MenuService } from '@/services/@types/menu';
import {
  mapStoreSoundboardEntryToDomain,
  mapBrandSoundboardEntryToDomain,
  mapGlobalSoundboardEntryToDomain,
} from '@/services/useCases/menu/adapters/domain';

export type GetSoundboardEntries = {
  input: void;
  output: SoundBoardEntry[];
};

export const getSoundboardEntries = async (): Promise<
  GetSoundboardEntries['output']
> => {
  const { data } =
    await menuService.get<MenuService.SoundboardResponse>(
      `/soundboard-entries`,
    );

  return data.soundboardEntries.map(mapGlobalSoundboardEntryToDomain);
};

export type GetBrandSoundboardEntries = {
  input: { brandId: string };
  output: SoundBoardEntry[];
};

export const getBrandSoundboardEntries = async (
  input: GetBrandSoundboardEntries['input'],
): Promise<GetBrandSoundboardEntries['output']> => {
  const { brandId } = input;
  const { data } = await menuService.get<MenuService.SoundboardResponse>(
    `/soundboard-entries/brands/${brandId}`,
  );

  return data.soundboardEntries.map(mapBrandSoundboardEntryToDomain(brandId));
};

export type GetStoreSoundboardEntries = {
  input: { storeId: string };
  output: SoundBoardEntry[];
};

export const getStoreSoundboardEntries = async (
  input: GetStoreSoundboardEntries['input'],
): Promise<GetStoreSoundboardEntries['output']> => {
  const { storeId } = input;
  const { data } = await menuService.get<MenuService.SoundboardResponse>(
    `/soundboard-entries/stores/${storeId}`,
  );

  return data.soundboardEntries.map(mapStoreSoundboardEntryToDomain(storeId));
};
