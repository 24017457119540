import decamelizeKeys from 'decamelize-keys';
import _ from 'lodash';

import { Store } from '@/@types/store';
import { StoreService } from '@/services/@types/store';
import { extractNumbers, formatPhone } from '@/utils/methods';

export const mapCreateUpdateStoreToApi = (
  store: Partial<Store>,
): StoreService.CreateUpdateRequest => {
  const serviceStore = decamelizeKeys(store);

  // Delete some keys due back-end validations
  const deletedKeys: (keyof Partial<typeof serviceStore>)[] = [
    'store_id',
    'legal_entity',
    'menu_preview',
    'use_store_hours',
    'caller_whitelist',
    'status',
    'country',
    'fleet',
    'brand',
    'promos',
    'specials',
  ];

  return {
    ..._.omitBy(_.omit(serviceStore, deletedKeys), _.isUndefined),
    store_status: serviceStore.status,
    primary_phone: formatPhone(extractNumbers(store.primaryPhone)),
    secondary_phone: formatPhone(extractNumbers(store.secondaryPhone)),
    twilio_number: formatPhone(extractNumbers(store.twilioNumber)),
    special: store.specials?.map((special) => special.value),
    activation_date: store.activationDate?.toISOString(),
    whitelisted_number: serviceStore.caller_whitelist?.map(
      ({ whitelistedNumber }) => formatPhone(extractNumbers(whitelistedNumber)),
    ),
    store_hours: store.storeHours?.map((storeHour) =>
      decamelizeKeys(storeHour),
    ),
  };
};
