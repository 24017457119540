import env from '@beam-australia/react-env';

import { Agent } from '@/@types/agent';
import { CreateAgentRequest } from '@/@types/agent/create';
import { dataService } from '@/services';
import { AgentService } from '@/services/@types/agent';
import { mapCreateAgentToApi } from '@/services/useCases/agent/adapters/api';
import { mapAgentToDomain } from '@/services/useCases/agent/adapters/domain';

export type CreateAgent = {
  input: CreateAgentRequest;
  output: Agent;
};

export const createAgent = async (
  input: CreateAgent['input'],
): Promise<CreateAgent['output']> => {
  const { data } = await dataService.post<
    AgentService.Agent,
    AgentService.CreateAgentRequest
  >(
    `/agents/workspace/${env('TWILIO_WORKSPACE_SID')}`,
    mapCreateAgentToApi(input),
  );
  return { ...mapAgentToDomain(data), brands: input.brands };
};
