import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Brand } from '@/@types/brand';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { useFuseSearch } from '@/hooks/useFuseSearch';
import { useNotification } from '@/hooks/useNotification';
import { useBrandColumnDefs } from '@/pages/Brands/List/useBrandColumnDefs';
import { useConnector } from '@/pages/Brands/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

type DeleteDialogState = {
  brand: Brand | null;
};

const initDeleteDialog: DeleteDialogState = {
  brand: null,
};

export function List() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useNotification();

  const { actions, selectors } = useConnector();

  const [deleteDialog, setDeleteDialog] =
    useState<DeleteDialogState>(initDeleteDialog);

  useEffect(() => {
    dispatch(actions.getBrands());
  }, []);

  const { brands, fetching, deleting } = selectors;

  const {
    filteredItems: filteredAgents,
    searchTerm,
    setSearchTerm,
  } = useFuseSearch({
    itemKeys: ['name'],
    items: brands,
  });

  const { columns } = useBrandColumnDefs({
    onDeleteClick: (brand) => setDeleteDialog({ brand }),
    onUpdateClick: (brand) => navigate(`/brands/${brand.id}`),
  });

  const handleDeleteStore = () => {
    if (deleteDialog.brand)
      dispatch(actions.deleteBrand({ id: deleteDialog.brand?.id }))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'Brand deleted successfully',
          });
          setDeleteDialog(initDeleteDialog);
        });
  };

  return (
    <PageCard
      title="Brands"
      headerContent={
        <>
          <FlexGrow />
          <S.Input
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            placeholder="Search"
          />
          <Button onClick={() => navigate('/brands/new')}>Add Brand</Button>
        </>
      }
    >
      {fetching && filteredAgents.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={filteredAgents}
          stickyHeader
          onRowClick={(row) => navigate(`/brands/${row.original.id}`)}
        />
      )}
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to delete <b>{deleteDialog.brand?.name}</b>?
          </Typography>
        }
        open={!!deleteDialog.brand}
        onOpenChange={(open) =>
          setDeleteDialog({ brand: open ? deleteDialog.brand : null })
        }
        onSubmit={handleDeleteStore}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
    </PageCard>
  );
}
