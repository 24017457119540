import { Bpo } from '@/@types/bpo';
import { dataService } from '@/services';
import { BpoService } from '@/services/@types/bpo';
import { mapBpoToDomain } from '@/services/useCases/bpo/adapters/domain';

export type CreateBpo = {
  input: { bpoName: string };
  output: Bpo;
};

export const createBpo = async (
  input: CreateBpo['input'],
): Promise<CreateBpo['output']> => {
  const { bpoName } = input;
  const { data } = await dataService.post<BpoService.Bpo>(`/bpos`, {
    name: bpoName,
  });

  return mapBpoToDomain(data);
};
