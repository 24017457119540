import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { Brand } from '@/@types/brand';
import { actions } from '@/store/useCases/brand';

export const adapter = createEntityAdapter<Brand>({
  sortComparer: (f1, f2) => f1.name.localeCompare(f2.name),
});

export type State = {
  brands: EntityState<Brand, string>;
};

export const initialState: State = {
  brands: adapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getBrands.fulfilled, (state, action) => {
      adapter.upsertMany(state.brands, action.payload);
    });
    builder.addCase(actions.createBrand.fulfilled, (state, action) => {
      adapter.addOne(state.brands, action.payload);
    });
    builder.addCase(actions.updateBrand.fulfilled, (state, action) => {
      adapter.updateOne(state.brands, {
        changes: _.omitBy(action.payload, _.isUndefined),
        id: action.payload.id,
      });
    });
    builder.addCase(actions.deleteBrand.fulfilled, (state, action) => {
      adapter.removeOne(state.brands, action.payload.id);
    });
    builder.addCase(actions.getBrand.fulfilled, (state, action) => {
      const currentBrand = state.brands.entities[action.payload.id];

      if (currentBrand) {
        adapter.updateOne(state.brands, {
          changes: _.omitBy(action.payload, _.isUndefined),
          id: action.payload.id,
        });
      } else {
        adapter.addOne(state.brands, action.payload);
      }
    });
    builder.addCase(actions.getBrandPromos.fulfilled, (state, action) => {
      adapter.updateOne(state.brands, {
        id: action.meta.arg.brandId,
        changes: { promos: action.payload },
      });
    });
    builder.addCase(actions.getBrandUpsells.fulfilled, (state, action) => {
      adapter.updateOne(state.brands, {
        id: action.meta.arg.brandId,
        changes: { upsells: action.payload },
      });
    });
    builder.addCase(actions.getBrandModels.fulfilled, (state, action) => {
      adapter.updateOne(state.brands, {
        id: action.meta.arg.brandId,
        changes: { models: action.payload },
      });
    });
    builder.addCase(actions.getBrandNluModels.fulfilled, (state, action) => {
      adapter.updateOne(state.brands, {
        id: action.meta.arg.brandId,
        changes: { nluModels: action.payload },
      });
    });
  },
});

export default slice;
