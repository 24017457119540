import { StorePromo } from '@/@types/store';
import { StoreService } from '@/services/@types/store';

export const mapStorePromoToApi = (
  storePromo: Omit<StorePromo, 'id'>,
): StoreService.StorePromoPayload => ({
  code: storePromo.code,
  description: storePromo.description,
  name: storePromo.name,
  prompt: storePromo.prompt,
});
