import { adapter, adapterWithFilter } from './slice';
import { StoreState } from '../..';

export const getUsers = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.user.users,
).selectAll;

export const getUserEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.user.users,
).selectEntities;

export const getUsersWithFilter = adapterWithFilter.getSelectors<StoreState>(
  (stt) => stt.entities.user.usersWithFilter,
).selectById;
