import styled from 'styled-components';

export * from '../styles';

export const PromoFormFieldContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;
export const PromosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const NewPromotionContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;
