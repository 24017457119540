import { ColumnDef, Typography } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';

import { HandoffMethodsInfo, StoreHandoffMode } from '@/@types/store';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';
import { HandoffMethodSchema } from '@/pages/Stores/StoreForm/schema/handoffMethods';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

const translations: Record<keyof HandoffMethodsInfo, string> = {
  pickup: 'Pickup',
  delivery: 'Delivery',
  dinein: 'Dine in',
  curbside: 'Curbside',
};

type UseColumnDefsOpts = {
  form: UseFormReturn<StoreSchema>;
};

export const useColumnDefs = (opts: UseColumnDefsOpts) => {
  const { form } = opts;
  const dispatch = useAppDispatch();
  const { actions, selectors } = useConnector();

  const { brandId } = useWatch({ control: form.control });

  const brand = brandId ? selectors.brandsEntities[brandId] : undefined;

  const { update, fields } = useFieldArray({
    control: form.control,
    name: 'triageHandoffMethods',
  });

  useEffect(() => {
    if (brandId) {
      dispatch(actions.getBrand({ id: brandId }))
        .unwrap()
        .then(() => {
          if (brand?.triageHandoffMethods) {
            const triageHandoffMode: HandoffMethodSchema = Object.entries(
              brand.triageHandoffMethods,
            ).map(([handoffMode]) => ({
              method: handoffMode as StoreHandoffMode,
              override: null,
              id: brand.id,
            }));

            form.setValue('triageHandoffMethods', triageHandoffMode);
          }
        });
    }
  }, [brandId]);

  function getResult(rowOriginal: HandoffMethodSchema[0]) {
    const field = fields.find((fld) => fld.method === rowOriginal.method);
    if (!field) {
      return;
    }

    const inStoreOrdering = form.getValues('inStoreOrdering');

    if (field.method === 'dinein') {
      if (!inStoreOrdering) {
        return (
          <S.ResultContainer className="result">
            <S.XCircleIcon size={24} />
            <Typography color="gray">In store ordering is disabled</Typography>
          </S.ResultContainer>
        );
      }
    }

    if (field.override === null) {
      return brand?.triageHandoffMethods?.[field.method] ? (
        <S.CheckCircle2Icon size={24} />
      ) : (
        <S.XCircleIcon size={24} />
      );
    }

    return field.override ? (
      <S.CheckCircle2Icon size={24} />
    ) : (
      <S.XCircleIcon size={24} />
    );
  }

  const columns: ColumnDef<HandoffMethodSchema[0]>[] = [
    {
      accessorKey: 'method',
      header: () => <div>Handoff methods</div>,
      cell: ({ row }) => {
        return <div>{translations[row.original.method]}</div>;
      },
    },
    {
      accessorKey: 'brand',
      header: () => <div>Handoff methods</div>,
      cell: ({ row }) => {
        const useMethod = brand?.triageHandoffMethods?.[row.original.method];
        return (
          <div>{useMethod ? <S.CheckCircle2Icon /> : <S.XCircleIcon />}</div>
        );
      },
    },

    {
      accessorKey: 'override',
      header: () => <div>Override</div>,
      cell: ({ row }) => {
        const field = fields.find((fld) => fld.method === row.original.method);
        const indexOfMethod = fields.findIndex(
          (fld) => fld.method === row.original.method,
        );

        if (!field) {
          return <div />;
        }

        return (
          <S.OverrideContainer>
            <S.FormItemToggle>
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.override === null}
                  onClick={() =>
                    update(indexOfMethod, { ...field, override: null })
                  }
                >
                  Brand setting
                </S.Toggle>
                <S.Toggle
                  pressed={field.override === true}
                  onClick={() =>
                    update(indexOfMethod, { ...field, override: true })
                  }
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={field.override === false}
                  onClick={() =>
                    update(indexOfMethod, { ...field, override: false })
                  }
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          </S.OverrideContainer>
        );
      },
    },
    {
      accessorKey: 'result',
      header: () => <div>Result</div>,
      cell: ({ row }) => {
        return <S.ResultCell>{getResult(row.original)}</S.ResultCell>;
      },
    },
  ];

  return { columns };
};
