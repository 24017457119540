import * as ParrotUi from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  height: 100%;
`;

export const SectionsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  flex: 1 1 0px;
  overflow: auto;
  padding-right: 1.5rem;
  position: relative;
`;

export const SectionContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`;

export const SectionTitle = styled(ParrotUi.Typography).attrs({
  variant: 'h3',
})``;

export const FormFieldContainerCss = css`
  display: flex;
  gap: 1rem;
`;

export const FormFieldContainer = styled.div`
  ${FormFieldContainerCss}
`;

export const ToggleContainer = styled.div`
  display: flex;
  border: 1px solid hsl(var(--border));
  border-radius: 6px;
`;

export const Toggle = styled(ParrotUi.Toggle)`
  flex: 1;
  border-radius: 0px;
  border-radius: 3px;
`;

export const FieldArrayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FieldArrayItemContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;

export const FormItemLabelContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
