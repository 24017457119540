import { useAppSelector } from '@/store';
import {
  actions as orderUcActions,
  selectors as orderUcSelectors,
} from '@/store/useCases/order';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(
      orderUcSelectors.isUcFetching('getOrderRecording'),
    ),
  };

  const actions = {
    getOrderRecording: orderUcActions.getOrderRecording,
  };

  return { actions, selectors };
};
