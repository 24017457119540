import { Brand, BrandModel } from '@/@types/brand';
import { dataService } from '@/services';
import { BrandService } from '@/services/@types/brand';
import { mapBrandToDomain } from '@/services/useCases/brand/adapters';

export type GetBrands = {
  input: void;
  output: Brand[];
};

export const getBrands = async (): Promise<GetBrands['output']> => {
  const { data } = await dataService.get<BrandService.Brand[]>('/brands');
  return data.map(mapBrandToDomain);
};

export type GetBrand = {
  input: { id: string };
  output: Brand;
};

export const getBrand = async (
  input: GetBrand['input'],
): Promise<GetBrand['output']> => {
  const { data: brand } = await dataService.get<BrandService.Brand>(
    `/brands/${input.id}`,
  );

  return mapBrandToDomain(brand);
};

export type GetBrandModels = {
  input: { brandKey: string; brandId: string };
  output: BrandModel[];
};

export const getBrandModels = async (
  input: GetBrandModels['input'],
): Promise<GetBrandModels['output']> => {
  const { data } = await dataService.get<BrandService.BrandModel[]>(
    `/brands/${input.brandKey}/models`,
  );
  return data;
};

export const getBrandNluModels = async (
  input: GetBrandModels['input'],
): Promise<GetBrandModels['output']> => {
  const { data } = await dataService.get<BrandService.BrandModel[]>(
    `/brands/${input.brandKey}/nlu-models`,
  );
  return data;
};
