import { Select } from '@kea-inc/parrot-ui';

import { ReducedMetricsData } from '@/@types/agent';

import { AgentMetric } from './AgentMetric';
import { QueueMetric } from './QueueMetric';
import * as S from './styles';

interface MetricsCardProps {
  reducedMetrics: ReducedMetricsData;
  minutes: string;
  onMinutesChange: (minutes: string) => void;
}

export function MetricsCard(props: MetricsCardProps) {
  const { reducedMetrics, minutes, onMinutesChange } = props;
  return (
    <S.Card>
      <S.CardContent>
        <QueueMetric
          customersInQueue={reducedMetrics.customersInQueue}
          maxWaitTime={reducedMetrics.maxWaitTime}
          avgWaitTime={reducedMetrics.avgWaitTime}
        />

        <AgentMetric
          activeAgents={reducedMetrics.activeAgents}
          maxIdleTime={reducedMetrics.maxIdleTime}
          avgIdleTime={reducedMetrics.avgIdleTime}
        />

        <Select required value={minutes} onValueChange={onMinutesChange}>
          <Select.Item value="10">10 minutes</Select.Item>
          <Select.Item value="20">20 minutes</Select.Item>
          <Select.Item value="40">40 minutes</Select.Item>
          <Select.Item value="60">60 minutes</Select.Item>
        </Select>
      </S.CardContent>
    </S.Card>
  );
}
