import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaginatedOrdersFilters } from '@/@types/order/paginatedOrder';

export interface State {
  totalRecords: number;
  debugMode: boolean;
  filters?: PaginatedOrdersFilters;
}

export const initialState: State = {
  totalRecords: 0,
  debugMode: false,
};

const slice = createSlice({
  name: 'ui/dataServiceOrder',
  initialState,
  reducers: {
    setTotalRecords: (state, action: PayloadAction<number>) => {
      state.totalRecords = action.payload;
    },
    toggleDebugMode: (state) => {
      state.debugMode = !state.debugMode;
    },
    setFilters: (state, action: PayloadAction<State['filters']>) => {
      state.filters = action.payload;
    },
  },
});

export default slice;
