import { HandoffMethodsInfo, Store } from '@/@types/store';
import { mapStoreHoursFormValuesToStore } from '@/pages/Stores/StoreForm/adapters/form/storeHours';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import { mapStoreHoursToFormValues } from '../storeHours';

export const mapStoreToFormValues = (store: Store): StoreSchema => {
  return {
    ...store,
    brandId: store.brandId,
    fleetId: store.fleetId,
    callerWhitelist:
      store.callerWhitelist?.map((whiteList) => ({
        number: whiteList.whitelistedNumber,
      })) ?? [],
    triageHandoffMethods: Object.entries(store.triageHandoffMethods ?? {})
      .filter(([method]) => method !== 'store_id')
      .map(([method, override]) => {
        return {
          method: method as keyof HandoffMethodsInfo,
          override,
        };
      }),
    storeHours: mapStoreHoursToFormValues(store.storeHours),
  };
};

export const mapFormValuesToStore = (
  values: StoreSchema,
  store?: Partial<Store>,
): Partial<Store> => {
  return {
    delayedRing: store?.delayedRing,
    fleet: store?.fleet ? { ...store?.fleet, id: values.fleetId } : undefined,
    id: store?.id,
    brand: store?.brand ? { ...store?.brand, id: values.brandId } : undefined,
    isOn: store?.isOn,
    legalEntity: store?.legalEntity,
    menuPreview: store?.menuPreview,
    storeStatus: store?.storeStatus,
    syncCashLimit: store?.syncCashLimit,
    useStoreHours: store?.useStoreHours,
    storeId: store?.storeId,
    activationDate: values.activationDate ?? undefined,
    address: values.address,
    area: values.area,
    bypassMode: values.bypassMode,
    brandId: values.brandId,
    fleetId: values.fleetId,
    cashLimit: values.cashLimit,
    taxRate: values.taxRate,
    city: values.city,
    currency: '',
    franchiseStoreNumber: values.franchiseStoreNumber,
    inStoreOrdering: values.inStoreOrdering,
    isProductionMirror: false,
    isTestStore: values.isTestStore,
    latitude: values.latitude,
    location: values.location,
    longitude: values.longitude,
    notes: values.notes ?? '',
    oloMock: values.oloMock,
    platformId: values.platformId,
    platformStoreId: values.platformStoreId,
    primaryPhone: values.primaryPhone,
    secondaryPhone: values.secondaryPhone,
    shouldTip: values.shouldTip,
    sipUri: values.sipUri,
    specials: values.specials,
    state: values.state,
    status: values.status,
    syncStoreHours: values.syncStoreHours,
    timezone: values.timezone,
    twilioNumber: values.twilioNumber,
    useDefaultOptionsToMaxQuantity: values.useDefaultOptionsToMaxQuantity,
    autoScheduleThreshold: values.storeAutoScheduleThreshold,
    zipcode: values.zipcode,
    country: values.country,
    callerWhitelist: values.callerWhitelist?.map((cWhitelist) => ({
      whitelistedNumber: cWhitelist.number,
    })),
    triageHandoffMethods: values.triageHandoffMethods?.reduce(
      (storeHandoffMethods, handoffMethod) => ({
        ...storeHandoffMethods,
        [handoffMethod.method]: handoffMethod.override,
      }),
      {} as HandoffMethodsInfo,
    ),
    storeHours: mapStoreHoursFormValuesToStore(
      values?.storeHours,
      values?.timezone,
    ),
    menuVersion: values.menuVersion,
    agentlessStoreForwarding: values.agentlessStoreForwarding,
  };
};
