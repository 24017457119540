import { dataService } from '@/services';

export type DeleteStore = {
  input: { id: string };
  output: { id: string };
};

export async function deleteStore(
  input: DeleteStore['input'],
): Promise<DeleteStore['output']> {
  await dataService.delete(`/stores/${input.id}`);

  return { id: input.id };
}
