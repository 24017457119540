import env from '@beam-australia/react-env';

import { dataService } from '@/services';

const twilioWorkspaceSid = env('TWILIO_WORKSPACE_SID');

export type UpdateRoleWeight = {
  input: { agentId: string; roleWeight: string };
};

export const updateRoleWeight = async (input: UpdateRoleWeight['input']) => {
  await dataService.patch(
    `/agents/${input.agentId}/workspace/${twilioWorkspaceSid}`,
    {
      role_weight: Number(input.roleWeight),
    },
  );
};
