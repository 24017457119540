import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export type TranscriptMessageContentBackground = 'muted' | 'primary';

const avatarWidth = 40;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  padding-bottom: 1rem;
`;

export const TranscriptMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TranscriptMessageContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const Typography = styled(ParrotUi.Typography)`
  margin-left: calc(${avatarWidth}px + 0.5rem);
`;

export const Avatar = styled(ParrotUi.Avatar)`
  width: ${avatarWidth}px;
`;

export const AvatarFallback = styled(ParrotUi.AvatarFallback)`
  background-color: hsl(var(--gray)) !important;
`;

export const TranscriptTimestampContainer = styled(ParrotUi.Typography)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;

export const TranscriptTimestamp = styled(ParrotUi.Typography)`
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;

export const TranscriptMessageContent = styled(ParrotUi.Typography)<{
  $background: 'muted' | 'primary';
}>`
  border-radius: 4px 20px 20px 20px;
  background-color: ${(props) => `hsl(var(--${props.$background}))`};
  padding: 0.5rem 0.75rem;
  max-width: 500px;
`;
