import { Platform } from '@/@types/platform';
import { dataService } from '@/services';
import { PlatformService } from '@/services/@types/platform';

export type CreatePlatform = {
  input: Pick<Platform, 'name'>;
  output: Platform;
};

export const createPlatform = async (
  input: CreatePlatform['input'],
): Promise<CreatePlatform['output']> => {
  const { data } = await dataService.post<PlatformService.Platform>(
    `/platforms`,
    input,
  );

  return data;
};
