import { FormField, Input, Label, FormItemInput } from '@kea-inc/parrot-ui';
import { MinusIcon, PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';
import { preserveCaretPosition } from '@/utils/methods';

import * as S from './styles';

interface WhitelistedNumbersFormFieldProps {
  form: UseFormReturn<StoreSchema>;
}

export function WhitelistedNumbersFormField(
  props: WhitelistedNumbersFormFieldProps,
) {
  const { form } = props;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'callerWhitelist',
  });

  const [newNumber, setNewNumber] = useState('');

  return (
    <S.Container>
      <Label>Whitelisted numbers</Label>
      <S.NumbersContainer>
        <Input
          value={newNumber}
          maskOptions={{ mask: '+1 (999) 999-9999' }}
          onChange={(evt) => {
            preserveCaretPosition(evt);
            setNewNumber(evt.target.value);
          }}
        />
        <S.AddDeleteButton
          variant="ghost"
          size="icon"
          type="button"
          onClick={() => {
            const field = fields.find((f) => f.number === newNumber);

            if (!field) {
              append({ number: newNumber });
              setNewNumber('');
            }
          }}
        >
          <PlusIcon />
        </S.AddDeleteButton>
      </S.NumbersContainer>
      {fields.map((fieldItem, index) => (
        <S.NumbersContainer key={fieldItem.number}>
          <FormField
            control={form.control}
            name={`callerWhitelist.${index}.number`}
            render={({ field }) => (
              <FormItemInput
                maskOptions={{ mask: '+1 (999) 999-9999' }}
                field={{
                  ...field,
                  value: field.value,
                }}
              />
            )}
          />
          <S.AddDeleteButton
            variant="ghost"
            size="icon"
            onClick={() => remove(index)}
            type="button"
          >
            <MinusIcon />
          </S.AddDeleteButton>
        </S.NumbersContainer>
      ))}
    </S.Container>
  );
}
