import decamelizeKeys from 'decamelize-keys';

import { CreateFleetRequest, UpdateFleetRequest } from '@/@types/fleet';
import { FleetService } from '@/services/@types/fleet';

export const mapCreateFleetToApi = (
  fleet: CreateFleetRequest,
): FleetService.CreateFleetRequest => decamelizeKeys(fleet);

export const mapUpdateFleetToApi = (
  fleet: UpdateFleetRequest,
): FleetService.UpdateFleetRequest => decamelizeKeys(fleet);
