import { FormField, FormItemToggle } from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import * as S from './styles';

interface SyncStoreHoursFormFieldProps {
  form: UseFormReturn<StoreSchema>;
}

export function SyncStoreHoursFormField(props: SyncStoreHoursFormFieldProps) {
  const { form } = props;
  return (
    <FormField
      control={form.control}
      name="syncStoreHours"
      render={({ field }) => (
        <FormItemToggle label="Sync store hours">
          <S.ToggleContainer>
            <S.Toggle
              pressed={field.value}
              onClick={() => field.onChange(true)}
            >
              AutoSync
            </S.Toggle>
            <S.Toggle
              pressed={!field.value}
              onClick={() => field.onChange(false)}
            >
              Manual
            </S.Toggle>
          </S.ToggleContainer>
        </FormItemToggle>
      )}
    />
  );
}
