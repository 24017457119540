import {
  Button,
  ColumnDef,
  DataTableSortHeader,
  Typography,
} from '@kea-inc/parrot-ui';

import { Bpo } from '@/@types/bpo';
import { TextCenter } from '@/components/DataTable';
import { TableActions } from '@/components/TableActions';

import * as S from './styles';

type UseBpoColumnDefsOpts = {
  onAgentsClick: (bpo: Bpo) => void;
  onWorksitesClick: (bpo: Bpo) => void;
  onUpdateClick: (bpo: Bpo) => void;
  onDeleteClick: (bpo: Bpo) => void;
};

export const useBpoColumnDefs = (opts: UseBpoColumnDefsOpts) => {
  const { onAgentsClick, onWorksitesClick, onDeleteClick, onUpdateClick } =
    opts;
  const columns: ColumnDef<Bpo>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => <DataTableSortHeader {...hProps} label="BPO" />,
      cell: ({ row }) => <Typography>{row.original.name}</Typography>,
    },
    {
      accessorKey: 'agents',
      header: () => <div>Agents</div>,
      cell: ({ row }) => (
        <Button
          size="icon"
          variant="ghost"
          onClick={(evt) => {
            evt.stopPropagation();
            onAgentsClick(row.original);
          }}
        >
          <S.Users2Icon size={18} />
        </Button>
      ),
    },
    {
      accessorKey: 'worksites',
      header: () => <div>Worksites</div>,
      cell: ({ row }) => (
        <Button
          size="icon"
          variant="ghost"
          onClick={(evt) => {
            evt.stopPropagation();
            onWorksitesClick(row.original);
          }}
        >
          <S.MenuIcon size={18} />
        </Button>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => (
        <TableActions
          onDeleteClick={() => onDeleteClick(row.original)}
          onUpdateClick={() => onUpdateClick(row.original)}
        />
      ),
    },
  ];

  return { columns };
};
