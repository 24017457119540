import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { PaginatedStoreEntity, Store } from '@/@types/store';
import { actions } from '@/store/useCases/store';

export const adapter = createEntityAdapter<Store>({
  sortComparer: (s1, s2) => {
    if (s1.brand && s2.brand) {
      return s1.brand.name.localeCompare(s2.brand.name);
    }
    return s1.brandId.localeCompare(s2.brandId);
  },
});

export const adapterWithFilter = createEntityAdapter<PaginatedStoreEntity>({
  sortComparer: (s1, s2) =>
    JSON.stringify(s1.id).localeCompare(JSON.stringify(s2.id)),
});

export type State = {
  stores: EntityState<Store, string>;
  storesWithFilter: EntityState<PaginatedStoreEntity, string>;
};

export const initialState: State = {
  stores: adapter.getInitialState(),
  storesWithFilter: adapterWithFilter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/store',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.updateStore.fulfilled, (state, action) => {
      const { store } = action.payload;
      if (store.id) {
        adapter.updateOne(state.stores, {
          id: store.id,
          changes: _.omitBy(store, _.isUndefined) as Store,
        });
      }
    });
    builder.addCase(actions.batchUpdateStores.fulfilled, (state, action) => {
      const batchUpdateStores = action.payload;
      const filters = _.omitBy(action.meta.arg.filters, _.isUndefined); // clean undefined values

      const filteredStores = state.storesWithFilter.entities[
        JSON.stringify(filters)
      ]?.storeIds.map((storeId) => state.stores.entities[storeId]);

      if (filteredStores) {
        adapterWithFilter.setOne(state.storesWithFilter, {
          id: JSON.stringify(filters),
          storeIds: batchUpdateStores.map((store) => store.storeId),
        });
        adapter.updateMany(
          state.stores,
          batchUpdateStores.map((updatedStore) => ({
            id: updatedStore.storeId,
            changes: updatedStore,
          })),
        );
      }
    });
    builder.addCase(actions.getPaginatedStores.fulfilled, (state, action) => {
      const { items } = action.payload;

      if (action.meta.arg?.filters) {
        const id = JSON.stringify(action.meta.arg.filters);
        const storeIds = state.storesWithFilter.entities[id]?.storeIds;
        if (storeIds) {
          adapterWithFilter.upsertOne(state.storesWithFilter, {
            id,
            storeIds: _.uniq(storeIds.concat(items.map((item) => item.id))),
          });
        } else {
          adapterWithFilter.addOne(state.storesWithFilter, {
            id,
            storeIds: items.map((item) => item.id),
          });
        }
      }
      adapter.upsertMany(state.stores, items);
    });

    builder.addCase(actions.getStoresByBrandId.fulfilled, (state, action) => {
      adapter.upsertMany(state.stores, action.payload);
    });
    builder.addCase(actions.updateOnOff.fulfilled, (state, action) => {
      const { id, isOn } = action.payload;

      adapter.updateOne(state.stores, { changes: { isOn }, id });
    });
    builder.addCase(actions.getPromos.fulfilled, (state, action) => {
      adapter.updateOne(state.stores, {
        changes: { promos: action.payload.promos },
        id: action.payload.storeId,
      });
    });
    builder.addCase(actions.getStore.fulfilled, (state, action) => {
      const currentStore = state.stores.entities[action.payload.id];

      if (currentStore) {
        adapter.updateOne(state.stores, {
          id: action.payload.id,
          changes: _.omitBy(
            action.payload,
            (v) => _.isUndefined(v) || _.isNull(v),
          ),
        });
      } else {
        adapter.addOne(state.stores, action.payload);
      }
    });
    builder.addCase(actions.getStoreByIds.fulfilled, (state, action) => {
      adapter.upsertMany(state.stores, action.payload);
    });
    builder.addCase(actions.getStoreMenuVersions.fulfilled, (state, action) => {
      adapter.updateOne(state.stores, {
        id: action.meta.arg.storeId,
        changes: { menuVersions: action.payload },
      });
    });
    builder.addCase(actions.createStore.fulfilled, (state, action) => {
      adapter.setOne(state.stores, action.payload);
    });
    builder.addCase(actions.deleteStore.fulfilled, (state, action) => {
      adapter.removeOne(state.stores, action.payload.id);
    });
  },
});

export default slice;
