import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { Worksite } from '@/@types/worksite';
import { actions } from '@/store/useCases/worksite';

export const adapter = createEntityAdapter<Worksite>({
  sortComparer: (f1, f2) => f1.name.localeCompare(f2.name),
});

export type State = {
  worksites: EntityState<Worksite, string>;
};

export const initialState: State = {
  worksites: adapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/worksite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getWorksites.fulfilled, (state, action) => {
      adapter.upsertMany(state.worksites, action.payload);
    });
  },
});

export default slice;
