import env from '@beam-australia/react-env';
import { datadogLogs, StatusType } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: env('DD_CLIENT_TOKEN'),
  env: env('DD_ENV'),
  service: env('DD_SERVICE'),
  version: env('DD_VERSION'),
  silentMultipleInit: true,
  sessionSampleRate: 100,
});

datadogLogs.logger.setLevel((env('LOG_LEVEL') as StatusType) || 'info');
