import { dataService } from '@/services';
import { UserService } from '@/services/@types/user';

export type DeleteUser = {
  input: { id: string };
  output: { id: string };
};

export const deleteUser = async (
  input: DeleteUser['input'],
): Promise<DeleteUser['output']> => {
  await dataService.delete<UserService.User>(`/users/${input.id}`);
  return { id: input.id };
};
