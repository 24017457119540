import { useAppSelector } from '@/store';
import { selectors as brandSelectors } from '@/store/entities/brand';
import { selectors as platformSelectors } from '@/store/entities/platform';
import {
  actions as storeUiActions,
  selectors as storeUiSelectors,
} from '@/store/ui/store';
import { actions as brandUcActions } from '@/store/useCases/brand';
import { actions as platformUcActions } from '@/store/useCases/platform';
import {
  selectors as storeUcSelectors,
  actions as storeUcActions,
} from '@/store/useCases/store';

export const useConnector = () => {
  const selectors = {
    bulkCreation: useAppSelector(storeUiSelectors.getBulkCreation),
    remainingRequiredStoreFields: useAppSelector(
      storeUiSelectors.getRemainingRequiredStoreFields,
    ),
    remainingStoreFields: useAppSelector(
      storeUiSelectors.getRemainingStoreFields,
    ),
    availableStoreFields: useAppSelector(
      storeUiSelectors.getAvailableStoreFields,
    ),
    brandEntities: useAppSelector(brandSelectors.getBrandsEntities),
    platformEntities: useAppSelector(platformSelectors.getPlatformsEntities),
    creating: useAppSelector(storeUcSelectors.isUcFetching('bulkCreation')),
  };

  const actions = {
    setBulkCreationCsvDataFields: storeUiActions.setBulkCreationCsvDataFields,
    setBulkCreationIsParsing: storeUiActions.setBulkCreationIsParsing,
    setBulkCreationSelectField: storeUiActions.setBulkCreationSelectField,
    setBulkCreationSelectedFields: storeUiActions.setBulkCreationSelectedFields,
    getBrands: brandUcActions.getBrands,
    getPlatforms: platformUcActions.getPlatforms,
    bulkCreation: storeUcActions.bulkCreation,
  };

  return { actions, selectors };
};
