import { createDraftSafeSelector } from '@reduxjs/toolkit';
import isUUID from 'validator/lib/isUUID';

import { adapter } from './slice';
import { StoreState } from '../..';

export const getAgents = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.agent.agents,
).selectAll;

export const getAgentEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.agent.agents,
).selectEntities;

export const getActivedAgents = createDraftSafeSelector(getAgents, (agents) =>
  agents.filter(
    (agent) =>
      agent.status === 'ACTIVE' &&
      !!agent.twilioWorkerSid &&
      !isUUID(agent.firstName),
  ),
);
