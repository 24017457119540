import { Worksite } from '@/@types/worksite';
import { dataService } from '@/services';
import { WorksiteService } from '@/services/@types/worksite';
import { mapWorksiteToDomain } from '@/services/useCases/worksite/adapters/domain';

export type GetWorksites = {
  input: void;
  output: Worksite[];
};

export const getWorksites = async (): Promise<GetWorksites['output']> => {
  const { data } =
    await dataService.get<WorksiteService.Worksite[]>('/worksites');
  return data.filter(({ id }) => Boolean(id)).map(mapWorksiteToDomain);
};
