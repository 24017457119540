import { OngoingCall } from '@/pages/Training/TrainingTabs/OngoingCall';
import { StartCall } from '@/pages/Training/TrainingTabs/StartCall';
import { useConnector } from '@/pages/Training/connector';

export function CallTab() {
  const { selectors } = useConnector();
  const { training } = selectors;
  const { isConnected } = training;

  return isConnected ? <OngoingCall /> : <StartCall />;
}
