import { Select, Typography } from '@kea-inc/parrot-ui';

import { STORE_FIELDS } from '@/constants/store';
import { useConnector } from '@/pages/Stores/BulkCreation/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

export function CSVAssignment() {
  const dispatch = useAppDispatch();

  const { actions, selectors } = useConnector();

  const { remainingRequiredStoreFields, availableStoreFields, bulkCreation } =
    selectors;

  const { csvDataFields, selectedFields } = bulkCreation;

  const { fields } = csvDataFields;

  return (
    <S.Container>
      {remainingRequiredStoreFields.length ? (
        <>
          <Typography color="error">
            The following fields are required and have no default value:
          </Typography>
          <Typography color="error">
            {remainingRequiredStoreFields
              .map((storeField) => storeField.label)
              .join(' ,')}
          </Typography>
        </>
      ) : null}

      <S.InputsContainer>
        {fields.map((field, index) => {
          const selectedField = selectedFields[field];

          const selectedStoreField = STORE_FIELDS.find(
            (storeField) => storeField.name === selectedField,
          );

          return (
            <S.InputContainer key={`${field}-${index}`}>
              <S.Input readOnly defaultValue={field} />
              <S.MoveRightIcon />
              <S.Select
                value={selectedField ?? ''}
                onValueChange={(value) => {
                  dispatch(
                    actions.setBulkCreationSelectField({
                      [field]: value,
                    }),
                  );
                }}
              >
                {selectedField ? (
                  <Select.Item value={selectedField}>
                    {selectedStoreField?.label}{' '}
                    {selectedStoreField?.isRequired ? '*' : ''}
                  </Select.Item>
                ) : null}
                {availableStoreFields.map((storeField) => (
                  <Select.Item key={storeField.name} value={storeField.name}>
                    {storeField.label} {storeField.isRequired ? '*' : ''}
                  </Select.Item>
                ))}
              </S.Select>
            </S.InputContainer>
          );
        })}
      </S.InputsContainer>
    </S.Container>
  );
}
