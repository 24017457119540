import { useAppSelector } from '@/store';
import { selectors as agentSelectors } from '@/store/entities/agent';
import {
  actions as agentUcActions,
  selectors as agentUcSelectors,
} from '@/store/useCases/agent';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(agentUcSelectors.isUcFetching('getAgents')),
    deleting: useAppSelector(agentUcSelectors.isUcFetching('deleteAgent')),
    agents: useAppSelector(agentSelectors.getAgents),
    agentEntities: useAppSelector(agentSelectors.getAgentEntities),
  };

  const actions = {
    getAgent: agentUcActions.getAgent,
    deleteAgent: agentUcActions.deleteAgent,
    getAgents: agentUcActions.getAgents,
    getPaginatedAgents: agentUcActions.getPaginatedAgents,
  };

  return { actions, selectors };
};
