import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  margin-bottom: 2rem;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const StepContent = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1rem 0rem;
`;

export const StepTypography = styled(ParrotUi.Typography)`
  cursor: pointer;
`;

export const DialogContent = styled(ParrotUi.DialogContent)`
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StoreKeyTypography = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const List = styled.div`
  padding: 1rem 0.5rem 0.5rem 0;

  overflow-y: auto;
  width: 100%;
`;

export const ListItem = styled.div`
  border: 1px solid #eff1f3;
  padding: 0.5rem;
  font-size: 0.8rem;
`;

export const StepAvatarFallback = styled(ParrotUi.AvatarFallback)<{
  $active: boolean;
}>`
  background-color: hsl(
    var(--${(props) => (props.$active ? 'primary' : 'grey')})
  );
  color: hsl(var(--white));
`;
