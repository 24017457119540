import { Auth0Provider } from '@auth0/auth0-react';
import env from '@beam-australia/react-env';

import { AppProvider } from './Provider';
import { PagesRoutes } from './pages/routes';

function App() {
  return (
    <Auth0Provider
      domain={env('AUTH0_DOMAIN')}
      clientId={env('AUTH0_CLIENT_ID')}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: env('AUTH0_AUDIENCE'),
      }}
    >
      <AppProvider>
        <PagesRoutes />
      </AppProvider>
    </Auth0Provider>
  );
}

export default App;
