import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import * as GlobalStyles from '@/styles';

export const SectionContainer = GlobalStyles.SectionContainer;

export const SectionTitle = GlobalStyles.SectionTitle;

export const FormFieldContainer = GlobalStyles.FormFieldContainer;

export const FormItemLabelContainer = GlobalStyles.FormItemLabelContainer;

export const FormItem = styled(ParrotUi.FormItem)`
  flex: 1;
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

export const AddDeleteButton = styled(ParrotUi.Button)`
  margin-top: 0px !important;
`;

export const ToggleContainer = styled.div`
  border: 1px solid hsl(var(--border));
  display: flex;
`;

export const Toggle = styled(ParrotUi.Toggle)`
  flex: 1;
  border-radius: 0px;
`;
