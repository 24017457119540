import { useEffect } from 'react';
import { useSocketEvent } from 'socket.io-react-hook';

import { UseSocketReturnType } from '@/@types';
import { ConnectionsData, MetricsData } from '@/@types/metrics';
import { useInterval } from '@/hooks/useInterval';
import { useNotification } from '@/hooks/useNotification';
import { safeGetEnvNumber } from '@/utils/safeGetEnvNumber';

export function useMetricsSocket(
  socketConfig: Partial<UseSocketReturnType<any>>,
  params?: {
    pastMinutes?: number;
  },
) {
  const { socket = {}, error = null, connected = false } = socketConfig;

  const pastMinutes = params?.pastMinutes ?? 10;
  const notification = useNotification();

  const { lastMessage: metrics, sendMessage: requestMetrics } =
    useSocketEvent<MetricsData>(socket, 'metrics');

  const { lastMessage: connections, sendMessage: requestConnections } =
    useSocketEvent<ConnectionsData[]>(socket, 'connections');

  useInterval(
    () => {
      requestMetrics(pastMinutes);
    },
    safeGetEnvNumber('METRICS_REQUEST_INTERVAL_IN_SECONDS', 15) * 1000,
  );

  useEffect(() => {
    if (connected) {
      requestMetrics(pastMinutes);
      requestConnections(null);
      notification.info('Successfully connected to the server', {
        autoClose: true,
        description: 'You are now receiving updates on every minute',
      });
    }
  }, [connected]);

  useEffect(() => {
    requestMetrics(pastMinutes);
  }, [pastMinutes]);

  useEffect(() => {
    if (error) {
      notification.error('Failed to connect to the server', {
        autoClose: false,
        description: 'You are no longer receiving updates',
      });
    }
  }, [error]);

  useEffect(
    () => () => {
      socket.disconnect();
    },
    [],
  );

  return {
    metrics,
    requestMetrics,
    connections,
    connected,
    error,
    socket,
  };
}
