import styled from 'styled-components';

export const TextLeft = styled.div`
  text-align: left;
`;

export const TextRight = styled.div`
  text-align: right;
`;
export const TextCenter = styled.div`
  text-align: center;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
