import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.div``;

export const RecordingContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const RecordingHeaderContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: 0.25rem;
`;

export const Audio = styled.audio`
  width: 100%;
`;

export const TrackerInputContainer = styled.div`
  flex: 1;
  display: flex;
`;
export const TrackerInput = styled.input`
  flex: 1;
`;

export const DownloadIconButton = styled(ParrotUi.Button).attrs({
  size: 'icon',
  variant: 'ghost',
})`
  width: 24px;
  height: 24px;
`;

export const RecordingIconButton = styled(ParrotUi.Button).attrs({
  size: 'icon',
  variant: 'ghost',
})`
  width: 32px;
  height: 32px;
`;

export const Select = styled(ParrotUi.Select)`
  height: 2rem;
  flex: 0.2;
`;
export const SelectItem = styled(ParrotUi.Select.Item)`
  white-space: pre-wrap;
`;
