import { useAppSelector } from '@/store';
import { selectors as roleSelectors } from '@/store/entities/role';
import {
  actions as roleUcActions,
  selectors as roleUcSelectors,
} from '@/store/useCases/role';

const isFetchingUcs = [
  roleUcSelectors.isUcFetching('getPermissions'),
  roleUcSelectors.isUcFetching('getRole'),
  roleUcSelectors.isUcFetching('getRoles'),
];

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector((state) =>
      isFetchingUcs.map((ucSelector) => ucSelector(state)).some(Boolean),
    ),
    updating: useAppSelector(roleUcSelectors.isUcFetching('updateRole')),
    roles: useAppSelector(roleSelectors.getRoles),
    roleEntities: useAppSelector(roleSelectors.getRoleEntities),
    permissions: useAppSelector(roleSelectors.getPermissions),
  };

  const actions = {
    getRoles: roleUcActions.getRoles,
    getRole: roleUcActions.getRole,
    updateRole: roleUcActions.updateRole,
    getPermissions: roleUcActions.getPermissions,
  };

  return { actions, selectors };
};
