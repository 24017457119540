import { Button, ColumnDef, DataTableSortHeader } from '@kea-inc/parrot-ui';
import { CopyIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Fleet } from '@/@types/fleet';
import { FlexCenter, TextCenter } from '@/components/DataTable';
import { TableActions } from '@/components/TableActions';

import * as S from './styles';

type UseFleetsColumnDefsOpts = {
  onFleetUsersClick: (fleet: Fleet) => void;
  onFleetStoresClick: (fleet: Fleet) => void;
  onDeleteClick: (fleet: Fleet) => void;
};

export const useFleetsColumnDefs = (opts: UseFleetsColumnDefsOpts) => {
  const { onDeleteClick, onFleetStoresClick, onFleetUsersClick } = opts;

  const navigate = useNavigate();

  const handleCopyIDToClipboard = (fleet: Fleet) => {
    navigator.clipboard.writeText(fleet.id);
  };

  const columns: ColumnDef<Fleet>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Name" isMulti />
      ),
      cell: ({ row }) => <S.EmailCell>{row.original.name}</S.EmailCell>,
    },
    {
      accessorKey: 'accountManager',
      header: () => <div>Account Manager</div>,
      cell: ({ row }) => <div>{row.original.accountManager}</div>,
    },
    {
      accessorKey: 'accountManagerEmail',
      header: () => <div>Account Manager Email</div>,
      cell: ({ row }) => <div>{row.original.accountManagerEmail}</div>,
    },
    {
      accessorKey: 'usersInFleet',
      header: () => <TextCenter>Users in fleet</TextCenter>,
      cell: ({ row }) => (
        <FlexCenter>
          <Button
            size="icon"
            variant="ghost"
            onClick={(evt) => {
              evt.stopPropagation();
              onFleetUsersClick(row.original);
            }}
          >
            <S.Users2Icon size={18} />
          </Button>
        </FlexCenter>
      ),
    },
    {
      accessorKey: 'stores',
      header: () => <TextCenter>Stores</TextCenter>,
      cell: ({ row }) => (
        <FlexCenter>
          <Button
            size="icon"
            variant="ghost"
            onClick={(evt) => {
              evt.stopPropagation();
              onFleetStoresClick(row.original);
            }}
          >
            <S.UtensilsIcon size={18} />
          </Button>
        </FlexCenter>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => (
        <TableActions
          dropDownMenuLabel={row.original.name}
          onUpdateClick={(evt) => {
            evt.stopPropagation();
            navigate(`/fleets/${row.original.id}`);
          }}
          onDeleteClick={(evt) => {
            evt.stopPropagation();
            onDeleteClick(row.original);
          }}
          dropdownMenuItems={[
            {
              label: 'Copy ID',
              Icon: <CopyIcon />,
              onClick: (evt) => {
                evt.stopPropagation();
                handleCopyIDToClipboard(row.original);
              },
            },
          ]}
        />
      ),
    },
  ];
  return { columns };
};
