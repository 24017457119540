import axios, { AxiosInstance, AxiosResponse } from 'axios';

export function addMissingJwtRetry(instance: AxiosInstance) {
  instance.interceptors.response.use(onSuccess, onError);
}

function onSuccess(response: AxiosResponse<any>) {
  return response;
}

function onError(error: any) {
  const status = error.response?.status;

  if (status === 403) {
    const token = localStorage.getItem('@kea-inc/ops-console/token');

    return axios({
      ...error.config,
      headers: {
        ...error.config.headers,
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return Promise.reject(error);
}
