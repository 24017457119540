import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Typography } from '@kea-inc/parrot-ui';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { PageCard } from '@/components/PageCard';
import { CSVAssignment } from '@/pages/Stores/BulkCreation/CSVAssignment';
import { ManualAssignment } from '@/pages/Stores/BulkCreation/ManualAssignment';
import {
  ManualAssignmentSchema,
  manualAssignmentSchema,
} from '@/pages/Stores/BulkCreation/ManualAssignment/schema';
import { Review } from '@/pages/Stores/BulkCreation/Review';
import { Step } from '@/pages/Stores/BulkCreation/Step';
import { Summary } from '@/pages/Stores/BulkCreation/Summary';
import { UploadCSV } from '@/pages/Stores/BulkCreation/UploadCSV/UploadCSV';
import {
  mapStoreDataFieldsAndFormValuesToStore,
  mapStoreFieldsToFormValues,
} from '@/pages/Stores/BulkCreation/adapter/form';
import { useBulkCreation } from '@/pages/Stores/BulkCreation/useBulkCreation';
import { isValidForm } from '@/utils/form';

import * as S from './styles';

const STEP_TO_TITLE: Record<number, string> = {
  0: '1. Upload a CSV file with the stores data.',
  1: '2. Assign the fields of the CSV file to the fields of the store.',
  2: '3. Manually assign value to fields.',
  3: '4. Review the data of the stores that will be created.',
  4: '5. Summary of the batch operation.',
};

export function BulkCreation() {
  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const {
    csvDataFields,
    isParsing,
    remainingRequiredStoreFields,
    remainingStoreFields,
    bulkCreationSubmit,
    creating,
  } = useBulkCreation({
    csvFile,
  });

  const handleStepClick = (currentStep: number) => {
    if (currentStep < step) {
      setStep(currentStep);
    }
  };

  const form = useForm<ManualAssignmentSchema>({
    resolver: zodResolver(manualAssignmentSchema),
    defaultValues: mapStoreFieldsToFormValues(remainingStoreFields),
  });

  const stores = useMemo(
    () =>
      mapStoreDataFieldsAndFormValuesToStore(
        csvDataFields.data,
        form.getValues(),
      ),
    [csvDataFields.data, form.getValues()],
  );

  const getCurrentStepContent = () => {
    const stepToContent: Record<typeof step, JSX.Element> = {
      0: <UploadCSV csvFile={csvFile} onUpload={setCsvFile} />,
      1: <CSVAssignment />,
      2: (
        <Form {...form}>
          <ManualAssignment form={form} />
        </Form>
      ),
      3: <Review stores={stores} />,
      4: <Summary stores={stores} />,
    };
    return stepToContent[step];
  };

  const handleNext = () => {
    if (step === 4) {
      navigate('/stores');
    }
    if (step === 3) {
      setOpenConfirmation(true);
    } else {
      setStep(step + 1);
    }
  };

  const canGoNextStep = () => {
    const canGo: Record<typeof step, boolean> = {
      0: !!csvFile,
      1: !remainingRequiredStoreFields.length,
      2: isValidForm(form),
      3: true,
      4: true,
    };

    return canGo[step];
  };

  const handleBulkCreationSubmit = () => {
    bulkCreationSubmit(stores).then(() => {
      setStep(step + 1);
      setOpenConfirmation(false);
    });
  };

  const getNextButtonLabel = () => {
    const stepToButton: Record<number, string> = {
      0: `Configure ${csvFile?.name ?? ''}`,
      1: 'Next',
      2: 'Next',
      3: 'Next',
      4: 'Back to stores',
    };
    return stepToButton[step];
  };

  return (
    <PageCard title="Bulk stores creation">
      <S.StepsContainer>
        <Step
          label="Upload CSV"
          currentStep={step}
          step={0}
          onClick={handleStepClick}
        />
        <Step
          label="CSV Assignment"
          currentStep={step}
          step={1}
          onClick={handleStepClick}
        />
        <Step
          label="Manual Assignment"
          currentStep={step}
          step={2}
          onClick={handleStepClick}
        />
        <Step
          label="Review"
          currentStep={step}
          step={3}
          onClick={handleStepClick}
        />
        <Step
          label="Summary"
          currentStep={step}
          step={4}
          onClick={handleStepClick}
        />
      </S.StepsContainer>

      <Typography size="lg">Create a batch of stores</Typography>
      <Typography muted>{STEP_TO_TITLE[step] ?? ''}</Typography>
      <S.StepContent>{getCurrentStepContent()}</S.StepContent>
      <Button
        onClick={handleNext}
        loading={isParsing}
        disabled={!canGoNextStep()}
      >
        {getNextButtonLabel()}
      </Button>
      <ConfirmationDialog
        description={
          <Typography>
            Are you sure you want to create {stores.length} store(s)?
          </Typography>
        }
        open={openConfirmation}
        onOpenChange={setOpenConfirmation}
        onSubmit={handleBulkCreationSubmit}
        loading={creating}
        variant="success"
        confirmationLabel="Yes, create"
      />
    </PageCard>
  );
}
