import { SoundBoardEntry } from '@/@types/menu';
import { MenuService } from '@/services/@types/menu';

export const mapGlobalSoundboardEntryToDomain = (
  entry: MenuService.SoundboardEntry,
): SoundBoardEntry => ({ ...entry, type: 'global' });

export const mapBrandSoundboardEntryToDomain =
  (brandId: string) =>
  (entry: MenuService.SoundboardEntry): SoundBoardEntry => ({
    ...entry,
    type: 'brand',
    brandId,
  });

export const mapStoreSoundboardEntryToDomain =
  (storeId: string) =>
  (entry: MenuService.SoundboardEntry): SoundBoardEntry => ({
    ...entry,
    type: 'store',
    storeId,
  });
