import {
  FormControl,
  FormField,
  FormMessage,
  Input,
  Label,
} from '@kea-inc/parrot-ui';
import { MinusIcon, PlusIcon, Volume2Icon } from 'lucide-react';
import { useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

import * as S from './styles';

interface SpecialsFormFieldProps {
  form: UseFormReturn<StoreSchema>;
}

export function SpecialsFormField(props: SpecialsFormFieldProps) {
  const { form } = props;
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'specials',
  });

  const [newSpecial, setNewSpecial] = useState('');

  function handleTextToSpeech(text: string) {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  }

  return (
    <S.SpecialsContainer>
      <Label>Specials</Label>
      <S.NewSpecialContainer>
        <Input
          value={newSpecial}
          onChange={(evt) => setNewSpecial(evt.target.value)}
        />
        <S.SpeechButton
          variant="ghost"
          size="icon"
          onClick={() => handleTextToSpeech(newSpecial)}
          type="button"
        >
          <Volume2Icon />
        </S.SpeechButton>
        <S.AddDeleteButton
          variant="ghost"
          disabled={!newSpecial}
          size="icon"
          type="button"
          onClick={() => {
            const field = fields.find((f) => f.value === newSpecial);
            if (!field) {
              append({ value: newSpecial });
              setNewSpecial('');
            }
          }}
        >
          <PlusIcon />
        </S.AddDeleteButton>
      </S.NewSpecialContainer>

      {fields.map((item, index) => {
        return (
          <S.FormFieldContainer key={item.value}>
            <FormField
              control={form.control}
              name={`specials.${index}.value`}
              render={({ field }) => (
                <S.FormItem>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <S.SpeechButton
                    variant="ghost"
                    type="button"
                    size="icon"
                    onClick={() => handleTextToSpeech(field.value)}
                  >
                    <Volume2Icon />
                  </S.SpeechButton>
                  <S.AddDeleteButton
                    variant="ghost"
                    size="icon"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <MinusIcon />
                  </S.AddDeleteButton>
                  <FormMessage />
                </S.FormItem>
              )}
            />
          </S.FormFieldContainer>
        );
      })}
    </S.SpecialsContainer>
  );
}
