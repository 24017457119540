import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Typography } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { PermissionsFormItem } from '@/pages/Admin/AdminForm/PermissionsFormItem';
import {
  RoleSchema,
  roleSchema,
} from '@/pages/Admin/AdminForm/RoleForm/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

export function RoleForm() {
  const navigate = useNavigate();
  const notification = useNotification();
  const { roleName = '' } = useParams<{ roleName: string }>();
  const dispatch = useAppDispatch();

  const { actions, selectors } = useConnector();
  const { permissions, roleEntities, fetching, updating } = selectors;

  useEffect(() => {
    if (roleName) {
      dispatch(actions.getRole({ name: roleName }));
    }
  }, [roleName]);

  useEffect(() => {
    dispatch(actions.getPermissions());
  }, []);

  const role = roleEntities[roleName];

  const form = useForm<RoleSchema>({
    resolver: zodResolver(roleSchema),
    defaultValues: { permissions: [] },
  });

  useEffect(() => {
    if (role)
      form.reset({
        permissions: role.permissions ?? [],
      });
  }, [role]);

  if (fetching) {
    return <KeaLoading />;
  }
  if (!role) {
    return null;
  }

  const handleSuccessSave = () => {
    notification.success('Success', {
      description: getCreateUpdateDescription('Role'),
    });
    navigate('/admin');
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    dispatch(actions.updateRole({ ...role, permissions: values.permissions }))
      .unwrap()
      .then(handleSuccessSave);
  };

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <S.PageCard
          backButton={<BackButton onClick={() => navigate('/admin')} />}
          title={role.name}
          footerContent={
            <ProtectedComponent allowedPermissions={['write:roles']}>
              <UpdateCreateButton type="submit" loading={updating} />
            </ProtectedComponent>
          }
        >
          <Typography>Set the permissions for this role.</Typography>

          <PermissionsFormItem
            form={form}
            permissions={permissions}
            type="role"
          />
        </S.PageCard>
      </S.Form>
    </Form>
  );
}
