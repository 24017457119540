import { Toast, Typography } from '@kea-inc/parrot-ui';

import { actions as notificationActions } from '@/store/ui/notification';

import { useAppDispatch, useAppSelector } from '../store';
import { getNotificationState } from '../store/ui/notification/selectors';

export const getCreateUpdateDescription = (name: string) =>
  `${name} created/updated successfully!`;

type NotificationProps = {
  description?: string;
  autoClose?: boolean;
};

type NotificationController = {
  success: (title: string, props?: NotificationProps) => void;
  error: (title: string, props?: NotificationProps) => void;
  info: (title: string, props?: NotificationProps) => void;
};

export function ToastNotification() {
  const { open, title, description, autoClose } =
    useAppSelector(getNotificationState);

  const dispatch = useAppDispatch();

  const handleOpenChange = (opn: boolean) => {
    dispatch(notificationActions.setOpen(opn));
  };

  return (
    <Toast
      description={<Typography>{description}</Typography>}
      title={title}
      open={open}
      onOpenChange={handleOpenChange}
      showClose
      swipeDirection="right"
      duration={!autoClose ? Infinity : undefined}
    />
  );
}

export function useNotification() {
  const dispatch = useAppDispatch();

  const notification: NotificationController = {
    success: (text, props) => {
      dispatch(notificationActions.setOpen(true));
      dispatch(notificationActions.setAutoClose(props?.autoClose ?? true));
      dispatch(
        notificationActions.setTitleDescription({
          title: text,
          description: props?.description,
        }),
      );
    },
    error: (text, props) => {
      dispatch(notificationActions.setOpen(true));
      dispatch(notificationActions.setAutoClose(props?.autoClose ?? true));
      dispatch(
        notificationActions.setTitleDescription({
          title: text,
          description: props?.description,
        }),
      );
    },
    info: (text, props) => {
      dispatch(notificationActions.setOpen(true));
      dispatch(notificationActions.setAutoClose(props?.autoClose ?? true));
      dispatch(
        notificationActions.setTitleDescription({
          title: text,
          description: props?.description,
        }),
      );
    },
  };

  return notification;
}
