import { CompressedMenu, CompressedMenuParser } from '@kea-inc/menu';

import { Order } from '@/@types/order';
import { OrderItem, OrderMenuParsedItem } from '@/@types/order/item';
import { Store } from '@/@types/store';
import { OrderService } from '@/services/@types/order';
import { menuService, orderService } from '@/services/clients';
import { mapOrderMenuItemsToDomain } from '@/services/useCases/order/adapters/domain/items';

type GetCompressedMenuItems = {
  input: { orderItems: OrderItem[]; storeId: string; menuVersion: string };
  output: { menuParser: CompressedMenuParser };
};

const getCompressedMenuItems = async (
  input: GetCompressedMenuItems['input'],
): Promise<GetCompressedMenuItems['output']> => {
  const { orderItems, storeId, menuVersion } = input;
  const menuServiceUrl = `/menus/${storeId}/version/${encodeURIComponent(
    menuVersion,
  )}/selections`;
  const { data: compressedMenu } = await menuService.post<CompressedMenu>(
    menuServiceUrl,
    {
      selections:
        orderItems.map((item) => ({
          itemId: item.menuItemId,
          optionIds: item.options?.map((option) => option.id) ?? [],
        })) ?? [],
    },
  );

  const menuParser = new CompressedMenuParser(compressedMenu);
  return { menuParser };
};

export type GetOrderMenuItems = {
  input: { order: Order; store: Store };
  output: OrderMenuParsedItem[];
};

export const getOrderMenuItems = async (
  input: GetOrderMenuItems['input'],
): Promise<GetOrderMenuItems['output']> => {
  const { id } = input.order;
  const { menuVersion, id: storeId } = input.store;

  const { data: orderItems } =
    await orderService.get<OrderService.OrderMenuItemsResponse>(
      `/orders/${id}/items`,
    );

  const { menuParser } = await getCompressedMenuItems({
    menuVersion,
    storeId,
    orderItems: orderItems.items,
  });

  return mapOrderMenuItemsToDomain(menuParser, orderItems.items);
};
