import {
  MenuCategory,
  MenuItem,
  MenuModifier,
  MenuOption,
} from '@/@types/menu';
import {
  GetBrandMenuCategoriesQueryVariables,
  GetBrandMenuItemByCategoryQueryVariables,
  GetBrandMenuModifierFromParentQueryVariables,
  GetBrandMenuOptionFromParentQueryVariables,
} from '@/graphql/schema';
import { graphqlSDK } from '@/services/clients/menuService';
import {
  mapBrandCategoriesToDomain,
  mapBrandItemsToDomain,
  mapBrandModifiersToDomain,
  mapBrandOptionsToDomain,
} from '@/services/useCases/menu/adapters/domain';

type GetBrandCategoriesInput = GetBrandMenuCategoriesQueryVariables;

export type GetBrandCategories = {
  input: GetBrandCategoriesInput;
  output: MenuCategory[];
};

export const getBrandCategories = async (
  input: GetBrandCategories['input'],
): Promise<GetBrandCategories['output']> => {
  const result = await graphqlSDK.GetBrandMenuCategories(input);

  return mapBrandCategoriesToDomain(result);
};

type GetBrandItemsInput = GetBrandMenuItemByCategoryQueryVariables;

export type GetBrandItems = {
  input: GetBrandItemsInput;
  output: MenuItem[];
};

export const getBrandItems = async (
  input: GetBrandItems['input'],
): Promise<GetBrandItems['output']> => {
  const result = await graphqlSDK.GetBrandMenuItemByCategory(input);

  return mapBrandItemsToDomain(result);
};

type GetBrandModifiersInput = GetBrandMenuModifierFromParentQueryVariables;

export type GetBrandModifiers = {
  input: GetBrandModifiersInput;
  output: MenuModifier[];
};

export const getBrandModifiers = async (
  input: GetBrandModifiers['input'],
): Promise<GetBrandModifiers['output']> => {
  const result = await graphqlSDK.GetBrandMenuModifierFromParent(input);

  return mapBrandModifiersToDomain(result);
};

type GetBrandOptionsInput = GetBrandMenuOptionFromParentQueryVariables;

export type GetBrandOptions = {
  input: GetBrandOptionsInput;
  output: MenuOption[];
};

export const getBrandOptions = async (
  input: GetBrandOptions['input'],
): Promise<GetBrandOptions['output']> => {
  const result = await graphqlSDK.GetBrandMenuOptionFromParent(input);

  return mapBrandOptionsToDomain(result);
};
