import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { PaginatedOrdersFilters } from '@/@types/order/paginatedOrder';
import {
  mapOrderFiltersToSearchParams,
  mapOrderSearchParamsToOrderFilters,
} from '@/pages/Orders/List/adapters/domain';
import {
  OrderFilterSchema,
  orderFilterSchema,
} from '@/pages/Orders/List/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from '../connector';

const orderFiltersSchemaDefaultValues: PaginatedOrdersFilters = {
  orderId: undefined,
  accountId: undefined,
  accountPhone: undefined,
  storeId: undefined,
  agentId: undefined,
  handoffMode: undefined,
  status: undefined,
  paymentType: undefined,
  brandId: undefined,
  orderApplication: undefined,
};

export const useFiltersForm = () => {
  const dispatch = useAppDispatch();

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { actions, selectors } = useConnector();

  const { filters } = selectors;

  const form = useForm<OrderFilterSchema>({
    resolver: zodResolver(orderFilterSchema),
    defaultValues: orderFiltersSchemaDefaultValues,
  });

  useEffect(() => {
    if (filters) {
      form.reset({
        ...filters,
      });
    }
  }, [filters]);

  useEffect(() => {
    if (searchParams.size) {
      dispatch(
        actions.setFilters(mapOrderSearchParamsToOrderFilters(searchParams)),
      );
    }
  }, [searchParams]);

  const handleFinallySubmit = (values: OrderFilterSchema) => {
    setFilterOpen(false);
    dispatch(actions.setFilters(values));
    if (values) {
      setSearchParams(mapOrderFiltersToSearchParams(values));
    }
  };

  const handleClearFilters = () => {
    form.reset(orderFiltersSchemaDefaultValues);
    dispatch(actions.setFilters(undefined));
    setSearchParams(undefined);
  };

  return {
    form,
    filterOpen,
    setFilterOpen,
    searchParams,
    handleFinallySubmit,
    handleClearFilters,
  };
};
