import {
  FormField,
  Input,
  Label,
  Select,
  FormItemInput,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { MinusIcon, PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { Brand } from '@/@types/brand';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';
import { BrandUpsellSchema } from '@/pages/Brands/BrandForm/schema/upsell';

import * as S from './styles';

const initUpsell: BrandUpsellSchema = {
  id: '',
  chainIds: '',
  phrase: '',
  priority: 0,
  promoId: '',
  quantity: 0,
  isNew: true,
};

interface UpsellProps {
  form: UseFormReturn<BrandSchema>;
  brand: Brand;
  id: HTMLDivElement['id'];
}

export function Upsells(props: UpsellProps) {
  const { form, brand, id } = props;
  const [newUpsell, setNewUpsell] = useState<BrandUpsellSchema>(initUpsell);

  const { fields, prepend, update } = useFieldArray({
    control: form.control,
    name: 'upsells',
    keyName: 'customId',
  });

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Add Upsells</S.SectionTitle>
      <S.NewFieldContainer>
        <S.InputContainer>
          <Label>Chain Ids</Label>
          <Input
            value={newUpsell.chainIds}
            onChange={(evt) =>
              setNewUpsell((pNewUpsell) => ({
                ...pNewUpsell,
                chainIds: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.InputContainer>
          <Label>Phrase</Label>
          <Input
            value={newUpsell.phrase}
            onChange={(evt) =>
              setNewUpsell((pNewUpsell) => ({
                ...pNewUpsell,
                phrase: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.SelectContainer>
          <Select
            value={newUpsell.promoId}
            label="Promo"
            onValueChange={(value) =>
              setNewUpsell((pNewPlatform) => ({
                ...pNewPlatform,
                promoId: value,
              }))
            }
          >
            {brand.promos?.map((promo) => (
              <Select.Item value={promo.id ?? ''} key={promo.id}>
                {promo.name}
              </Select.Item>
            ))}
          </Select>
        </S.SelectContainer>
        <S.QuantityPriorityContainer>
          <Label>Quantity</Label>
          <Input
            value={newUpsell.quantity}
            onChange={(evt) =>
              setNewUpsell((pNewUpsell) => ({
                ...pNewUpsell,
                quantity: Number(evt.target.value),
              }))
            }
          />
        </S.QuantityPriorityContainer>
        <S.QuantityPriorityContainer>
          <Label>Priority</Label>
          <Input
            value={newUpsell.priority}
            onChange={(evt) =>
              setNewUpsell((pNewUpsell) => ({
                ...pNewUpsell,
                priority: Number(evt.target.value),
              }))
            }
          />
        </S.QuantityPriorityContainer>
        <S.AddDeleteButton
          variant="ghost"
          disabled={!newUpsell.chainIds}
          type="button"
          size="icon"
          onClick={() => {
            const field = fields.find((f) => f.chainIds === newUpsell.chainIds);
            if (!field) {
              prepend(newUpsell);
              setNewUpsell(initUpsell);
            } else {
              if (field.deleted) {
                const index = fields.indexOf(field);
                update(index, newUpsell);
                setNewUpsell(initUpsell);
              }
            }
          }}
        >
          <PlusIcon />
        </S.AddDeleteButton>
      </S.NewFieldContainer>
      <S.FieldArrayContainer>
        {fields
          .filter((field) => !field.deleted)
          .map((fieldItem) => {
            const index = fields.findIndex(
              (fldItem) => fldItem.customId === fieldItem.customId,
            );
            return (
              <S.FieldArrayItemContainer key={fieldItem.customId}>
                <FormField
                  control={form.control}
                  name={`upsells.${index}.chainIds`}
                  render={({ field }) => <FormItemInput field={field} />}
                />
                <FormField
                  control={form.control}
                  name={`upsells.${index}.phrase`}
                  render={({ field }) => <FormItemInput field={field} />}
                />
                <FormField
                  control={form.control}
                  name={`upsells.${index}.promoId`}
                  render={({ field }) => (
                    <FormItemSelect field={field}>
                      {brand.promos?.map((promo) => (
                        <Select.Item value={promo.id ?? ''} key={promo.id}>
                          {promo.name}
                        </Select.Item>
                      )) ?? []}
                    </FormItemSelect>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`upsells.${index}.quantity`}
                  render={({ field }) => (
                    <FormItemInput field={field} style={{ flex: 0.3 }} />
                  )}
                />
                <FormField
                  control={form.control}
                  name={`upsells.${index}.priority`}
                  render={({ field }) => (
                    <FormItemInput field={field} style={{ flex: 0.3 }} />
                  )}
                />
                <S.AddDeleteButton
                  variant="ghost"
                  size="icon"
                  onClick={() =>
                    update(index, {
                      ...fieldItem,
                      deleted: true,
                    })
                  }
                >
                  <MinusIcon />
                </S.AddDeleteButton>
              </S.FieldArrayItemContainer>
            );
          })}
      </S.FieldArrayContainer>
    </S.SectionContainer>
  );
}
