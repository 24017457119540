import { Store } from '@/@types/store';

export const STORE_HANDOFF_MODES = [
  'pickup',
  'delivery',
  'dinein',
  'curbside',
] as const;

export const INIT_TRIAGE_HANDOFF_METHODS: Exclude<
  Store['triageHandoffMethods'],
  undefined
> = {
  curbside: false,
  delivery: false,
  dinein: false,
  pickup: false,
} as const;
