import { ColumnDef, DataTableSortHeader, Typography } from '@kea-inc/parrot-ui';
import { CopyIcon } from 'lucide-react';

import { User } from '@/@types/user';
import { TextCenter } from '@/components/DataTable';
import { TableActions } from '@/components/TableActions';
import { RolesColumn } from '@/pages/Admin/List/Users/RolesColumn';

type UseUsersColumnDefsOpts = {
  onUpdateClick: (user: User) => void;
  onDeleteClick: (user: User) => void;
};

export const useUsersColumnDefs = (opts: UseUsersColumnDefsOpts) => {
  const { onDeleteClick, onUpdateClick } = opts;

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Name" />,
      cell: ({ row }) => (
        <Typography>
          {`${row.original.firstName} ${row.original.lastName}`}
        </Typography>
      ),
    },
    {
      accessorKey: 'roles',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Name" />,
      cell: ({ row }) => <RolesColumn user={row.original} />,
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => {
        const handleCopy = (value: string) => () => {
          navigator.clipboard.writeText(value);
        };
        return (
          <TableActions
            onDeleteClick={() => onDeleteClick(row.original)}
            onUpdateClick={() => onUpdateClick(row.original)}
            dropDownMenuLabel={row.original.firstName}
            dropdownMenuItems={[
              {
                Icon: <CopyIcon />,
                label: 'Copy ID',
                onClick: handleCopy(row.original.id),
              },
              {
                Icon: <CopyIcon />,
                label: 'Copy e-mail',
                onClick: handleCopy(row.original.email),
              },
              {
                Icon: <CopyIcon />,
                label: 'Copy auth0 ID',
                onClick: handleCopy(row.original.auth0UserId),
              },
            ]}
          />
        );
      },
    },
  ];

  return { columns };
};
