import { ColumnDef } from '@kea-inc/parrot-ui';
import { CopyIcon, ListIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Account } from '@/@types/account';
import { Copy } from '@/components/Copy';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import { TableActions } from '@/components/TableActions';
import { useNotification } from '@/hooks/useNotification';
import { useConnector } from '@/pages/Accounts/connector';
import { formatUUID } from '@/utils/uuid';

import * as S from './styles';

export const useAccountsColumnDefs = () => {
  const navigate = useNavigate();
  const notification = useNotification();

  const { selectors } = useConnector();

  const { accountEntities } = selectors;

  const handleCopyIDToClipboard = (account: Account) => {
    navigator.clipboard.writeText(account.id);
    notification.success('', { description: 'Text copied to clipboard!' });
  };

  const columns = useMemo<ColumnDef<Account>[]>(
    () => [
      {
        accessorKey: 'id',
        header: () => <div>ID</div>,
        cell: ({ row }) => (
          <Copy value={row.original.id}>{formatUUID(row.original.id)}</Copy>
        ),
      },
      {
        accessorKey: 'accountId',
        header: () => <div>Caller</div>,
        cell: ({ row }) => {
          const account = accountEntities?.[row.original.id];
          return (
            <ProtectedComponent allowedPermissions={['read:account-details']}>
              <S.CallerCellTypography>
                {account?.firstName} {account?.lastName}
              </S.CallerCellTypography>
            </ProtectedComponent>
          );
        },
      },
      {
        accessorKey: 'phone',
        header: () => <div>Phone</div>,
        cell: ({ row }) => {
          const account = accountEntities?.[row.original.id];
          return (
            <ProtectedComponent allowedPermissions={['read:account-details']}>
              <S.CallerCellTypography>{account?.phone}</S.CallerCellTypography>
            </ProtectedComponent>
          );
        },
      },
      {
        accessorKey: 'actions',
        header: () => <div>Actions</div>,
        cell: ({ row }) => {
          return (
            <S.ActionsCell>
              <TableActions
                dropDownMenuLabel={formatUUID(row.original.id)}
                dropdownMenuItems={[
                  {
                    Icon: <ListIcon />,
                    label: 'See orders',
                    onClick: () =>
                      navigate(`/orders?accountId=${row.original.id}`),
                  },
                  {
                    label: 'Copy ID',
                    Icon: <CopyIcon />,
                    onClick: (evt) => {
                      evt.stopPropagation();
                      handleCopyIDToClipboard(row.original);
                    },
                  },
                ]}
              />
            </S.ActionsCell>
          );
        },
      },
    ],
    [accountEntities],
  );

  return { columns };
};
