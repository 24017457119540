import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const List = styled.ul`
  padding: 1rem 0.5rem 0.5rem 0;

  overflow-y: auto;
  flex: 1 1 0px;
`;

export const DialogContent = styled(ParrotUi.DialogContent)`
  min-height: 70%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.li`
  border: 1px solid hsl(var(--border));
  padding: 0.25rem;
`;
