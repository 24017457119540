import { Button, DataTable, Typography } from '@kea-inc/parrot-ui';
import _ from 'lodash';
import { FilterIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Store } from '@/@types/store';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard/PageCard';
import { useNotification } from '@/hooks/useNotification';
import { BatchUpdateMenu } from '@/pages/Stores/List/BatchUpdateMenu';
import { mapSearchParamsToPaginatedStoreFilters } from '@/pages/Stores/List/adapters';
import { StoreFilterSchema } from '@/pages/Stores/List/schema';
import { useFiltersForm } from '@/pages/Stores/List/useFiltersForm';
import { useAppDispatch } from '@/store';

import { FilterDialog } from './FilterDialog';
import { useStoresColumnDefs } from './useStoresColumnDefs';
import { useConnector } from '../connector';

type ConfirmationState = {
  store: Store | null;
};
type DeleteState = {
  store: Store | null;
};

const initDeleteDialog: DeleteState = {
  store: null,
};

const pageSize = 15;

export const List = () => {
  const [lastPage, setLastPage] = useState(1);
  const [syncConfirmation, setSyncConfirmation] = useState<ConfirmationState>({
    store: null,
  });
  const [deleteDialog, setDeleteDialog] =
    useState<DeleteState>(initDeleteDialog);

  const { actions, selectors } = useConnector();
  const {
    fetching,
    totalRecords,
    syncing,
    deleting,
    storesWithFilter,
    filters,
  } = selectors;

  const dispatch = useAppDispatch();
  const notification = useNotification();
  const navigate = useNavigate();

  const {
    filterOpen,
    searchParams,
    form,
    handleFinallySubmit,
    setFilterOpen,
    handleClearFilters,
  } = useFiltersForm();

  const { columns } = useStoresColumnDefs({
    onSyncMenu: (store) => setSyncConfirmation({ store }),
    onDeleteClick: (store) => {
      setDeleteDialog({ store });
    },
  });

  const stores = filters ? storesWithFilter ?? [] : selectors.stores;

  useEffect(() => {
    dispatch(actions.getBrands());
    dispatch(actions.getFleets());
  }, []);

  const handleSyncMenu =
    (store: Store): React.MouseEventHandler<HTMLButtonElement> =>
    (evt) => {
      const { brand, id } = store;
      evt.stopPropagation();
      dispatch(
        actions.syncMenu({
          brandKey: brand?.key ?? '',
          storeId: id,
        }),
      )
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: `Store menu synced!`,
          });
          setSyncConfirmation({ store: null });
        })
        .catch(() => {
          notification.error('Error', {
            description: 'Store menu not synced. Please try again later.',
          });
        });
    };

  const handleDeleteStore = () => {
    if (deleteDialog.store)
      dispatch(actions.deleteStore({ id: deleteDialog.store.id }))
        .unwrap()
        .then(() => {
          notification.success('Success', {
            description: 'Store deleted successfully',
          });
          setDeleteDialog(initDeleteDialog);
        });
  };

  const handlePaginatedStores = useCallback(
    _.debounce(
      (sParams?: URLSearchParams) =>
        dispatch(
          actions.getPaginatedStores({
            page: lastPage,
            pageSize,
            filters: sParams?.size
              ? mapSearchParamsToPaginatedStoreFilters(sParams)
              : undefined,
          }),
        )
          .unwrap()
          .then((res) => {
            setLastPage(lastPage + 1);
            dispatch(actions.setTotalRecords(res.totalRecords));
          }),
      500,
    ),
    [],
  );

  useEffect(() => {
    if (!fetching) {
      handlePaginatedStores(searchParams);
    }
  }, [searchParams]);

  const handleSubmit = (values: StoreFilterSchema) => {
    dispatch(actions.getPaginatedStores({ page: 1, pageSize, filters: values }))
      .unwrap()
      .then((res) => {
        setLastPage(2);
        dispatch(actions.setTotalRecords(res.totalRecords));
      })
      .finally(() => {
        handleFinallySubmit(values);
      });
  };

  const handleLoadMore = () => {
    if (!fetching)
      dispatch(
        actions.getPaginatedStores({ page: lastPage, pageSize, filters }),
      )
        .unwrap()
        .then((res) => {
          setLastPage(lastPage + 1);
          if (res.items.length < pageSize) {
            dispatch(actions.setTotalRecords(stores.length));
          }
        });
  };

  const PageCardHeader = (
    <>
      <FlexGrow />
      {filters ? (
        <>
          <Button onClick={handleClearFilters} variant="ghost">
            Clear filters
          </Button>
          <BatchUpdateMenu stores={stores} filters={filters} />
        </>
      ) : null}
      <Button onClick={() => setFilterOpen(true)}>
        <FilterIcon size={14} /> Filter
      </Button>
      <Button onClick={() => navigate('/stores/new/bulk')}>
        Bulk creation
      </Button>
      <Button onClick={() => navigate('/stores/new')}>Add store</Button>
    </>
  );

  return (
    <PageCard title="Stores" headerContent={PageCardHeader}>
      {fetching && stores.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={stores}
          stickyHeader
          onRowClick={(fleet) => navigate(`/stores/${fleet.original.id}`)}
          virtualizationProps={{ estimateSize: () => 68 }}
          infiniteLoadProps={{
            onLoadMore: handleLoadMore,
            totalRecords,
          }}
        />
      )}
      <ConfirmationDialog
        description={
          <Typography>
            This is going to sync the menu for{' '}
            {`${syncConfirmation.store?.brand?.name} - ${syncConfirmation.store?.franchiseStoreNumber}`}
          </Typography>
        }
        open={!!syncConfirmation.store}
        onOpenChange={(open) =>
          setSyncConfirmation({ store: open ? syncConfirmation.store : null })
        }
        onSubmit={
          syncConfirmation.store
            ? handleSyncMenu(syncConfirmation.store)
            : undefined
        }
        loading={syncing}
        confirmationLabel="Yes, sync"
      />
      <ConfirmationDialog
        description={
          <Typography>
            {`This will permanently delete `}
            <b>
              {deleteDialog.store?.brand?.name}{' '}
              {deleteDialog.store?.franchiseStoreNumber}
            </b>{' '}
            store
          </Typography>
        }
        open={!!deleteDialog.store}
        onOpenChange={(open) =>
          setDeleteDialog({ store: open ? deleteDialog.store : null })
        }
        onSubmit={handleDeleteStore}
        loading={deleting}
        variant="error"
        confirmationLabel="Yes, delete"
      />
      <FilterDialog
        open={filterOpen}
        onOpenChange={setFilterOpen}
        onSubmit={handleSubmit}
        form={form}
      />
    </PageCard>
  );
};
