import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Tabs, TabsList, TabsTrigger } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import { FormType } from '@/constants/form';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { useScrollTab } from '@/hooks/useScrollTab';
import { BrandInfo } from '@/pages/Brands/BrandForm/Sections';
import { Handoff } from '@/pages/Brands/BrandForm/Sections/Handoff';
import { Platforms } from '@/pages/Brands/BrandForm/Sections/Platforms';
import { Promotions } from '@/pages/Brands/BrandForm/Sections/Promotions';
import { Upsells } from '@/pages/Brands/BrandForm/Sections/Upsells';
import {
  mapBrandFormValuesToCreateRequest,
  mapBrandFormValuesToUpdateRequest,
  mapBrandPromosFormValuesToRequest,
  mapBrandUpsellsFormValuesToRequest,
} from '@/pages/Brands/BrandForm/adapter/api';
import { mapBrandToFormValues } from '@/pages/Brands/BrandForm/adapter/form';
import { BrandSchema, brandSchema } from '@/pages/Brands/BrandForm/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

type Tab = 'brandInfo' | 'platforms' | 'promotions' | 'upsells' | 'handoff';

const TABS = {
  'Brand info': 'brandInfo',
  Platforms: 'platforms',
  Promotions: 'promotions',
  Upsells: 'upsells',
  Handoff: 'handoff',
} satisfies Record<string, Tab>;

interface BrandFormProps {
  type: FormType;
}

export function BrandForm(props: BrandFormProps) {
  const { type } = props;

  const navigate = useNavigate();
  const { brandId = '' } = useParams<{ brandId: string }>();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const { handleChangeTab, handleScroll, tab } = useScrollTab<Tab>({
    initTab: 'brandInfo',
  });

  const { actions, selectors } = useConnector();

  useEffect(() => {
    if (brandId)
      dispatch(actions.getBrand({ id: brandId }))
        .unwrap()
        .then((brand) => {
          dispatch(actions.getBrandPromos({ brandId }));
          dispatch(actions.getBrandUpsells({ brandId }));
          dispatch(actions.getBrandModels({ brandId, brandKey: brand.key }));
          dispatch(actions.getBrandNluModels({ brandId, brandKey: brand.key }));
        });
  }, [brandId]);

  useEffect(() => {
    dispatch(actions.getPlatforms());
  }, []);

  const { brandEntities, fetching, creatingUpdating } = selectors;

  const brand = brandId ? brandEntities[brandId] : undefined;

  const form = useForm<BrandSchema>({
    resolver: zodResolver(brandSchema),
    defaultValues: {
      triageHandoffMethods: {
        curbside: false,
        delivery: false,
        dinein: false,
        pickup: false,
      },
    },
  });

  useEffect(() => {
    if (brand) {
      form.reset(mapBrandToFormValues(brand));
    }
  }, [brand]);

  const handleSuccessSave = () => {
    notification.success('Success', {
      description: getCreateUpdateDescription('Brand'),
    });
    navigate(`/brands`);
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    if (type === 'create')
      dispatch(actions.createBrand(mapBrandFormValuesToCreateRequest(values)))
        .unwrap()
        .then((newBrand) => {
          dispatch(
            actions.syncBrandPromos({
              brandId: newBrand.id,
              promos: mapBrandPromosFormValuesToRequest(values.promos),
            }),
          );

          dispatch(
            actions.syncBrandUpsells({
              brandId: newBrand.id,
              upsells: mapBrandUpsellsFormValuesToRequest(values.upsells),
            }),
          );
        })
        .finally(handleSuccessSave);

    if (type === 'update' && brand) {
      dispatch(actions.updateBrand(mapBrandFormValuesToUpdateRequest(values)))
        .unwrap()
        .then((updatedBrand) => {
          dispatch(
            actions.syncBrandPromos({
              brandId: updatedBrand.id,
              promos: mapBrandPromosFormValuesToRequest(values.promos),
            }),
          );
          dispatch(
            actions.syncBrandUpsells({
              brandId: updatedBrand.id,
              upsells: mapBrandUpsellsFormValuesToRequest(values.upsells),
            }),
          );
        })
        .finally(handleSuccessSave);
    }
  };

  if (fetching) {
    return <KeaLoading />;
  }
  if (type === 'update' && !brand) {
    return null;
  }

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <PageCard
          backButton={<BackButton onClick={() => navigate('/brands')} />}
          title={
            brand
              ? `${brand.name} [${brand.id.slice(-5)}]`
              : 'Create a new brand'
          }
          headerContent={
            <Tabs value={tab} onValueChange={handleChangeTab}>
              <TabsList>
                {Object.entries(TABS)
                  .filter(([, value]) =>
                    value !== TABS['Upsells'] ? true : !!brand,
                  )
                  .map(([label, value]) => (
                    <TabsTrigger key={value} value={value}>
                      {label}
                    </TabsTrigger>
                  ))}
              </TabsList>
            </Tabs>
          }
          footerContent={
            <UpdateCreateButton type="submit" loading={creatingUpdating} />
          }
        >
          <S.SectionsContainer onWheel={handleScroll}>
            <BrandInfo form={form} id={TABS['Brand info']} />
            <Platforms form={form} id={TABS['Platforms']} />
            <Promotions form={form} id={TABS['Promotions']} />
            {brand ? (
              <Upsells form={form} brand={brand} id={TABS['Upsells']} />
            ) : null}
            <Handoff form={form} id={TABS['Handoff']} />
          </S.SectionsContainer>
        </PageCard>
      </S.Form>
    </Form>
  );
}
