import { Select } from '@kea-inc/parrot-ui';

import { TRAINING_HANDOFF_MODES } from '@/constants/training';
import { useConnector } from '@/pages/Training/connector';
import { useAppDispatch } from '@/store';

export function HandoffModeSelect() {
  const dispatch = useAppDispatch();
  const { actions, selectors } = useConnector();
  const { training, fetchingAgents: fetching } = selectors;
  const { isCreatingTask, selectedHandoffMode } = training;

  const handleHandoffModeChange = (hndoffMode: string) => {
    dispatch(actions.setSelectedHandoffMode(hndoffMode));
  };

  return (
    <Select
      placeholder={fetching ? 'Loading...' : ''}
      disabled={fetching ?? isCreatingTask}
      label="Select a handoff mode"
      value={selectedHandoffMode ?? ''}
      onValueChange={handleHandoffModeChange}
    >
      {Object.entries(TRAINING_HANDOFF_MODES).map(([value, name]) => (
        <Select.Item key={value} value={value}>
          {name}
        </Select.Item>
      ))}
    </Select>
  );
}
