import { useAppSelector } from '@/store';
import { selectors as orderUiSelectors } from '@/store/ui/order';

export const useConnector = () => {
  const selectors = {
    timestamp: useAppSelector(orderUiSelectors.getTimestamp),
  };

  return { selectors };
};
