import {
  DataTable,
  Dialog,
  DialogHeader,
  DialogProps,
  DialogTitle,
} from '@kea-inc/parrot-ui';
import { useEffect } from 'react';

import { KeaLoading } from '@/components/KeaLoading';
import { useAppDispatch } from '@/store';

import * as S from './styles';
import { useFleetUsersColumnDefs } from './useFleetUsersColumnDefs';
import { useConnector } from '../connector';

type ExtendedProps = DialogProps;

interface UsersDialogProps extends ExtendedProps {
  fleetId?: string;
}

export function UsersDialog(props: UsersDialogProps) {
  const { fleetId, ...dialogProps } = props;

  const { actions, selectors } = useConnector();

  useEffect(() => {
    if (fleetId) {
      dispatch(actions.getFleetUsersByFleetId({ fleetId }));
    }
  }, [fleetId]);

  const { fetchingFleetUsers, fleetEntities } = selectors;

  const fleet = fleetId ? fleetEntities[fleetId] : undefined;

  const dispatch = useAppDispatch();

  const { columns } = useFleetUsersColumnDefs();

  return (
    <Dialog {...dialogProps}>
      <S.DialogContent>
        {fetchingFleetUsers ? (
          <KeaLoading />
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>{`Users in ${fleet?.name} `}</DialogTitle>
            </DialogHeader>
            <S.DataTableContainer>
              <DataTable
                columns={columns}
                stickyHeader
                data={fleet?.users ?? []}
              />
            </S.DataTableContainer>
          </>
        )}
      </S.DialogContent>
    </Dialog>
  );
}
