import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useMemo } from 'react';

import { ROUTES, Route, RoutesType } from '@/routes';
import { Permissions } from '@/services/@types/user/auth0Permissions';
import { applyJwtToken } from '@/services/middlewares/applyJwtToken';

const ROLES_KEY = 'https://kea.cloud/v2/roles';

interface IDecodedJwt {
  [ROLES_KEY]: string[];
  permissions: Permissions[];
}
const useAuthorization = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<Permissions[]>([]);
  const [userTokenLoaded, setUserTokenLoaded] = useState<boolean>(false);
  const { user, getAccessTokenSilently } = useAuth0();

  const userHasOneOfRoles = (comparisonRoles: string[]) =>
    (roles ?? []).some((role) => comparisonRoles.includes(role));
  const userHasOneOfPermissions = (comparisonPermissions: Permissions[]) =>
    (permissions ?? []).some((role) => comparisonPermissions.includes(role));
  const available: Route[] = [];
  const availableRoutes = useMemo(() => {
    Object.keys(ROUTES).forEach((route) => {
      const routeObj = ROUTES[route as keyof RoutesType];
      if (
        userHasOneOfRoles(routeObj.roles) ||
        userHasOneOfPermissions(routeObj.permissions ?? [])
      ) {
        available.push({ ...routeObj, path: route });
      }
    });

    return available;
  }, [roles, permissions]);

  const retrieveUser = async () => {
    if (!user) {
      return setUserTokenLoaded(true);
    }
    setUserTokenLoaded(false);
    const token = await getAccessTokenSilently();
    const decoded = jwtDecode<IDecodedJwt>(token);
    setRoles(decoded[ROLES_KEY]);
    setPermissions(decoded?.permissions || []);
    applyJwtToken(token, user.email);
    setUserTokenLoaded(true);
  };

  useEffect(() => {
    retrieveUser();
  }, [user]);

  return { roles, permissions, availableRoutes, userTokenLoaded };
};

export { useAuthorization };
