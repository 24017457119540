import { RouteObject } from 'react-router-dom';

import { OrderInfo } from '@/pages/Orders/OrderInfo';

import { List } from './List/List';

export const ordersRouter: RouteObject[] = [
  {
    path: '/orders',
    element: <List />,
    index: true,
  },
  {
    path: '/orders/:orderId',
    element: <OrderInfo />,
  },
];
