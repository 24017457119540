import { Badge, ColumnDef, Typography } from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';
import {
  CopyIcon,
  MessageSquareOffIcon,
  MicOffIcon,
  UsersIcon,
} from 'lucide-react';
import { useMemo } from 'react';

import { Order } from '@/@types/order';
import { Copy } from '@/components/Copy';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import { TableActions } from '@/components/TableActions';
import { PlayRecording } from '@/pages/Orders/List/PlayRecording';
import { useConnector } from '@/pages/Orders/connector';
import {
  mapOrderPaymentTypeToVariant,
  mapOrderStatusToVariant,
} from '@/utils/color';
import { formatUUID } from '@/utils/uuid';

import * as S from './styles';

type UseOrdersColumnDefsOpts = {
  onAgentDialogClick: (agentIds: string[]) => void;
  onDeleteTranscripts: (orderId: string) => void;
  onDeleteRecordings: (orderId: string) => void;
};

export const useOrdersColumnDefs = (opts: UseOrdersColumnDefsOpts) => {
  const { onDeleteRecordings, onDeleteTranscripts } = opts;

  const { selectors } = useConnector();

  const { storeEntities, brandEntities, agentEntities, accountEntities } =
    selectors;

  const handleCopyIDToClipboard = (order: Order) => {
    navigator.clipboard.writeText(order.id);
  };

  const columns = useMemo<ColumnDef<Order>[]>(
    () => [
      {
        accessorKey: 'id',
        header: () => <div>ID</div>,
        cell: ({ row }) => (
          <Copy value={row.original.id}>{formatUUID(row.original.id)}</Copy>
        ),
      },
      {
        accessorKey: 'agent',
        header: () => <div>Agent</div>,
        cell: ({ row }) => {
          if (!row.original.mostRecentAgentId) {
            return <Badge variant="outline">NO AGENT</Badge>;
          }
          const firstAgentId = row.original.mostRecentAgentId ?? '';
          const firstAgent = agentEntities?.[firstAgentId];
          if (!firstAgent) {
            return <Badge variant="outline">NO AGENT</Badge>;
          }
          return (
            <S.AgentCell>
              <Typography>{`${firstAgent.firstName} ${firstAgent.lastName}`}</Typography>
              {row.original.orderAgent.length > 1 ? (
                <S.AgentsNameCell>
                  <Typography>{`[${firstAgent.username}]`}</Typography>
                  <S.AgentIconButton
                    size="icon"
                    variant="ghost"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      opts.onAgentDialogClick(
                        row.original.orderAgent.map((agent) => agent.agentId),
                      );
                    }}
                  >
                    <UsersIcon size={13} />
                  </S.AgentIconButton>
                </S.AgentsNameCell>
              ) : null}
            </S.AgentCell>
          );
        },
      },
      {
        accessorKey: 'accountId',
        maxSize: 200,
        size: 200,
        header: () => <div>Caller</div>,
        cell: ({ row }) => {
          const account = accountEntities?.[row.original.accountId];
          if (!account) {
            return (
              <Badge variant="outline">
                {formatUUID(row.original.accountId)}
              </Badge>
            );
          }
          return (
            <ProtectedComponent allowedPermissions={['read:account-details']}>
              <S.LinkWithIcon to={`/accounts/${account?.id}`}>
                <S.CallerCellTypography>
                  {account?.firstName} {account?.lastName}
                </S.CallerCellTypography>

                <S.CallerCellTypography>
                  {account?.phone}
                </S.CallerCellTypography>
              </S.LinkWithIcon>
            </ProtectedComponent>
          );
        },
      },
      {
        accessorKey: 'store',
        header: () => <div>Store</div>,
        cell: ({ row }) => {
          const store = storeEntities?.[row.original.storeId];

          if (!store) {
            return <div />;
          }
          return (
            <S.StoreCell>
              <S.LinkWithIcon to={`/stores/${row.original.storeId}`}>
                <S.StoreCellTypography>
                  {`${brandEntities?.[store.brandId]?.name} [${
                    store.location ?? store.franchiseStoreNumber
                  }]`}
                </S.StoreCellTypography>
                <S.StoreCellTypography>{store.address}</S.StoreCellTypography>
              </S.LinkWithIcon>
            </S.StoreCell>
          );
        },
      },
      {
        accessorKey: 'status',
        header: () => <div>Status</div>,
        cell: ({ row }) => (
          <Badge variant={mapOrderStatusToVariant(row.original.status)}>
            {row.original.status?.toUpperCase()}
          </Badge>
        ),
      },
      {
        accessorKey: 'total',
        header: () => <div>Total</div>,
        cell: ({ row }) => <div>${row.original.total}</div>,
      },
      {
        accessorKey: 'handoffMode',
        header: () => <S.HeaderNoWrap>Handoff mode</S.HeaderNoWrap>,
        cell: ({ row }) => <div>{row.original.handoffMode?.toUpperCase()}</div>,
      },
      {
        accessorKey: 'source',
        header: () => <div>App</div>,
        cell: ({ row }) => (
          <S.SourceCell>{row.original.source?.toUpperCase()}</S.SourceCell>
        ),
      },
      {
        accessorKey: 'paymentType',
        header: () => <div>Payment</div>,
        cell: ({ row }) => {
          if (!row.original.paymentType) {
            return <div />;
          }
          const paymentType = row.original.paymentType;
          return (
            <Badge
              variant={mapOrderPaymentTypeToVariant(row.original.paymentType)}
            >
              {paymentType === 'credit_card'
                ? 'CARD'
                : paymentType?.toUpperCase()}
            </Badge>
          );
        },
      },
      {
        accessorKey: 'startTime',
        header: () => <S.HeaderNoWrap>Started at</S.HeaderNoWrap>,
        cell: ({ row }) => {
          if (!row.original.startTime) {
            return <div />;
          }
          return (
            <S.StartedAtCell>
              <Typography>
                {dayjs(row.original.startTime).format('MM/DD/YYYY')}
              </Typography>
              <Typography>
                {dayjs(row.original.startTime).format('hh:mm A (z)')}
              </Typography>
            </S.StartedAtCell>
          );
        },
      },
      {
        accessorKey: 'actions',
        header: () => <div>Actions</div>,
        cell: ({ row }) => {
          if (!row.original.paymentType) {
            return <div />;
          }
          return (
            <S.ActionsCell>
              <PlayRecording orderId={row.original.id} />
              <TableActions
                dropDownMenuLabel={formatUUID(row.original.id)}
                dropdownMenuItems={[
                  {
                    label: 'Copy ID',
                    Icon: <CopyIcon />,
                    onClick: (evt) => {
                      evt.stopPropagation();
                      handleCopyIDToClipboard(row.original);
                    },
                  },
                  {
                    label: 'Delete order transcripts',
                    Icon: <MessageSquareOffIcon />,
                    onClick: (evt) => {
                      evt.stopPropagation();
                      onDeleteTranscripts(row.original.id);
                    },
                  },
                  {
                    label: 'Delete order recordings',
                    Icon: <MicOffIcon />,
                    onClick: (evt) => {
                      evt.stopPropagation();
                      onDeleteRecordings(row.original.id);
                    },
                  },
                ]}
              />
            </S.ActionsCell>
          );
        },
      },
    ],
    [agentEntities, storeEntities, brandEntities, accountEntities],
  );

  return { columns };
};
