import dayjs from 'dayjs';

export function getDuration(startISO: string, endISO: string) {
  if (!startISO || !endISO) {
    return '';
  }

  const start = new Date(startISO);
  const end = new Date(endISO);

  const diffInSeconds = dayjs(end).diff(start, 'seconds');

  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;

  const duration = new Date(0, 0, 0, 0, minutes, seconds);

  return dayjs(duration).format('mm[m]:ss[s]');
}
