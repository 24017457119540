import * as ParrotUi from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

export const Card = styled(ParrotUi.Card)<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  ${(props) =>
    props.$open
      ? css`
          padding-bottom: 1rem;
        `
      : css`
          padding: 1rem 0rem;
        `}
`;

export const CardTitle = styled(ParrotUi.CardTitle)``;

export const CardHeader = styled(ParrotUi.CardHeader)`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  & > * {
    margin-top: 0px !important;
  }
`;

export const CardContent = styled(ParrotUi.CardContent)<{ $open: boolean }>`
  overflow: auto;
  ${(props) =>
    !props.$open
      ? css`
          padding: 0px;
        `
      : ''};
`;

export const Collapsible = styled(ParrotUi.Collapsible)``;
export const CollapsibleContent = styled(ParrotUi.CollapsibleContent)``;

export const TimeSpan = styled(ParrotUi.Typography)`
  color: hsl(var(--accent_1));
`;

export const HiddenButton = styled(ParrotUi.Button)`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;
