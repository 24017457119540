import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/training';

export const prefix = 'useCases/training';

export const actions = {
  getDeviceToken: createAsyncThunk(
    `${prefix}/getDeviceToken`,
    async (input: api.GetDeviceToken['input'], thunkApi) => {
      try {
        const result = await api.getDeviceToken(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createTrainingTask: createAsyncThunk(
    `${prefix}/createTrainingTask`,
    async (input: api.CreateTrainingTask['input'], thunkApi) => {
      try {
        await api.createTrainingTask(input);
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
