import * as ParrotUi from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

import { PageCard as PageCardComponent } from '@/components/PageCard';
import * as GlobalStyles from '@/styles';

export const SectionsContainer = GlobalStyles.SectionsContainer;
export const Form = GlobalStyles.Form;
export const FormFieldContainer = GlobalStyles.FormFieldContainer;

export const PageCard = styled(PageCardComponent).attrs({
  cardContentClassName: 'cardContentClassName',
})`
  .cardContentClassName {
    gap: 1rem;
  }
`;

export const Button = styled(ParrotUi.Button)<{ $disabled: boolean }>`
  padding: 0.5rem;
  ${(props) =>
    props.$disabled
      ? css`
          opacity: 0.25;
        `
      : undefined}
`;

export const PermissionsContainer = styled.div`
  display: flex;
  border: 1px solid hsl(var(--border));
  border-radius: 6px;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
