import _ from 'lodash';

import { Agent } from '@/@types/agent';
import { OrderCallFlow, OrderCallFlowProduct } from '@/@types/order/callFlow';

export function getEventName(eventName?: string) {
  if (!eventName) {
    return '';
  }

  const translations: Record<string, string> = {
    add_order_item: 'Item added to cart',
    remove_order_item: 'Item removed from cart',
    update_order_item: 'Item updated',
    order_details_changed: 'Order details changed',
    vbx_message: 'VBX',
    call_end: 'End of call',
    uoa: 'UOA',
  };

  if (eventName in translations) {
    return translations[eventName];
  }

  return snakeCaseToTitleCase(eventName).trim();
}

function snakeCaseToTitleCase(input: string | undefined): string {
  const firstWord = (input?.split('_')[0] ?? '').replace(/^\w/, (c) =>
    c.toUpperCase(),
  );
  const restOfWords = (
    input?.split('_').slice(1).join(' ') ?? ''
  ).toLowerCase();
  return `${firstWord} ${restOfWords}`;
}

export function getCardHeaderLabel(call: OrderCallFlow) {
  if (call.callSegment) {
    return `${getEventName(call.callSegment)}: Started`;
  }
  return `${getEventName(call.parentSegment)}: ${getEventName(call.eventName)}`;
}

export function getNextOrderChangedEvent(
  data: OrderCallFlow[] | undefined,
  index: number,
) {
  const nextEvent: OrderCallFlow | undefined = data
    ?.slice(index)
    .find(
      (event: any) =>
        event?.eventInfo?.products && event?.eventInfo?.products?.length > 0,
    );
  return nextEvent;
}

export function getPreviousOrderChangedEvent(
  data: OrderCallFlow[] | undefined,
  index: number,
) {
  const nextEvent: OrderCallFlow | undefined = data
    ?.slice(0, index + 1)
    ?.reverse()
    .find(
      (event: any) =>
        event?.eventInfo?.products && event?.eventInfo?.products?.length > 0,
    );
  return nextEvent;
}

export function getProductsFromEvents(
  event: OrderCallFlow,
  index: number,
  eventList: OrderCallFlow[] | undefined,
): OrderCallFlowProduct[] {
  const orderDetailsEvent =
    event.eventInfo?.change === 'remove_order_item'
      ? getPreviousOrderChangedEvent(eventList, index)
      : getNextOrderChangedEvent(eventList, index);

  let eventProducts =
    event.eventInfo?.result?.createdOrderItems ??
    event.eventInfo?.result ??
    event.eventInfo?.orderItem;

  if (eventProducts && !eventProducts.length) {
    eventProducts = [eventProducts];
  }

  const fetchedProducts = eventProducts?.reduce(
    (acc: Partial<OrderCallFlow>[], product: any) => {
      const productData = orderDetailsEvent?.eventInfo?.products?.find(
        (p: any) => String(p.productId) === product.itemId,
      );
      if (productData) {
        acc.push(productData);
      }

      return acc;
    },
    [],
  );

  const products =
    fetchedProducts && fetchedProducts.length > 0
      ? fetchedProducts
      : orderDetailsEvent?.eventInfo?.products;

  return products ?? [];
}

/**
 * This function finds all deep occurrences of agent_id and sets agent_username in the same object
 */

export const addAgentUsernames = (
  call: OrderCallFlow,
  agentEntities: Record<string, Agent>,
) => {
  const eventInfo = _.cloneDeep(call.eventInfo);
  if (!eventInfo) {
    return;
  }
  Object.values(eventInfo).forEach((value) => {
    if (Array.isArray(value)) {
      value.forEach((vl) => {
        if (vl.agent_id) {
          vl.agent_username = agentEntities[vl.agent_id]?.username ?? '';
        }
      });
      return;
    }

    if (_.isPlainObject(value) && value?.agent_id) {
      value.agent_username = agentEntities[value?.agent_id]?.username ?? '';
    }
  });

  if (eventInfo?.agent_id) {
    eventInfo.agent_username =
      agentEntities[eventInfo?.agent_id]?.username ?? '';
  }
  return eventInfo;
};
