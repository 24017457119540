import { AxiosInstance, AxiosResponse } from 'axios';

import logger from '../config/logger';

export function addLoggerInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(onSuccess, onError);
}

function onSuccess(response: AxiosResponse<any>) {
  const { config, status } = response;
  const { method, baseURL, url, data: requestData } = config;

  const message = `${method?.toUpperCase()} ${baseURL}${url} finished successfully with status ${status}`;
  const attributes = { requestData };
  logger.info(message, attributes);
  return response;
}

function onError(error: any) {
  if (!error.response) {
    return;
  }

  const { config, data: responseData, status } = error.response;
  const { method, baseURL, url, data: requestData } = config;
  const message = `${method.toUpperCase()} ${baseURL}${url} failed with status ${status}`;
  const attributes = {
    requestData,
    responseData,
  };
  logger.error(message, attributes);
  return Promise.reject(error);
}
