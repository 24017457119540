import { User } from '@/@types/user';
import { UpdateUserRequest } from '@/@types/user/update';
import { dataService } from '@/services';
import { UserService } from '@/services/@types/user';
import { mapUpdateUserRequestToApi } from '@/services/useCases/user/adapters/api';
import { mapUserToDomain } from '@/services/useCases/user/adapters/domain';

export type UpdateUser = {
  input: UpdateUserRequest;
  output: User;
};

export const updateUser = async (
  input: UpdateUser['input'],
): Promise<UpdateUser['output']> => {
  const { id } = input;
  const { data } = await dataService.patch<UserService.User>(
    `/users/${id}`,
    mapUpdateUserRequestToApi(input),
  );
  return mapUserToDomain({ ...data, id });
};
