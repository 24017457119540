import _ from 'lodash';

import { PaginatedAccountsFilters } from '@/@types/account/paginatedAccount';

export const mapAccountSearchParamsToAccountFilters = (
  search: URLSearchParams,
) => {
  const accountFilters: PaginatedAccountsFilters = Object.fromEntries(search);

  return accountFilters;
};

export const mapAccountFiltersToSearchParams = (
  filters: PaginatedAccountsFilters,
) => {
  const searchParams = new URLSearchParams(_.omitBy(filters, _.isUndefined));

  return searchParams;
};
