import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import {
  MenuCategory,
  MenuItem,
  MenuModifier,
  MenuOption,
  SoundBoardEntry,
} from '@/@types/menu';
import { actions } from '@/store/useCases/menu';

type ExtendedType = MenuCategory | MenuItem | MenuOption | MenuModifier;

export type MenuGroup<T extends ExtendedType> = {
  [parentId: string]: T[];
};

export const soundboardAdapter = createEntityAdapter<SoundBoardEntry>({
  sortComparer: (e1, e2) => {
    if (e1.type === e2.type) {
      return 0;
    }
    if (e1.type === 'global' && e2.type === 'brand') {
      return 1;
    }
    if (e1.type === 'brand' && e2.type === 'global') {
      return -1;
    }
    if (e1.type === 'brand' && e2.type === 'store') {
      return -1;
    }
    if (e1.type === 'store' && e2.type === 'brand') {
      return 1;
    }

    return 0;
  },
});

export type State = {
  categoryEntities: MenuGroup<MenuCategory>;
  itemEntities: MenuGroup<MenuItem>;
  optionEntities: MenuGroup<MenuOption>;
  modifierEntities: MenuGroup<MenuModifier>;
  soundbarEntries: EntityState<SoundBoardEntry, string>;
};

export const initialState: State = {
  categoryEntities: {},
  itemEntities: {},
  optionEntities: {},
  modifierEntities: {},
  soundbarEntries: soundboardAdapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getSoundboardEntries.fulfilled, (state, action) => {
      soundboardAdapter.upsertMany(state.soundbarEntries, action.payload);
    });
    builder.addCase(
      actions.getBrandSoundboardEntries.fulfilled,
      (state, action) => {
        soundboardAdapter.upsertMany(state.soundbarEntries, action.payload);
      },
    );
    builder.addCase(
      actions.getStoreSoundboardEntries.fulfilled,
      (state, action) => {
        soundboardAdapter.upsertMany(state.soundbarEntries, action.payload);
      },
    );
    builder.addCase(
      actions.createBrandSoundboardEntries.fulfilled,
      (state, action) => {
        const {
          meta: {
            arg: { brandId },
          },
        } = action;
        soundboardAdapter.removeMany(
          state.soundbarEntries,
          Object.values(state.soundbarEntries.entities)
            .filter((entry) => entry.brandId === brandId)
            .map((entry) => entry.id),
        );
      },
    );
    // Category
    builder.addCase(actions.getBrandCategories.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { brandId },
        },
      } = action;

      state.categoryEntities[brandId] = action.payload;
    });
    builder.addCase(actions.getCategories.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { storeId },
        },
      } = action;

      state.categoryEntities[storeId] = action.payload;
    });
    // Item
    builder.addCase(actions.getBrandItems.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { brandId, parentChainId },
        },
      } = action;

      state.itemEntities[`${brandId}-${parentChainId}`] = action.payload;
    });
    builder.addCase(actions.getItems.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { categoryId, storeId },
        },
      } = action;

      state.itemEntities[`${storeId}-${categoryId}`] = action.payload;
    });
    // Modifier
    builder.addCase(actions.getBrandModifiers.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { brandId, parentChainId: parentId },
        },
      } = action;

      state.modifierEntities[`${brandId}-${parentId}`] = action.payload;
    });
    builder.addCase(actions.getModifiers.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { parentId, storeId },
        },
      } = action;

      state.modifierEntities[`${storeId}-${parentId}`] = action.payload;
    });
    // Options
    builder.addCase(actions.getBrandOptions.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { parentChainId: parentId, brandId },
        },
      } = action;

      state.optionEntities[`${brandId}-${parentId}`] = action.payload;
    });
    builder.addCase(actions.getOptions.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { storeId, parentId },
        },
      } = action;

      state.optionEntities[`${storeId}-${parentId}`] = action.payload;
    });
  },
});

export default slice;
