import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/user';

export const prefix = 'useCases/user';

export const actions = {
  getPaginatedUsers: createAsyncThunk(
    `${prefix}/getPaginatedUsers`,
    async (input: api.GetPaginatedUsers['input'], thunkApi) => {
      try {
        const result = await api.getPaginatedUsers(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getUser: createAsyncThunk(
    `${prefix}/getUser`,
    async (input: api.GetUser['input'], thunkApi) => {
      try {
        const result = await api.getUser(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createUser: createAsyncThunk(
    `${prefix}/createUser`,
    async (input: api.CreateUser['input'], thunkApi) => {
      try {
        const result = await api.createUser(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateUser: createAsyncThunk(
    `${prefix}/updateUser`,
    async (input: api.UpdateUser['input'], thunkApi) => {
      try {
        const result = await api.updateUser(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteUser: createAsyncThunk(
    `${prefix}/deleteUser`,
    async (input: api.DeleteUser['input'], thunkApi) => {
      try {
        const result = await api.deleteUser(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
