import { Button, ButtonProps } from '@kea-inc/parrot-ui';
import { CheckIcon } from 'lucide-react';

interface UpdateCreateButtonProps extends ButtonProps {
  label?: string;
}

export const UpdateCreateButton = (props: UpdateCreateButtonProps) => {
  const { label = 'Save', ...buttonProps } = props;
  return (
    <Button {...buttonProps}>
      {label}
      <CheckIcon size={16} />
    </Button>
  );
};
