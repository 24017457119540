import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Checkbox = styled(ParrotUi.Checkbox)`
  border-color: hsl(var(--gray));
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
