import {
  FormField,
  Select,
  Tooltip,
  Typography,
  FormItemInput,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ProtectedComponent } from '@/components/ProtectedComponent';
import { useNotification } from '@/hooks/useNotification';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';
import { useAppDispatch } from '@/store';

import { BypassModeFormField } from './BypassModeFormField';
import { PlatformModeFormField } from './PlatformModeFormField';
import * as S from './styles';
import { useConnector } from '../../connector';

interface ConnectorInfoProps {
  form: UseFormReturn<StoreSchema>;
  id: HTMLDivElement['id'];
}

export function ConnectorInfo(props: ConnectorInfoProps) {
  const { form, id } = props;

  const { selectors, actions } = useConnector();
  const {
    platforms,
    brands,
    syncingStoreId,
    storesEntities,
    buyingTwilioNumber,
  } = selectors;

  const { storeId = '' } = useParams<{ storeId: string }>();

  const { twilioNumber, primaryPhone } = useWatch({ control: form.control });

  const store = storesEntities[storeId];

  const dispatch = useAppDispatch();
  const notification = useNotification();

  const handleSyncPlatformStoreId = () => {
    const brand = brands.find((brnd) => brnd.id === form.getValues('brandId'));
    if (brand) {
      const platformApiKey = brand.platforms?.find(
        (platform) => platform.platformId === form.getValues('platformId'),
      );

      if (platformApiKey) {
        dispatch(
          actions.syncPlatformStoreId({
            platformApiKey: platformApiKey.platformApiKey ?? '',
            brandKey: brand.key,
            extRef: form.getValues('franchiseStoreNumber'),
          }),
        )
          .unwrap()
          .catch(() =>
            notification.error('Error', {
              description: `Failed getting Platform Store ID`,
            }),
          );
      } else {
        notification.error('Error', {
          description: `There is no Platform API for ${brand.name}`,
        });
      }
    } else {
      notification.error('Error', {
        description: `There is no Brand selected for the store`,
      });
    }
  };

  const handleBuyTwilioNumber = () => {
    form.trigger('primaryPhone').then((valid) => {
      if (valid && primaryPhone) {
        dispatch(actions.buyTwilioNumber({ primaryPhone }))
          .unwrap()
          .then(({ phoneNumber }) =>
            form.setValue('twilioNumber', phoneNumber),
          );
      }
    });
  };

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Connector info</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="primaryPhone"
          render={({ field }) => (
            <FormItemInput
              field={field}
              maskOptions={{ mask: '+1 (999) 999-9999' }}
              label={
                <S.PhoneInputLabelContainer>
                  <Typography>External phone</Typography>
                  <Tooltip
                    content="The store's external number that customers dial (AKA “Primary Phone”)"
                    position="right-center"
                  >
                    <S.HelpCircleIcon />
                  </Tooltip>
                </S.PhoneInputLabelContainer>
              }
            />
          )}
        />
        <FormField
          control={form.control}
          name="secondaryPhone"
          render={({ field }) => (
            <FormItemInput
              field={field}
              maskOptions={{ mask: '+1 (999) 999-9999' }}
              label={
                <S.PhoneInputLabelContainer>
                  <Typography>Forward phone</Typography>
                  <Tooltip
                    content="Stores set up this secondary line so we can forward calls to them"
                    position="right-center"
                  >
                    <S.HelpCircleIcon />
                  </Tooltip>
                </S.PhoneInputLabelContainer>
              }
            />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="twilioNumber"
          render={({ field }) => (
            <FormItemInput
              field={field}
              maskOptions={{ mask: '+1 (999) 999-9999' }}
              label={
                <S.PhoneInputLabelContainer>
                  <Typography>Internal phone</Typography>
                  <Tooltip
                    content="Kea's direct line that only we know (AKA “Twilio Number”)"
                    position="right-center"
                  >
                    <S.HelpCircleIcon />
                  </Tooltip>
                </S.PhoneInputLabelContainer>
              }
            />
          )}
        />
        <S.BuyDIDButton
          disabled={!!twilioNumber || !primaryPhone}
          $alignSelf={
            form.formState.errors?.twilioNumber ? 'center' : 'flex-end'
          }
          type="button"
          loading={buyingTwilioNumber}
          onClick={handleBuyTwilioNumber}
        >
          Buy DID
        </S.BuyDIDButton>
        <FormField
          control={form.control}
          name="sipUri"
          render={({ field }) => (
            <FormItemInput field={field} label="Sip URI" />
          )}
        />
        <BypassModeFormField form={form} />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={props.form.control}
          name="platformId"
          render={({ field }) => (
            <FormItemSelect label="Platform" field={field}>
              {platforms.map((platform) => (
                <Select.Item value={platform.id} key={platform.id}>
                  {platform.name}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <PlatformModeFormField form={form} />
        <S.PlatformStoreContainer>
          <FormField
            control={form.control}
            name="platformStoreId"
            render={({ field }) => (
              <FormItemInput field={field} label="Platform store ID" />
            )}
          />
          <S.SyncStoreIdButton
            onClick={handleSyncPlatformStoreId}
            disabled={!form.getValues('platformStoreId')}
            loading={syncingStoreId}
            type="button"
            $alignSelf={
              form.formState.errors?.platformStoreId ? 'center' : 'flex-end'
            }
          >
            Sync ID
          </S.SyncStoreIdButton>
        </S.PlatformStoreContainer>
      </S.FormFieldContainer>
      <ProtectedComponent allowedPermissions={['write:menu']}>
        <S.StoreMenuVersionContainer>
          <FormField
            control={props.form.control}
            name="menuVersion"
            render={({ field }) => (
              <FormItemSelect label="Menu version" field={field}>
                {store?.menuVersions?.map((menuVersion) => (
                  <Select.Item
                    value={menuVersion.version}
                    key={menuVersion.version}
                  >
                    {menuVersion.version}
                  </Select.Item>
                ))}
              </FormItemSelect>
            )}
          />
        </S.StoreMenuVersionContainer>
      </ProtectedComponent>
    </S.SectionContainer>
  );
}
