import styled from 'styled-components';

import { PageCard as PageCardComponent } from '@/components/PageCard';

export const PageCard = styled(PageCardComponent).attrs({
  cardContentClassName: 'cardContentClassName',
})`
  .cardContentClassName {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-left: 0px;
    padding-bottom: 0px;
  }
`;
