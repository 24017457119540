import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Card = styled(ParrotUi.Card)``;

export const CardHeader = styled(ParrotUi.CardHeader)`
  display: flex;

  flex-direction: row;
`;

export const CardTitle = styled(ParrotUi.CardTitle)``;
export const Select = styled(ParrotUi.Select.Root)``;
export const SelectTrigger = styled(ParrotUi.Select.Trigger)`
  width: 200px;
`;
export const SelectContent = styled(ParrotUi.Select.Content)`
  height: 400px;
  overflow: auto;
`;

export const CardContent = styled(ParrotUi.CardContent)`
  display: flex;
`;

export const Collapsible = styled(ParrotUi.Collapsible)`
  overflow: auto;
  flex: 1 1 0px;
`;

export const SearchAgentInput = styled(ParrotUi.Input)`
  width: 250px;
  margin-right: 1rem;
`;
