import { RouteObject } from 'react-router-dom';

import { RoleForm, UserForm } from '@/pages/Admin/AdminForm';

import { List } from './List';

export const adminRouter: RouteObject[] = [
  {
    path: '/admin',
    element: <List />,
    index: true,
  },
  {
    path: '/admin/users/:userId',
    element: <UserForm type="update" />,
  },
  {
    path: '/admin/users/new',
    element: <UserForm type="create" />,
  },
  {
    path: '/admin/roles/:roleName',
    element: <RoleForm />,
  },
];
