import * as ParrotUi from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

export * from '../styles';

export const RolesContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const IconButton = styled(ParrotUi.Button)<{ $disabled: boolean }>`
  border-radius: 9999px;
  padding: 0.5rem;
  ${(props) =>
    props.$disabled
      ? css`
          opacity: 0.25;
        `
      : undefined}
`;
