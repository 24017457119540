import { z } from 'zod';

import { BrandUpsell } from '@/@types/brand';

type SchemaObjShape = Record<
  keyof BrandUpsell & { deleted?: boolean; isNew?: boolean },
  z.ZodTypeAny
>;

export const brandUpsellSchema = z.object({
  id: z.string(),
  phrase: z.string(),
  priority: z.coerce.number(),
  promoId: z.string(),
  quantity: z.coerce.number(),
  chainIds: z.string(),
  deleted: z.boolean().optional(),
  isNew: z.boolean().optional(),
} satisfies SchemaObjShape);

export type BrandUpsellSchema = z.infer<typeof brandUpsellSchema>;
