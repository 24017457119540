import env from '@beam-australia/react-env';

import { Agent } from '@/@types/agent';
import {
  GetPaginatedAgentsRequest,
  GetPaginatedAgentsResponse,
} from '@/@types/agent/get';
import { dataService } from '@/services';
import { AgentService } from '@/services/@types/agent';
import { mapPaginatedAgentRequestToApi } from '@/services/useCases/agent/adapters/api';
import { mapAgentToDomain } from '@/services/useCases/agent/adapters/domain';

export type GetPaginatedAgents = {
  input: GetPaginatedAgentsRequest;
  output: GetPaginatedAgentsResponse;
};

export const getPaginatedAgents = async (
  input: GetPaginatedAgents['input'],
): Promise<GetPaginatedAgents['output']> => {
  const params: AgentService.GetPaginatedAgentsRequest =
    mapPaginatedAgentRequestToApi(input);
  const { data } =
    await dataService.get<AgentService.GetPaginatedAgentsResponse>(
      '/agents/agents_ops_view',
      {
        params,
        version: 'v3',
      },
    );

  return {
    items: data.items.map(mapAgentToDomain),
    totalPages: data.total_pages,
    totalRecords: data.total_records,
  };
};

export type GetAgents = {
  input: void;
  output: Agent[];
};

export const getAgents = async (): Promise<GetAgents['output']> => {
  const { data } = await dataService.get<AgentService.Agent[]>('/agents');
  return data.map(mapAgentToDomain);
};

export type GetAgent = {
  input: { id: string };
  output: Agent;
};

export const getAgent = async (
  input: GetAgent['input'],
): Promise<GetAgent['output']> => {
  const { data } = await dataService.get<AgentService.Agent>(
    `/agents/${input.id}/workspace/${env('TWILIO_WORKSPACE_SID')}`,
  );
  return mapAgentToDomain(data);
};
