import { User } from '@/@types/user';
import { CreateUserRequest } from '@/@types/user/create';
import { dataService } from '@/services';
import { UserService } from '@/services/@types/user';
import { mapCreateUserRequestToApi } from '@/services/useCases/user/adapters/api';
import { mapUserToDomain } from '@/services/useCases/user/adapters/domain';

export type CreateUser = {
  input: CreateUserRequest;
  output: User;
};

export const createUser = async (
  input: CreateUser['input'],
): Promise<CreateUser['output']> => {
  const { data } = await dataService.post<UserService.User>(
    `/users`,
    mapCreateUserRequestToApi(input),
  );
  return mapUserToDomain(data);
};
