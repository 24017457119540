import {
  FormField,
  Select,
  FormItemInput,
  FormItemSelect,
  Typography,
  Tooltip,
} from '@kea-inc/parrot-ui';
import { CircleHelpIcon } from 'lucide-react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { FlexGrow } from '@/components/FlexGrow';
import { ProtectedComponent } from '@/components/ProtectedComponent';
import {
  AMAZON_VOICES,
  IBM_VOICES,
  ELEVENLABS_VOICES,
} from '@/constants/voices';
import { useConnector } from '@/pages/Brands/BrandForm/connector';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';

import * as S from './styles';
interface BrandInfoProps {
  form: UseFormReturn<BrandSchema>;
  id: HTMLDivElement['id'];
}

export function BrandInfo(props: BrandInfoProps) {
  const { form } = props;

  const { id } = useWatch({ control: form.control });

  const { selectors } = useConnector();

  const { brandEntities } = selectors;

  const brandModels = id ? brandEntities[id]?.models : [];

  const brandNluModels = id ? brandEntities[id]?.nluModels : [];

  const voices = IBM_VOICES.concat(AMAZON_VOICES).concat(ELEVENLABS_VOICES);

  return (
    <S.SectionContainer id={props.id}>
      <S.SectionTitle>Brand info</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => <FormItemInput field={field} label="Name" />}
        />
        <FormField
          control={form.control}
          name="spokenName"
          render={({ field }) => (
            <FormItemInput field={field} label="Spoken name" />
          )}
        />
        <FormField
          control={form.control}
          name="key"
          render={({ field }) => <FormItemInput field={field} label="Key" />}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="cashLimit"
          render={({ field }) => (
            <FormItemInput field={field} label="Cash limit" />
          )}
        />
        <FormField
          control={form.control}
          name="voice"
          render={({ field }) => (
            <FormItemSelect field={field} label="Voice">
              {voices.map((voice) => (
                <Select.Item value={voice} key={voice}>
                  {voice}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="transcriptionServiceProvider"
          render={({ field }) => (
            <FormItemSelect field={field} label="Transcription service">
              <Select.Item value="deepgram">Deepgram</Select.Item>
              <Select.Item value="google">Google</Select.Item>
            </FormItemSelect>
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="shouldTip"
          render={({ field }) => (
            <S.FormItemToggle label="Tip">
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value}
                  onClick={() => field.onChange(true)}
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={!field.value}
                  onClick={() => field.onChange(false)}
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="tipPercentage"
          render={({ field }) => (
            <FormItemInput
              field={field}
              label="Tip percentage"
              type="number"
              disabled={!form.getValues('shouldTip')}
            />
          )}
        />
        <FormField
          control={form.control}
          name="priority"
          render={({ field }) => (
            <FormItemInput
              field={field}
              type="number"
              label="Call queue priority"
            />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="deepgramModel"
          render={({ field }) => (
            <FormItemInput field={field} label="Deepgram model name" />
          )}
        />

        <FormField
          control={form.control}
          name="deepgramModelVersion"
          render={({ field }) => (
            <FormItemInput field={field} label="Deepgram model version" />
          )}
        />
        <ProtectedComponent allowedPermissions={['write:ml_config']}>
          <FormField
            control={form.control}
            name="nluModel"
            render={({ field }) => (
              <FormItemSelect field={field} label="NLU model">
                {brandNluModels?.map((brandModel) => (
                  <Select.Item value={brandModel.model} key={brandModel.model}>
                    {brandModel.model}
                  </Select.Item>
                ))}
              </FormItemSelect>
            )}
          />

          <FormField
            control={form.control}
            name="nerModel"
            render={({ field }) => (
              <FormItemSelect field={field} label="NER model">
                {brandModels?.map((brandModel) => (
                  <Select.Item value={brandModel.model} key={brandModel.model}>
                    {brandModel.model}
                  </Select.Item>
                ))}
              </FormItemSelect>
            )}
          />
        </ProtectedComponent>
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="useDefaultOptionsToMaxQuantity"
          render={({ field }) => (
            <S.FormItemToggle label="Default Options Quantity">
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value}
                  onClick={() => field.onChange(true)}
                >
                  Max Quantity
                </S.Toggle>
                <S.Toggle
                  pressed={!field.value}
                  onClick={() => field.onChange(false)}
                >
                  Min Quantity
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="agentlessStoreForwarding"
          render={({ field }) => (
            <S.FormItemToggle
              label={
                <S.FormItemLabelContainer>
                  <Typography>Agentless Store Forwarding</Typography>
                  <Tooltip content="This will enable forwarding calls to the store if automated ordering fails and there are no queued agents that are idle to take a call.">
                    <CircleHelpIcon size={16} />
                  </Tooltip>
                </S.FormItemLabelContainer>
              }
            >
              <S.ToggleContainer>
                <S.Toggle
                  pressed={field.value === true}
                  onClick={() => field.onChange(true)}
                >
                  On
                </S.Toggle>
                <S.Toggle
                  pressed={field.value === false}
                  onClick={() => field.onChange(false)}
                >
                  Off
                </S.Toggle>
              </S.ToggleContainer>
            </S.FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="autoScheduleThreshold"
          render={({ field }) => (
            <FormItemInput
              field={field}
              type="number"
              label="Auto-schedule Threshold (minutes)"
            />
          )}
        />
        <FlexGrow />
      </S.FormFieldContainer>
    </S.SectionContainer>
  );
}
