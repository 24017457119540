import env from '@beam-australia/react-env';

import { ReducedAgentsMetrics } from '@/@types/agent';
import { dataService } from '@/services';

const twilioWorkspaceSid = env('TWILIO_WORKSPACE_SID');

export type QueueBrandPatchPayload = {
  id: string;
  trained?: boolean;
  queued: boolean;
  key: string;
};

type UpdateBrandsInput = {
  agent: ReducedAgentsMetrics;
  brands: QueueBrandPatchPayload[];
};

export type UpdateBrands = {
  input: UpdateBrandsInput;
  output: void;
};

export const updateBrands = async (
  input: UpdateBrands['input'],
): Promise<UpdateBrands['output']> => {
  const { agent, brands } = input;
  await dataService.patch(
    `/agents/${agent.id}/workspace/${twilioWorkspaceSid}`,
    {
      username: agent.username,
      brands,
    },
  );
};
