import {
  Badge,
  ColumnDef,
  DataTableSortHeader,
  Tooltip,
} from '@kea-inc/parrot-ui';
import { AlarmClockOff } from 'lucide-react';

import { ReducedAgentsMetrics } from '@/@types/agent';
import { TableActions } from '@/components/TableActions';
import { TimeSpan } from '@/components/TimeSpan';
import { mapStatusColorToVariant } from '@/utils/color';

import * as S from './styles';
import { BrandsColumn } from '../BrandsColumn';
import { RoleWeightColumn } from '../RoleWeightColumn';

type UseAgentColumnDefsOpts = {
  onNewBrandClick: (agent: ReducedAgentsMetrics) => void;
  onRoleWeightSave: (rW: string, rAgent: ReducedAgentsMetrics) => void;
  onClockOutAgent: (agentId: string) => void;
};

export const useAgentColumnDefs = (opts: UseAgentColumnDefsOpts) => {
  const { onNewBrandClick, onRoleWeightSave, onClockOutAgent } = opts;
  const columns: ColumnDef<ReducedAgentsMetrics>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Agent name" isMulti />
      ),
      cell: ({ row }) => {
        const agentName: string = row.getValue('name');
        return <div>{agentName}</div>;
      },
    },
    {
      accessorKey: 'brands',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Brands" isMulti />
      ),
      sortingFn: (r1, r2) =>
        r1.original.brands.length - r2.original.brands.length,
      cell: ({ row }) => (
        <BrandsColumn
          agent={row.original}
          onNewBrandClick={() => onNewBrandClick(row.original)}
        />
      ),
      size: 800,
    },
    {
      accessorKey: 'roleWeight',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Role weight" isMulti />
      ),
      cell: ({ row }) => (
        <RoleWeightColumn
          reducedAgent={row.original}
          onRoleWeightSave={onRoleWeightSave}
        />
      ),
    },
    {
      accessorKey: 'status',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Status" isMulti />
      ),
      cell: ({ row }) => {
        return (
          <Tooltip content={row.original.description} disableHoverableContent>
            <S.StatusBadgeContainer>
              <Badge
                variant={mapStatusColorToVariant(row.original.statusColor)}
              >
                {row.original.status}
              </Badge>
            </S.StatusBadgeContainer>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'timeSpan',
      header: () => <div>Time span</div>,
      cell: ({ row }) => (
        <TimeSpan color={row.original.statusColor}>
          {row.original.timeSpan}
        </TimeSpan>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <div>Actions</div>,
      cell: ({ row }) => {
        return (
          <S.ActionsCell>
            <TableActions
              dropDownMenuLabel={row.getValue('name')}
              dropdownMenuItems={[
                {
                  Icon: <AlarmClockOff />,
                  label: 'Clock Out',
                  onClick: () => onClockOutAgent(row.original.id),
                  disable: row.original.status === 'Offline',
                },
              ]}
            />
          </S.ActionsCell>
        );
      },
    },
  ];

  return { columns };
};
