import keaLogoWhite from '@/assets/kea-logo-white.svg';
import keaLogo from '@/assets/kea-logo.svg';
import { useAppSelector } from '@/store';
import { cn } from '@/utils/cn';

interface KeaLogoProps {
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const widths = {
  sm: 'w-12',
  md: 'w-16',
  lg: 'w-24',
};

export function KeaLogo({ size = 'sm', className }: KeaLogoProps) {
  const theme = useAppSelector((state) => state.ui.theme.value);
  const logo = theme === 'dark' ? keaLogoWhite : keaLogo;
  const alt = theme === 'dark' ? 'Kea Logo (White)' : 'Kea Logo';

  const w = widths[size];

  return <img src={logo} alt={alt} className={cn(w, className)} />;
}
