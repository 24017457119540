import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@kea-inc/parrot-ui';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useState } from 'react';

import { useInterval } from '@/hooks/useInterval';
import { useConnector } from '@/pages/Training/connector';

import { ParticipantCard } from './ParticipantCard';
import { PartipantConnection } from './PartipantConnection';
import * as S from './styles';

dayjs.extend(duration);

function Timer() {
  const [time, setTime] = useState<string>('00:00:00');
  const { selectors } = useConnector();
  const { training } = selectors;
  const { connectedAt } = training;

  useInterval(() => {
    if (!connectedAt) {
      return;
    }
    const now = dayjs();
    const seconds = now.diff(connectedAt, 'second');
    const formatted = dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
    setTime(formatted);
  }, 1000);

  return (
    <S.Timer>
      <S.TimeTypography variant="p">{time}</S.TimeTypography>
    </S.Timer>
  );
}

export function OngoingCall() {
  const { selectors } = useConnector();
  const { training, agentEntities } = selectors;
  const { selectedAgentId } = training;
  const { user } = useAuth0();

  const selectedAgent = selectedAgentId
    ? agentEntities[selectedAgentId]
    : undefined;

  return (
    <S.OngoingCallContainer>
      <div>
        <Typography variant="h4">You are currently in a call</Typography>

        <S.FromToContainer>
          <ParticipantCard name={user?.name} />
          <PartipantConnection />
          <ParticipantCard
            name={`${selectedAgent?.firstName} ${selectedAgent?.lastName}`}
            isAgent
          />
        </S.FromToContainer>
      </div>

      <Timer />
    </S.OngoingCallContainer>
  );
}
