import { ValueOf } from '@/utils/methods';

export const USER_ROLES = {
  Account_Manager: 'Account_Manager',
  Administrator: 'Administrator',
  // @deprecated
  BPO_Manager: 'BPO_Manager',
  Operations: 'Operations',
  Quality_Control: 'Quality_Control',
  Restaurant_Operator: 'Restaurant_Operator',
  Agent: 'Agent',
  BPO_Trainer: 'BPO_Trainer',
  IT_Administrator: 'IT_Administrator',
  BPO_QC: 'BPO_QC',
  BPO_Supervisor: 'BPO_Supervisor',
  SuperUserManuallyCreated: 'SuperUserManuallyCreated',
} as const;

export type UserRole = ValueOf<typeof USER_ROLES>;
