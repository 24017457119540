export const ORDER_FILTER_HANDOFF_MODES = [
  'Delivery',
  'Pickup',
  'Curbside',
] as const;

export const ORDER_STATUS = [
  'pending',
  'placed',
  'dismissed',
  'forward',
] as const;

export const PAYMENT_TYPES = ['Cash', 'Card'] as const;

export const APPLICATIONS = [
  'EXTENSION',
  'UOA',
  'REORDER',
  'ORDERBOT',
] as const;
