import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Card = styled(ParrotUi.Card)`
  display: flex;
  flex-direction: column;
  height: 160px;
`;

export const CardContent = styled(ParrotUi.CardContent)`
  display: flex;
  align-items: center;
  padding: 1.5rem;
`;

export const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem;
  justify-content: flex-start;
`;

export const MetricTitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const MetricTitle = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const MetricContent = styled.div`
  display: flex;
  gap: 5rem;
`;
