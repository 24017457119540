import _ from 'lodash';

import { PaginatedOrdersFilters } from '@/@types/order/paginatedOrder';
import { OrderFilterSchema } from '@/pages/Orders/List/schema';

export const mapOrderSearchParamsToOrderFilters = (search: URLSearchParams) => {
  const orderFilters: PaginatedOrdersFilters = Object.fromEntries(search);

  orderFilters.status =
    orderFilters.status?.toLowerCase() as OrderFilterSchema['status'];

  return orderFilters;
};

export const mapOrderFiltersToSearchParams = (
  filters: PaginatedOrdersFilters,
) => {
  const searchParams = new URLSearchParams(_.omitBy(filters, _.isUndefined));

  return searchParams;
};
