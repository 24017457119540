import { Typography } from '@kea-inc/parrot-ui';
import { ExternalLinkIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import { LinkWithIconProps } from './types';

export function LinkWithIcon({
  to,
  children,
  icon = ExternalLinkIcon,
  ...rest
}: LinkWithIconProps) {
  const navigate = useNavigate();
  const Icon = icon;

  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (evt) => {
    evt.stopPropagation();
    navigate(to);
  };

  return (
    <S.Link to="" onClick={handleLinkClick}>
      <Typography {...rest}>{children}</Typography>
      <Icon size={14} />
    </S.Link>
  );
}
