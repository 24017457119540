import * as ParrotUi from '@kea-inc/parrot-ui';
import { Circle, Dumbbell } from 'lucide-react';
import styled from 'styled-components';

export const Input = styled(ParrotUi.Input)`
  width: 250px;
`;

export const StatusCircleIcon = styled(Circle)`
  color: hsl(var(--gray));
  fill: hsl(var(--gray));
`;

export const WeightIcon = styled(Dumbbell)`
  color: hsl(var(--gray));
  fill: hsl(var(--gray));
`;

export const ActiveCircleIcon = styled(Circle)`
  color: hsl(var(--success));
  fill: hsl(var(--success));
`;

export const TrainingCircleIcon = styled(Circle)`
  color: hsl(var(--warning));
  fill: hsl(var(--warning));
`;

export const NotLongerActiveCircleIcon = styled(Circle)`
  color: hsl(var(--error));
  fill: hsl(var(--error));
`;
