import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/role';

export const prefix = 'useCases/role';

export const actions = {
  getPermissions: createAsyncThunk(
    `${prefix}/getPermissions`,
    async (_, thunkApi) => {
      try {
        const result = await api.getPermissions();

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getRoles: createAsyncThunk(`${prefix}/getRoles`, async (_, thunkApi) => {
    try {
      const result = await api.getRoles();

      return result;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }),
  getRole: createAsyncThunk(
    `${prefix}/getRole`,
    async (input: api.GetRole['input'], thunkApi) => {
      try {
        const result = await api.getRole(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateRole: createAsyncThunk(
    `${prefix}/updateRole`,
    async (input: api.UpdateRole['input'], thunkApi) => {
      try {
        const result = await api.updateRole(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
