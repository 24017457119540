import React from 'react';

import { useAuthorization } from '@/hooks/useAuthorization';
import { Permissions } from '@/services/@types/user/auth0Permissions';

interface ProtectedComponentProps {
  allowedPermissions: Permissions[];
  children: React.ReactNode;
}

export function ProtectedComponent(props: ProtectedComponentProps) {
  const { allowedPermissions, children } = props;
  const { permissions } = useAuthorization();

  if (allowedPermissions.length === 0) {
    return children;
  }

  const canRenderComponent = permissions.some((permission) =>
    allowedPermissions.includes(permission),
  );

  if (!canRenderComponent) {
    return null;
  }

  return children;
}
