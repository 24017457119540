import { Typography } from '@kea-inc/parrot-ui';

import { OrderMenuItemModifier } from '@/@types/order/item';

import { MenuItemOption } from './MenuItemOption';

interface MenuItemModifierProps {
  modifier: OrderMenuItemModifier;
  marginLeft: number;
}

export function MenuItemModifier(props: MenuItemModifierProps) {
  const { modifier, marginLeft } = props;
  const optionsWithoutModifiers = Object.values(modifier.options).filter(
    (option) =>
      !option.modifiers || !Object.values(option.modifiers ?? {}).length,
  );
  const optionsWithModifiers = Object.values(modifier.options).filter(
    (option) =>
      option.modifiers && Object.values(option.modifiers ?? {}).length,
  );
  return (
    <div>
      {optionsWithoutModifiers.map((option) => (
        <div key={option.id} style={{ marginLeft: `${marginLeft}rem` }}>
          <Typography color="gray">
            - {modifier?.spokenName}: {option.spokenName}
          </Typography>
        </div>
      ))}
      {optionsWithModifiers.map((option) => (
        <MenuItemOption
          key={option.id}
          option={option}
          marginLeft={marginLeft + 1.5}
        />
      ))}
    </div>
  );
}
