import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { Fleet } from '@/@types/fleet';
import { actions } from '@/store/useCases/fleet';

export const adapter = createEntityAdapter<Fleet>({
  sortComparer: (f1, f2) => f1.id.localeCompare(f2.id),
});

export type State = {
  fleets: EntityState<Fleet, string>;
};

export const initialState: State = {
  fleets: adapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/fleet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getFleets.fulfilled, (state, action) => {
      adapter.upsertMany(state.fleets, action.payload);
    });
    builder.addCase(actions.getFleet.fulfilled, (state, action) => {
      adapter.addOne(state.fleets, action.payload);
    });
    builder.addCase(actions.createFleet.fulfilled, (state, action) => {
      adapter.addOne(state.fleets, action.payload);
    });
    builder.addCase(actions.updateFleet.fulfilled, (state, action) => {
      adapter.updateOne(state.fleets, {
        id: action.payload.id,
        changes: action.payload,
      });
    });
    builder.addCase(
      actions.getFleetStoresByFleetId.fulfilled,
      (state, action) => {
        const fleetStores = action.payload;
        const fleetId = action.meta.arg.fleetId;
        adapter.updateOne(state.fleets, {
          id: fleetId,
          changes: { stores: fleetStores },
        });
      },
    );
    builder.addCase(
      actions.getFleetUsersByFleetId.fulfilled,
      (state, action) => {
        const fleetUsers = action.payload;
        const fleetId = action.meta.arg.fleetId;
        adapter.updateOne(state.fleets, {
          id: fleetId,
          changes: { users: fleetUsers },
        });
      },
    );
    builder.addCase(actions.deleteFleet.fulfilled, (state, action) => {
      adapter.removeOne(state.fleets, action.payload);
    });
  },
});

export default slice;
