import { FormField, Input, Label, FormItemInput } from '@kea-inc/parrot-ui';
import { MinusIcon, PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { BrandSchema } from '@/pages/Brands/BrandForm/schema';
import { BrandPromotionSchema } from '@/pages/Brands/BrandForm/schema/promotion';

import * as S from './styles';

const initPromo: BrandPromotionSchema = {
  code: '',
  description: '',
  name: '',
  prompt: '',
  deleted: false,
  isNew: true,
};

interface PromotionsProps {
  form: UseFormReturn<BrandSchema>;
  id: HTMLDivElement['id'];
}

export function Promotions(props: PromotionsProps) {
  const { form, id } = props;
  const [newPromo, setNewPromo] = useState<BrandPromotionSchema>(initPromo);

  const { fields, prepend, update } = useFieldArray({
    control: form.control,
    name: 'promos',
    keyName: 'customId',
  });

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Add Promotions</S.SectionTitle>
      <S.NewFieldContainer>
        <S.InputContainer>
          <Label>Code</Label>
          <Input
            value={newPromo.code}
            onChange={(evt) =>
              setNewPromo((pNewPromo) => ({
                ...pNewPromo,
                code: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.InputContainer>
          <Label>Name</Label>
          <Input
            value={newPromo.name}
            onChange={(evt) =>
              setNewPromo((pNewPromo) => ({
                ...pNewPromo,
                name: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.InputContainer>
          <Label>Description</Label>
          <Input
            value={newPromo.description}
            onChange={(evt) =>
              setNewPromo((pNewPromo) => ({
                ...pNewPromo,
                description: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.InputContainer>
          <Label>Prompt</Label>
          <Input
            value={newPromo.prompt}
            onChange={(evt) =>
              setNewPromo((pNewPromo) => ({
                ...pNewPromo,
                prompt: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.AddDeleteButton
          variant="ghost"
          disabled={!newPromo.code}
          type="button"
          size="icon"
          onClick={() => {
            const field = fields.find((f) => f.code === newPromo.code);
            if (!field) {
              prepend(newPromo);
              setNewPromo(initPromo);
            } else {
              if (field.deleted) {
                const index = fields.indexOf(field);
                update(index, newPromo);
                setNewPromo(initPromo);
              }
            }
          }}
        >
          <PlusIcon />
        </S.AddDeleteButton>
      </S.NewFieldContainer>
      <S.FieldArrayContainer>
        {fields
          .filter((field) => !field.deleted)
          .map((fieldItem) => {
            const index = fields.findIndex(
              (fldItem) => fldItem.customId === fieldItem.customId,
            );
            return (
              <S.FieldArrayItemContainer key={fieldItem.customId}>
                <FormField
                  control={form.control}
                  name={`promos.${index}.code`}
                  render={({ field }) => <FormItemInput field={field} />}
                />
                <FormField
                  control={form.control}
                  name={`promos.${index}.name`}
                  render={({ field }) => <FormItemInput field={field} />}
                />
                <FormField
                  control={form.control}
                  name={`promos.${index}.description`}
                  render={({ field }) => <FormItemInput field={field} />}
                />
                <FormField
                  control={form.control}
                  name={`promos.${index}.prompt`}
                  render={({ field }) => <FormItemInput field={field} />}
                />
                <S.AddDeleteButton
                  variant="ghost"
                  disabled={!fieldItem.code}
                  size="icon"
                  type="button"
                  onClick={() =>
                    update(index, {
                      ...fieldItem,
                      deleted: true,
                    })
                  }
                >
                  <MinusIcon />
                </S.AddDeleteButton>
              </S.FieldArrayItemContainer>
            );
          })}
      </S.FieldArrayContainer>
    </S.SectionContainer>
  );
}
