import { Typography } from '@kea-inc/parrot-ui';

import { OrderMenuItemOption } from '@/@types/order/item';
import { MenuItemModifier } from '@/pages/Orders/OrderInfo/OrderTabs/Cart/MenuItemModifier';
import { formatCurrency } from '@/utils/format';

interface MenuItemOptionProps {
  option: OrderMenuItemOption;
  marginLeft: number;
}

export function MenuItemOption(props: MenuItemOptionProps) {
  const { option, marginLeft } = props;
  return (
    <div
      key={option.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: `${marginLeft}rem`,
      }}
    >
      <Typography color="gray">
        - {option.quantity}x {option.spokenName} (
        {formatCurrency(option.cost * option.quantity)})
      </Typography>
      {Object.values(option.modifiers ?? {}).map((modifier) => (
        <MenuItemModifier
          modifier={modifier}
          marginLeft={marginLeft + 1.5}
          key={modifier.id}
        />
      ))}
    </div>
  );
}
