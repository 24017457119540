import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const PagesContainer = styled.div`
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
`;

export const Nav = styled.nav`
  display: flex;
  margin-top: 0.25rem;
  margin-left: 1.5rem;
`;

export const Link = styled(ReactRouterLink)`
  font-size: 0.9rem;
  color: hsl(var(--foreground));
`;

export const Header = styled.header`
  padding-left: 0.5rem;
  padding-right: 1rem;
  border-bottom-width: 1px;
`;

export const HeaderContent = styled.div`
  display: flex;
  height: 4rem;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;
