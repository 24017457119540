import { RouteObject } from 'react-router-dom';

import { Dashboard } from './Dashboard';

export const menuRouter: RouteObject[] = [
  {
    path: '/menu-tool',
    element: <Dashboard />,
    index: true,
  },
];
