import camelcaseKeys from 'camelcase-keys';

import { PaginatedUsersResponse, User } from '@/@types/user';
import { UserService } from '@/services/@types/user';

export const mapPaginatedUserResponseToDomain = (
  service: UserService.PaginatedUsersResponse,
): PaginatedUsersResponse => {
  const { items, ...rest } = service;

  return {
    ...camelcaseKeys(rest),
    items: items.map((item) => ({
      ...camelcaseKeys(item),
      permissions: item.permissions?.map((permission) => ({
        ...permission,
        id: permission.name,
      })),
    })),
  };
};

export const mapUserToDomain = (service: UserService.User): User => {
  const user = camelcaseKeys(service);

  return {
    ...user,
    permissions:
      user.permissions?.map((permission) => ({
        ...permission,
        id: permission.name,
      })) ?? [],
    roles: user.roles ?? [],
    fleets: user.fleets ?? [],
  };
};
