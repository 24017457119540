import {
  Avatar,
  AvatarFallback,
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Form,
  FormField,
  Select,
  Typography,
  FormItemInput,
  FormItemSelect,
  FormItemCombobox,
} from '@kea-inc/parrot-ui';
import { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import {
  APPLICATIONS,
  ORDER_FILTER_HANDOFF_MODES,
  ORDER_STATUS,
  PAYMENT_TYPES,
} from '@/constants/order';
import { useAppDispatch } from '@/store';
import { upperFirst } from '@/utils/methods';

import { useConnector } from './connector';
import * as S from './styles';
import { OrderFilterSchema } from '../schema';

interface FilterProps extends DialogProps {
  onSubmit: (values: OrderFilterSchema) => void;
  form: UseFormReturn<OrderFilterSchema>;
}

export function FilterDialog(props: FilterProps) {
  const dispatch = useAppDispatch();
  const { onSubmit, form } = props;

  const { actions, selectors } = useConnector();

  const { brands, stores, agents, fetching, fetchingStores } = selectors;

  const { brandId } = useWatch({ control: form.control });

  useEffect(() => {
    if (brandId) {
      dispatch(actions.getStoresByBrandId({ brandId }));
    }
  }, [brandId]);

  const filteredStores = useMemo(
    () => (brandId ? stores.filter((store) => store.brandId === brandId) : []),
    [brandId, stores],
  );

  return (
    <Dialog {...props}>
      <S.DialogContent>
        <DialogHeader>
          <DialogTitle>Orders filter</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="orderId"
                render={({ field }) => (
                  <FormItemInput field={field} label="Order ID" />
                )}
              />
              <FormField
                control={form.control}
                name="accountId"
                render={({ field }) => (
                  <FormItemInput field={field} label="Account ID" />
                )}
              />
              <FormField
                control={form.control}
                name="accountPhone"
                render={({ field }) => (
                  <FormItemInput field={field} label="Account phone" />
                )}
              />
              <FormField
                control={form.control}
                name="brandId"
                render={({ field }) => (
                  <FormItemCombobox
                    label="Brand"
                    placeholder="Select a brand"
                    field={field}
                    popoverContentProps={{ align: 'start' }}
                    items={brands}
                    emptyLabel="No brands found."
                    getItemLabel={(brand) => brand?.name}
                    renderItem={(brand) => (
                      <S.ComboboxItem key={brand.id}>
                        <S.NameEmailContainer>
                          <Typography>{brand.name}</Typography>
                        </S.NameEmailContainer>
                      </S.ComboboxItem>
                    )}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="storeId"
                render={({ field }) => (
                  <FormItemCombobox
                    label="Store"
                    field={field}
                    disabled={fetchingStores || !brandId}
                    placeholder="Select a store"
                    popoverContentProps={{ align: 'start' }}
                    items={filteredStores}
                    emptyLabel="No stores found."
                    getItemLabel={(store) =>
                      `${store?.area} | ${store?.franchiseStoreNumber}`
                    }
                    renderItem={(store) => (
                      <S.ComboboxItem key={store.id}>
                        <S.NameEmailContainer>
                          <Typography>{`${store.area} | ${store.franchiseStoreNumber}`}</Typography>
                        </S.NameEmailContainer>
                      </S.ComboboxItem>
                    )}
                  />
                )}
              />
            </S.FieldItemsContainer>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="agentId"
                render={({ field }) => (
                  <FormItemCombobox
                    label="Agent"
                    placeholder="Select an agent"
                    field={field}
                    popoverContentProps={{ align: 'start' }}
                    items={agents}
                    emptyLabel="No agents found."
                    getItemLabel={(agent) =>
                      `${agent?.firstName} ${agent?.lastName} (${agent?.username})`
                    }
                    renderItem={(agent) => (
                      <S.ComboboxItem key={agent.email}>
                        <Avatar>
                          <AvatarFallback>
                            {`${agent.firstName.at(0)}${agent.lastName.at(
                              0,
                            )}`.toUpperCase()}
                          </AvatarFallback>
                        </Avatar>
                        <S.NameEmailContainer>
                          <S.AgentName>{`${agent.firstName} ${agent.lastName}`}</S.AgentName>
                          <Typography muted>{agent.email}</Typography>
                        </S.NameEmailContainer>
                      </S.ComboboxItem>
                    )}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="handoffMode"
                render={({ field }) => (
                  <FormItemSelect label="Handoff mode" field={field}>
                    {ORDER_FILTER_HANDOFF_MODES.map((handoffMode) => (
                      <Select.Item value={handoffMode} key={handoffMode}>
                        {handoffMode}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
              <FormField
                control={form.control}
                name="status"
                render={({ field }) => (
                  <FormItemSelect label="Status" field={field}>
                    {ORDER_STATUS.map((status) => (
                      <Select.Item value={status} key={status}>
                        {upperFirst(status)}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
              <FormField
                control={form.control}
                name="paymentType"
                render={({ field }) => (
                  <FormItemSelect label="Payment type" field={field}>
                    {PAYMENT_TYPES.map((paymentType) => (
                      <Select.Item value={paymentType} key={paymentType}>
                        {paymentType}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
              <FormField
                control={form.control}
                name="orderApplication"
                render={({ field }) => (
                  <FormItemSelect label="Order application" field={field}>
                    {APPLICATIONS.map((application) => (
                      <Select.Item value={application} key={application}>
                        {application}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
            </S.FieldItemsContainer>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="dateRange"
                render={({ field }) => (
                  <S.FormItemDatePicker
                    field={field}
                    label="Date range"
                    datePickerProps={{
                      CalendarProps: {
                        mode: 'range',
                        selected: {
                          from: field.value?.from,
                          to: field.value?.to,
                        },
                        onSelect: (range) =>
                          field.onChange({ from: range?.from, to: range?.to }),
                        initialFocus: true,
                        numberOfMonths: 2,
                      },
                    }}
                  />
                )}
              />
            </S.FieldItemsContainer>
            <DialogFooter>
              <Button type="submit" loading={fetching}>
                Apply
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </S.DialogContent>
    </Dialog>
  );
}
