import { Middleware, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

export const printMiddleware: Middleware =
  (_) => (next: ThunkDispatch<any, any, any>) => (actn) => {
    const action = actn as PayloadAction<
      any,
      string,
      { arg: Record<string, unknown> | string }
    >;
    if (action.type.includes('/pending')) {
      console.log(
        `%c ${action.type.substring(0, action.type.lastIndexOf('/'))} %c`,
        `color: white; font-weight: bolder; background: blue`,
        'font-weight: bolder; font-style: italic;',
        action.meta.arg || '',
      );
    }

    if (action.type.includes('/fulfilled')) {
      console.log(
        `%c ${action.type.substring(0, action.type.lastIndexOf('/'))} %c`,
        `color: white; font-weight: bolder; background: green`,
        'font-weight: bolder; font-style: italic;',
        action.payload &&
          typeof action.payload === 'object' &&
          Object.keys(action.payload).length !== 0
          ? action.payload
          : '',
      );
    }
    return next(action);
  };

export const errorMiddleware: Middleware =
  () => (next: ThunkDispatch<any, any, any>) => (actn) => {
    const action = actn as PayloadAction<
      any,
      string,
      { arg: Record<string, unknown> | string }
    >;

    if (action.type.includes('/rejected')) {
      console.log(
        `%c ${action.type.substring(0, action.type.lastIndexOf('/'))} %c`,
        `color: white; font-weight: bolder; background: red`,
        'font-weight: bolder; font-style: italic;',
        action.payload &&
          typeof action.payload === 'object' &&
          Object.keys(action.payload).length !== 0
          ? action.payload
          : '',
      );
    }
    return next(action);
  };
