import * as ParrotUi from '@kea-inc/parrot-ui';
import {
  ArrowUpRightFromSquare,
  LogOut,
  Moon,
  Settings,
  Sun,
} from 'lucide-react';
import styled, { css } from 'styled-components';

export const UserMenuContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const Avatar = styled(ParrotUi.Avatar)`
  border-width: 2px;
  border-color: hsl(var(--white));
`;

export const DropdownMenuLabel = styled(ParrotUi.DropdownMenuLabel)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled(ParrotUi.Typography)`
  font-weight: bold;
`;

export const UserEmail = styled(ParrotUi.Typography)`
  color: hsl(var(--gray));
`;

const userMenuIconStyle = css`
  color: hsl(var(--gray));
  margin-right: 0.5rem;
`;

export const SettingsIcon = styled(Settings)`
  ${userMenuIconStyle}
`;

export const MoonIcon = styled(Moon)`
  ${userMenuIconStyle}
`;

export const SunIcon = styled(Sun)`
  ${userMenuIconStyle}
`;
export const ArrowUpRightFromSquareIcon = styled(ArrowUpRightFromSquare)`
  ${userMenuIconStyle}
`;

export const LogOutIcon = styled(LogOut)`
  ${userMenuIconStyle}
  color: hsl(var(--error))
`;
