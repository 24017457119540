import { BadgeVariants } from '@kea-inc/parrot-ui';
import { Color } from '@kea-inc/parrot-ui/dist/types';

import { DataServiceOrder, Order } from '@/@types/order';

export const mapStatusColorToVariant = (statusColor: Color): BadgeVariants => {
  switch (statusColor) {
    case 'primary':
    case 'error':
    case 'success':
    case 'black':
    case 'warning':
      return statusColor;

    case 'gray':
      return 'outline';
    case 'accent_1':
      return 'accent1';
    case 'accent_2':
      return 'accent2';

    case 'white':
      return 'secondary';

    default:
      return 'primary';
  }
};

export const mapStoreStatusToVariant = (status: string): BadgeVariants => {
  switch (status) {
    case 'ACTIVE':
      return 'success';
    case 'INACTIVE':
      return 'error';

    default:
      return 'primary';
  }
};

export const mapOrderStatusToVariant = (
  status: Order['status'],
): BadgeVariants => {
  const statusToVariant: Record<Order['status'], BadgeVariants> = {
    dismissed: 'error',
    forward: 'warning',
    pending: 'primary',
    placed: 'success',
  };

  return statusToVariant[status];
};

export const mapOrderPaymentTypeToVariant = (
  paymentType: Order['paymentType'],
): BadgeVariants => {
  const statusToVariant: Record<Order['paymentType'], BadgeVariants> = {
    credit_card: 'primary',
    cash: 'success',
  };

  return statusToVariant[paymentType];
};

export const mapDataServiceOrderPaymentTypeToVariant = (
  paymentType: DataServiceOrder['paymentType'],
): BadgeVariants => {
  const statusToVariant: Record<
    DataServiceOrder['paymentType'],
    BadgeVariants
  > = {
    Card: 'primary',
    Cash: 'success',
    '-': 'warning',
  };

  return statusToVariant[paymentType];
};

export const mapDataServiceOrderStatusToVariant = (
  status: DataServiceOrder['orderStatus'],
): BadgeVariants => {
  const statusToVariant: Record<
    DataServiceOrder['orderStatus'],
    BadgeVariants
  > = {
    Dismissed: 'error',
    Forward: 'warning',
    Pending: 'primary',
    Placed: 'success',
  };

  if (!status) {
    return 'warning';
  }

  return statusToVariant[status];
};
