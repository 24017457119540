import {
  Badge,
  BadgeProps,
  ColumnDef,
  DataTableSortHeader,
  Typography,
} from '@kea-inc/parrot-ui';

import { Agent } from '@/@types/agent';
import { TextCenter } from '@/components/DataTable';
import { AgentActionsColumn } from '@/pages/Agents/List/AgentActionsColumn';

const STATUS_TO_BADGE_VARIANT: Record<Agent['status'], BadgeProps['variant']> =
  {
    ACTIVE: 'success',
    NO_LONGER_ACTIVE: 'error',
    TRAINING: 'warning',
  } as const;

type UseAgentColumnDefsOpts = {
  onUpdateClick: (bpo: Agent) => void;
  onDeleteClick: (bpo: Agent) => void;
};

export const useAgentColumnDefs = (opts: UseAgentColumnDefsOpts) => {
  const { onDeleteClick, onUpdateClick } = opts;

  const columns: ColumnDef<Agent>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Name" />,
      cell: ({ row }) => (
        <Typography>
          {`${row.original.firstName} ${row.original.lastName}`}
        </Typography>
      ),
    },
    {
      accessorKey: 'username',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Username" />,
      cell: ({ row }) => <Typography>{row.original.username}</Typography>,
    },
    {
      accessorKey: 'email',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Email" />,
      cell: ({ row }) => <Typography>{row.original.email}</Typography>,
    },
    {
      accessorKey: 'roleWeight',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Role weight" />
      ),
      cell: ({ row }) => <Typography>{row.original.roleWeight}</Typography>,
    },
    {
      accessorKey: 'status',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Status" />,
      cell: ({ row }) => (
        <Badge variant={STATUS_TO_BADGE_VARIANT[row.original.status]}>
          {row.original.status}
        </Badge>
      ),
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => (
        <AgentActionsColumn
          agent={row.original}
          onDeleteClick={onDeleteClick}
          onUpdateClick={onUpdateClick}
        />
      ),
    },
  ];

  return { columns };
};
