import dayjs from 'dayjs';

export const getStoreHourFromToOnOff = (from: string, to: string) => {
  const fromSplitted = from.split(':');
  const toSplitted = to.split(':');
  const todayFrom = dayjs()
    .utc()
    .hour(Number(fromSplitted.at(0)))
    .minute(Number(fromSplitted.at(1)))
    .second(0);

  const todayTo = dayjs()
    .utc()
    .hour(Number(toSplitted.at(0)))
    .minute(Number(toSplitted.at(1)))
    .second(0);

  return { todayFrom, todayTo };
};
