import { Typography } from '@kea-inc/parrot-ui';

import { Store } from '@/@types/store';

import * as S from './styles';

interface SummaryProps {
  stores: Partial<Store>[];
}

export function Summary(props: SummaryProps) {
  const { stores } = props;
  return (
    <S.Container>
      <Typography>Summary</Typography>
      <S.Card>
        <S.CardContent>
          <S.CircleCheckIcon size={48} />
          <S.ContentContainer>
            <S.SuccessTypography color="success">Success</S.SuccessTypography>
            <Typography>
              {stores.length} store(s) have been submitted for creation. Follow
              the results in Slack channel.
            </Typography>
          </S.ContentContainer>
        </S.CardContent>
      </S.Card>
    </S.Container>
  );
}
