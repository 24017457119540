import { Permissions } from '@/services/@types/user/auth0Permissions';

export const userRoles = {
  AccountManager: 'Account_Manager',
  Administrator: 'Administrator',
  BPOManager: 'BPO_Manager',
  Operations: 'Operations',
  QualityControl: 'Quality_Control',
  RestaurantOperator: 'Restaurant_Operator',
  Agent: 'Agent',
  BPOTrainer: 'BPO_Trainer',
  BPOQC: 'BPO_QC',
  BPOSupervisor: 'BPO_Supervisor',
};

export const environments = {
  DEV: 'develop',
  STAGING: 'staging',
  UAT: 'uat',
  PRODUCTION: 'production',
};

export type Route = {
  tab: string;
  roles: string[];
  path?: string;
  envs?: string[];
  permissions?: Permissions[];
};

export type AppRoutes = {
  [key: string]: Route;
};

const {
  AccountManager,
  Administrator,
  BPOManager,
  Operations,
  QualityControl,
  BPOTrainer,
  BPOSupervisor,
  BPOQC,
} = userRoles;

const { DEV, STAGING, UAT } = environments;

export const ROUTES = {
  '/fleets': {
    tab: 'Fleets',
    roles: [Administrator, Operations, AccountManager],
    permissions: ['access:fleets'],
  },
  '/stores': {
    tab: 'Stores',
    roles: [Administrator, Operations, AccountManager],
    permissions: ['access:stores'],
  },
  '/bpos': {
    tab: 'BPOs',
    roles: [Administrator, Operations, AccountManager],
    permissions: ['access:bpos'],
  },
  '/agents': {
    tab: 'Agents',
    roles: [Administrator, Operations, AccountManager, BPOManager],
    permissions: ['access:agents'],
  },
  '/brands': {
    tab: 'Brands',
    roles: [Administrator, Operations, AccountManager],
    permissions: ['access:brands'],
  },
  '/orders': {
    tab: 'Orders',
    roles: [
      Administrator,
      Operations,
      AccountManager,
      QualityControl,
      BPOManager,
      BPOTrainer,
      BPOQC,
      BPOSupervisor,
    ],
    permissions: ['access:orders'],
  },
  '/platforms': {
    tab: 'Platforms',
    roles: [Administrator],
    permissions: ['access:platforms'],
  },
  '/admin': {
    tab: 'Admin',
    roles: [Administrator, Operations, AccountManager],
    permissions: ['access:users'],
  },
  '/training': {
    tab: 'Training',
    roles: [
      Administrator,
      Operations,
      BPOManager,
      QualityControl,
      BPOTrainer,
      BPOQC,
    ],
    envs: [DEV, STAGING, UAT],
    permissions: ['access:training'],
  },
  '/metrics': {
    tab: 'Metrics',
    roles: [Administrator, Operations, BPOManager, BPOTrainer, BPOSupervisor],
    permissions: ['access:metrics'],
  },
  '/menu-tool': {
    tab: 'Menu Tool',
    roles: [Administrator, Operations, QualityControl, BPOTrainer, BPOQC],
    permissions: ['access:menu'],
  },
  '/accounts': {
    tab: 'Accounts',
    roles: [Administrator, Operations, AccountManager],
    permissions: ['read:accounts'],
  },
} satisfies Record<string, Route>;

export type RoutesType = typeof ROUTES;
