import Fuse, { FuseOptionKey, IFuseOptions } from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  actions as searchUiActions,
  selectors as searchUiSelectors,
} from '@/store/ui/search';
import { NestedArrayKeyOf } from '@/utils/methods';

export const getFuseOptions = <T extends Record<string, unknown>>(
  keys: (keyof T)[],
): IFuseOptions<T> => ({
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  isCaseSensitive: false,
  keys: keys as FuseOptionKey<T>[],
});

type UseFuseSearchOpts<T extends Record<string, unknown>> = {
  items: T[];
  itemKeys: NestedArrayKeyOf<T>[];
};

export const useFuseSearch = <T extends Record<string, unknown>>(
  opts: UseFuseSearchOpts<T>,
) => {
  const searches = useAppSelector(searchUiSelectors.getSearches);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { itemKeys, items } = opts;
  const fuseOptions: IFuseOptions<T> = useMemo(
    () => getFuseOptions<T>(itemKeys),
    [],
  );
  const fuse = useMemo(() => new Fuse(items, fuseOptions), [items]);
  const [searchTerm, setSearchTerm] = useState<string>(
    searches[location.pathname],
  );

  const filteredItems = useMemo(() => {
    if (searchTerm) {
      return fuse.search(searchTerm).map(({ item }) => item);
    }
    return items;
  }, [searchTerm, items]);

  useEffect(() => {
    if (searchTerm) {
      dispatch(searchUiActions.addSearch({ [location.pathname]: searchTerm }));
    }
  }, [searchTerm]);

  return { filteredItems, setSearchTerm, searchTerm };
};
