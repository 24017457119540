import { CopyIcon, ListIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Agent } from '@/@types/agent';
import { DropdownMenuItemType, TableActions } from '@/components/TableActions';
import { useConnector } from '@/pages/Agents/AgentForm/connector';
import { useAppDispatch } from '@/store';

import * as S from './styles';

interface AgentActionsColumnProps {
  agent: Agent;
  onUpdateClick: (bpo: Agent) => void;
  onDeleteClick: (bpo: Agent) => void;
}

export function AgentActionsColumn(props: AgentActionsColumnProps) {
  const { agent, onDeleteClick, onUpdateClick } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { actions } = useConnector();

  const handleCopyIDToClipboard = () => {
    navigator.clipboard.writeText(agent.id);
  };

  const CHANGE_STATUS_SUB_MENUS: Record<Agent['status'], DropdownMenuItemType> =
    useMemo(
      () =>
        ({
          ACTIVE: {
            Icon: <S.ActiveCircleIcon />,
            label: 'Active',
            onClick: () =>
              dispatch(actions.updateAgent({ id: agent.id, status: 'ACTIVE' })),
          },
          TRAINING: {
            Icon: <S.TrainingCircleIcon />,
            label: 'Training',
            onClick: () =>
              dispatch(
                actions.updateAgent({ id: agent.id, status: 'TRAINING' }),
              ),
          },
          NO_LONGER_ACTIVE: {
            Icon: <S.NotLongerActiveCircleIcon />,
            label: 'No Longer Active',
            onClick: () =>
              dispatch(
                actions.updateAgent({
                  id: agent.id,
                  status: 'NO_LONGER_ACTIVE',
                }),
              ),
          },
        }) as const,
      [],
    );
  return (
    <TableActions
      onDeleteClick={() => onDeleteClick(agent)}
      onUpdateClick={() => onUpdateClick(agent)}
      dropDownMenuLabel={agent.username}
      dropdownSubMenuItems={[
        {
          label: 'Change status',
          Icon: <S.StatusCircleIcon />,
          subMenus: Object.entries(CHANGE_STATUS_SUB_MENUS)
            .filter(([status, _]) => status !== agent.status)
            .map(([_, subMenu]) => subMenu),
        },
      ]}
      dropdownMenuItems={[
        {
          Icon: <ListIcon />,
          label: 'See orders',
          onClick: () => navigate(`/orders?agentId=${agent.id}`),
        },
        {
          Icon: <CopyIcon />,
          label: 'Copy ID',
          onClick: handleCopyIDToClipboard,
        },
      ]}
    />
  );
}
