import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Form,
  FormField,
  FormItemInput,
} from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { useConnector } from './connector';
import * as S from './styles';
import { AccountFilterSchema } from '../schema';

interface FilterProps extends DialogProps {
  onSubmit: (values: AccountFilterSchema) => void;
  form: UseFormReturn<AccountFilterSchema>;
}

export function FilterDialog(props: FilterProps) {
  const { onSubmit, form } = props;

  const { selectors } = useConnector();

  const { fetching } = selectors;

  return (
    <Dialog {...props}>
      <S.DialogContent>
        <DialogHeader>
          <DialogTitle>Accounts filter</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="ids"
                render={({ field }) => (
                  <FormItemInput field={field} label="Account ID" />
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItemInput field={field} label="Account phone" />
                )}
              />
            </S.FieldItemsContainer>

            <DialogFooter>
              <Button type="submit" loading={fetching}>
                Apply
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </S.DialogContent>
    </Dialog>
  );
}
