import { FleetUser } from '@/@types/fleet';
import { dataService } from '@/services';
import { FleetService } from '@/services/@types/fleet';
import { mapFleetUseToDomain } from '@/services/useCases/fleet/adapters/domain';

export type GetFleetUsersByFleetId = {
  input: { fleetId: string };
  output: FleetUser[];
};

export const getFleetUsersByFleetId = async (
  input: GetFleetUsersByFleetId['input'],
): Promise<GetFleetUsersByFleetId['output']> => {
  const { data } = await dataService.get<FleetService.FleetUser[]>(
    `/fleets/${input.fleetId}/users`,
  );

  return data.map(mapFleetUseToDomain);
};
