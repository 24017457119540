import { Button, Typography } from '@kea-inc/parrot-ui';
import {
  DownloadIcon,
  PauseIcon,
  PlayIcon,
  Volume2Icon,
  VolumeXIcon,
} from 'lucide-react';

import { Order } from '@/@types/order';
import { FlexGrow } from '@/components/FlexGrow';
import {
  getRecordingTime,
  useRecording,
} from '@/pages/Orders/OrderInfo/OrderData/Recording/useRecording';

import * as S from './styles';

interface RecordingProps {
  order: Order;
}

export function Recording(props: RecordingProps) {
  const { order } = props;

  const {
    audioRef,
    handleRecordingStateChange,
    recordingState,
    selectedRecording,
    setSelectedRecording,
  } = useRecording({ order });

  const { audioDuration, isPlaying, muted, progress, speed, trackerTimes } =
    recordingState;

  function handleLoadedData() {
    if (audioRef.current?.duration) {
      handleRecordingStateChange({
        audioDuration: getRecordingTime(audioRef.current.duration),
      });
    }
  }

  const handlePlaying = (play: boolean) => () => {
    handleRecordingStateChange({ isPlaying: play });
  };

  const handleChangeProgress: React.ChangeEventHandler<HTMLInputElement> = (
    evt,
  ) => {
    handleRecordingStateChange({ progress: evt.target.valueAsNumber });

    if (audioRef.current) {
      const duration =
        (evt.target.valueAsNumber * audioRef.current.duration) / 100;
      audioRef.current.currentTime = duration;

      handleRecordingStateChange({ trackerTimes: getRecordingTime(duration) });
    }
  };

  const handleClickMute = (mute: boolean) => () => {
    handleRecordingStateChange({ muted: !muted });
    if (audioRef.current) {
      audioRef.current.muted = mute;
    }
  };

  const handleClickSpeed = () => {
    if (audioRef.current) {
      const value = speed + 0.5;
      const newSpeed = value > 2 ? 1 : value;

      audioRef.current.playbackRate = newSpeed;
      handleRecordingStateChange({ speed: newSpeed });
    }
  };

  function handleClickDownload() {
    if (!selectedRecording) {
      return;
    }

    const link = document.createElement('a');
    link.href = selectedRecording;
    link.download = `${order.id}_${selectedRecording.split('/').at(-1)}.wav`;
    link.click();
  }

  if (!selectedRecording) {
    return <div />;
  }
  return (
    <S.Container>
      <S.RecordingHeaderContainer>
        <S.Select
          value={selectedRecording}
          onValueChange={setSelectedRecording}
        >
          {order?.recordingUrls?.map((recording, index) => (
            <S.SelectItem key={recording} value={recording}>
              {`Recording ${index + 1}`}
            </S.SelectItem>
          ))}
        </S.Select>
        <FlexGrow />
        <Button variant="link" onClick={handleClickSpeed}>
          {speed}x
        </Button>
        <S.DownloadIconButton onClick={handleClickDownload}>
          <DownloadIcon size={15} />
        </S.DownloadIconButton>
      </S.RecordingHeaderContainer>
      <S.RecordingContainer>
        <S.RecordingIconButton onClick={handlePlaying(!isPlaying)}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </S.RecordingIconButton>
        <Typography>
          {trackerTimes.minutes.toString().padStart(2, '0')}:
          {trackerTimes.seconds.toString().padStart(2, '0')}
        </Typography>
        <S.TrackerInputContainer>
          <S.TrackerInput
            onChange={handleChangeProgress}
            type="range"
            min="1"
            max="100"
            step={0.1}
            value={progress}
          />
        </S.TrackerInputContainer>

        <Typography>
          {audioDuration.minutes.toString().padStart(2, '0')}:
          {audioDuration.seconds.toString().padStart(2, '0')}
        </Typography>

        <S.Audio
          ref={audioRef}
          title={`ORDER_${order.id}`}
          onLoadedData={handleLoadedData}
        />
        <S.RecordingIconButton onClick={handleClickMute(!muted)}>
          {!muted ? <Volume2Icon /> : <VolumeXIcon />}
        </S.RecordingIconButton>
      </S.RecordingContainer>
    </S.Container>
  );
}
