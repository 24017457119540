import { Button, DataTable } from '@kea-inc/parrot-ui';
import _ from 'lodash';
import { FilterIcon } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FlexGrow } from '@/components/FlexGrow';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { FilterDialog } from '@/pages/Accounts/List/FilterDialog';
import { mapFilterFormValuesToApi } from '@/pages/Accounts/List/adapters';
import { mapAccountSearchParamsToAccountFilters } from '@/pages/Accounts/List/adapters/domain';
import { useFiltersForm } from '@/pages/Accounts/List/useFiltersForm';
import { useConnector } from '@/pages/Accounts/connector';
import { useAccountsColumnDefs } from '@/pages/Accounts/hooks/useAccountsColumnDefs';
import { useAppDispatch } from '@/store';

const pageSize = 15;

export function List() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [lastPage, setLastPage] = useState(1);

  const { actions, selectors } = useConnector();

  const { fetching, totalRecords, accountsWithFilters, filters } = selectors;

  const accounts = filters ? accountsWithFilters ?? [] : selectors.accounts;

  const { columns } = useAccountsColumnDefs();

  const {
    form,
    filterOpen,
    searchParams,
    setFilterOpen,
    handleFinallySubmit,
    handleClearFilters,
  } = useFiltersForm();

  const handlePaginatedAccounts = useCallback(
    _.debounce(
      (sParams?: URLSearchParams) =>
        dispatch(
          actions.getPaginatedAccounts({
            page: lastPage,
            pageSize,
            filters: sParams?.size
              ? mapAccountSearchParamsToAccountFilters(sParams)
              : undefined,
          }),
        )
          .unwrap()
          .then((res) => {
            setLastPage(lastPage + 1);
            dispatch(actions.setTotalRecords(res.totalRecords));
          }),
      500,
    ),
    [searchParams],
  );

  useEffect(() => {
    if (!fetching) {
      handlePaginatedAccounts(searchParams);
    }
  }, [searchParams]);

  const handleLoadMore = () => {
    if (!fetching)
      dispatch(
        actions.getPaginatedAccounts({ page: lastPage, pageSize, filters }),
      )
        .unwrap()
        .then((res) => {
          setLastPage(lastPage + 1);
          if (res.items.length < pageSize) {
            dispatch(actions.setTotalRecords(accounts.length));
          }
        });
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    const filteredValues = mapFilterFormValuesToApi(values);
    dispatch(
      actions.getPaginatedAccounts({
        page: 1,
        pageSize,
        filters: filteredValues,
      }),
    )
      .unwrap()
      .then((res) => {
        setLastPage(2);
        dispatch(actions.setTotalRecords(res.totalRecords));
      })
      .finally(() => {
        handleFinallySubmit(filteredValues);
      });
  };

  const PageCardHeader = (
    <>
      <FlexGrow />
      {filters ? (
        <Button onClick={handleClearFilters} variant="ghost">
          Clear filters
        </Button>
      ) : null}

      <Button onClick={() => setFilterOpen(true)}>
        <FilterIcon size={14} /> Filter
      </Button>
    </>
  );

  return (
    <PageCard title="Accounts" headerContent={PageCardHeader}>
      {fetching && accounts.length === 0 ? (
        <KeaLoading />
      ) : (
        <DataTable
          columns={columns}
          data={accounts}
          stickyHeader
          onRowClick={(row) => {
            navigate(`/accounts/${row.original.id}`);
          }}
          virtualizationProps={{ estimateSize: () => 90, overscan: 5 }}
          infiniteLoadProps={{
            onLoadMore: handleLoadMore,
            totalRecords,
          }}
        />
      )}
      <FilterDialog
        open={filterOpen}
        onOpenChange={setFilterOpen}
        onSubmit={handleSubmit}
        form={form}
      />
    </PageCard>
  );
}
