import React from 'react';
import { Device } from 'twilio-client';

import { Agent, AgentBrand } from '@/@types/agent';
import { Store } from '@/@types/store';

export interface Token {
  token: string;
}
export interface TrainingProviderState {
  connectedAt: Date | null;
  createTrainingTask: (handoffMode?: string) => void;
  device: Device | null;
  handleAccept: () => void;
  handleDecline: () => void;
  isConnected: boolean;
  isCreatingTask: boolean;
  isExpectingCall: boolean;
  isReady: boolean;
  isRinging: boolean;
  selectedAgent: Agent | null;
  selectedBrand: AgentBrand | null;
  selectedStore: Store | null;
  setSelectedAgent: React.Dispatch<React.SetStateAction<Agent | null>>;
  setSelectedBrand: React.Dispatch<React.SetStateAction<AgentBrand | null>>;
  setSelectedStore: React.Dispatch<React.SetStateAction<Store | null>>;
  setupDevice: () => void;
}

export interface TrainingProviderProps {
  children: React.ReactNode;
}

export enum DeviceEvents {
  READY = 'ready',
  INCOMING = 'incoming',
  ERROR = 'error',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}
