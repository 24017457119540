import { recordingsService } from '@/services/clients';
import { mapRecordingToDomain } from '@/services/useCases/order/adapters/domain/recording';

export type GetOrderRecording = {
  input: { orderId: string };
  output: { urls: string[] };
};

export const getOrderRecording = async (
  input: GetOrderRecording['input'],
): Promise<GetOrderRecording['output']> => {
  const {
    data: { versions },
  } = await recordingsService.get<{ versions: string[] }>(
    `/recordings/${input.orderId}/versions`,
  );

  const recordings = await Promise.all(
    versions.map((version) =>
      recordingsService.get<Blob>(`/recordings/${input.orderId}`, {
        responseType: 'blob',
        params: { version_id: version },
      }),
    ),
  );

  return {
    urls: recordings.map(({ data }) => mapRecordingToDomain(data)),
  };
};

export type DeleteOrderRecording = {
  input: { orderId: string };
  output: void;
};

export const deleteOrderRecording = async (
  input: DeleteOrderRecording['input'],
): Promise<DeleteOrderRecording['output']> => {
  await recordingsService.delete(`/recordings/${input.orderId}`);
};
