import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const DialogContent = styled(ParrotUi.DialogContent)`
  min-width: 60vw;
`;

export const FieldItemsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1rem;
`;

export const ComboboxItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
