import { ColumnDef, DataTableSortHeader, Typography } from '@kea-inc/parrot-ui';

import { Platform } from '@/@types/platform';
import { TextRight } from '@/components/DataTable';

import * as S from './styles';

type UseAgentColumnDefsOpts = {
  onUpdateClick: (bpo: Platform) => void;
  onDeleteClick: (bpo: Platform) => void;
};

export const usePlatformColumnDefs = (opts: UseAgentColumnDefsOpts) => {
  const { onDeleteClick, onUpdateClick } = opts;

  const columns: ColumnDef<Platform>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Name" />,
      cell: ({ row }) => <Typography>{row.original.name}</Typography>,
    },
    {
      accessorKey: 'actions',
      header: () => <TextRight>Actions</TextRight>,
      cell: ({ row }) => (
        <S.TableActions
          onDeleteClick={() => onDeleteClick(row.original)}
          onUpdateClick={() => onUpdateClick(row.original)}
        />
      ),
    },
  ];

  return { columns };
};
