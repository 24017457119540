import * as z from 'zod';

import { BrandPromo } from '@/@types/brand';

type BrandPromotionFormValues = Pick<
  BrandPromo,
  'id' | 'code' | 'name' | 'description' | 'prompt'
> & { isNew: boolean; deleted: boolean };

type SchemaObjShape = Record<keyof BrandPromotionFormValues, z.ZodTypeAny>;

export const promotionSchema = z.object({
  id: z.string().optional(),
  code: z.string(),
  name: z.string(),
  description: z.string(),
  prompt: z.string(),
  deleted: z.boolean().optional(),
  isNew: z.boolean().optional(),
} satisfies SchemaObjShape);

export type BrandPromotionSchema = z.infer<typeof promotionSchema>;
