import { Store } from '@/@types/store';
import { dataService } from '@/services';
import { StoreService } from '@/services/@types/store';
import { mapCreateUpdateStoreToApi } from '@/services/useCases/store/adapters/api';
import { mapStoreToDomain } from '@/services/useCases/store/adapters/domain';

export type CreateStore = {
  input: Partial<Store>;
  output: Store;
};

export async function createStore(
  input: CreateStore['input'],
): Promise<CreateStore['output']> {
  const { data } = await dataService.post<
    StoreService.Store,
    StoreService.CreateUpdateRequest
  >(`/stores`, mapCreateUpdateStoreToApi(input));

  return mapStoreToDomain(data);
}
