import * as ParrotUi from '@kea-inc/parrot-ui';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0.1rem;
  }
`;

export const Typography = styled(ParrotUi.Typography)<{
  $weight?: CSSProperties['fontWeight'];
  $color?: string;
}>`
  font-weight: ${(props) => props.$weight};
  white-space: nowrap;
  @media only screen and (min-height: 720px) and (max-width: 1280px) {
    font-size: 16px;
  }
`;
