import camelcaseKeys from 'camelcase-keys';

import { AgentBrand } from '@/@types/agent';
import { Brand, BrandPromo, BrandUpsell } from '@/@types/brand';
import { AgentService } from '@/services/@types/agent';
import { BrandService } from '@/services/@types/brand';

export const mapBrandPromoToDomain = (
  service: BrandService.BrandPromo,
): BrandPromo => camelcaseKeys(service);

export const mapBrandUpsellToDomain = (
  service: BrandService.BrandUpsell,
): BrandUpsell => camelcaseKeys(service);

export const mapBrandToDomain = (brand: BrandService.Brand): Brand => ({
  ...camelcaseKeys(brand),
  platforms: brand.platforms?.map((platform) => camelcaseKeys(platform)),
});

export const mapAgentBrandToDomain = (
  brand: AgentService.AgentBrand,
): AgentBrand => ({
  ...brand,
  ...mapBrandToDomain(brand),
});
