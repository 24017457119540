import { z } from 'zod';

import { TriageHandoffMethods } from '@/@types/brand';

type SchemaObjShape = Record<keyof TriageHandoffMethods, z.ZodTypeAny>;

export const triageHandoffMethodsSchema = z.object({
  curbside: z.boolean(),
  delivery: z.boolean(),
  dinein: z.boolean(),
  pickup: z.boolean(),
} satisfies SchemaObjShape);

export type BrandPlatformSchema = z.infer<typeof triageHandoffMethodsSchema>;
