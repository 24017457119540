import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PageCard as PageCardComponent } from '@/components/PageCard';

export const PageCard = styled(PageCardComponent).attrs({
  cardContentClassName: 'cardContentClassName',
})`
  .cardContentClassName {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 0px;
  }
`;
export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Linked = styled(Link)`
  color: hsl(var(--primary));
`;
