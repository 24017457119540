import { Store } from '@/@types/store';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';
import { getStoreHourFromToOnOff } from '@/utils/storeHours';

export const mapStoreHoursFormValuesToStore = (
  values: StoreSchema['storeHours'],
  timezone: StoreSchema['timezone'],
): Store['storeHours'] =>
  Object.values(values ?? {}).flatMap((storeHour) => {
    const { todayFrom, todayTo } = getStoreHourFromToOnOff(
      storeHour.from,
      storeHour.to,
    );

    const onActionTime =
      storeHour.fromAmPm === 'PM' ? todayFrom.add(12, 'hour') : todayFrom;
    const offActionTime =
      storeHour.toAmPm === 'PM' ? todayTo.add(12, 'hour') : todayTo;

    return [
      {
        action: 'TURN_ON',
        dayOfWeek: storeHour.dayOfWeek,
        actionTime: onActionTime.format('HH:mm:ss'),
        timezone,
        active: storeHour.active,
      },
      {
        action: 'TURN_OFF',
        dayOfWeek: storeHour.dayOfWeek,
        actionTime: offActionTime.format('HH:mm:ss'),
        timezone,
        active: storeHour.active,
      },
    ];
  });
