import { CANADA_STATES } from '@/constants/CanadaStates';
import { US_STATES } from '@/constants/USStates';
import { TIMEZONES } from '@/constants/timezones';

export const STORE_STATUSES = ['ACTIVE', 'INACTIVE', 'DELETED'] as const;

export type StoreField = {
  name: string;
  label: string;
  isRequired: boolean;
  isHidden?: boolean;
  allowedValues?: string[];
  mask?: string;
} & (
  | {
      type: 'text' | 'uuid' | 'enum';
      defaultValue?: string;
    }
  | {
      type: 'date';
      defaultValue?: Date | null;
    }
  | {
      type: 'boolean';
      defaultValue?: boolean;
    }
  | {
      type: 'number';
      defaultValue?: number;
    }
);

export const STORE_FIELDS: StoreField[] = [
  {
    name: 'brand_id',
    label: 'Brand',
    isRequired: true,
    type: 'uuid',
    defaultValue: '',
  },
  {
    name: 'fleet_id',
    label: 'Fleet',
    isRequired: true,
    type: 'uuid',
    defaultValue: '',
  },
  {
    name: 'franchise_store_number',
    label: 'Franchise Store Number',
    isRequired: true,
    type: 'text',
  },
  {
    name: 'cash_limit',
    label: 'Cash Limit',
    isRequired: true,
    type: 'number',
    defaultValue: 0,
  },
  {
    name: 'tax_rate',
    label: 'Tax rate',
    isRequired: true,
    type: 'number',
  },
  { name: 'zipcode', label: 'Zipcode', isRequired: true, type: 'text' },
  {
    name: 'timezone',
    label: 'Timezone',
    isRequired: true,
    allowedValues: Object.keys(TIMEZONES),
    type: 'enum',
  },
  {
    name: 'latitude',
    label: 'Latitude',
    isRequired: false,
    type: 'number',
    isHidden: true,
  },
  {
    name: 'longitude',
    label: 'Longitude',
    isRequired: false,
    type: 'number',
    isHidden: true,
  },
  {
    name: 'state',
    label: 'State',
    isRequired: true,
    allowedValues: [...Object.keys(US_STATES), ...Object.keys(CANADA_STATES)],
    type: 'enum',
  },
  { name: 'address', label: 'Address', isRequired: true, type: 'text' },
  { name: 'city', label: 'City', isRequired: true, type: 'text' },
  {
    name: 'area',
    label: 'Area',
    isRequired: true,
    type: 'text',
    isHidden: true,
  },
  {
    name: 'primary_phone',
    label: 'External Phone (Primary)',
    isRequired: true,
    type: 'text',
  },
  {
    name: 'secondary_phone',
    label: 'Forward Phone (Secondary)',
    isRequired: true,
    type: 'text',
  },
  {
    name: 'twilio_number',
    label: 'Internal Number (Twilio Number)',
    isRequired: false,
    type: 'text',
    mask: '+1 (999) 999-9999',
  },
  {
    name: 'sip_uri',
    label: 'Sip URI',
    isRequired: false,
    isHidden: true,
    type: 'text',
  },
  {
    name: 'location',
    label: 'Location',
    isRequired: false,
    isHidden: true,
    type: 'text',
  },
  {
    name: 'bypass_mode',
    label: 'Bypass Mode',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'platform_id',
    label: 'Platform',
    isRequired: true,
    type: 'uuid',
    defaultValue: '',
  },
  {
    name: 'platform_store_id',
    label: 'Platform Store ID',
    isRequired: true,
    type: 'uuid',
  },
  {
    name: 'notes',
    label: 'Notes',
    isRequired: false,
    type: 'text',
  },
  {
    name: 'is_on',
    label: 'Is On',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
    isHidden: true,
  },
  {
    name: 'store_status',
    label: 'Store Status',
    isRequired: false,
    allowedValues: ['ACTIVE', 'INACTIVE', 'DELETED'],
    defaultValue: 'ACTIVE',
    type: 'enum',
  },
  {
    name: 'store_details',
    label: 'Store Details',
    isRequired: false,
    type: 'text',
  },

  {
    name: 'activation_date',
    label: 'Activation Date',
    isRequired: false,
    type: 'date',
    defaultValue: null,
  },
  {
    name: 'sync_cash_limit',
    label: 'Sync Cash Limit',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'sync_store_hours',
    label: 'Sync Store Hours',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'is_test_store',
    label: 'Is Test Store',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'is_production_mirror',
    label: 'Is Production Mirror',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'should_tip',
    label: 'Should Tip',
    isRequired: false,
    type: 'boolean',
    defaultValue: undefined,
  },
  {
    name: 'in_store_ordering',
    label: 'In Store Ordering',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'use_default_options_to_max_quantity',
    label: 'Options Max Quantity',
    isRequired: false,
    type: 'boolean',
    defaultValue: false,
  },
] as const;
