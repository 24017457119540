import { FormField, Select, FormItemSelect } from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

interface CountryFormItemProps {
  form: UseFormReturn<StoreSchema>;
}

export const CountryFormField = (props: CountryFormItemProps) => {
  return (
    <FormField
      control={props.form.control}
      name="country"
      render={({ field }) => (
        <FormItemSelect label="Country" field={field}>
          <Select.Item value="US">United States</Select.Item>
          <Select.Item value="CA">Canada</Select.Item>
        </FormItemSelect>
      )}
    />
  );
};
