import { RouteObject } from 'react-router-dom';

import { AgentForm } from '@/pages/Agents/AgentForm/AgentForm';

import { List } from './List';

export const agentRouter: RouteObject[] = [
  {
    path: '/agents',
    element: <List />,
    index: true,
  },
  {
    path: '/agents/new',
    element: <AgentForm type="create" />,
  },
  {
    path: '/agents/:agentId',
    element: <AgentForm type="update" />,
  },
];
