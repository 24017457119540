import { FormField, Toggle, FormItemToggle } from '@kea-inc/parrot-ui';
import { CheckIcon } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';

import { BrandSchema } from '@/pages/Brands/BrandForm/schema';

import * as S from './styles';
interface HandoffProps {
  form: UseFormReturn<BrandSchema>;
  id: HTMLDivElement['id'];
}

export function Handoff(props: HandoffProps) {
  const { form, id } = props;
  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Handoff methods</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="triageHandoffMethods.curbside"
          render={({ field }) => (
            <FormItemToggle>
              <Toggle
                pressed={field.value}
                onClick={() => field.onChange(!field.value)}
              >
                {field.value ? <CheckIcon /> : null}
                Curbside
              </Toggle>
            </FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="triageHandoffMethods.delivery"
          render={({ field }) => (
            <FormItemToggle>
              <Toggle
                pressed={field.value}
                onClick={() => field.onChange(!field.value)}
              >
                {field.value ? <CheckIcon /> : null}
                Delivery
              </Toggle>
            </FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="triageHandoffMethods.dinein"
          render={({ field }) => (
            <FormItemToggle>
              <Toggle
                pressed={field.value}
                onClick={() => field.onChange(!field.value)}
              >
                {field.value ? <CheckIcon /> : null}
                Dine in
              </Toggle>
            </FormItemToggle>
          )}
        />
        <FormField
          control={form.control}
          name="triageHandoffMethods.pickup"
          render={({ field }) => (
            <FormItemToggle>
              <Toggle
                pressed={field.value}
                onClick={() => field.onChange(!field.value)}
              >
                {field.value ? <CheckIcon /> : null}
                Pickup
              </Toggle>
            </FormItemToggle>
          )}
        />
      </S.FormFieldContainer>
    </S.SectionContainer>
  );
}
