import decamelizeKeys from 'decamelize-keys';

import { PaginatedStoresFilter } from '@/@types/store';
import { StoreService } from '@/services/@types/store';

export const mapPaginatedStoresFilterToApi = (
  filter?: PaginatedStoresFilter,
): StoreService.FilterPayload | undefined => {
  const clearedFilters = Object.entries(filter ?? {})
    .filter(([, value]) => !!value)
    .reduce(
      (flts, [k, v]) => ({ ...flts, [k]: v }),
      {} as PaginatedStoresFilter,
    );

  return filter ? decamelizeKeys(clearedFilters) : undefined;
};
