import { OrderCallFlow } from '@/@types/order/callFlow';
import { OrderService } from '@/services/@types/order';

export const mapCallFlowResponseToDomain = (
  response: OrderService.OrderCallFlowResponse,
): OrderCallFlow[] => {
  let parentSegment = '';
  return response.timeline.reduce((events, flow) => {
    if (flow.callSegment) {
      parentSegment = flow.callSegment;
    }
    events.push({ ...flow, parentSegment });
    return events;
  }, [] as OrderCallFlow[]);
};
