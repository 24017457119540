import camelcaseKeys from 'camelcase-keys';
import dayjs from 'dayjs';

import { OrderTranscript } from '@/@types/order/transcript';
import { OrderService } from '@/services/@types/order';

export const mapOrderTranscriptsToDomain = (
  serviceTranscript: OrderService.OrderTranscript[],
): OrderTranscript[] => {
  const camelcasedTranscripts: Omit<OrderTranscript, 'recordingTimestamp'>[] =
    camelcaseKeys(serviceTranscript);
  const firstRecordingTimestamp = camelcasedTranscripts.at(0)?.createdAt;
  return camelcasedTranscripts.map((transcript) => {
    return {
      ...transcript,
      recordingTimestamp: dayjs(transcript?.createdAt).diff(
        firstRecordingTimestamp,
        'seconds',
      ),
    };
  });
};
