import { RouteObject } from 'react-router-dom';

import { TrainingTabs } from '@/pages/Training/TrainingTabs';

export const trainingRouter: RouteObject[] = [
  {
    path: '/training',
    element: <TrainingTabs />,
    index: true,
  },
];
