import { ColumnDef, DataTableSortHeader, Typography } from '@kea-inc/parrot-ui';

import { Auth0Role } from '@/@types/user';
import { TextCenter } from '@/components/DataTable';
import { TableActions } from '@/components/TableActions';

type UseRolesColumnDefsOpts = {
  onUpdateClick: (user: Auth0Role) => void;
};

export const useRolesColumnDefs = (opts: UseRolesColumnDefsOpts) => {
  const { onUpdateClick } = opts;

  const columns: ColumnDef<Auth0Role>[] = [
    {
      accessorKey: 'name',
      header: (hProps) => <DataTableSortHeader {...hProps} label="Name" />,
      cell: ({ row }) => <Typography>{row.original.name}</Typography>,
    },
    {
      accessorKey: 'description',
      header: (hProps) => (
        <DataTableSortHeader {...hProps} label="Description" />
      ),
      cell: ({ row }) => <Typography>{row.original.description}</Typography>,
    },
    {
      accessorKey: 'actions',
      header: () => <TextCenter>Actions</TextCenter>,
      cell: ({ row }) => {
        return (
          <TableActions onUpdateClick={() => onUpdateClick(row.original)} />
        );
      },
    },
  ];

  return { columns };
};
