import styled from 'styled-components';

import * as TableActionsComponent from '@/components/TableActions';
import * as GlobalStyles from '@/styles';

export const InputButtonContainer = GlobalStyles.PageHeaderInputContainer;

export const PageHeaderInput = GlobalStyles.PageHeaderInput;

export const TableActions = styled(TableActionsComponent.TableActions)`
  justify-content: flex-end;
`;
