import { Store, StoreHandoffMode } from '@/@types/store';
import { INIT_STORE_HOURS } from '@/constants/storeHours';
import { INIT_TRIAGE_HANDOFF_METHODS } from '@/constants/triageHandoffMethods';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';

export const getFormDefaultValues = (store?: Store): Partial<StoreSchema> => {
  const baseDefaultValues: Partial<StoreSchema> = {
    agentlessStoreForwarding: null,
    storeHours: INIT_STORE_HOURS,
  };
  return !store
    ? {
        ...baseDefaultValues,
        country: 'US',
        syncStoreHours: true,
        triageHandoffMethods: Object.keys(INIT_TRIAGE_HANDOFF_METHODS).map(
          (handoffMode) => {
            const method = handoffMode as StoreHandoffMode;
            return {
              method,
              override: null,
            };
          },
        ),
      }
    : { ...baseDefaultValues };
};
