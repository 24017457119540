import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

import * as S from './styles';

interface FormItemYesNoProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> {
  field: ControllerRenderProps<TFieldValues, TName>;
  label: string;
}

export function FormItemYesNo<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: FormItemYesNoProps<TFieldValues, TName>) {
  const { field, label } = props;
  return (
    <S.FormItemToggle label={label}>
      <S.ToggleContainer>
        <S.Toggle pressed={field.value} onClick={() => field.onChange(true)}>
          Yes
        </S.Toggle>
        <S.Toggle pressed={!field.value} onClick={() => field.onChange(false)}>
          No
        </S.Toggle>
      </S.ToggleContainer>
    </S.FormItemToggle>
  );
}
