import { Order } from '@/@types/order';
import {
  PaginatedOrdersFilters,
  PaginatedOrders,
} from '@/@types/order/paginatedOrder';
import { OrderService } from '@/services/@types/order';
import { orderService } from '@/services/clients';
import { mapGetOrdersFiltersToApi } from '@/services/useCases/order/adapters/api';
import {
  mapOrderToDomain,
  mapPaginatedOrdersToDomain,
} from '@/services/useCases/order/adapters/domain';

export type GetOrder = {
  input: { id: string };
  output: Order;
};

export const getOrder = async (
  input: GetOrder['input'],
): Promise<GetOrder['output']> => {
  const { data } = await orderService.get<OrderService.Order>(
    `/orders/${input.id}`,
  );
  return mapOrderToDomain(data);
};

type GetPaginatedOrdersInput = {
  pageSize: number;
  page: number;
  filters?: Partial<PaginatedOrdersFilters>;
};

export type GetPaginatedOrders = {
  input: GetPaginatedOrdersInput;
  output: PaginatedOrders;
};

export const getPaginatedOrders = async (
  input: GetPaginatedOrders['input'],
): Promise<GetPaginatedOrders['output']> => {
  const { data: orders } = await orderService.get<OrderService.PaginatedOrders>(
    '/orders',
    {
      params: {
        ...mapGetOrdersFiltersToApi(input.filters),
        page: input.page.toString(),
        pageSize: input.pageSize.toString(),
      },
    },
  );

  const paginatedOrders = mapPaginatedOrdersToDomain(orders);

  return {
    items: [...paginatedOrders.items],
    totalPages: paginatedOrders.totalPages,
    totalRecords: paginatedOrders.totalRecords,
  };
};
