import {
  FormField,
  Select,
  FormItemInput,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { useEffect, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { CANADA_STATES } from '@/constants/CanadaStates';
import { US_STATES } from '@/constants/USStates';
import { TIMEZONES } from '@/constants/timezones';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';
import { useAppDispatch } from '@/store';

import { CountryFormField } from './CountryFormField';
import { useConnector } from '../../connector';
import * as S from '../styles';

interface StoreInfoProps {
  form: UseFormReturn<StoreSchema>;
  id: HTMLDivElement['id'];
}

export const StoreInfo = (props: StoreInfoProps) => {
  const dispatch = useAppDispatch();
  const { form, id } = props;

  const { actions, selectors } = useConnector();
  const { brands, fleets } = selectors;

  const states = useMemo(() => {
    if (form.getValues('country') === 'US') {
      return US_STATES;
    } else {
      return CANADA_STATES;
    }
  }, [form.getValues('country')]);

  const { zipcode, country } = useWatch({ control: form.control });

  useEffect(() => {
    form.setValue('zipcode', '');
    form.setValue('latitude', '');
    form.setValue('longitude', '');
    form.setValue('timezone', '');
    form.setValue('address', '');
    form.setValue('city', '');
    form.setValue('state', '');
    form.setValue('location', '');
  }, [country]);

  const handleZipcodeBlur = () => {
    if (zipcode)
      dispatch(actions.getGoogleMapsInformation({ zipcode, country }))
        .unwrap()
        .then((result) => {
          const { lat, lng, timezone, address, city, state, storeLocation } =
            result;
          form.setValue('latitude', lat);
          form.setValue('longitude', lng);
          form.setValue('timezone', timezone);
          form.setValue('address', address);
          form.setValue('city', city);
          form.setValue('state', state);
          form.setValue('location', storeLocation);

          return result;
        });
  };

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Store info</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={props.form.control}
          name="brandId"
          render={({ field }) => (
            <FormItemSelect label="Brand" field={field}>
              {brands.map((brand) => (
                <Select.Item value={brand.id} key={brand.id}>
                  {brand.name}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={props.form.control}
          name="fleetId"
          render={({ field }) => (
            <FormItemSelect label="Fleet" field={field}>
              {fleets.map((fleet) => (
                <Select.Item value={fleet.id} key={fleet.id}>
                  {fleet.name}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="franchiseStoreNumber"
          render={({ field }) => (
            <FormItemInput field={field} label="Store number" />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="cashLimit"
          render={({ field }) => (
            <FormItemInput field={field} label="Cash limit" />
          )}
        />
        <FormField
          control={form.control}
          name="taxRate"
          render={({ field }) => (
            <FormItemInput field={field} label="Tax rate" />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <CountryFormField form={form} />
        <FormField
          control={form.control}
          name="zipcode"
          render={({ field }) => (
            <FormItemInput
              field={field}
              label="Zipcode"
              onBlur={handleZipcodeBlur}
              maskOptions={{ mask: country === 'US' ? '99999' : 'a9a 9a9' }}
            />
          )}
        />
        <FormField
          control={form.control}
          name="timezone"
          render={({ field }) => (
            <FormItemSelect label="Timezone" field={field}>
              {Object.entries(TIMEZONES).map(([timezone, name]) => (
                <Select.Item value={timezone} key={timezone}>
                  {name} ({timezone})
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="latitude"
          render={({ field }) => (
            <FormItemInput field={field} label="Latitude" />
          )}
        />
        <FormField
          control={form.control}
          name="longitude"
          render={({ field }) => (
            <FormItemInput field={field} label="Longitude" />
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItemSelect label="State" field={field}>
              {Object.entries(states).map(([code, stateName]) => (
                <Select.Item value={code} key={code}>
                  {stateName}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItemInput field={field} label="Address" />
          )}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => <FormItemInput field={field} label="City" />}
        />
        <FormField
          control={form.control}
          name="location"
          render={({ field }) => (
            <FormItemInput
              field={field}
              label="Store location"
              style={{ flex: 2 }}
            />
          )}
        />
      </S.FormFieldContainer>
    </S.SectionContainer>
  );
};
