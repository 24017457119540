import { adapter } from './slice';
import { StoreState } from '../..';

export const getFleets = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.fleet.fleets,
).selectAll;

export const getFleetEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.fleet.fleets,
).selectEntities;
