import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogProps,
  AlertDialogTitle,
  Button,
  ButtonProps,
} from '@kea-inc/parrot-ui';
import React, { useEffect, useRef, useState } from 'react';

type ExtendedProps = AlertDialogProps;

interface ConfirmationDialogProps extends ExtendedProps {
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  description: React.ReactNode;
  loading?: boolean;
  variant?: ButtonProps['variant'];
  confirmationLabel?: string;
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {
    onSubmit,
    description,
    loading,
    variant = 'warning',
    confirmationLabel = 'Yes',
    ...alertDialogProps
  } = props;

  const [secondsToEnable, setSecondsToEnable] = useState<number>(3);

  const interval = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (alertDialogProps.open) {
      interval.current = setInterval(() => {
        setSecondsToEnable((prev) => prev - 1);
      }, 1000);
    } else {
      setSecondsToEnable(3);
      clearInterval(interval.current);
    }
  }, [alertDialogProps.open]);

  useEffect(() => {
    if (secondsToEnable === 0) {
      clearInterval(interval.current);
    }
  }, [secondsToEnable]);

  const isEnabled = secondsToEnable <= 0;

  return (
    <AlertDialog {...alertDialogProps}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          {isEnabled ? (
            <Button variant={variant} onClick={onSubmit} loading={loading}>
              {confirmationLabel}
            </Button>
          ) : (
            <Button variant={variant} disabled>
              Wait {secondsToEnable}s
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
