import * as ParrotUi from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

export * from '../styles';

export const ManualStoreHoursContainer = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const StoreHourFieldsContainer = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

export const DayOfWeekBadge = styled(ParrotUi.Badge)<{ $disabled?: boolean }>`
  display: flex;
  justify-content: center;
  width: 80%;
  cursor: pointer;
  ${(props) =>
    props.$disabled
      ? css`
          pointer-events: none;
        `
      : ''}
`;

export const StoreHoursFormItem = styled(ParrotUi.FormItem)`
  flex: 0.25;
  display: flex;
  justify-content: center;
`;

export const AmPmButton = styled(ParrotUi.Button).attrs({ size: 'sm' })``;

export const AmPmToggle = styled(ParrotUi.Toggle)`
  width: 50;
  border-radius: 0px;
`;
