import { adapter } from './slice';
import { StoreState } from '../..';

export const getPlatforms = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.platform.platform,
).selectAll;

export const getPlatformsEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.platform.platform,
).selectEntities;
