import {
  FormField,
  Input,
  Label,
  Select,
  FormItemInput,
  FormItemSelect,
} from '@kea-inc/parrot-ui';
import { MinusIcon, PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { BrandPlatform } from '@/@types/brand';
import { BrandSchema } from '@/pages/Brands/BrandForm/schema';

import * as S from './styles';
import { useConnector } from '../../connector';

const initBrandPlatform: BrandPlatform = {
  platformId: '',
  platformName: '',
  platformApiKey: '',
  sharedSecretKey: '',
};

interface PlatformsProps {
  form: UseFormReturn<BrandSchema>;
  id: HTMLDivElement['id'];
}

export function Platforms(props: PlatformsProps) {
  const { form, id } = props;

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'platforms',
  });

  const [newPlatform, setNewPlatform] =
    useState<BrandPlatform>(initBrandPlatform);

  const { selectors } = useConnector();

  const { platforms } = selectors;

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Add Platforms</S.SectionTitle>
      <S.NewFieldContainer>
        <S.SelectContainer>
          <Select
            value={newPlatform.platformId}
            label="Platform"
            onValueChange={(value) =>
              setNewPlatform((pNewPlatform) => ({
                ...pNewPlatform,
                platformId: value,
              }))
            }
          >
            {platforms.map((platform) => (
              <Select.Item value={platform.id} key={platform.id}>
                {platform.name}
              </Select.Item>
            ))}
          </Select>
        </S.SelectContainer>
        <S.InputContainer>
          <Label>Platform API</Label>
          <Input
            value={newPlatform.platformApiKey}
            onChange={(evt) =>
              setNewPlatform((pNewPlatform) => ({
                ...pNewPlatform,
                platformApiKey: evt.target.value,
              }))
            }
          />
        </S.InputContainer>
        <S.InputContainer>
          <Label>Shared Secret Key</Label>
          <Input
            value={newPlatform.sharedSecretKey}
            onChange={(evt) =>
              setNewPlatform((pNewPlatform) => ({
                ...pNewPlatform,
                sharedSecretKey: evt.target.value,
              }))
            }
          />
        </S.InputContainer>

        <S.AddDeleteButton
          variant="ghost"
          disabled={!newPlatform.platformId}
          size="icon"
          onClick={() => {
            const field = fields.find(
              (f) =>
                f.platformId === newPlatform.platformId &&
                f.platformApiKey === newPlatform.platformApiKey,
            );
            if (!field) {
              append(newPlatform);
              setNewPlatform(initBrandPlatform);
            }
          }}
        >
          <PlusIcon />
        </S.AddDeleteButton>
      </S.NewFieldContainer>
      <S.FieldArrayContainer>
        {fields.map((fieldItem, index) => (
          <S.FieldArrayItemContainer
            key={`${fieldItem.platformName}-${fieldItem.platformApiKey}`}
          >
            <FormField
              control={form.control}
              name={`platforms.${index}.platformId`}
              render={({ field }) => (
                <FormItemSelect field={field}>
                  {platforms.map((platform) => (
                    <Select.Item value={platform.id} key={platform.id}>
                      {platform.name}
                    </Select.Item>
                  ))}
                </FormItemSelect>
              )}
            />
            <FormField
              control={form.control}
              name={`platforms.${index}.platformApiKey`}
              render={({ field }) => <FormItemInput field={field} />}
            />
            <FormField
              control={form.control}
              name={`platforms.${index}.sharedSecretKey`}
              render={({ field }) => <FormItemInput field={field} />}
            />
            <S.AddDeleteButton
              variant="ghost"
              disabled={!fieldItem.platformId}
              size="icon"
              onClick={() => remove(index)}
            >
              <MinusIcon />
            </S.AddDeleteButton>
          </S.FieldArrayItemContainer>
        ))}
      </S.FieldArrayContainer>
    </S.SectionContainer>
  );
}
