import {
  PaginatedUsersRequest,
  PaginatedUsersResponse,
  User,
} from '@/@types/user';
import { dataService } from '@/services';
import { UserService } from '@/services/@types/user';
import {
  mapPaginatedUserResponseToDomain,
  mapUserToDomain,
} from '@/services/useCases/user/adapters/domain';

export type GetPaginatedUsers = {
  input: PaginatedUsersRequest;
  output: PaginatedUsersResponse;
};

export const getPaginatedUsers = async (
  input: GetPaginatedUsers['input'],
): Promise<GetPaginatedUsers['output']> => {
  const { page, pageSize, searchTerm } = input;
  const { data } = await dataService.get<UserService.PaginatedUsersResponse>(
    '/users',
    {
      params: {
        page,
        pageSize,
        search: searchTerm || undefined,
      },
      version: 'v3',
    },
  );
  return mapPaginatedUserResponseToDomain(data);
};

export type GetUser = {
  input: { id: string };
  output: User;
};

export const getUser = async (
  input: GetUser['input'],
): Promise<GetUser['output']> => {
  const { data } = await dataService.get<UserService.User>(
    `/users/${input.id}`,
  );
  return mapUserToDomain(data);
};
