import { RouteObject } from 'react-router-dom';

import { AccountInfo } from '@/pages/Accounts/AccountInfo';

import { List } from './List/List';

export const accountsRouter: RouteObject[] = [
  {
    path: '/accounts',
    element: <List />,
    index: true,
  },
  {
    path: '/accounts/:accountId',
    element: <AccountInfo />,
  },
];
