import { graphqlClient } from '@/services/clients/menuService';

import {
  dataService,
  coreService,
  operatorService,
  recordingsService,
  menuService,
  orderService,
  accountService,
} from '..';

export function applyJwtToken(token: string, email?: string) {
  if (email) {
    localStorage.setItem('@kea-inc/ops-console/email', email);
  }

  localStorage.setItem('@kea-inc/ops-console/token', token);
  orderService.defaults.headers.common.Authorization = `Bearer ${token}`;
  dataService.defaults.headers.common.Authorization = `Bearer ${token}`;
  coreService.defaults.headers.common.Authorization = `Bearer ${token}`;
  operatorService.defaults.headers.common.Authorization = `Bearer ${token}`;
  recordingsService.defaults.headers.common.Authorization = `Bearer ${token}`;
  menuService.defaults.headers.common.Authorization = `Bearer ${token}`;
  accountService.defaults.headers.common.Authorization = `Bearer ${token}`;
  graphqlClient.setHeader('Authorization', `Bearer ${token}`);
}
