import { Bpo } from '@/@types/bpo';
import { dataService } from '@/services';
import { BpoService } from '@/services/@types/bpo';
import { mapBpoToDomain } from '@/services/useCases/bpo/adapters/domain';

export type UpdateBpo = {
  input: { bpoName: string; bpoId: string };
  output: Bpo;
};

export const updateBpo = async (
  input: UpdateBpo['input'],
): Promise<UpdateBpo['output']> => {
  const { bpoId, bpoName } = input;
  const { data } = await dataService.patch<BpoService.Bpo>(`/bpos/${bpoId}`, {
    name: bpoName,
  });

  return mapBpoToDomain(data);
};
