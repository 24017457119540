import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Tabs, TabsList, TabsTrigger } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { KeaLoading } from '@/components/KeaLoading';
import { PageCard } from '@/components/PageCard';
import { UpdateCreateButton } from '@/components/UpdateCreateButton';
import { FormType } from '@/constants/form';
import {
  getCreateUpdateDescription,
  useNotification,
} from '@/hooks/useNotification';
import { useScrollTab } from '@/hooks/useScrollTab';
import { AgentInfo, Status } from '@/pages/Agents/AgentForm/Sections';
import {
  mapBrandToFormValues,
  mapFormValuesToCreateRequest,
  mapFormValuesToUpdateRequest,
} from '@/pages/Agents/AgentForm/adapters/form';
import {
  AgentSchema,
  agentSchema,
} from '@/pages/Agents/AgentForm/schema/schema';
import { useAppDispatch } from '@/store';

import { useConnector } from './connector';
import * as S from './styles';

type Tab = 'agentInfo' | 'status';

const TABS = {
  'Agent info': 'agentInfo',
  Status: 'status',
} satisfies Record<string, Tab>;

interface AgentFormProps {
  type: FormType;
}

export function AgentForm(props: AgentFormProps) {
  const { type } = props;
  const navigate = useNavigate();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const { agentId = '' } = useParams<{ agentId: string }>();

  const { handleChangeTab, handleScroll, tab } = useScrollTab<Tab>({
    initTab: 'agentInfo',
  });

  const { actions, selectors } = useConnector();
  const { agentEntities, brands, fetching, creatingUpdating } = selectors;

  useEffect(() => {
    if (agentId) {
      dispatch(actions.getAgent({ id: agentId }));
    }
  }, [agentId]);

  useEffect(() => {
    dispatch(actions.getBpos());
    dispatch(actions.getWorksites());
    dispatch(actions.getBrands());
  }, []);

  useEffect(() => {
    if (!agent) {
      form.setValue('brands', brands.map(mapBrandToFormValues));
    }
  }, [brands]);

  const agent = agentId ? agentEntities[agentId] : undefined;

  const form = useForm<AgentSchema>({
    resolver: zodResolver(agentSchema),
  });

  useEffect(() => {
    if (agent) {
      form.reset(agent);
    }
  }, [agent]);

  const handleSuccessSave = () => {
    notification.success('Success', {
      description: getCreateUpdateDescription('Agent'),
    });
    navigate(`/agents`);
  };

  const handleSubmit: Parameters<typeof form.handleSubmit>[0] = (values) => {
    if (type === 'create')
      dispatch(
        actions.createAgent(mapFormValuesToCreateRequest(values, brands)),
      )
        .unwrap()
        .then(handleSuccessSave);

    if (type === 'update' && agent) {
      dispatch(actions.updateAgent(mapFormValuesToUpdateRequest(values, agent)))
        .unwrap()
        .then(handleSuccessSave);
    }
  };

  if (fetching) {
    return <KeaLoading />;
  }
  if (type === 'update' && !agent) {
    return null;
  }

  return (
    <Form {...form}>
      <S.Form onSubmit={form.handleSubmit(handleSubmit)}>
        <PageCard
          backButton={<BackButton onClick={() => navigate('/agents')} />}
          headerContent={
            <Tabs value={tab} onValueChange={handleChangeTab}>
              <TabsList>
                {Object.entries(TABS).map(([label, value]) => (
                  <TabsTrigger key={value} value={value}>
                    {label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          }
          title={
            agent
              ? `${agent.firstName} ${agent.lastName}`
              : 'Create a new agent'
          }
          footerContent={
            <UpdateCreateButton type="submit" loading={creatingUpdating} />
          }
        >
          <S.SectionsContainer onWheel={handleScroll}>
            <AgentInfo form={form} id={TABS['Agent info']} />
            <Status form={form} id={TABS['Status']} />
          </S.SectionsContainer>
        </PageCard>
      </S.Form>
    </Form>
  );
}
