export function getInitials(name?: string) {
  if (!name) {
    return '';
  }

  const [firstName, lastName] = name.split(' ');

  if (!lastName) {
    return firstName.slice(0, 2).toUpperCase();
  }
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
}
