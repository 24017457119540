import { useAuth0 } from '@auth0/auth0-react';

import { useConnector } from '@/pages/Training/connector';
import { useAppDispatch } from '@/store';
import { actions as trainingUcActions } from '@/store/useCases/training';

export const useTraining = () => {
  const { actions, selectors } = useConnector();

  const dispatch = useAppDispatch();

  const { user } = useAuth0();

  const { training, agentEntities, storeEntities } = selectors;
  const { device, selectedAgentId, selectedBrandId, selectedStoreId } =
    training;

  function handleDecline() {
    dispatch(
      actions.mergeState({
        isExpectingCall: false,
        isRinging: false,
        isConnected: false,
      }),
    );
    device?.activeConnection()?.reject();
  }

  function handleAccept() {
    dispatch(
      actions.mergeState({
        isExpectingCall: false,
        isRinging: false,
        isConnected: true,
      }),
    );
    device?.activeConnection()?.accept();
  }

  async function createTrainingTask(handoffMode: string | null) {
    if (!selectedAgentId || !selectedStoreId || !selectedBrandId) {
      return;
    }

    const selectedAgent = agentEntities[selectedAgentId];
    const selectedStore = storeEntities[selectedStoreId];

    dispatch(
      actions.mergeState({
        isExpectingCall: true,
        isCreatingTask: true,
        isRinging: false,
      }),
    );

    dispatch(
      trainingUcActions.createTrainingTask({
        store: selectedStore,
        agent: selectedAgent,
        brandId: selectedBrandId,
        handoffMode,
        user,
      }),
    )
      .unwrap()
      .finally(() =>
        dispatch(
          actions.mergeState({
            isCreatingTask: false,
          }),
        ),
      );
  }

  return {
    createTrainingTask,
    handleAccept,
    handleDecline,
  };
};
