import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Theme = 'dark' | 'light';
export type State = {
  value: Theme;
};

export const initialState: State = {
  value: (localStorage.getItem('@kea-inc/theme') ?? 'light') as Theme,
};

const slice = createSlice({
  name: 'ui/theme',
  initialState,
  reducers: {
    setTheme: (state: State, action: PayloadAction<Theme>) => {
      state.value = action.payload;
    },
    switchTheme: (state: State) => {
      state.value = state.value === 'light' ? 'dark' : 'light';
      localStorage.setItem('@kea-inc/theme', state.value);
    },
  },
});

export default slice;
