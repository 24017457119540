import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { Platform } from '@/@types/platform';
import { actions } from '@/store/useCases/platform';

export const adapter = createEntityAdapter<Platform>({
  sortComparer: (s1, s2) => s1.id.localeCompare(s2.id),
});

export type State = {
  platform: EntityState<Platform, string>;
};

export const initialState: State = {
  platform: adapter.getInitialState(),
};

const slice = createSlice({
  name: 'entities/platform',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getPlatforms.fulfilled, (state, action) => {
      adapter.upsertMany(state.platform, action.payload);
    });
    builder.addCase(actions.deletePlatform.fulfilled, (state, action) => {
      adapter.removeOne(state.platform, action.payload.id);
    });
    builder.addCase(actions.createPlatform.fulfilled, (state, action) => {
      adapter.addOne(state.platform, action.payload);
    });
    builder.addCase(actions.updatePlatform.fulfilled, (state, action) => {
      adapter.updateOne(state.platform, {
        id: action.payload.id,
        changes: action.payload,
      });
    });
  },
});

export default slice;
