import { CreateSoundboardEntries } from '@/@types/menu';
import { menuService } from '@/services';
import { mapCreateEntryToApi } from '@/services/useCases/menu/adapters/api';

export type CreateBrandSoundboardEntries = {
  input: { brandId: string; entries: CreateSoundboardEntries };
  output: void;
};

export const createBrandSoundboardEntries = async (
  input: CreateBrandSoundboardEntries['input'],
): Promise<CreateBrandSoundboardEntries['output']> => {
  const { brandId, entries } = input;
  await menuService.put(
    `/soundboard-entries/brands/${brandId}`,
    mapCreateEntryToApi(entries),
  );
};

export type CreateStoreSoundboardEntries = {
  input: { storeId: string; entries: CreateSoundboardEntries };
  output: CreateSoundboardEntries;
};

export const createStoreSoundboardEntries = async (
  input: CreateStoreSoundboardEntries['input'],
): Promise<CreateStoreSoundboardEntries['output']> => {
  const { storeId, entries } = input;
  await menuService.put(`/soundboard-entries/stores/${storeId}`, entries);
  return input.entries;
};
