import './datadog';

import { datadogLogs } from '@datadog/browser-logs';

import { Logger } from './types';

function createPayload(attributes = {}) {
  const email = localStorage.getItem('@kea-inc/ops-console/email');

  return {
    ...attributes,
    email,
  };
}

const logger: Logger = {} as Logger;

logger.info = (message, attributes) => {
  datadogLogs.logger.info(message, createPayload(attributes));
};

logger.debug = (message, attributes) => {
  datadogLogs.logger.debug(message, createPayload(attributes));
};

logger.error = (message, attributes) => {
  datadogLogs.logger.error(message, createPayload(attributes));
};

logger.warn = (message, attributes) => {
  datadogLogs.logger.warn(message, createPayload(attributes));
};

export default logger;
