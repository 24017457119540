import _ from 'lodash';

import { StorePromo } from '@/@types/store';
import { StoreSchema } from '@/pages/Stores/StoreForm/schema';
import { StorePromoSchema } from '@/pages/Stores/StoreForm/schema/promos';
import { SyncStorePromos } from '@/services/useCases';

export const mapStorePromoFormValuesToRequest = (
  storePromoSchema: StorePromoSchema,
): StorePromo => {
  const promo = _.omitBy(
    _.omit(storePromoSchema, ['isNew', 'deleted']),
    (v) => v === '',
  ) as StorePromo;

  return promo;
};

export const mapStorePromosFormValuesToRequest = (
  storePromos: StoreSchema['promos'],
): SyncStorePromos['input']['promos'] => {
  const filteredBrandUpsells =
    storePromos?.filter(
      (brandUpsell) => !(brandUpsell.isNew && brandUpsell.deleted),
    ) ?? [];
  return {
    created: filteredBrandUpsells
      .filter((upsell) => upsell.isNew)
      .map(mapStorePromoFormValuesToRequest),
    updated: filteredBrandUpsells
      .filter((upsell) => !upsell.isNew && !upsell.deleted)
      .map(mapStorePromoFormValuesToRequest),
    deleted: filteredBrandUpsells
      .filter((upsell) => upsell.deleted)
      .map(mapStorePromoFormValuesToRequest),
  };
};
