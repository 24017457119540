import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const DialogContent = styled(ParrotUi.DialogContent)`
  min-width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
`;

export const DataTableContainer = styled.div`
  overflow: auto;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
`;

export * from '../styles';
