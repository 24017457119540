import _ from 'lodash';

import { Store } from '@/@types/store';
import { StoreField } from '@/constants/store';
import { ManualAssignmentSchema } from '@/pages/Stores/BulkCreation/ManualAssignment/schema';
import { BulkCreationState } from '@/store/ui/store';

export const mapStoreFieldsToFormValues = (
  storeField: StoreField[],
): ManualAssignmentSchema => {
  return storeField.reduce(
    (defaultValues, strField) => {
      const key = _.camelCase(strField.name) as keyof ManualAssignmentSchema;
      defaultValues[key] =
        strField.type === 'boolean'
          ? !!strField.defaultValue
          : strField.defaultValue;

      return defaultValues;
    },
    {} as Record<string, unknown>,
  );
};

export const mapStoreDataFieldsAndFormValuesToStore = (
  storeDataFields: BulkCreationState['csvDataFields']['data'],
  formValues: ManualAssignmentSchema,
) =>
  storeDataFields.map(
    (storeDataField) => _.merge(formValues, storeDataField) as Partial<Store>,
  );
