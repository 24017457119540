import { Platform, PlatformStore } from '@/@types/platform';
import { dataService } from '@/services';
import { PlatformService } from '@/services/@types/platform';

export type GetPlatforms = {
  input: void;
  output: Platform[];
};

export const getPlatforms = async () => {
  const { data } =
    await dataService.get<PlatformService.Platform[]>('/platforms');
  return data;
};

type SyncPlatformStoreIdInput = {
  extRef: string;
  brandKey: string;
  platformApiKey: string;
};

export type SyncPlatformStoreId = {
  input: SyncPlatformStoreIdInput;
  output: PlatformStore;
};

export const syncPlatformStoreId = async (
  input: SyncPlatformStoreId['input'],
): Promise<SyncPlatformStoreId['output']> => {
  const { brandKey, extRef, platformApiKey } = input;
  const { data } = await dataService.get<PlatformService.PlatformStore>(
    `/stores/platform_store/extref/${extRef}/brand/${brandKey}/platform_api/${platformApiKey}`,
  );

  return { platformStoreId: data.platform_store_id };
};
