import { adapter, permissionAdapter } from './slice';
import { StoreState } from '../..';

export const getRoles = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.role.roles,
).selectAll;

export const getRoleEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.role.roles,
).selectEntities;

export const getPermissions = permissionAdapter.getSelectors<StoreState>(
  (stt) => stt.entities.role.permissions,
).selectAll;

export const getPermissionEntities = permissionAdapter.getSelectors<StoreState>(
  (stt) => stt.entities.role.permissions,
).selectEntities;
