import { Button, DataTable } from '@kea-inc/parrot-ui';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import { useMemo } from 'react';

import { MetricsData } from '@/@types/metrics';
import { FlexGrow } from '@/components/FlexGrow';
import { useStorageState } from '@/hooks/useStorageState';

import * as S from './styles';
import { useBrandsColumnDefs } from './useBrandsColumnDefs';
import { reduceBrands } from '../../utils';

const HIDDEN_ROWS_KEY = '@kea-inc/ops-console/hidden-brands';

interface BrandsTableProps {
  metrics: MetricsData;
}

export function BrandsTable(props: BrandsTableProps) {
  const { metrics } = props;
  const [open, setOpen] = useStorageState('brandsTableOpen', false);

  const [hiddenRows, setHiddenRows] = useStorageState<string[]>(
    HIDDEN_ROWS_KEY,
    [],
  );

  const { columns } = useBrandsColumnDefs({
    onHideRow: (name) => setHiddenRows([...hiddenRows, name]),
  });

  const reducedBrands = useMemo(
    () =>
      reduceBrands(metrics).filter(
        (brand) => hiddenRows.indexOf(brand.name) === -1,
      ),
    [metrics, hiddenRows],
  );

  return (
    <S.Card $open={open}>
      <S.CardHeader onClick={() => setOpen(!open)}>
        <S.CardTitle>Brands</S.CardTitle>
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        <FlexGrow />
        {open ? (
          <Button
            variant="ghost"
            onClick={(evt) => {
              evt.stopPropagation();
              setHiddenRows([]);
            }}
          >
            Show all brands
          </Button>
        ) : null}
      </S.CardHeader>
      <S.CardContent $open={open}>
        <S.Collapsible open={open} onOpenChange={setOpen}>
          <S.CollapsibleContent>
            <DataTable columns={columns} data={reducedBrands} stickyHeader />
          </S.CollapsibleContent>
        </S.Collapsible>
      </S.CardContent>
    </S.Card>
  );
}
