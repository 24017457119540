import {
  FormField,
  Select,
  FormItemInput,
  FormItemSelect,
  FormItemTextArea,
} from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { AgentSchema } from '@/pages/Agents/AgentForm/schema/schema';

import * as S from './styles';
import { useConnector } from '../../connector';

interface AgentInfoProps {
  form: UseFormReturn<AgentSchema>;
  id: HTMLDivElement['id'];
}

export function AgentInfo(props: AgentInfoProps) {
  const { form, id } = props;

  const { selectors } = useConnector();
  const { bpos, worksites } = selectors;

  return (
    <S.SectionContainer id={id}>
      <S.SectionTitle>Agent info</S.SectionTitle>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItemInput field={field} label="First name" />
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItemInput field={field} label="Last name" />
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => <FormItemInput field={field} label="Email" />}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItemInput field={field} label="Username" />
          )}
        />
        <FormField
          control={form.control}
          name="bpoId"
          render={({ field }) => (
            <FormItemSelect field={field} label="BPO">
              {bpos.map((bpo) => (
                <Select.Item value={bpo.id} key={bpo.id}>
                  {bpo.name}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
        <FormField
          control={form.control}
          name="workSiteId"
          render={({ field }) => (
            <FormItemSelect field={field} label="Worksite">
              {worksites.map((worksite) => (
                <Select.Item value={worksite.id} key={worksite.id}>
                  {worksite.name}
                </Select.Item>
              ))}
            </FormItemSelect>
          )}
        />
      </S.FormFieldContainer>
      <S.FormFieldContainer>
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItemTextArea field={field} label="Notes" />
          )}
        />
      </S.FormFieldContainer>
    </S.SectionContainer>
  );
}
