import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Form,
  FormField,
  Select,
  FormItemInput,
  Typography,
  FormItemSelect,
  FormItemCombobox,
} from '@kea-inc/parrot-ui';
import { UseFormReturn } from 'react-hook-form';

import { TIMEZONES } from '@/constants/timezones';

import { useConnector } from './connector';
import * as S from './styles';
import { StoreFilterSchema } from '../schema';

interface FilterProps extends DialogProps {
  onSubmit: (values: StoreFilterSchema) => void;
  form: UseFormReturn<StoreFilterSchema>;
}

export function FilterDialog(props: FilterProps) {
  const { onSubmit, form } = props;

  const { selectors } = useConnector();

  const { brands, fleets, fetching } = selectors;

  return (
    <Dialog {...props}>
      <S.DialogContent>
        <DialogHeader>
          <DialogTitle>Stores filter</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="storeId"
                render={({ field }) => (
                  <FormItemInput field={field} label="Store ID" />
                )}
              />
              <FormField
                control={form.control}
                name="franchiseStoreNumber"
                render={({ field }) => (
                  <FormItemInput field={field} label="Store number" />
                )}
              />
              <FormField
                control={form.control}
                name="brandId"
                render={({ field }) => (
                  <FormItemCombobox
                    label="Brand"
                    placeholder="Select a brand"
                    field={field}
                    popoverContentProps={{ align: 'start' }}
                    items={brands}
                    emptyLabel="No brands found."
                    getItemLabel={(brand) => brand?.name}
                    renderItem={(brand) => (
                      <S.ComboboxItem key={brand.id}>
                        <Typography>{brand.name}</Typography>
                      </S.ComboboxItem>
                    )}
                  />
                )}
              />
              <FormField
                control={form.control}
                name="primaryPhone"
                render={({ field }) => (
                  <FormItemInput field={field} label="Primary number" />
                )}
              />
              <FormField
                control={form.control}
                name="secondaryPhone"
                render={({ field }) => (
                  <FormItemInput field={field} label="Secondary number" />
                )}
              />
            </S.FieldItemsContainer>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="twilioNumber"
                render={({ field }) => (
                  <FormItemInput field={field} label="Twilio number" />
                )}
              />
              <FormField
                control={form.control}
                name="location"
                render={({ field }) => (
                  <FormItemInput field={field} label="Location" />
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItemInput field={field} label="Address" />
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItemInput field={field} label="City" />
                )}
              />
              <FormField
                control={form.control}
                name="area"
                render={({ field }) => (
                  <FormItemInput field={field} label="Area" />
                )}
              />
            </S.FieldItemsContainer>
            <S.FieldItemsContainer>
              <FormField
                control={form.control}
                name="fleetId"
                render={({ field }) => (
                  <FormItemSelect label="Fleet" field={field}>
                    {fleets.map((fleet) => (
                      <Select.Item value={fleet.id} key={fleet.id}>
                        {fleet.name}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
              <FormField
                control={form.control}
                name="isOn"
                render={({ field }) => (
                  <FormItemSelect label="Is on" field={field}>
                    {['ON', 'OFF'].map((onOff) => (
                      <Select.Item value={onOff} key={onOff}>
                        {onOff}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
              <FormField
                control={form.control}
                name="timezone"
                render={({ field }) => (
                  <FormItemSelect label="Timezone" field={field}>
                    {Object.entries(TIMEZONES).map(([key, value]) => (
                      <Select.Item value={key} key={key}>
                        {`${value} (${key})`}
                      </Select.Item>
                    ))}
                  </FormItemSelect>
                )}
              />
            </S.FieldItemsContainer>
            <DialogFooter>
              <Button type="submit" loading={fetching}>
                Apply
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </S.DialogContent>
    </Dialog>
  );
}
