import { useNotification } from '@/hooks/useNotification';
import { getLastModifiedISO } from '@/utils/getLastModifiedISO';
import { isCSVFileValid } from '@/utils/isCSVFileValid';

import * as S from './styles';

interface UploadCSVProps {
  csvFile: File | null;
  onUpload: (file: File | null) => void;
}

export function UploadCSV(props: UploadCSVProps) {
  const { csvFile, onUpload } = props;

  const notification = useNotification();

  const onFileDrop = (files: File[]) => {
    const file = files[0];

    if (isCSVFileValid(file)) {
      onUpload(file);
    } else {
      notification.error('Invalid file type', {
        description: 'Please upload a CSV file.',
      });
    }
  };

  return (
    <S.Container>
      <S.CSVContainer>
        {csvFile ? (
          <S.CSVFile
            mime="text/plain"
            name={csvFile.name}
            size={csvFile.size}
            createdAtISO={getLastModifiedISO(csvFile.lastModified)}
            onDelete={() => onUpload(null)}
          />
        ) : (
          <S.CSVFileDropzone
            onDrop={onFileDrop}
            accept={{
              'text/plain': ['.csv'],
              'application/vnd.ms-excel': ['.csv'],
            }}
          />
        )}
      </S.CSVContainer>
    </S.Container>
  );
}
