import * as z from 'zod';

import { Fleet } from '@/@types/fleet';

type SchemaObjShape = Record<
  keyof Pick<Fleet, 'name' | 'accountManager' | 'accountManagerEmail'>,
  z.ZodTypeAny
>;

export const fleetSchema = z.object({
  name: z.string(),
  accountManager: z.string(),
  accountManagerEmail: z
    .string()
    .email('This is not a valid email.')
    .refine((aME) => aME.includes('@kea.cloud'), {
      message: `Please use user's @kea.cloud email address`,
    }),
} satisfies SchemaObjShape);

export type FleetSchema = z.infer<typeof fleetSchema>;
