export const IBM_VOICES = [
  'ar-AR_OmarVoice',
  'ar-MS_OmarVoice',
  'cs-CZ_AlenaVoice',
  'de-DE_BirgitVoice',
  'de-DE_BirgitV3Voice',
  'de-DE_DieterVoice',
  'de-DE_DieterV3Voice',
  'de-DE_ErikaV3Voice',
  'en-AU_CraigVoice',
  'en-AU_MadisonVoice',
  'en-AU_SteveVoice',
  'en-GB_CharlotteV3Voice',
  'en-GB_JamesV3Voice',
  'en-GB_KateVoice',
  'en-GB_KateV3Voice',
  'en-US_AllisonVoice',
  'en-US_AllisonV3Voice',
  'en-US_EmilyV3Voice',
  'en-US_HenryV3Voice',
  'en-US_KevinV3Voice',
  'en-US_LisaVoice',
  'en-US_LisaV3Voice',
  'en-US_MichaelVoice',
  'en-US_MichaelV3Voice',
  'en-US_OliviaV3Voice',
  'es-ES_EnriqueVoice',
  'es-ES_EnriqueV3Voice',
  'es-ES_LauraVoice',
  'es-ES_LauraV3Voice',
  'es-LA_SofiaVoice',
  'es-LA_SofiaV3Voice',
  'es-US_SofiaVoice',
  'es-US_SofiaV3Voice',
  'fr-CA_LouiseV3Voice',
  'fr-FR_NicolasV3Voice',
  'fr-FR_ReneeVoice',
  'fr-FR_ReneeV3Voice',
  'it-IT_FrancescaVoice',
  'it-IT_FrancescaV3Voice',
  'ja-JP_EmiVoice',
  'ja-JP_EmiV3Voice',
  'ko-KR_HyunjunVoice',
  'ko-KR_SiWooVoice',
  'ko-KR_YoungmiVoice',
  'ko-KR_YunaVoice',
  'nl-BE_AdeleVoice',
  'nl-BE_BramVoice',
  'nl-NL_EmmaVoice',
  'nl-NL_LiamVoice',
  'pt-BR_IsabelaVoice',
  'pt-BR_IsabelaV3Voice',
  'sv-SE_IngridVoice',
  'zh-CN_LiNaVoice',
  'zh-CN_WangWeiVoice',
  'zh-CN_ZhangJingVoice',
];

export const AMAZON_VOICES = [
  'Polly.Zeina',
  'Polly.Zhiyu',
  'Polly.Naja',
  'Polly.Mads',
  'Polly.Lotte',
  'Polly.Ruben',
  'Polly.Nicole',
  'Polly.Olivia',
  'Polly.Russell',
  'Polly.Amy',
  'Polly.Emma',
  'Polly.Brian',
  'Polly.Aditi',
  'Polly.Raveena',
  'Polly.Aria',
  'Polly.Ayanda',
  'Polly.Ivy',
  'Polly.Joanna',
  'Polly.Kendra',
  'Polly.Kimberly',
  'Polly.Salli',
  'Polly.Joey',
  'Polly.Justin',
  'Polly.Kevin',
  'Polly.Matthew',
  'Polly.Matthew-Neural',
  'Polly.Geraint',
  'Polly.Céline/Celine',
  'Polly.Léa',
  'Polly.Mathieu',
  'Polly.Chantal',
  'Polly.Gabrielle',
  'Polly.Marlene',
  'Polly.Vicki',
  'Polly.Hans',
  'Polly.Dóra/Dora',
  'Polly.Karl',
  'Polly.Carla',
  'Polly.Bianca',
  'Polly.Giorgio',
  'Polly.Mizuki',
  'Polly.Takumi',
  'Polly.Seoyeon',
  'Polly.Liv',
  'Polly.Ewa',
  'Polly.Maja',
  'Polly.Jacek',
  'Polly.Jan',
  'Polly.Camila',
  'Polly.Vitória/Vitoria',
  'Polly.Ricardo',
  'Polly.Inês/Ines',
  'Polly.Cristiano',
  'Polly.Carmen',
  'Polly.Tatyana',
  'Polly.Maxim',
  'Polly.Conchita',
  'Polly.Lucia',
  'Polly.Enrique',
  'Polly.Mia',
  'Polly.Lupe',
  'Polly.Penélope/Penelope',
  'Polly.Miguel',
  'Polly.Astrid',
  'Polly.Filiz',
  'Polly.Gwyneth',
];

export const ELEVENLABS_VOICES = [
  'Elevenlabs.Mark',
  'Elevenlabs.Rachel',
  'Elevenlabs.Drew',
  'Elevenlabs.Clyde',
  'Elevenlabs.Paul',
  'Elevenlabs.Domi',
  'Elevenlabs.Dave',
  'Elevenlabs.Fin',
  'Elevenlabs.Sarah',
  'Elevenlabs.Antoni',
  'Elevenlabs.Thomas',
  'Elevenlabs.Charlie',
  'Elevenlabs.George',
  'Elevenlabs.Emily',
  'Elevenlabs.Elli',
  'Elevenlabs.Callum',
  'Elevenlabs.Patrick',
  'Elevenlabs.Harry',
  'Elevenlabs.Liam',
  'Elevenlabs.Dorothy',
  'Elevenlabs.Josh',
  'Elevenlabs.Arnold',
  'Elevenlabs.Charlotte',
  'Elevenlabs.Alice',
  'Elevenlabs.Matilda',
  'Elevenlabs.James',
  'Elevenlabs.Joseph',
  'Elevenlabs.Jeremy',
  'Elevenlabs.Michael',
  'Elevenlabs.Ethan',
  'Elevenlabs.Chris',
  'Elevenlabs.Gigi',
  'Elevenlabs.Freya',
  'Elevenlabs.Brian',
  'Elevenlabs.Grace',
  'Elevenlabs.Daniel',
  'Elevenlabs.Lily',
  'Elevenlabs.Serena',
  'Elevenlabs.Adam',
  'Elevenlabs.Nicole',
  'Elevenlabs.Bill',
  'Elevenlabs.Jessie',
  'Elevenlabs.Sam',
  'Elevenlabs.Glinda',
  'Elevenlabs.Giovanni',
  'Elevenlabs.Mimi',
  'Elevenlabs.Christopher',
];
