import { AxiosError } from 'axios';
import decamelizeKeys from 'decamelize-keys';

import { StorePromo } from '@/@types/store';
import { StoreService } from '@/services/@types/store';
import { dataService } from '@/services/clients';
import { mapStorePromoToApi } from '@/services/useCases/store/adapters/api/promos';
import { mapStorePromoToDomain } from '@/services/useCases/store/adapters/domain';
import { WithSyncPayload } from '@/utils/methods';

type GetPromosInput = {
  storeId: string;
};
type GetPromosOutput = {
  promos: StorePromo[];
  storeId: string;
};

export type GetPromos = {
  input: GetPromosInput;
  output: GetPromosOutput;
};

export async function getPromos(
  input: GetPromos['input'],
): Promise<GetPromos['output']> {
  try {
    const { data } = await dataService.get<StoreService.StorePromo[]>(
      `/stores/${input.storeId}/promos`,
      {
        version: 'v3',
      },
    );
    return { promos: data.map(mapStorePromoToDomain), storeId: input.storeId };
  } catch (e) {
    const axiosError = e as AxiosError;
    if (axiosError.response?.status === 404) {
      return { promos: [], storeId: input.storeId };
    }
    throw axiosError;
  }
}

type SyncStorePromosInput = {
  promos: WithSyncPayload<StorePromo>;
  storeId: string;
};

export type SyncStorePromos = {
  input: SyncStorePromosInput;
  output: void;
};

export async function syncStorePromos(
  input: SyncStorePromos['input'],
): Promise<SyncStorePromos['output']> {
  const { created, updated, deleted } = input.promos;

  const createdPromises = created.map(({ storeId, ...promo }) => {
    const promoPayload = decamelizeKeys(
      mapStorePromoToApi(promo),
    ) as StoreService.StorePromoPayload;

    return dataService.post(
      `/stores/promos`,
      {
        store_id: storeId ?? input.storeId,
        ...promoPayload,
      },
      {
        version: 'v3',
      },
    );
  });

  const updatedPromises = updated.map(({ id, ...promo }) => {
    const promoPayload = decamelizeKeys(
      mapStorePromoToApi(promo),
    ) as StoreService.StorePromoPayload;

    return dataService.patch(`/stores/promos/${id}`, promoPayload, {
      version: 'v3',
    });
  });

  const deletedPromises = deleted.map(({ id }) => {
    return dataService.delete(`/stores/promos/${id}`, {
      version: 'v3',
    });
  });

  await Promise.all(
    createdPromises.concat(updatedPromises).concat(deletedPromises),
  );
}
