import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/brand';

export const prefix = 'useCases/brand';

export const actions = {
  getBrands: createAsyncThunk(`${prefix}/getBrands`, async (_, thunkApi) => {
    try {
      const result = await api.getBrands();

      return result;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }),
  getBrand: createAsyncThunk(
    `${prefix}/getBrand`,
    async (input: api.GetBrand['input'], thunkApi) => {
      try {
        const result = await api.getBrand(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createBrand: createAsyncThunk(
    `${prefix}/createBrand`,
    async (input: api.CreateBrand['input'], thunkApi) => {
      try {
        const result = await api.createBrand(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateBrand: createAsyncThunk(
    `${prefix}/updateBrand`,
    async (input: api.UpdateBrand['input'], thunkApi) => {
      try {
        const result = await api.updateBrand(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  deleteBrand: createAsyncThunk(
    `${prefix}/deleteBrand`,
    async (input: api.DeleteBrand['input'], thunkApi) => {
      try {
        const result = await api.deleteBrand(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  updateAgentBrands: createAsyncThunk(
    `${prefix}/updateBrands`,
    async (input: api.UpdateBrands['input'], thunkApi) => {
      try {
        const result = await api.updateBrands(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getBrandPromos: createAsyncThunk(
    `${prefix}/getBrandPromos`,
    async (input: api.GetBrandPromos['input'], thunkApi) => {
      try {
        const result = await api.getBrandPromos(input);

        return result;
      } catch (e) {
        return import.meta.env.MODE === 'development'
          ? thunkApi.rejectWithValue(e)
          : [];
      }
    },
  ),
  getBrandUpsells: createAsyncThunk(
    `${prefix}/getBrandUpsells`,
    async (input: api.GetBrandUpsells['input'], thunkApi) => {
      try {
        const result = await api.getBrandUpsells(input);

        return result;
      } catch (e) {
        return import.meta.env.MODE === 'development'
          ? thunkApi.rejectWithValue(e)
          : [];
      }
    },
  ),
  getBrandModels: createAsyncThunk(
    `${prefix}/getBrandModels`,
    async (input: api.GetBrandModels['input'], thunkApi) => {
      try {
        const result = await api.getBrandModels(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getBrandNluModels: createAsyncThunk(
    `${prefix}/getBrandNluModels`,
    async (input: api.GetBrandModels['input'], thunkApi) => {
      try {
        const result = await api.getBrandNluModels(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  syncBrandPromos: createAsyncThunk(
    `${prefix}/syncBrandPromos`,
    async (input: api.SyncBrandPromos['input'], thunkApi) => {
      try {
        const result = await api.syncBrandPromos(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  syncBrandUpsells: createAsyncThunk(
    `${prefix}/syncBrandUpsells`,
    async (input: api.SyncBrandUpsells['input'], thunkApi) => {
      try {
        const result = await api.syncBrandUpsells(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
