import camelcaseKeys from 'camelcase-keys';

import { Account } from '@/@types/account';
import { PaginatedAccounts } from '@/@types/account/paginatedAccount';
import { AccountService } from '@/services/@types/account';

export const mapAccountToDomain = (account: AccountService.Account): Account =>
  camelcaseKeys(account);

export const mapPaginatedAccountsToDomain = (
  serviceAccount: AccountService.PaginatedAccounts,
): PaginatedAccounts => ({
  items: serviceAccount.items,
  totalPages: serviceAccount.total_pages,
  totalRecords: serviceAccount.total_records,
});
