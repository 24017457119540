import { createSlice } from '@reduxjs/toolkit';

import { Token } from '@/@types/training';
import { actions } from '@/store/useCases/training';

export type State = {
  token: Token | null;
};

export const initialState: State = {
  token: null,
};

const slice = createSlice({
  name: 'entities/training',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getDeviceToken.fulfilled, (state, action) => {
      state.token = action.payload;
    });
  },
});

export default slice;
