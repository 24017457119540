import env from '@beam-australia/react-env';

export function safeGetEnvNumber(envName: string, defaultValue: number) {
  const value = env(envName);
  if (!value) {
    return defaultValue;
  }

  const numberValue = Number(value);

  if (Number.isNaN(numberValue)) {
    return defaultValue;
  }

  return numberValue;
}
