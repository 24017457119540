import { Tooltip } from '@kea-inc/parrot-ui';
import { BanIcon } from 'lucide-react';

import { User } from '@/@types/user';
import { useConnector } from '@/pages/Admin/List/connector';
import { USER_ROLE_TO_ICON } from '@/utils/user';

import * as S from './styles';

interface RolesColumnProps {
  user: User;
}

export function RolesColumn(props: RolesColumnProps) {
  const { user } = props;
  const { roles: userRoles } = user;

  const { selectors } = useConnector();
  const { roles } = selectors;

  return (
    <S.RolesContainer>
      {roles.map((role) => {
        const Icon = USER_ROLE_TO_ICON[role.name]?.Icon;
        const userHasRole = !!userRoles.find(
          (userRole) => userRole === role.name,
        );
        return (
          <Tooltip key={role.name} content={role.description}>
            <S.IconButton size="icon" $disabled={!userHasRole}>
              {Icon ? <Icon /> : <BanIcon />}
            </S.IconButton>
          </Tooltip>
        );
      })}
    </S.RolesContainer>
  );
}
