import {
  BookHeadphonesIcon,
  CheckIcon,
  ClipboardListIcon,
  CpuIcon,
  HeadphonesIcon,
  LucideIcon,
  SettingsIcon,
  ShieldIcon,
  UserCheckIcon,
  UserCircleIcon,
  UserIcon,
  Users2Icon,
  UtensilsIcon,
} from 'lucide-react';

import { USER_ROLES, UserRole } from '@/constants/user';

export type UserRoleIcon = {
  name: UserRole;
  Icon: LucideIcon;
};

export const USER_ROLE_TO_ICON: Record<string, UserRoleIcon> = {
  [USER_ROLES.Account_Manager]: {
    name: USER_ROLES.Account_Manager,
    Icon: ClipboardListIcon,
  },
  [USER_ROLES.Administrator]: {
    name: USER_ROLES.Administrator,
    Icon: ShieldIcon,
  },
  [USER_ROLES.Agent]: {
    name: USER_ROLES.Agent,
    Icon: HeadphonesIcon,
  },
  [USER_ROLES.BPO_Manager]: {
    name: USER_ROLES.BPO_Manager,
    Icon: Users2Icon,
  },
  [USER_ROLES.BPO_QC]: {
    name: USER_ROLES.BPO_QC,
    Icon: UserCheckIcon,
  },
  [USER_ROLES.BPO_Supervisor]: {
    name: USER_ROLES.BPO_Supervisor,
    Icon: UserCircleIcon,
  },
  [USER_ROLES.BPO_Trainer]: {
    name: USER_ROLES.BPO_Trainer,
    Icon: BookHeadphonesIcon,
  },
  [USER_ROLES.IT_Administrator]: {
    name: USER_ROLES.IT_Administrator,
    Icon: CpuIcon,
  },
  [USER_ROLES.Operations]: {
    name: USER_ROLES.Operations,
    Icon: SettingsIcon,
  },
  [USER_ROLES.Quality_Control]: {
    name: USER_ROLES.Quality_Control,
    Icon: CheckIcon,
  },
  [USER_ROLES.Restaurant_Operator]: {
    name: USER_ROLES.Restaurant_Operator,
    Icon: UtensilsIcon,
  },
  [USER_ROLES.SuperUserManuallyCreated]: {
    name: USER_ROLES.SuperUserManuallyCreated,
    Icon: UserIcon,
  },
};
