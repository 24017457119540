import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '@kea-inc/parrot-ui';
import { Link, useLocation } from 'react-router-dom';

import { browserRouter } from '@/pages/routes';
import { ROUTES, Route, RoutesType } from '@/routes';

import * as S from './styles';

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const routes =
    browserRouter.routes
      .at(0)
      ?.children?.filter((route) => route.index && route.path !== '/') ?? [];
  return (
    <S.Nav className={className} {...props}>
      <NavigationMenu>
        <NavigationMenuList>
          {routes.map((route) => {
            const routePath = route.path as keyof RoutesType;
            const currentRoute: Route = ROUTES[routePath];
            return (
              <MainNavItem to={route.path ?? ''} key={route.id}>
                {currentRoute.tab}
              </MainNavItem>
            );
          })}
        </NavigationMenuList>
      </NavigationMenu>
    </S.Nav>
  );
}

function MainNavItem({
  className,
  to,
  ...props
}: React.ComponentProps<typeof Link>) {
  const location = useLocation();
  const isActive = location.pathname.includes(to.toString());

  return (
    <NavigationMenuItem>
      <NavigationMenuLink
        active={isActive}
        className={navigationMenuTriggerStyle()}
        asChild
      >
        <S.Link to={to} className={className}>
          {props.children}
        </S.Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  );
}
