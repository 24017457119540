import { FormField, Typography } from '@kea-inc/parrot-ui';
import { CheckIcon } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';

import { UserSchema } from '@/pages/Admin/AdminForm/UserForm/schema';

import * as S from './styles';
import { useConnector } from '../connector';

interface FleetsProps {
  form: UseFormReturn<UserSchema>;
}

export function Fleets(props: FleetsProps) {
  const { form } = props;

  const { selectors } = useConnector();
  const { fleets } = selectors;

  return (
    <S.SectionContainer>
      <Typography>Fleets</Typography>
      <S.SectionContent>
        <FormField
          control={form.control}
          name="fleets"
          render={({ field }) => (
            <>
              {fleets.map((fleet) => {
                const useHasFleet = !!field.value?.find(
                  (userFleet) => userFleet === fleet.id,
                );
                return (
                  <S.IconButton
                    key={fleet.id}
                    $disabled={!useHasFleet}
                    type="button"
                    onClick={() =>
                      field.onChange(
                        useHasFleet
                          ? field.value?.filter(
                              (userFleet) => userFleet !== fleet.id,
                            )
                          : [...field.value, fleet.id],
                      )
                    }
                  >
                    {useHasFleet ? <CheckIcon size={18} /> : null}
                    {fleet.name}
                  </S.IconButton>
                );
              })}
            </>
          )}
        />
      </S.SectionContent>
    </S.SectionContainer>
  );
}
