import { RouteObject } from 'react-router-dom';

import { BulkCreation } from '@/pages/Stores/BulkCreation';
import { StoreForm } from '@/pages/Stores/StoreForm';

import { List } from './List';

export const storesRouter: RouteObject[] = [
  {
    path: '/stores',
    element: <List />,
    index: true,
  },
  {
    path: '/stores/new',
    element: <StoreForm type="create" />,
  },
  {
    path: '/stores/:storeId',
    element: <StoreForm type="update" />,
  },
  {
    path: '/stores/new/bulk',
    element: <BulkCreation />,
  },
];
