import { Typography } from '@kea-inc/parrot-ui';

import { OrderCallFlow } from '@/@types/order/callFlow';
import { getProductsFromEvents } from '@/pages/Orders/OrderInfo/OrderTabs/CallFlow/utils';
import { formatCurrency } from '@/utils/format';

import * as S from './styles';

interface ProductCartProps {
  callFlow: OrderCallFlow[];
  event: OrderCallFlow;
  index: number;
}

export function ProductCart(props: ProductCartProps) {
  const { callFlow, index, event } = props;
  const products = getProductsFromEvents(event, index, callFlow);
  return (
    <S.ProductCardContainer>
      {products.map((product) => (
        <S.ProductContainer key={product.id}>
          <S.ProductContent>
            <Typography>
              {product.quantity}x {product.name}
            </Typography>
            <Typography>
              {formatCurrency(product.totalcost ?? product.basecost)}
            </Typography>
          </S.ProductContent>
          <S.ChoicesContainer>
            {product.choices?.map((choice) => (
              <S.Choice key={choice.id}>
                <Typography muted>
                  - {choice.quantity}x {choice.name} (
                  {formatCurrency(choice.cost * choice.quantity)})
                </Typography>
              </S.Choice>
            ))}
          </S.ChoicesContainer>
        </S.ProductContainer>
      ))}
    </S.ProductCardContainer>
  );
}
