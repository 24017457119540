import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { addLoggerInterceptor } from '../middlewares/logger';
import { addMissingJwtRetry } from '../middlewares/missingJwtRetry';

interface Props {
  defaultVersion: 'v0' | 'v1' | 'v2' | 'v3' | 'v4';
}

interface Options extends AxiosRequestConfig {
  version?: string;
}

const removeVersion = (options: Options) => {
  const reducedOptions = { ...options };
  delete reducedOptions.version;
  return reducedOptions;
};

export function makeClientWithVersion(
  api: AxiosInstance,
  { defaultVersion }: Props,
) {
  function getVersion(options: Options) {
    if (options.version) {
      return options.version;
    }

    return defaultVersion;
  }

  function get<T>(url: string, options: Options = {}) {
    return api.get<T>(`/${getVersion(options)}${url}`, {
      ...removeVersion(options),
    });
  }

  function patch<T, P extends object = object>(
    url: string,
    payload = {} as P,
    options: Options = {},
  ) {
    return api.patch<T>(`/${getVersion(options)}${url}`, payload, {
      ...removeVersion(options),
    });
  }

  function post<T, P extends object = object>(
    url: string,
    payload = {} as P,
    options: Options = {},
  ) {
    return api.post<T>(`/${getVersion(options)}${url}`, payload, {
      ...removeVersion(options),
    });
  }

  function put<T>(url: string, payload = {}, options: Options = {}) {
    return api.put<T>(`/${getVersion(options)}${url}`, payload, {
      ...removeVersion(options),
    });
  }

  function deleteRequest<T>(url: string, options: Options = {}) {
    return api.delete<T>(`/${getVersion(options)}${url}`, {
      ...removeVersion(options),
    });
  }

  addMissingJwtRetry(api);
  addLoggerInterceptor(api);

  return {
    ...api,
    get,
    patch,
    post,
    put,
    delete: deleteRequest,
  };
}
