import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '@/services/useCases/menu';

export const prefix = 'useCases/menu';

export const actions = {
  //Category
  getBrandCategories: createAsyncThunk(
    `${prefix}/getBrandCategories`,
    async (input: api.GetBrandCategories['input'], thunkApi) => {
      try {
        const result = await api.getBrandCategories(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getCategories: createAsyncThunk(
    `${prefix}/getCategories`,
    async (input: api.GetCategories['input'], thunkApi) => {
      try {
        const result = await api.getCategories(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  // Items
  getBrandItems: createAsyncThunk(
    `${prefix}/getBrandItems`,
    async (input: api.GetBrandItems['input'], thunkApi) => {
      try {
        const result = await api.getBrandItems(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getItems: createAsyncThunk(
    `${prefix}/getItems`,
    async (input: api.GetItems['input'], thunkApi) => {
      try {
        const result = await api.getItems(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  // Modifier
  getBrandModifiers: createAsyncThunk(
    `${prefix}/getBrandModifiers`,
    async (input: api.GetBrandModifiers['input'], thunkApi) => {
      try {
        const result = await api.getBrandModifiers(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getModifiers: createAsyncThunk(
    `${prefix}/getModifiers`,
    async (input: api.GetModifiers['input'], thunkApi) => {
      try {
        const result = await api.getModifiers(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  // Option
  getBrandOptions: createAsyncThunk(
    `${prefix}/getBrandOptions`,
    async (input: api.GetBrandOptions['input'], thunkApi) => {
      try {
        const result = await api.getBrandOptions(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getOptions: createAsyncThunk(
    `${prefix}/getOptions`,
    async (input: api.GetOptions['input'], thunkApi) => {
      try {
        const result = await api.getOptions(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getSoundboardEntries: createAsyncThunk(
    `${prefix}/getSoundboardEntries`,
    async (_, thunkApi) => {
      try {
        const result = await api.getSoundboardEntries();

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getBrandSoundboardEntries: createAsyncThunk(
    `${prefix}/getBrandSoundboardEntries`,
    async (input: api.GetBrandSoundboardEntries['input'], thunkApi) => {
      try {
        const result = await api.getBrandSoundboardEntries(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  getStoreSoundboardEntries: createAsyncThunk(
    `${prefix}/getStoreSoundboardEntries`,
    async (input: api.GetStoreSoundboardEntries['input'], thunkApi) => {
      try {
        const result = await api.getStoreSoundboardEntries(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createBrandSoundboardEntries: createAsyncThunk(
    `${prefix}/createBrandSoundboardEntries`,
    async (input: api.CreateBrandSoundboardEntries['input'], thunkApi) => {
      try {
        const result = await api.createBrandSoundboardEntries(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
  createStoreSoundboardEntries: createAsyncThunk(
    `${prefix}/createStoreSoundboardEntries`,
    async (input: api.CreateStoreSoundboardEntries['input'], thunkApi) => {
      try {
        const result = await api.createStoreSoundboardEntries(input);

        return result;
      } catch (e) {
        return thunkApi.rejectWithValue(e);
      }
    },
  ),
} as const;
