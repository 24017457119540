export const TIMEZONES: Record<string, string> = {
  'America/New_York': 'Eastern Time',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Central Time (Saskatchewan)',
  'America/Phoenix': 'Mountain Time (Arizona)',
  'America/Denver': 'Mountain Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/Anchorage': 'Alaska Time',
  'Pacific/Honolulu': 'Hawaii-Aleutian Time',
};
