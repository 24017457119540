import { BadgeProps } from '@kea-inc/parrot-ui';
import { useMemo } from 'react';

import { ReducedAgentsMetrics } from '@/@types/agent';
import { ReducedAgentBrand } from '@/@types/brand';

import * as S from './styles';

interface BrandsColumnProps {
  agent: ReducedAgentsMetrics;
  onNewBrandClick: () => void;
}

const getBrandBadgeVariant = (
  brand: ReducedAgentBrand,
): BadgeProps['variant'] => {
  if (brand.status === 'draft') {
    return 'error';
  }
  if (brand.inCall) {
    return 'success';
  }
};

export function BrandsColumn(props: BrandsColumnProps) {
  const { agent, onNewBrandClick } = props;

  const sortedBrandsByInCall = useMemo(
    () =>
      // _.orderBy(agent.brands, (brand) => brand.inCall, 'desc'),
      agent.brands.sort((b1, b2) => {
        if (b1.inCall) {
          return -1;
        }
        if (b2.inCall) {
          return 1;
        }
        return 0;
      }),
    [agent.brands],
  );

  return (
    <S.Container>
      {sortedBrandsByInCall.map((brand) => (
        <S.Badge key={brand.id} size="sm" variant={getBrandBadgeVariant(brand)}>
          {brand.name}
        </S.Badge>
      ))}
      <S.Badge size="sm" variant="outline" onClick={onNewBrandClick}>
        <S.PencilIcon size={12} />
        Edit
      </S.Badge>
    </S.Container>
  );
}
