import * as ParrotUi from '@kea-inc/parrot-ui';
import { HelpCircle } from 'lucide-react';
import styled, { CSSProperties } from 'styled-components';

export * from '../styles';

export const PlatformStoreContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex: 0.5;
  align-items: flex-end;
`;

export const StoreMenuVersionContainer = styled.div`
  width: 100%;
`;

export const SyncStoreIdButton = styled(ParrotUi.Button)<{
  $alignSelf: CSSProperties['alignSelf'];
}>`
  width: 100px;
  align-self: ${(props) => props.$alignSelf};
`;

export const PhoneInputLabelContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const HelpCircleIcon = styled(HelpCircle).attrs({ size: 16 })``;

export const BuyDIDButton = styled(ParrotUi.Button)<{
  $alignSelf: CSSProperties['alignSelf'];
}>`
  align-self: ${(props) => props.$alignSelf};
  width: 90px;
`;
