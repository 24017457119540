import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { getFilters } from '@/store/ui/order/selectors';

import { adapter, adapterWithFilter } from './slice';
import { StoreState } from '../..';

export const getOrders = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.order.orders,
).selectAll;

export const getOrderEntities = adapter.getSelectors<StoreState>(
  (stt) => stt.entities.order.orders,
).selectEntities;

export const getOrdersWithFilter = adapterWithFilter.getSelectors<StoreState>(
  (stt) => stt.entities.order.ordersWithFilter,
).selectById;

export const getOrdersWithFilters = createDraftSafeSelector(
  getOrderEntities,
  (stt: StoreState) =>
    getOrdersWithFilter(stt, JSON.stringify(getFilters(stt))),
  (orderEntities, ordersWithFilters) =>
    ordersWithFilters?.orderIds.map((orderId) => orderEntities[orderId]),
);
