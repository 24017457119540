import { useAppSelector } from '@/store';
import { selectors as roleSelectors } from '@/store/entities/role';
import { selectors as userSelectors } from '@/store/entities/user';
import {
  actions as userUiActions,
  selectors as userUiSelectors,
} from '@/store/ui/user';
import { actions as roleUcActions } from '@/store/useCases/role';
import {
  actions as userUcActions,
  selectors as userUcSelectors,
} from '@/store/useCases/user';

export const useConnector = () => {
  const selectors = {
    fetching: useAppSelector(userUcSelectors.isUcFetching('getPaginatedUsers')),
    deleting: useAppSelector(userUcSelectors.isUcFetching('deleteUser')),
    users: useAppSelector(userSelectors.getUsers),
    userEntities: useAppSelector(userSelectors.getUserEntities),
    totalRecords: useAppSelector(userUiSelectors.getTotalRecords),
    roles: useAppSelector(roleSelectors.getRoles),
  };

  const actions = {
    getPaginatedUsers: userUcActions.getPaginatedUsers,
    getUser: userUcActions.getUser,
    deleteUser: userUcActions.deleteUser,
    getRoles: roleUcActions.getRoles,
    setTotalRecords: userUiActions.setTotalRecords,
  };

  return { actions, selectors };
};
