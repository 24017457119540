import env from '@beam-australia/react-env';
import { Typography } from '@kea-inc/parrot-ui';
import { useEffect } from 'react';

import * as S from './styles';

const menuToolLink = env('MENU_TOOL');

export function Dashboard() {
  useEffect(() => {
    window.open(menuToolLink);
  }, []);
  return (
    <S.Container>
      <Typography variant="h1">Menu-tool was moved to a new url</Typography>
      <Typography variant="h1">
        Please click{' '}
        <S.Linked to={menuToolLink} target="_blank">
          here
        </S.Linked>
      </Typography>
    </S.Container>
  );
}
