import * as ParrotUi from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export * from '../styles';

export const Navbar = styled.nav`
  border-right: solid 1px hsl(var(--border));
  padding: 1.5rem 1.5rem 0rem 0rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;

export const Tabs = styled(ParrotUi.Tabs)`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`;

export const TabsList = styled(ParrotUi.TabsList)`
  display: flex;
  flex-direction: column;
  width: 7rem;
  height: auto;
  width: 100%;
  align-items: flex-start;
`;

export const TabsTrigger = styled(ParrotUi.TabsTrigger)`
  width: 100%;
  justify-content: flex-start;
`;

export const Container = styled.div`
  padding: 1.5rem;
  flex: 1 1 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(ParrotUi.Typography)``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Input = styled(ParrotUi.Input)`
  width: 250px;
  margin-right: 1rem;
`;

export const DataTableContainer = styled.div`
  flex: 1 1 0px;
  height: calc(100% - 40px);
  overflow: auto;
`;
