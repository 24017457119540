import { RouteObject } from 'react-router-dom';

import { BrandForm } from '@/pages/Brands/BrandForm/BrandForm';

import { List } from './List';

export const brandsRouter: RouteObject[] = [
  {
    path: '/brands',
    element: <List />,
    index: true,
  },
  {
    path: '/brands/new',
    element: <BrandForm type="create" />,
  },
  {
    path: '/brands/:brandId',
    element: <BrandForm type="update" />,
  },
];
