import { soundboardAdapter } from '@/store/entities/menu/slice';

import { StoreState } from '../..';

export const getSoundboardEntries = soundboardAdapter.getSelectors<StoreState>(
  (stt) => stt.entities.menu.soundbarEntries,
).selectAll;

export const getSoundboardEntryEntities =
  soundboardAdapter.getSelectors<StoreState>(
    (stt) => stt.entities.menu.soundbarEntries,
  ).selectEntities;

// Category
export const getCategoryEntities = (state: StoreState) =>
  state.entities.menu.categoryEntities;

// Item
export const getItemEntities = (state: StoreState) =>
  state.entities.menu.itemEntities;

// Modifier
export const getModifierEntities = (state: StoreState) =>
  state.entities.menu.modifierEntities;

// Option
export const getOptionEntities = (state: StoreState) =>
  state.entities.menu.optionEntities;
